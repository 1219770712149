import React from 'react';

import { useParams } from '#hooks/useNavigation';

import ProductIntegrationInfo from '#components/products/PoductIntegrationInfo';

function ProductIntegrations() {
  const urlParams = useParams<{
    productId? : string;
    productIntegrationId? : string;
  }>();

  const parsedProductId = parseInt(urlParams.productId || '0');
  const parsedIntegrationId = parseInt(urlParams.productIntegrationId || '0');
  const productId = isNaN(parsedProductId) ? undefined : parsedProductId;
  const integrationId = isNaN(parsedIntegrationId)
    ? undefined
    : parsedIntegrationId;

  if (!integrationId) return null;

  return (
    <ProductIntegrationInfo
      productId={productId}
      productIntegrationId={integrationId}
    />
  );
}

export default ProductIntegrations;
