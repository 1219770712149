import React from 'react';

import { useSearchParams } from '#hooks/useNavigation';

import Password from '#components/auth/PasswordReset';

function PasswordReset() {
  const [urlParams] = useSearchParams();

  const username = urlParams.get('username') || '';
  const token = urlParams.get('token') || '';

  return (
    <Password
      username={username}
      token={token}
    />
  );
}

export default PasswordReset;
