import React, { useCallback } from 'react';

import { Tax } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.taxes;

interface TaxFormProps {
  tax : Tax;
}

function TaxForm({ tax : fallback } : TaxFormProps) {
  const { state, dispatch, editing } = useForm<Tax>();

  const setName = useCallback((name : string) => {
    dispatch({ name })
  }, [dispatch]);

  const setRate = useCallback((rate : number | null) => {
    dispatch({ rate : (rate ?? 0) / 100 })
  }, [dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `tax-${fallback?.id}`
    : 'tax-new';

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localKeys.labels.name)}
        value={state ? state.name : fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.half}
      />
      <TextInput
        id={`${idPrefix}-rate`}
        label={localize(localKeys.labels.rate)}
        value={(state ? state.rate : fallback.rate) * 100}
        onChange={setRate}
        disabled={!editing}
        inputType={settings.inputType.number}
        inputFormat={settings.inputFormat.float}
        width={settings.dimensions.half}
      />
    </Form>
  );
}

export default TaxForm;
