import React from 'react';

import { useParams } from '#hooks/useNavigation';

import CustomerIndex from '#components/customers/CustomerIndex';
import CustomerInfo from '#components/customers/CustomerInfo';

function Customers() {
  const urlParams = useParams<{ customerId? : string; }>();

  const parsedId = parseInt(urlParams.customerId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !!id
        ? <CustomerInfo customerId={id} />
        : <CustomerIndex />
      }
    </>
  );
}

export default Customers;
