import React, { useCallback, useState, useEffect } from 'react';

import { Engine, Integration } from '#types';

import useIntegrations from '#hooks/useIntegrations';
import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';
import Select from '#materials/Select';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.integrations;

interface IntegrationFormProps {
  id : string;
  integration : Integration;
  formKey? : string;
  onChange : (integration : Integration) => void;
  disabled? : boolean;
}

function IntegrationForm({
  integration,
  formKey,
  onChange,
  disabled,
} : IntegrationFormProps) {
  const { retrieveEngines } = useIntegrations();

  const { dispatch } = useForm({
    init : integration,
    onChange,
    key : formKey,
  });

  const [engines, setEngines] = useState<Engine[]>([]);

  const retrieve = useCallback(async () => {
    const engines = await retrieveEngines();
    if (engines) setEngines(listRecords(engines));
  }, [retrieveEngines]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  function setName(name : string) { dispatch({ name }) };
  function setEngine(engine : Engine | null) {
    dispatch({ engineId : engine ? engine.id : undefined })
  };

  const engine = engines.find(
    engine => engine.id === integration.engineId
  ) ?? null;

  const idPrefix = (integration && integration.id)
    ? `integration-${integration?.id}`
    : `integration-new`

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localKeys.labels.integrationName)}
        value={integration.name}
        onChange={setName}
        disabled={disabled}
        width={settings.dimensions.half}
      />
      <Select
        label={localize(localKeys.labels.engineName)}
        selected={engine}
        options={engines}
        onChange={setEngine}
        search={undefined}
        labelGenerator={(engine : Engine | null) =>
          `${engine?.name}`}
        keyGenerator={(engine : Engine | null) =>
          `${engine?.id}-${engine?.name}`}
        width={settings.dimensions.half}
      />
    </Form>
  );
}

export default IntegrationForm;
