import React, { useCallback } from 'react';

import { Tax } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import useTaxes from '#hooks/useTaxes';

import { settings } from '#materials';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import TaxForm from '#components/taxes/TaxForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.taxes.createTax;
const localeButtonKeys = locale.keys.buttons;

interface CreateTaxProps {
  onSave? : (tax : Tax) => void;
  onCancel? : () => void;
}

function CreateTaxControl({ onSave, onCancel } : CreateTaxProps) {
  const { state : tax, reset } = useFormContext<Tax>();
  const { createTax } = useTaxes();

  const handleSave = useCallback(async () => {
    if (!tax) return;

    const newTax = await createTax(tax);
    if (newTax && onSave) onSave(newTax);
  }, [tax, createTax, onSave]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Section title={localize(localeContentKeys.title)}>
      { !!tax && <TaxForm tax={tax} /> }
      <Button
        onClick={handleSave}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
    </Section>
  );
}

function CreateTax({ onSave, onCancel } : CreateTaxProps) {
  return (
    <FormProvider
      init={{
        name : '',
        rate : 0,
        tagId : '',
        productIds : [],
        availableChannelIds : [],
      }}
      editing={true}
    >
      <CreateTaxControl onSave={onSave} onCancel={onCancel} />
    </FormProvider>
  )
}

export default CreateTax;
