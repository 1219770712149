import React, { useCallback } from 'react';

import { Channel } from '#types';

import useForm from '#hooks/useForm';
import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import {
  Action,
  TableActionCell,
  TableCheckboxCell,
  TableCell,
} from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

export const TABLE_KEYS = {
  id : 'id' as const,
  name : 'name' as const,
  read : 'read' as const,
  write : 'write' as const,
  actions : 'actions' as const,
};
const localTableKeys = locale.keys.tables.channels;

export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface ChannelRowProps {
  channel : Channel;
  tableKeys : TableKeys[];
  generateActions? : (channel : Channel) => React.ReactElement;
  onSave? : () => Promise<void>;
  onCancel? : () => void;
}

function ChannelRow({
  channel : fallback,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <TableCell />,
  onSave = async () => {},
  onCancel = () => {},
} : ChannelRowProps) {
  const { updateChannel } = useIntegrations();

  const { state, dispatch, reset, editing } = useForm<Channel>();
  const channel = state || fallback;

  const handleSave = useCallback(async () => {
    if (!state) return;
    await updateChannel(state);
    await onSave();
    reset();
  }, [state, reset, onSave, updateChannel]);

  const handleCancel = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  function generateEditActions() {
    return (
      <TableActionCell>
        <Action
          label={localize(localTableKeys.actions.save)}
          onClick={() => handleSave()}
        >
          <Icon icon={settings.svgIcons.check} />
        </Action>
        <Action
          label={localize(localTableKeys.actions.cancel)}
          onClick={() => handleCancel()}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.clear} />
        </Action>
      </TableActionCell>
    );
  }

  function rowGenerator(key : string) {
    switch (key) {
      case TABLE_KEYS.id: return (
        <TableCell>{ `# ${channel.id}` }</TableCell>
      );
      case TABLE_KEYS.name: return (
        <TableCell>{ channel.name }</TableCell>
      );
      case TABLE_KEYS.read: return (
        <TableCheckboxCell
          id={`${channel.id}-read`}
          checked={channel.read}
          onChange={() => { dispatch({ read : !channel.read }) }}
          disabled={!editing}
        />
      );
      case TABLE_KEYS.write: return (
        <TableCheckboxCell
          id={`${channel.id}-write`}
          checked={channel.write}
          onChange={() => { dispatch({ write : !channel.write }) }}
          disabled={!editing}
        />
      );
      case TABLE_KEYS.actions:
        return !editing
          ? generateActions(channel)
          : generateEditActions();
      default: return <TableCell></TableCell>;
    }
  }

  return (
    <>
      { tableKeys.map(key =>
        <React.Fragment key={`channel-${channel.id}-${key}`}>
          { rowGenerator(key) }
        </React.Fragment>
      ) }
    </>
  );
}

export default ChannelRow;
