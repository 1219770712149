import React from 'react';

import { useParams } from '#hooks/useNavigation';

import SupplierIndex from '#components/suppliers/SupplierIndex';
import SupplierInfo from '#components/suppliers/SupplierInfo';

function Suppliers() {
  const urlParams = useParams<{ supplierId? : string }>();

  const parsedId = parseInt(urlParams.supplierId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !id
        ? <SupplierIndex />
        : <SupplierInfo supplierId={id} />
      }
    </>
  );
}

export default Suppliers;
