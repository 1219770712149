import React, { useCallback, useState } from 'react';

import Section from '#components/dashboard/Section';
import CreateCard from '#components/cards/CreateCard';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.cards.cardRegistration;

interface CardRegistrationProps {
  customerId : number;
  name : string;
  email : string;
}

function CardRegistration({ customerId, name, email } : CardRegistrationProps) {
  const [completed, setCompleted] = useState(false);

  const handleSave = useCallback(() => {
    setCompleted(true);
  }, [setCompleted]);

  if (!customerId || !name || !email) return (
    <Section title={localize(localeContentKeys.noCustomer)} />
  );

  if (completed) return (
    <Section
      title={localize(localeContentKeys.thanks)}
      text={localize(localeContentKeys.completed)}
    />
  );

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={`${localize(localeContentKeys.hello)} ${name}. ` +
       `${localize(localeContentKeys.text)} (${email}).`}
    >
      <CreateCard
        customerId={customerId}
        email={email}
        onSave={handleSave}
      />
    </Section>
  );
}

export default CardRegistration;
