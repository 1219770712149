import React, { useCallback, useEffect, useState } from 'react';

import { Collection, Product } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useOptions from '#hooks/useOptions';
import useProducts from '#hooks/useProducts';

import Banner from '#materials/Banner';
import Button from '#materials/Button';
import Icon from '#materials/Icon';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import { settings } from '#materials';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ProductTable from '#components/products/ProductTable';
import ProductSearch from '#components/products/ProductSearch';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.tags.tagProducts;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.products;
const localeNotificationKeys = locale.keys.notifications.tags;

interface CollectionProductsProps {
  collection : Collection;
  onUpdate? (collection : Collection) : void;
}

function CollectionProducts(
{
  collection,
  onUpdate
} : CollectionProductsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    addProductToCollection,
    removeProductFromCollection,
    getCollectionProducts,
  } = useOptions();

  const { retrieveProducts } = useProducts();

  const [collectionProducts, setCollectionProducts] = useState<Product[]>([]);
  const [
    availableCollectionProducts,
    setAvailableCollectionProducts
  ] = useState<Product[]>([]);
  const [add, setAdd] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<Product | null>(null);

  const retrieve = useCallback(async () => {
    if (!collection) return;

    const [newCollectionProducts, newProducts] = await Promise.all(
      [
        getCollectionProducts(collection),
        retrieveProducts(),
      ]
    );

    if (!newProducts || !newCollectionProducts) return;
    setCollectionProducts(newCollectionProducts);

    setAvailableCollectionProducts(
      listRecords(newProducts).filter(
        product => product.id && !collection.productIds.includes(product.id)
      )
    );
  }, [getCollectionProducts, collection]);

  const handleAdd = useCallback(() => {
    setAdd(true);
  }, []);

  const handleCancel = useCallback(() => {
    setAdd(false);
  }, []);

  const handleView = useCallback((product : Product) => () => {
    if (!product.id) return;
    navigate(`/products/${product.id}`);
  }, [navigate]);

  const handleAddProduct = useCallback((product : Product) => async () => {
    const success = await addProductToCollection(collection, product);
    if (onUpdate) onUpdate(collection);
    if (success) {
      createNotification({
        key : 'add-product-success',
        message : localize(localeNotificationKeys.add.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'add-product-error',
        message : localize(localeNotificationKeys.add.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [addProductToCollection, collection]);

  const handleInitRemoveProduct = useCallback((product : Product) => () => {
    setRemoveTarget(product);
  }, [setRemoveTarget]);

  const handleCancelRemoveProduct = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemoveProduct = useCallback((product : Product) => async () => {
    const success = await removeProductFromCollection(collection, product);
    if (onUpdate) onUpdate(collection);

    setRemoveTarget(null);
    if (success) {
      createNotification({
        key : 'remove-product-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-product-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [collection, onUpdate, createNotification, removeProductFromCollection]);

  const filterAvailableProducts = useCallback((product : Product) => {
    return !!product.id &&
      collection &&
      !collection.productIds.includes(product.id);
  }, [collection]);

  const generateRemoveActions = useCallback((product : Product) => {
    return (add
      ? <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.removeProduct)}
          onClick={handleInitRemoveProduct(product)}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      </TableActionCell>
      : <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          onClick={handleView(product)}
        >
          <Icon icon={settings.svgIcons.shoppingBag} />
        </Action>
      </TableActionCell>
    )
  }, [add, handleView, handleRemoveProduct]);

  const generateAddActions = useCallback((product : Product) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.addProduct)}
          onClick={handleAddProduct(product)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAddProduct]);

  useEffect(() => {
    if (!collection) return;

    async function fetchProducts() {
      const retrievedProducts = await retrieveProducts();
      if (!retrievedProducts) return;

      if (collection !== undefined) {
        const collectionProducts = listRecords(retrievedProducts).filter(
          product => product.id
            && collection && collection.productIds.includes(product.id)
        );
        setCollectionProducts(collectionProducts);
      }
    }
    fetchProducts();
  }, [collection, retrieveProducts]);

  return (
    <>
      <Segment title={localize(localeContentKeys.title)} />
      { !!removeTarget &&
        <Banner
          icon={<Icon icon={settings.svgIcons.remove} />}
          actions={(
            <>
              <Button
                onClick={handleRemoveProduct(removeTarget)}
              >
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button
                onClick={handleCancelRemoveProduct}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmRemove) }
        </Banner>
      }
      { collectionProducts.length
        ? <ProductTable
          products={collectionProducts}
          generateActions={generateRemoveActions}
        />
        : <Text>{ localize(localeContentKeys.noProducts) }</Text>
      }
      { add
        ? <>
          <Segment title={localize(localeContentKeys.availableProducts)}>
            <ProductSearch
              pageCount={5}
              filter={filterAvailableProducts}
              generateActions={generateAddActions}
            />
            <Button
              colour={settings.colours.button.alert}
              onClick={handleCancel}
            >
              { localize(localeButtonKeys.close) }
            </Button>
          </Segment>
        </>
        : <Button
          onClick={handleAdd}
        >
          { localize(localeButtonKeys.edit) }
        </Button>
      }
    </>
  );
}

export default CollectionProducts;
