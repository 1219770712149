import React from 'react';

import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton, {
  ListItemButtonProps ,
} from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';

import { Link } from '#hooks/useNavigation';

interface ListItemProps {
  href? : string;
  selected? : boolean;
  onClick? : () => void;
  icon? : React.ReactNode;
  button? : React.ReactNode;
  inset? : number;
  children : React.ReactNode;
}

interface ItemProps {
  children : React.ReactNode;
}

function ListItemButton(props: ListItemButtonProps) {
  return (
    <MuiListItemButton
      {...props}
    />
  );
}

function LinkWrapper<C extends React.ElementType>(
  props: ListItemButtonProps<C, { component?: C }>
) {
  return <ListItemButton {...props} />;
};

function ListItem({
  href,
  selected = false,
  onClick,
  icon,
  button,
  inset = 0,
  children,
} : ListItemProps) {

  function ItemWrapper({ children } : ItemProps) {
    if (href) {
      return (
        <LinkWrapper
          selected={selected}
          component={Link}
          to={href}
          disableGutters={!!inset}
          sx={{
            ...(inset > 0 && {
              pl : [(inset + 1) * 2],
            })
          }}
        >
          { children }
        </LinkWrapper>
      )
    } else if (onClick) {
      return (
        <ListItemButton
          selected={selected}
          onClick={onClick}
          disableGutters={!!inset}
          sx={{
            ...(inset > 0 && {
              pl : [(inset + 1) * 2],
            })
          }}
        >
          { children }
        </ListItemButton>
      )
    } else {
      return (
        <MuiListItem
          disableGutters={!!inset}
          sx={{
            ...(inset > 0 && {
              pl : [(inset + 1) * 2],
            })
          }}
        >
          { children }
        </MuiListItem>
      )
    }
  }

  return (
    <ItemWrapper>
      { !!icon &&
        <MuiListItemIcon>
          { icon }
        </MuiListItemIcon>
      }
      <MuiListItemText>
        { children }
      </MuiListItemText>
      { !!button && button }
    </ItemWrapper>
  )
}

export default ListItem;
