import React, { useState, useEffect, useCallback } from 'react';

import { Service } from '#types';

import { FormProvider } from '#context/FormContext';

import useForm from '#hooks/useForm';
import useServices from '#hooks/useServices';

import Section from '#components/dashboard/Section';
import ServiceForm from '#components/services/ServiceForm';

import { settings } from '#materials';
import Button from '#materials/Button';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.services.createService;
const localeButtonKeys = locale.keys.buttons;

interface CreateServiceProps {
  onSave? : (service : Service) => void;
  onCancel? : () => void;
}

interface CreateServiceControlProps extends CreateServiceProps {
  service : Service;
}

function CreateServiceControl({
  service : fallback,
  onCancel,
  onSave,
} : CreateServiceControlProps) {
  const { createService } = useServices();

  const { state : service, reset } = useForm<Service>();

  const [saved, setSaved] = useState(true);
  const [cleared, setCleared] = useState(true);

  useEffect(() => {
    setSaved(false);
    setCleared(false);
  }, [service] );

  const handleSave = useCallback(async () => {
    if (service) {
      const newService = await createService(service);
      if (!newService) return;

      setSaved(true);
      if (onSave) await onSave(newService);
    }
  }, [service, createService, onSave]);

  const handleClear = useCallback(() => {
    reset();
    setCleared(true);
  }, [reset]);

  return (
    <>
      <ServiceForm
        service={service ?? fallback}
      />
      <Button
        onClick={handleSave}
        disabled={saved}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={onCancel}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
      <Button
        onClick={handleClear}
        disabled={cleared}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
    </>
  );
}

function CreateService({ onSave, onCancel } : CreateServiceProps) {
  const { gernerateDefaultService } = useServices();
  const [init] = useState<Service>(gernerateDefaultService());

  return (
    <Section title={localize(localeContentKeys.title)}>
      <FormProvider
        init={init}
        editingInit={true}
      >
        <CreateServiceControl
          service={init}
          onSave={onSave}
          onCancel={onCancel}
        />
      </FormProvider>
    </Section>
  );
}

export default CreateService;
