import React, { useCallback, useEffect, useState } from 'react';

import { RevenueCentre } from '#types';

import useNotifications from '#hooks/useNotifications';
import usePayments from '#hooks/usePayments';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import RevenueCentreTable from '#components/revenueCentres/RevenueCentreTable';
import CreateRevenueCentre
  from '#components/revenueCentres/CreateRevenueCentre';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.revenueCentres.revenueCentreIndex;
const localeTableKeys = locale.keys.tables.revenueCentres;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.revenueCentres;

interface RevenueCentreIndexProps {
  showHead? : boolean;
}

function RevenueCentreIndex({ showHead = true } : RevenueCentreIndexProps) {
  const { createNotification } = useNotifications();
  const {
    refreshRevenueCentres,
    retrieveRevenueCentres,
    deleteRevenueCentre,
  } = usePayments();

  const [revenueCentres, setRevenueCentres] = useState<RevenueCentre[]>([]);
  const [createNew, setCreateNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<RevenueCentre | null>(null);

  const handleRefresh = useCallback(async () => {
    await refreshRevenueCentres();
  }, [refreshRevenueCentres]);

  const handleNew = useCallback(() => {
    setCreateNew(true);
  }, [setCreateNew]);

  const handleSave = useCallback((revenueCentre : RevenueCentre) => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleCancel = useCallback(() => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleInitDelete = useCallback(
    (revenueCentre : RevenueCentre) => () => {
      setDeleteTarget(revenueCentre);
    },
    [setDeleteTarget],
  );

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleConfirmDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteRevenueCentre(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-revenue-centre-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.pointOfSale} />),
        colour : settings.colours.alert.primary,
      })
    } else {
      createNotification({
        key : 'delete-revenue-centre-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.pointOfSale} />),
        colour : settings.colours.alert.alert,
      })
    }
    setDeleteTarget(null);
  }, [deleteTarget, deleteRevenueCentre, createNotification]);

  const generateActions = useCallback((revenueCentre : RevenueCentre) => {
    return (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.view)}`+
            ` ${revenueCentre.id}` }
          href={`/revenue-centres/${revenueCentre.id}`}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.pointOfSale} />
        </Action>
        <Action
          label={`${localize(localeTableKeys.actions.delete)}`+
            ` ${revenueCentre.id}` }
          onClick={handleInitDelete(revenueCentre)}
          disabled={!!deleteTarget}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete} />
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  useEffect(() => {
    const retrieve = async () => {
      const newRevenueCentres = await retrieveRevenueCentres();
      if (newRevenueCentres) {
        setRevenueCentres(listRecords(newRevenueCentres));
      }
    };
    retrieve();
  }, [retrieveRevenueCentres, setRevenueCentres]);

  return (
    <>
      { showHead && (
        <>
          <Section
            title={localize(localeContentKeys.title)}
            text={localize(localeContentKeys.body)}
          >
            <Button
              onClick={handleRefresh}
            >
              { localize(localeButtonKeys.refresh) }
            </Button>
            <Button
              onClick={handleNew}
              disabled={createNew}
            >
              { localize(localeButtonKeys.new) }
            </Button>
          </Section>
          { createNew && (
            <CreateRevenueCentre
              onSave={handleSave}
              onCancel={handleCancel}
            />
          ) }
        </>
      ) }
      <Section
        title={localize(localeContentKeys.index.title)}
      >
        { !!deleteTarget && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.delete} />)}
            actions={(
              <>
                <Button
                  onClick={handleConfirmDelete}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.index.confirmDelete)
              + ` (${deleteTarget.name})` }
          </Banner>
        ) }
        <RevenueCentreTable
          revenueCentres={revenueCentres}
          generateActions={generateActions}
        />
      </Section>
    </>
  );
}

export default RevenueCentreIndex;
