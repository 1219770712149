import React from 'react';

import { useParams } from '#hooks/useNavigation';

import ScheduleIndex from '#components/schedules/ScheduleIndex';
import SchedulingInfo from '#components/schedules/ScheduleInfo';

function Scheduling() {
  const urlParams = useParams<{ scheduleId? : string; }>();

  const parsedId = parseInt(urlParams.scheduleId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !!id
        ?
        <SchedulingInfo scheduleId={id} />
        :
        <ScheduleIndex />
      }
    </>
  );
}

export default Scheduling;
