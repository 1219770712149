import React, { useCallback, useEffect, useState } from 'react';

import { Customer, CustomerIntegration } from '#types';

import useCustomers from '#hooks/useCustomers';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';

import Section from '#components/dashboard/Section';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customerIntegration;
const localeCustomerFormKeys = locale.keys.forms.customer;

interface CustomerIntegrationInfoProps {
  customerId? : number;
  customerIntegrationId? : number;
}

function CustomerIntegrationInfo({
  customerId,
  customerIntegrationId,
} : CustomerIntegrationInfoProps) {
  const { retrieveCustomer } = useCustomers();

  const [customer, setCustomer] = useState<Customer | null>(null);
  const [
    customerIntegration,
    setCustomerIntegration,
  ] = useState<CustomerIntegration | null>(null);

  const retrieve = useCallback(async () => {
    if (!customerId) return;
    const customer = await retrieveCustomer(customerId);
    if (!customer) return;

    setCustomer(customer);
    if (!customer.integrations || !customerIntegrationId ||
      !(customerIntegrationId in customer.integrations)) return;
    setCustomerIntegration(customer.integrations[customerIntegrationId]);
  }, [customerId, customerIntegrationId, retrieveCustomer]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (!!customerIntegration
    ? <Section
      title={customerIntegration ?
        `${localize(localeContentKeys.title)} #${customerIntegration.id}`
        : localize(localeContentKeys.title)
      }
      text={localize(localeContentKeys.text)}
    >
      <Form>
        { customer
          ? <TextInput
            id={`customer-integration-${customerIntegration.id}-defaultname`}
            label={localize(localeCustomerFormKeys.labels.defaultName)}
            value={customer.defaultName ?? ''}
            onChange={() => {}}
            width={settings.dimensions.half}
            disabled
          />
          : <Text>{localize(localeContentKeys.noCustomer)}</Text>
        }
      </Form>
      <Segment>
        <Form>
        { Object.entries(customerIntegration.data).map(([key, value]) => (
          <TextInput
            key={`customer-integration-${customerIntegration.id}-data-${key}`}
            id={`customer-integration-${customerIntegration.id}-data-${key}`}
            label={key}
            value={`${value}`}
            onChange={() => {}}
            width={settings.dimensions.full}
            disabled
          />
        )) }
        </Form>
      </Segment>
    </Section>
    : <Section
      title={localize(localeContentKeys.notFound)}
    >
      <></>
    </Section>
  );
}

export default CustomerIntegrationInfo;
