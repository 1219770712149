import React from 'react';

import { useParams } from '#hooks/useNavigation';

import TagIndex from '#components/tags/TagIndex';
import TagInfo from '#components/tags/TagInfo';

function Tags() {
  const urlParams = useParams<{ tagId? : string; }>();

  const parsedId = parseInt(urlParams.tagId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !id
        ? <TagIndex />
        : <TagInfo tagId={id} />
      }
    </>
  );
}

export default Tags;
