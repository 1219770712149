import React, { useCallback, useEffect, useState } from 'react';

import { ExternalCustomer } from '#types';

import { settings } from '#materials';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.externalCustomers;

export const TABLE_KEYS = {
  externalId : 'externalId' as const,
  name : 'name' as const,
  email : 'email' as const,
  phone : 'phone' as const,
  actions : 'actions' as const,
};
type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultKeys = Object.values(TABLE_KEYS);

interface ExternalCustomerTableProps {
  customerIntegrations : ExternalCustomer[];
  tableKeys? : TableKeys[];
  generateActions? : (customer : ExternalCustomer) => CellElement;
  pageCount? : number;
}

function ExternalCustomerTable({
  customerIntegrations,
  tableKeys = defaultKeys,
  generateActions,
  pageCount = 20,
} : ExternalCustomerTableProps) {
  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const generateHead = useCallback(() => {
    setHead(
      <>
        { tableKeys.map((key) => {
          switch (key) {
            case TABLE_KEYS.externalId:
              return (<TableCell key={key}>
                { localize(localKeys.headings.externalId) }
              </TableCell>);
            case TABLE_KEYS.name:
              return (<TableCell key={key}>
                { localize(localKeys.headings.name) }
              </TableCell>);
            case TABLE_KEYS.email:
              return (<TableCell key={key}>
                { localize(localKeys.headings.email) }
              </TableCell>);
            case TABLE_KEYS.phone:
              return (<TableCell key={key}>
                { localize(localKeys.headings.phone) }
              </TableCell>);
            default: return <TableCell />;
          }
        }) }
      </>
    );
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    setRows(customerIntegrations.map((customerIntegration, i) => {
      return (
        <React.Fragment key={customerIntegration.externalId}>
          { tableKeys.map((key) => {
            switch (key) {
              case TABLE_KEYS.externalId:
                return (<TableCell key={key} font={settings.fonts.mono}>
                  { customerIntegration.externalId }
                </TableCell>);
              case TABLE_KEYS.name:
                return (<TableCell key={key}>
                  { customerIntegration.name }
                </TableCell>);
              case TABLE_KEYS.email:
                return (<TableCell key={key}>
                  { customerIntegration.email }
                </TableCell>);
              case TABLE_KEYS.phone:
                return (<TableCell key={key}>
                  { customerIntegration.phone }
                </TableCell>);
              case TABLE_KEYS.actions:
                return (<React.Fragment key={key}>
                  { generateActions
                    ? generateActions(customerIntegration)
                    : <TableCell /> }
                </React.Fragment>);
              default: return <TableCell />;
            }
          }) }
        </React.Fragment>
      )
    }));
  }, [
    tableKeys,
    customerIntegrations,
    generateActions,
  ]);

  useEffect(() => { generateHead(); }, [generateHead]);
  useEffect(() => { generateRows(); }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={pageCount}
    />
  );
}

export default ExternalCustomerTable;
