import React from 'react';

import {
  createBrowserRouter,
  RouterProvider,
} from '#hooks/useNavigation';

import Dashboard from '#layouts/Dashboard';
import Simple from '#layouts/Simple';

import AddressesPage from '#pages/Addresses';
import CardRegistration from '#pages/CardRegistration';
import CategoriesPage from '#pages/Categories';
import ChannelsPage from '#pages/Channels';
import CustomersPage from '#pages/Customers';
import CustomersIntegrationPage from '#pages/CustomersIntegration';
import TagsPage from '#pages/Tags';
import TaxesPage from '#pages/Taxes';
import TimeSlotPage from '#pages/TimeSlots';
import SchedulesPage from '#pages/Scheduling';
import RoutesPage from '#pages/Routes';
import ServicesPage from '#pages/Services';
import ServiceChannelsPage from '#pages/ServiceChannels';
import SettingsPage from '#pages/Settings';
import SuppliersPage from '#pages/Suppliers';
import LocationsPage from '#pages/Locations';
import Login from '#pages/Login';
import LogsPage from '#pages/Logs';
import OrdersPage from '#pages/Orders';
import OrdersReportPage from '#pages/OrdersReport';
import PasswordReset from '#pages/PasswordReset';
import IntegrationsPage from '#pages/Integrations';
import RevenueCentresPage from '#pages/RevenueCentres';
import ProductsPage from '#pages/Products';
import ProductIntegrationsPage from '#pages/ProductIntegrations';
import AdjustmentsPage from '#pages/Adjustments';

function AppRoutes() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: (<Dashboard />),
      errorElement: (<></>),
      children: [
        {
          path: "/addresses/",
          element: (<AddressesPage />),
        },
        {
          path: '/adjustments/',
          element: (<AdjustmentsPage />)
        },
        {
          path: '/adjustments/:adjustmentId',
          element: (<AdjustmentsPage />)
        },
        {
          path: "/categories/:categoryId?",
          element: (<CategoriesPage />),
        },
        {
          path: "/channels/",
          element: (<ChannelsPage />),
        },
        {
          path: "/customers/:customerId?",
          element: (<CustomersPage />),
        },
        {
          path: "/customers/integration/",
          element: (<CustomersIntegrationPage />),
        },
        {
          path: "/customers/:customerId/integrations/:customerIntegrationId",
          element: (<CustomersIntegrationPage />),
        },
        {
          path: "/integrations/:integrationId?",
          element: (<IntegrationsPage />),
        },
        {
          path: "/locations/:locationId?",
          element: (<LocationsPage />),
        },
        {
          path: "/logs/",
          element: (<LogsPage />),
        },
        {
          path: "/orders/:orderId?",
          element: (<OrdersPage />),
        },
        {
          path: "/orders/report/",
          element: (<OrdersReportPage />),
        },
        {
          path: "/products/:productId?",
          element: (<ProductsPage />),
        },
        {
          path: "/products/:productId/integrations/:productIntegrationId",
          element: (<ProductIntegrationsPage />),
        },
        {
          path: "/revenue-centres/:revenueCentreId?",
          element: (<RevenueCentresPage />),
        },
        {
          path: "/routes/:routeId?",
          element: (<RoutesPage />),
        },
        {
          path: "/routes/:routeId?",
          element: (<RoutesPage />),
        },
        {
          path: "/services/:serviceId?",
          element: (<ServicesPage />),
        },
        {
          path: "/service-channels/:serviceChannelId?",
          element: (<ServiceChannelsPage />),
        },
        {
          path: "/settings/",
          element: (<SettingsPage />),
        },
        {
          path: "/scheduling/:scheduleId?",
          element: (<SchedulesPage />),
        },
        {
          path: "suppliers/:supplierId?",
          element: (<SuppliersPage />),
        },
        {
          path: "/tags/:tagId?",
          element: (<TagsPage />),
        },
        {
          path: "/taxes/:taxId?",
          element: (<TaxesPage />),
        },
        {
          path: "/time-slots/:timeSlotId?",
          element: (<TimeSlotPage />),
        },
      ],
    },
    {
      path: "/",
      element: (<Simple />),
      errorElement: <></>,
      children: [
        {
          path: "/cards/register/",
          element: (<CardRegistration />),
        },
        {
          path: "/login/",
          element: (<Login />),
        },
        {
          path: "/reset-password/",
          element: (<PasswordReset />),
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default AppRoutes;
