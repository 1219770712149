import React, { useCallback, useState } from 'react';

import { Address, Customer } from '#types';

import useNotifications from '#hooks/useNotifications';
import useCustomers from '#hooks/useCustomers';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import AddressTable, { TABLE_KEYS } from '#components/addresses/AddressTable';
import SearchAddress from '#components/addresses/SearchAddress';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customerAddresses;
const localeTableKeys = locale.keys.tables.customers;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.addresses

interface CustomerAddressesProps {
    customer : Customer;
}

function CustomerAddresses({ customer } : CustomerAddressesProps) {
  const { createNotification } = useNotifications();
  const { addAddressToCustomer, removeAddressFromCustomer } = useCustomers();
  const [removeTartget, setRemoveTarget] = useState<Address | null>(null);

  const customerAddresses = customer?.addresses
    ? Object.values(customer?.addresses)
    : [];
  const addressTableLength = customerAddresses.length;

  const handleInitRemove = useCallback((address : Address) => () => {
    setRemoveTarget(address);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemove = useCallback(async () => {
    if (!removeTartget) return;

    const success = await removeAddressFromCustomer(customer, removeTartget);
    if (success) {
      createNotification({
        key : 'remove-address-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.pinDrop} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-address-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.pinDrop} />),
        colour : settings.colours.alert.alert,
      });
    }
    setRemoveTarget(null);
  }, [removeTartget, customer, removeAddressFromCustomer, createNotification]);

  const handleSave = useCallback(async (address : Address | null) => {
    if (!address) return;

    const success = await addAddressToCustomer(customer, address);
    if (success) {
      createNotification({
        key : 'add-address-success',
        message : localize(localeNotificationKeys.add.success),
        icon : (<Icon icon={settings.svgIcons.pinDrop} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'add-address-error',
        message : localize(localeNotificationKeys.add.error),
        icon : (<Icon icon={settings.svgIcons.pinDrop} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [customer, addAddressToCustomer, createNotification]);

  const generateAddressActions = useCallback((address : Address) => {
    return (
      <TableActionCell>
        { !!customer
          ? <Action
            label={
              `${localize(localeTableKeys.actions.removeAddress)} ${address.id}`
            }
            onClick={handleInitRemove(address)}
            disabled={removeTartget !== null}
            colour={settings.colours.button.alert}
          >
            <Icon icon={settings.svgIcons.remove}/>
          </Action>
          : <></>
        }
      </TableActionCell>
    );
  }, [customer, removeTartget, handleInitRemove]);

  return (
    <>
      { customer &&
        <Section
          title={localize(localeContentKeys.title)}
          text={localize(localeContentKeys.text)}
        >
          { removeTartget && (
            <Banner
              icon={(<Icon icon={settings.svgIcons.delete} />)}
              actions={(
                <>
                  <Button onClick={handleRemove}>
                    { localize(localeButtonKeys.remove) }
                  </Button>
                  <Button onClick={handleCancelRemove}>
                    { localize(localeButtonKeys.cancel) }
                  </Button>
                </>
              )}
              colour={settings.colours.alert.alert}
            >
              { localize(localeContentKeys.confirmRemove) }
            </Banner>
          ) }
          <AddressTable
            addresses={customerAddresses}
            count={addressTableLength}
            tableKeys={Object.values(TABLE_KEYS)}
            generateActions={generateAddressActions}
          />
          <SearchAddress
            label={localize(localeContentKeys.searchAddresses)}
            onSave={handleSave}
          />
        </Section>
      }
    </>
  );
}

export default CustomerAddresses;
