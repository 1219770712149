import React, { useCallback, useState } from 'react';

import { Category } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useCategories from '#hooks/useCategories';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import CategoryForm from '#components/categories/CategoryForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.categories.categoryDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.categories;

interface CategoryDetailsProps {
  category : Category;
  onSave? : (category : Category) => void;
}

function CategoryDetailsControl(
  { category : init, onSave } : CategoryDetailsProps
) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();

  const {
    state : category,
    reset,
    editing,
    setEditing,
  } = useFormContext<Category>();
  const { refreshCategory, updateCategory, deleteCategory } = useCategories();

  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!category || !category.id) return;
    refreshCategory(category.id);
  }, [category, refreshCategory]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!category) return;

    const updatedCategory = await updateCategory(category);
    if (updatedCategory) {
      if (onSave) onSave(updatedCategory);
      setEditing(false);
    }
  }, [category, updateCategory, onSave, setEditing]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleDelete = useCallback(async () => {
    if (!category) return;

    const success = await deleteCategory(category);
    if (success) {
      createNotification({
        key : 'delete-category-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : <Icon icon={settings.svgIcons.category}/>,
        colour : settings.colours.alert.primary,
      });
      navigate('/categories/');
    } else {
      createNotification({
        key : 'delete-category-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : <Icon icon={settings.svgIcons.category}/>,
        colour : settings.colours.alert.alert,
      });
    }
  }, [category, deleteCategory, navigate, createNotification]);

  return (
    <>
      { deleting && (
        <Banner
          icon={ <Icon icon={settings.svgIcons.delete} /> }
          actions={(
            <>
              <Button
                onClick={handleDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (category && editing)
        ? <>
          <Button
            onClick={handleSave}
          >
            {localize(localeButtonKeys.save)}
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleRefresh}
          >
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button
            onClick={handleEdit}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <CategoryForm category={category || init} />
    </>
  );
}

function CategoryDetails({ category, onSave } : CategoryDetailsProps) {
  return (
    <FormProvider init={category} editingInit={false}>
      <CategoryDetailsControl category={category} onSave={onSave} />
    </FormProvider>
  );
}

export default CategoryDetails;
