import React from 'react';

import Alert from '@mui/material/Alert';

import {
  AlertColour,
  Dimension,
  convert,
  settings,
  cloneElements,
} from '#materials/types';
import { IconElement } from '#materials/Icon';
import { ButtonElement, isButtonElement } from '#materials/Button';
import { IconButtonElement, isIconButtonElement } from '#materials/IconButton';

import { px } from '#materials';

export type ActionElement = ButtonElement | IconButtonElement;

function isActionElement(
  element : React.ReactNode
) : element is ActionElement {
  return isButtonElement(element) || isIconButtonElement(element);
}

interface BannerProps {
  onClose? : () => void;
  icon? : IconElement;
  actions? : ActionElement | ActionElement[];
  colour? : AlertColour;
  height? : Dimension;
  width? : Dimension;
  ref? : React.RefObject<any>;
  children : React.ReactNode;
}

function Banner({
  onClose,
  colour = settings.colours.alert.secondary,
  icon,
  actions,
  height = settings.sizes.medium,
  width,
  children,
} : BannerProps) {

  const renderedIcon = cloneElements<IconElement>(icon, {
    colour : convert.colours.alertToButton(colour),
    size : settings.sizes.small
  })

  const renderedActions = cloneElements<ButtonElement | IconButtonElement>(
    actions,
    {
      size : settings.sizes.small,
      variant : settings.variants.button.text,
      colour : convert.colours.alertToButton(colour),
    },
    isActionElement,
  )

  return (
    <Alert
      onClose={onClose}
      color={colour}
      icon={renderedIcon}
      action={renderedActions}
      severity='info'
      variant='outlined'
      sx={{
        width : width === settings.dimensions.full ? '100%' : undefined,
        height : height === settings.dimensions.small ? px(2) : undefined,
        mx : [1],
        my : height === settings.dimensions.small ? [0.5] : [0],
        pt : height === settings.dimensions.small ? [0] : undefined,
        color : colour + '.dark',
        border : 1,
        borderColor : colour + '.dark',
        backgroundColor : '#fff',
        '& .MuiAlert-message' : { overflow : 'hidden' },
        '& .MuiAlert-icon' : { color : colour + '.dark' },
      }}
    >
      { children }
    </Alert>
  );
}

export default Banner;
