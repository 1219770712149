import React from 'react';

import AppSettings from '#components/settings/AppSettings';
import UserSettings from '#components/settings/UserSettings';

function SettingsIndex() {
  return (
    <>
      <UserSettings />
      <AppSettings />
    </>
  );
}

export default SettingsIndex;
