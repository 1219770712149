import React, { useCallback, useState } from 'react';

import { Product, Integration, ExternalProduct } from '#types';

import useNotifications from '#hooks/useNotifications';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ExternalProductsSearch from
  '#components/products/ExternalProductsSearch';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productImport;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.productIntegrations;
const localeNotificationKeys = locale.keys.notifications;

interface ImportProductProps {
  onImport? : (product : Product) => void;
  onCancel? : () => void;
}

function ImportProduct({
  onImport,
  onCancel,
} : ImportProductProps) {
  const { createNotification } = useNotifications();
  const { importProduct } = useProducts();

  const [integration, setIntegrations] = useState<Integration | null>(null);

  const handleImport = useCallback(
    async (externalProduct : ExternalProduct) => {
      if (!integration) return;
      const newProduct = await importProduct(integration, externalProduct);
      if (newProduct) {
        createNotification({
          key : 'import-product-success',
          message : localize(localeNotificationKeys.products.imported.success),
          colour : settings.colours.alert.primary,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        })
        if (onImport) onImport(newProduct);
      } else {
        createNotification({
          key : 'import-product-error',
          message : localize(localeNotificationKeys.products.imported.error),
          colour : settings.colours.alert.alert,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        })
      }
    },
    [onImport, integration, importProduct, createNotification],
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const generateActions = useCallback(
    (externalProduct : ExternalProduct) => (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.import)} ` +
          `${externalProduct.externalId}`}
          onClick={() => handleImport(externalProduct)}
        >
          <Icon icon={settings.svgIcons.download} />
        </Action>
      </TableActionCell>
    ),
    [handleImport],
  );

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <ExternalProductsSearch
        generateActions={generateActions}
        setIntegration={setIntegrations}
        onCancel={handleCancel}
      />
    </Section>
  );
}

export default ImportProduct;
