import React, { useCallback, useEffect, useState } from 'react';

import {
  Product,
  Category,
  LineItem,
  DraftCustomOrder,
} from '#types';

import { FormProvider } from '#context/FormContext';

import useForm from '#hooks/useForm';
import useProducts from '#hooks/useProducts';
import useCategories from '#hooks/useCategories';
import useOptions from '#hooks/useOptions';

import { settings } from '#materials';
import Form from '#materials/Form';
import Select from '#materials/Select';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeFormKeys = locale.keys.forms.orders;

interface FormState {
  category? : Category | null;
  product? : Product | null;
}

interface ItemSearchProps extends FormState {
  orders? : DraftCustomOrder[] | null;
  setProduct? : (product : Product | null) => void;
  setCategory? : (category : Category | null) => void;
  setOrders? : (orders : DraftCustomOrder[]) => void;
  setLineItems? : (lineItems : LineItem[]) => void;
}

function ItemSearchControl({
  orders,
  setProduct,
  setCategory,
  setLineItems,
  setOrders,
  ...props
} : ItemSearchProps) {
  const { state, dispatch } = useForm<FormState>();

  const product = state?.product ?? props.product ?? null;
  const category = state?.category ?? props.category ?? null;

  const { products : allProducts } = useProducts();
  const { categories : allCategories } = useCategories();
  const { customOrders : allOrders } = useOptions();
  orders = orders ?? allOrders;

  const [products, setProducts] = useState<Product[]>(
    listRecords(allProducts),
  );
  const [categories, setCategories] = useState<Category[]>(
    listRecords(allCategories),
  );

  const dispatchProduct = useCallback((value : Product | null) => {
    dispatch({ product : value });
    if (setProduct) setProduct(value);
  }, [dispatch, setProduct]);
  const dispatchCategory = useCallback((value : Category | null) => {
    dispatch({ category : value });
    if (setCategory) setCategory(value);
  }, [dispatch, setCategory]);

  useEffect(() => { setProducts(listRecords(allProducts)); }, [allProducts]);
  useEffect(() => {
    setCategories(listRecords(allCategories));
  }, [allCategories]);

  useEffect(() => {
    if (!allProducts) return;
    if (category) {
      const categoryProducts = category.productIds
        .map((id) => allProducts[id])
        .filter((product) => !!product) as Product[];
      setProducts(categoryProducts);
      return;
    }
    setProducts(listRecords(allProducts));
  }, [category, allProducts]);

  useEffect(() => {
    if (!orders) {
      setOrders?.([]);
      return;
    }

    const matchingItems : LineItem[] = [];
    const matchingOrders : DraftCustomOrder[] = [];
    orders.forEach((order) => {
      let matchingOrder = false;
      Object.values(order.lineItems).forEach((lineItem) => {
        if (
          (!product?.id || lineItem.productId === product?.id)
            && (!category || category.productIds.includes(lineItem.productId))
        ) {
          matchingItems.push(lineItem);
          matchingOrder = true;
        }
      });
      Object.values(order.selections).forEach((selection) => {
        if (
          (!product?.id || selection.productId === product?.id)
            && (!category || category.productIds.includes(selection.productId))
        ) {
          const item = Object.values(order.lineItems).find(
            (i) => i.id === selection.lineItemId,
          );
          matchingItems.push({
            customerId : order.customer?.id ?? null,
            serviceChannelId : order.serviceChannel?.id ?? null,
            locationId : order.location?.id ?? null,
            addressId : order.address?.id ?? null,
            timeSlotId : order.timeSlot?.id ?? null,
            timeSlotIteration : order.timeSlotIteration ?? null,
            timeSlotDivision : order.timeSlotDivision ?? null,
            price : {
              amount : 0,
              currencyCode : 'CAD',
              increment : 0,
              calculatedValue : 0,
            },
            ...item,
            productId : selection.productId,
            quantity : selection.quantity,
          });
          matchingOrder = true;
        }
      });
      if (matchingOrder) matchingOrders.push(order);
    });
    setLineItems?.(matchingItems);
    setOrders?.(matchingOrders);
  }, [product, category, orders, setOrders, setLineItems]);

  const error = !!(product?.id && category)
    && !category.productIds.includes(product.id)

  return (
    <>
      <Form>
        <Select
          label={localize(localeFormKeys.labels.category)}
          selected={category}
          onChange={dispatchCategory}
          options={categories}
          errors={error
            ? [localize(localeFormKeys.errors.productCategoryMismatch)]
            : []}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={(category) => category?.name ?? ''}
          keyGenerator={(category) => `${category?.id}`}
          width={settings.dimensions.half}
        />
        <Select
          label={localize(localeFormKeys.labels.product)}
          selected={product}
          onChange={dispatchProduct}
          options={products}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={(product) => product?.name ?? ''}
          keyGenerator={(product) => `${product?.id}`}
          width={settings.dimensions.half}
        />
      </Form>
    </>
  );
}

function ItemSearch(props : ItemSearchProps) {
  return (
    <FormProvider init={props}>
      <ItemSearchControl {...props}/>
    </FormProvider>
  );
}

export default ItemSearch;
