import React from 'react';

import MaterialProviders from '#materials/Provider';
import {
  NotificationProvider,
  AuthProvider,
  IntegrationProvider,
  AreaProvider,
  LocationProvider,
  ServiceChannelProvider,
  ServiceProvider,
  ScheduleProvider,
  RouteProvider,
  RevenueCentreProvider,
  ProductsProvider,
  TagProvider,
  TaxProvider,
  CategoryProvider,
  SupplierProvider,
  CustomersProvider,
  CardsProvider,
  AdjustmentProvider,
  ConditionProvider,
  OrderProvider,
  OptionsProvider,
  SubscriptionsProvider,
} from '@mrktbox/clerk';

interface ProviderProps {
  children? : React.ReactNode;
}

function Providers({ children } : ProviderProps) {
  return (
    <MaterialProviders>
    <NotificationProvider>
    <AuthProvider>
    <IntegrationProvider>
    <AreaProvider>
    <LocationProvider>
    <ServiceChannelProvider>
    <ServiceProvider>
    <ScheduleProvider>
    <RouteProvider>
    <RevenueCentreProvider>
    <ProductsProvider>
    <TagProvider>
    <TaxProvider>
    <CategoryProvider>
    <SupplierProvider>
    <CustomersProvider>
    <CardsProvider>
    <AdjustmentProvider>
    <ConditionProvider>
    <OrderProvider>
    <OptionsProvider>
    <SubscriptionsProvider>
    { children }
    </SubscriptionsProvider>
    </OptionsProvider>
    </OrderProvider>
    </ConditionProvider>
    </AdjustmentProvider>
    </CardsProvider>
    </CustomersProvider>
    </SupplierProvider>
    </CategoryProvider>
    </TaxProvider>
    </TagProvider>
    </ProductsProvider>
    </RevenueCentreProvider>
    </RouteProvider>
    </ScheduleProvider>
    </ServiceProvider>
    </ServiceChannelProvider>
    </LocationProvider>
    </AreaProvider>
    </IntegrationProvider>
    </AuthProvider>
    </NotificationProvider>
    </MaterialProviders>
  );
}

export default Providers;
