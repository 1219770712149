import React from 'react';

import { Outlet } from '#hooks/useNavigation';

import { settings } from '#materials';

import AppView from '#components/dashboard/AppView';
import Workspace from '#components/dashboard/Workspace';
import Toaster from '#components/dashboard/Toaster';

function Simple() {
  return (
    <AppView>
      <Workspace width={settings.dimensions.medium}>
        <Outlet />
      </Workspace>
      <Toaster />
    </AppView>
  );
}

export default Simple;
