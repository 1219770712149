import React from 'react';

import ChannelIndex from '#components/channels/ChannelIndex';

function Channels() {
  return (
    <>
      <ChannelIndex />
    </>
  );
}

export default Channels;
