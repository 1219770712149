import React, { useCallback, useState, useEffect } from 'react';

import { Service, Route } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useRoutes from '#hooks/useRoutes';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import { TableActionCell, Action } from '#materials/TableCell';
import Banner from '#materials/Banner';

import Section from '#components/dashboard/Section';
import RouteTable from '#components/routes/RouteTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeButtonKeys = locale.keys.buttons;
const localeContentKeys = locale.keys.content.services.serviceRoutes;
const localeTableKeys = locale.keys.tables.routes;
const localeNotificationKeys = locale.keys.notifications.routes;

interface ServiceRoutesProps {
  service : Service;
}

function ServiceRoutes({ service } : ServiceRoutesProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    retrieveRoutes,
    addServiceToRoute,
    removeServiceFromRoute,
  } = useRoutes();

  const [serviceRoutes, setServiceRoutes] = useState<Route[]>([]);
  const [availableRoutes, setAvailableRoutes] = useState<Route[]>([]);
  const [edit, setEdit] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<Route | null>(null);

  const handleEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const handleCancel = useCallback(() => {
    setEdit(false);
  }, []);

  const handleView = useCallback((route : Route) => () => {
    navigate(`/routes/${route.id}`);
  }, [navigate]);

  const handleAdd = useCallback((route : Route) => async () => {
    await addServiceToRoute(route, service);
  }, [service, addServiceToRoute]);

  const handleInitRemove = useCallback((route : Route) => () => {
    setRemoveTarget(route);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemoveConfirm = useCallback(async () => {
    if (!removeTarget) return;
    const success = await removeServiceFromRoute(removeTarget, service);
    if (success) {
      createNotification({
        key : 'remove-route-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.route} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-route-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.route} />),
        colour : settings.colours.alert.alert,
      });
    }
    setRemoveTarget(null);
  }, [service, removeTarget, removeServiceFromRoute, createNotification]);

  const generateRemoveActions = useCallback((route : Route) => {
    return (
      <TableActionCell>
        { edit
          ? (<Action
            label={localize(localeTableKeys.actions.remove)}
            onClick={handleInitRemove(route)}
            disabled={!!removeTarget}
            colour={settings.colours.button.alert}
          >
            <Icon icon={settings.svgIcons.remove} />
          </Action>)
          : (<Action
            label={localize(localeTableKeys.actions.view)}
            onClick={handleView(route)}
          >
            <Icon icon={settings.svgIcons.route} />
          </Action>)
        }
      </TableActionCell>
    );
  }, [edit, removeTarget, handleView, handleInitRemove]);

  const generateAddActions = useCallback((route : Route) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.add)}
          onClick={handleAdd(route)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAdd]);

  useEffect(() => {
    if (!service.id) return;

    async function retrieve() {
      const retrievedRoutes = await retrieveRoutes();
      if (!retrievedRoutes) return;

      setServiceRoutes(listRecords(retrievedRoutes).filter(
        route => service.id && route.serviceIds &&
          route.serviceIds.includes(service.id),
      ));
      setAvailableRoutes(listRecords(retrievedRoutes).filter(
        route => service.id && route.serviceIds &&
          !(route.serviceIds.includes(service.id)),
      ));
    }

    retrieve();
  }, [service, retrieveRoutes]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { !!removeTarget && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.remove} />)}
          actions={(
            <>
              <Button
                onClick={handleRemoveConfirm}
              >
                {localize(localeButtonKeys.remove)}
              </Button>
              <Button
                onClick={handleCancelRemove}
              >
                {localize(localeButtonKeys.cancel)}
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmRemove) +
            ` (${removeTarget.name})` }
        </Banner>
      )}
      { serviceRoutes.length
        ? <RouteTable
            routes={serviceRoutes}
            generateActions={generateRemoveActions}
          />
        : <Text>{localize(localeContentKeys.noRoutes)}</Text>
      }
      { edit
        ? <>
          <Segment title={localize(localeContentKeys.availableRoutes)}>
            <RouteTable
              routes={availableRoutes}
              generateActions={generateAddActions}
            />
            <Button
              onClick={handleCancel}
              colour={settings.colours.button.alert}
            >
              {localize(localeButtonKeys.close)}
            </Button>
          </Segment>
        </>
        : <Button
            onClick={handleEdit}
          >
            {localize(localeButtonKeys.edit)}
          </Button>
      }
    </Section>
  );
}

export default ServiceRoutes;
