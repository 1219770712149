import React, { useCallback, useState } from 'react';

import { RevenueCentre } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import usePayments from '#hooks/usePayments';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import RevenueCentreForm from '#components/revenueCentres/RevenueCentreForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys =
  locale.keys.content.revenueCentres.revenueCentreDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.revenueCentres;

interface RevenueCentreDetailsProps {
  revenueCentre : RevenueCentre;
  onSave? : (revenueCentre : RevenueCentre) => void;
}

function RevenueCentreDetailsControl(
  { revenueCentre : init, onSave } : RevenueCentreDetailsProps
) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();

  const {
    state : revenueCentre,
    reset,
    editing,
    setEditing,
  } = useFormContext<RevenueCentre>();
  const {
    refreshRevenueCentre,
    updateRevenueCentre,
    deleteRevenueCentre
  } = usePayments();

  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!revenueCentre || !revenueCentre.id) return;
    refreshRevenueCentre(revenueCentre.id);
  }, [revenueCentre, refreshRevenueCentre]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!revenueCentre) return;

    const updatedRevenueCentre = await updateRevenueCentre(revenueCentre);
    if (updatedRevenueCentre) {
      if (onSave) onSave(updatedRevenueCentre);
      setEditing(false);
    }
  }, [revenueCentre, updateRevenueCentre, setEditing, onSave]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleConfirmDelete = useCallback(async () => {
    if (!revenueCentre) return;
    const success = await deleteRevenueCentre(revenueCentre);
    if (success) {
      createNotification({
        key : 'delete-revenue-centre-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.pointOfSale} />),
        colour : settings.colours.alert.primary,
      })
      navigate('/revenue-centres/');
    } else {
      createNotification({
        key : 'delete-revenue-centre-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.pointOfSale} />),
        colour : settings.colours.alert.alert,
      })
    }
  }, [revenueCentre, deleteRevenueCentre, createNotification, navigate]);

  return (
    <>
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button
                onClick={handleConfirmDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (revenueCentre && editing)
        ? <>
          <Button
            onClick={handleSave}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleRefresh}
            disabled={deleting}
          >
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button
            onClick={handleEdit}
            disabled={deleting}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <RevenueCentreForm revenueCentre={revenueCentre || init} />
    </>
  );
}

function RevenueCentreDetails(
  { revenueCentre, onSave } : RevenueCentreDetailsProps
) {
  return (
    <FormProvider init={revenueCentre} editingInit={false}>
      <RevenueCentreDetailsControl
        revenueCentre={revenueCentre}
        onSave={onSave}
      />
    </FormProvider>
  );
}

export default RevenueCentreDetails;
