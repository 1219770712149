import React, { useCallback } from 'react';

import { Category } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.categories;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface CategoryTableProps {
  categories : Category[];
  tableKeys? : TableKeys[];
  showSubCategories? : boolean;
  generateActions? : (category : Category) => CellElement;
}

function CategoryTable({
  categories,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <></>,
  showSubCategories,
} : CategoryTableProps) {

  const generateHead = useCallback((showSubCategories? : Boolean) => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `category-table-head=${key}`
        switch(key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.id) }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.name) }
            </TableCell>;
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [tableKeys]);

  const generateRow = useCallback((category : Category) => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `category-table-row-${category.id}-${key}`
        switch(key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { `# ${category.id}` }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { category.name }
            </TableCell>;
          case TABLE_KEYS.actions:
            return (
              <React.Fragment key={mapKey}>
                { generateActions(category) }
              </React.Fragment>
            );
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [tableKeys, generateActions]);

  return (
    <Table
      head={generateHead(showSubCategories)}
      rows={categories.map((category) => (
        <React.Fragment key={`category-table-row-${category.id}`}>
          { generateRow(category) }
        </React.Fragment>
      ))}
    />
  );
}

export default CategoryTable;
