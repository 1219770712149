import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import defaults from '../theme.json';

let theme : any;
try { theme = require('#public/theme.json') }
catch (e) { theme = {} }

interface ProviderProps {
  children : React.ReactNode;
}

function Providers({ children } : ProviderProps) {
  const defaultTheme = createTheme({
    ...{ ...defaults, ...theme },
    layout : {
      barHeight : 60,
      drawerWidth : 240,
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        { children }
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default Providers;
