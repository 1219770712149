import React, { useCallback, useEffect, useState } from 'react';

import { Service, ServiceChannel, Location } from '#types';

import useServices from '#hooks/useServices';

import Text from '#materials/Text';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.services.servicesTable;
const localeTableKeys = locale.keys.tables.services;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  channel : 'channel',
  location : 'location',
  leadTime : 'leadTime',
  actions : 'actions',
};
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

const defaultKeys = Object.values(TABLE_KEYS);

interface ServiceTableProps {
  services : Service[];
  tableKeys? : TableKeys[];
  count? : number;
  pageCount? : number;
  generateActions? : (service : Service) => CellElement;
}

function ServiceTable({
  services,
  tableKeys = defaultKeys,
  count,
  pageCount = 20,
  generateActions = () => <TableCell />,
} : ServiceTableProps) {
  const { retrieveServiceChannels, retrieveLocations } = useServices();
  const [
    channels,
    setChannels,
  ] = useState<{ [id : string] : ServiceChannel | null }>({});
  const [
    locations,
    setLocations,
] = useState<{ [id : string] : Location | null }>({});
  const [head, setHead] = useState<React.ReactNode>();
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const retrieve = useCallback(async () => {
    const [retrievedChannels, retrievedLocations] = await Promise.all([
      retrieveServiceChannels(),
      retrieveLocations(),
    ]);
    if (retrievedChannels) setChannels(retrievedChannels);
    if (retrievedLocations) setLocations(retrievedLocations);
  }, [retrieveServiceChannels, retrieveLocations, setChannels, setLocations]);

  const generateHead = useCallback(() => {
    return (
      <>
        { tableKeys.map((key : TableKeys) => {
          switch (key) {
            case TABLE_KEYS.id:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.id) }
              </TableCell>);
            case TABLE_KEYS.name:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.name) }
              </TableCell>);
            case TABLE_KEYS.channel:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.serviceChannel) }
              </TableCell>);
            case TABLE_KEYS.location:
              return( <TableCell key={key}>
                { localize(localeTableKeys.headings.location) }
              </TableCell>);
            default: return (<TableCell key={key} />);
          }
        }) }
      </>
    )
  }, [tableKeys]);

  const generateRows = useCallback((service : Service) => {
    const channel = service.serviceChannelId
      ? (channels[service.serviceChannelId] ?? null)
      : null;
    const location = service.locationId
      ? (locations[service.locationId] ?? null)
      : null;

    return (
      <>
        { tableKeys.map((key : TableKeys) => {
          switch (key) {
            case TABLE_KEYS.id :
              return (<TableCell key={key}>
                { `# ${service.id}` }
              </TableCell>);
            case TABLE_KEYS.name :
              return (<TableCell key={key}>
                { service.name }
              </TableCell>);
            case TABLE_KEYS.channel :
              return (<TableCell key={key}>
                { channel?.name }
              </TableCell>);
            case TABLE_KEYS.location :
              return (<TableCell key={key}>
                { location?.name }
              </TableCell>);
            case TABLE_KEYS.actions:
              return (
                <React.Fragment key={key}>
                  { generateActions(service) }
                </React.Fragment>
              );
            default: return <TableCell />;
          }
        }) }
      </>
    )
  }, [tableKeys, generateActions, channels, locations]);

  useEffect(() => { retrieve(); }, [retrieve]);

  useEffect(() => { setHead(generateHead()); }, [generateHead]);
  useEffect(() => {
    setRows(services.map((service) => (
      <React.Fragment key={`service-${service.id}`}>
        { generateRows(service) }
      </React.Fragment>
    )));
  }, [services, generateRows]);

  return rows.length > 0
    ? (<Table
      head={head}
      rows={rows}
      pageCount={pageCount}
    />)
    : (<Text>{ localize(localeContentKeys.notFound) }</Text>
  );
}

export default ServiceTable;
