import React from 'react';

import { Schedule } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.schedules;

interface ScheduleFormProps {
  schedule : Schedule;
  onChange? : (schedule : Schedule) => void;
}

function ScheduleForm({ schedule: fallback, onChange } : ScheduleFormProps) {

  const { state, dispatch, editing } = useForm<Schedule>({ onChange });

  function setName(name : string) { dispatch({ name }) };

  const idPrefix = (fallback && fallback.id)
    ? `schedule-${fallback?.id}`
    : 'schedule-new';

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localKeys.labels.name)}
        value={state?.name ?? fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.full}
      />
    </Form>
  );
}

export default ScheduleForm;
