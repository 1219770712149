import React, { useEffect, useState } from 'react';

import { Category, Tag } from '#types';

import useCategories from '#hooks/useCategories';
import useTags from '#hooks/useTags';

import Section from '#components/dashboard/Section';
import CategoryDetails from '#components/categories/CategoryDetails';
import CategoryImages from '#components/categories/CategoryImages';
import CategorySubCategories from
  '#components/categories/CategorySubCategories';
import TagProducts from '#components/tags/TagProducts';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.categories.categoryDetails;

interface CategoryInfoProps {
  categoryId : number;
}

function CategoryInfo({ categoryId } : CategoryInfoProps) {
  const { retrieveCategory } = useCategories();
  const { retrieveTag } = useTags();

  const [category, setCategory] = useState<Category | null>(null);
  const [tag, setTag] = useState<Tag | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function retrieve() {
      const newCategory = await retrieveCategory(categoryId);
      if (newCategory) setCategory(newCategory);

      const newTag = await retrieveTag(categoryId);
      if (newTag) setTag(newTag);
      setLoaded(true);
    }
    retrieve();
  }, [categoryId, retrieveCategory, retrieveTag]);

  if (!loaded) return <></>;
  if (!category || !tag) return (
    <Section title={localize(localeContentKeys.notFound)}> </Section>
  );

  return (
    <>
      <Section
        title={`${category.name} (#${category.id})`}
        text={localize(localeContentKeys.text)}
      >
        <CategoryDetails category={category} />
      </Section>
      <CategoryImages category={category} />
      <CategorySubCategories category={category} />
      <TagProducts tag={tag} />
    </>
  );
}

export default CategoryInfo;
