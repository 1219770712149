import React, { useCallback, useEffect, useState } from 'react';

import { Schedule } from '#types';

import useTimeSlots from '#hooks/useTimeSlots';

import Section from '#components/dashboard/Section';
import ScheduleDetailsForm from '#components/schedules/ScheduleDetails';
import ScheduleTimeslots from '#components/schedules/ScheduleTimeslots';

interface ScheduleInfoProps {
  scheduleId : number;
}

function ScheduleInfo({ scheduleId } : ScheduleInfoProps) {
  const { retrieveSchedule } = useTimeSlots();

  const [schedule, setSchedule] = useState<Schedule | null>(null);

  const retrieve = useCallback(async () => {
    const schedule = await retrieveSchedule(scheduleId);
    setSchedule(schedule);
  }, [scheduleId, retrieveSchedule, setSchedule]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <>
      <Section
        title={`${schedule ? schedule?.name : ''} (#${scheduleId})`}
      >
        { !!schedule &&
          <ScheduleDetailsForm schedule={schedule} />
        }
      </Section>
      { !!schedule &&
        <>
          { !!schedule
            ? <ScheduleTimeslots schedule={schedule} onUpdate={retrieve} />
            : <></>
          }
        </>
      }
    </>
  );
}

export default ScheduleInfo;
