import React, { useCallback } from 'react';

import { Config } from '#types';

import { FormProvider } from '#context/FormContext';

import Table from '#materials/Table';
import {
  CellElement,
  TableCell,
} from '#materials/TableCell';

import ConfigRow, { TABLE_KEYS } from '#components/configs/ConfigRow';

import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.configs;

interface ConfigTableProps {
  configs : Config[];
  generateActions? : (config : Config) => CellElement;
  editing? : number[];
  setEditing? : (editing : number[]) => void;
  onUpdate? : (config : Config) => Promise<void>;
}

function ConfigTable({
  configs,
  generateActions = () => <TableCell />,
  editing = [],
  setEditing = () => {},
  onUpdate = async () => {},
} : ConfigTableProps) {
  const handleSave = useCallback((config : Config) => async () => {
    await onUpdate(config);
    setEditing(editing.filter(id => id !== config.id));
  }, [editing, onUpdate, setEditing])

  const handleCancel = useCallback((config : Config) => () => {
    setEditing(editing.filter((id) => id !== config.id));
  }, [editing, setEditing]);

  function headGenerator(key : string) {
    switch (key) {
      case TABLE_KEYS.id:
        return (
          <TableCell>
            { localize(localeTableKeys.headings.id) }
          </TableCell>
        );
      case TABLE_KEYS.name:
        return (
          <TableCell>
            { localize(localeTableKeys.headings.name) }
          </TableCell>
        );
      case TABLE_KEYS.value:
        return (
          <TableCell>
            { localize(localeTableKeys.headings.value) }
          </TableCell>
        );
      case TABLE_KEYS.actions:
        return (
          <TableCell></TableCell>
        );
      default:
        return (
          <TableCell></TableCell>
        );
    }
  }

  return (
    <Table
      head={Object.values(TABLE_KEYS).map((key) => (
        <React.Fragment key={key}>
          { headGenerator(key) }
        </React.Fragment>
      ))}
      rows={configs.map((config) => (
        <FormProvider
          key={`config-table-row-${config.id}`}
          init={config}
          editing={!!config.id && editing.includes(config.id)}
        >
          <ConfigRow
            config={config}
            tableKeys={Object.values(TABLE_KEYS)}
            generateActions={generateActions}
            onSave={handleSave(config)}
            onCancel={handleCancel(config)}
          />
        </FormProvider>
      ))}
    />
  );
}

export default ConfigTable;
