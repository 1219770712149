import React, { useCallback, useState, useEffect } from 'react';

import { Channel } from '#types';

import useIntegrations from '#hooks/useIntegrations';

import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import ChannelTable from '#components/channels/ChannelTable';

import { settings } from '#materials';
import { Action, TableActionCell } from '#materials/TableCell';
import Icon from '#materials/Icon';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.channelIndex;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.channels;

interface ChannelIndexProps {
  showHead? : boolean;
}

function ChannelIndex({ showHead = true } : ChannelIndexProps) {
  const [channels, setChannels] = useState<Channel[]>([]);

  const {
    retrieveChannels,
   } = useIntegrations();

  const [editing, setEditing] = useState<number[]>([]);

  const retrieve = useCallback(async () => {
    const retrievedChannels = await retrieveChannels();
    if (retrievedChannels) {
      setChannels(listRecords(retrievedChannels))
    }
  }, [retrieveChannels]);

  const handleRefresh = useCallback(async () => {
    await retrieve();
    setEditing([]);
  }, [retrieve])

  const handleUpdate = useCallback(async () => {
    await retrieve();
  }, [retrieve]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  function generateActions(channel : Channel) {
    return (
      <TableActionCell>
        <Action
          label={ localize(localeTableKeys.actions.edit) }
          onClick={() => {
            if (!channel.id) return;
            setEditing([...editing, channel.id])
          }}
        >
          <Icon icon={ settings.svgIcons.edit }/>
        </Action>
      </TableActionCell>
    );
  }

  return (
    <>
      { showHead &&
        <>
          <Section
            title={localize(localeContentKeys.title)}
            text={localize(localeContentKeys.body)}
          >
            <Button
              onClick={handleRefresh}
            >
              { localize(localeButtonKeys.refresh) }
            </Button>
          </Section>
        </>
      }
      <Section
        title={localize(localeContentKeys.index)}
      >
        <ChannelTable
          channels={channels}
          generateActions={generateActions}
          editing={editing}
          setEditing={setEditing}
          onUpdate={handleUpdate}
        />
      </Section>
    </>
  );
}

export default ChannelIndex;
