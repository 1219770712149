import React, { useCallback, useEffect, useState } from 'react';

import { ServiceChannel } from '#types';

import useServices from '#hooks/useServices';

import Section from '#components/dashboard/Section';
import ServiceChannelsDetails
  from '#components/serviceChannels/ServiceChannelDetails';
import ServiceChannelServices
  from '#components/serviceChannels/ServiceChannelServices';
import ServiceChannelTags from '#components/serviceChannels/ServiceChannelTags';

import locale, { localize } from '#utils/locale';

const localeContentKeys =
  (locale.keys.content.serviceChannels.serviceChannelIndex);

interface ServiceChannelInfoProps {
  serviceChannelId : number;
}

function ServiceChannelInfo({ serviceChannelId } : ServiceChannelInfoProps) {
  const { retrieveServiceChannel } = useServices();

  const [
    serviceChannel,
    setServiceChannel
  ] = useState<ServiceChannel | null>(null);

  const retrieve = useCallback(async () => {
    const serviceChannel = await retrieveServiceChannel(serviceChannelId);
    setServiceChannel(serviceChannel);
  },[serviceChannelId,retrieveServiceChannel, setServiceChannel]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <>
      <Section
        title={`${serviceChannel
          ? serviceChannel?.name
          : ''} (#${serviceChannelId})`}
        text={serviceChannel ? '' : localize(localeContentKeys.loading)}
      >
        { !!serviceChannel &&
          <ServiceChannelsDetails serviceChannel={serviceChannel} />
        }
      </Section>
      { !!serviceChannel && (
        <>
          <ServiceChannelServices serviceChannel={serviceChannel} />
          <ServiceChannelTags serviceChannel={serviceChannel} />
        </>
      ) }
    </>
  );
}

export default ServiceChannelInfo;
