import React, { useCallback, useEffect, useState } from 'react';

import { Tag, Product } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useTags from '#hooks/useTags';
import useCategories from '#hooks/useCategories';
import useSuppliers from '#hooks/useSuppliers';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Form from '#materials/Form';
import Icon from '#materials/Icon';
import Text from '#materials/Text';
import Banner from '#materials/Banner';
import { TableActionCell, Action, CellElement } from '#materials/TableCell';
import Button from '#materials/Button';
import TextInput from '#materials/TextInput';

import Section from '#components/dashboard/Section';
import TagTable from '#components/tags/TagTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productTags;
const localeButtonKeys = locale.keys.buttons;
const localeFormKeys = locale.keys.forms.tags;
const localeTableKeys = locale.keys.tables.tags;
const localeNotificationKeys = locale.keys.notifications.tags;

interface ProductTagsProps {
  product : Product;
  tags? : Tag[];
  generateActions? : (tag : Tag) => CellElement;
  onUpdate? (tag : Tag) : void;
  title? : string;
  text? : string;
  confirmRemove? : string;
  notAssigned? : string;
  notFound? : string;
}

function ProductTags({
  product,
  tags : providedTags,
  generateActions,
  onUpdate,
  title = localize(localeContentKeys.title),
  text = localize(localeContentKeys.body),
  confirmRemove = localize(localeContentKeys.confirmRemove),
  notAssigned = localize(localeContentKeys.notAssigned),
  notFound = localize(localeContentKeys.notFound),
} : ProductTagsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    addProductToTag,
    removeProductFromTag,
  } = useTags();
  const { retrieveTags } = useTags();

  const { retrieveCategories } = useCategories();
  const { retrieveSuppliers } = useSuppliers();

  const [tags, setTags] = useState<Tag[]>([]);
  const [
    productTags,
    setProductTags
  ] = useState<Tag[]>([]);
  const [
    availableProductTags,
    setAvailableProductTags
  ] = useState<Tag[]>([]);
  const [query, setQuery] = useState('');
  const [add, setAdd] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<Tag | null>(null);

  const retrieve = useCallback(async () => {
    if (providedTags) {
      setTags(providedTags);
      return;
    }

    const [newCategories, newSuppliers, newTags] = await Promise.all(
      [
        retrieveCategories(),
        retrieveSuppliers(),
        retrieveTags(),
      ]
    );
    if (!newTags || !newCategories || !newSuppliers) return;
    setTags(listRecords(newTags).filter(
      tag => !(listRecords(newCategories).some(
        category => category.id === tag.id) ||
      listRecords(newSuppliers).some(
        supplier => supplier.id === tag.id))
    ));
  }, [
    providedTags,
    retrieveTags,
    retrieveCategories,
    retrieveSuppliers,
  ]);

  const handleAdd = useCallback(() => {
    setAdd(true);
  }, []);

  const handleCancel = useCallback(() => {
    setAdd(false);
  },[]);

  const handleView = useCallback((tag : Tag) => () => {
    navigate(`/tags/${tag.id}`);
  }, [navigate]);

  const handleAddTag = useCallback((tag : Tag) => async () => {
    const success = await addProductToTag(product, tag);
    if (onUpdate) onUpdate(tag);
    if (success) {
      createNotification({
        key : 'add-tag-success',
        message : localize(localeNotificationKeys.add.success),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'add-tag-error',
        message : localize(localeNotificationKeys.add.error),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [product, onUpdate, createNotification, addProductToTag]);

  const handleInitRemoveTag = useCallback((tag : Tag) => () => {
    setRemoveTarget(tag);
  }, [setRemoveTarget]);

  const handleCancelRemoveTag = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemoveTag = useCallback((tag : Tag) => async () => {
    const success = await removeProductFromTag(product, tag);
    if (onUpdate) onUpdate(tag);

    setRemoveTarget(null);
    if (success) {
      createNotification({
        key : 'remove-tag-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-tag-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [product, onUpdate, createNotification, removeProductFromTag]);

  const generateRemoveActions = useCallback((tag : Tag) => {
    return (add
      ? <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.removeTag)}
          onClick={handleInitRemoveTag(tag)}
          colour={settings.colours.button.alert}
          disabled={!!removeTarget}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      </TableActionCell>
      : (generateActions
        ? generateActions(tag)
        : <TableActionCell>
          <Action
            label={localize(localeTableKeys.actions.view)}
            onClick={handleView(tag)}
          >
            <Icon icon={settings.svgIcons.localOffer} />
          </Action>
        </TableActionCell>
      )
    );
  }, [generateActions, add, removeTarget, handleView, handleInitRemoveTag]);

  const generateAddActions = useCallback((tag : Tag) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.addTag)}
          onClick={handleAddTag(tag)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAddTag]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  useEffect(() => {
    if (!tags.length) return;
  }, [tags]);

  useEffect(() => {
    const productId = product.id;
    if (productId === undefined) return;
    setProductTags(tags.filter(
      tag => !!tag.productIds && tag.productIds.includes(productId)
    ));
  }, [product, tags]);

  useEffect(() => {
    const productId = product.id;
    if (productId === undefined) return;

    const filteredTags = query
      ? tags.filter(
        tag => tag.name.toLowerCase().includes(query.toLowerCase())
      )
      : tags;

    setAvailableProductTags(filteredTags.filter(
      tag => !!tag.productIds && !tag.productIds.includes(productId)
    ));
  }, [product, tags, query]);

  return (
    <Section
      title={title}
      text={text}
    >
      { !!removeTarget &&
        <Banner
          icon={<Icon icon={settings.svgIcons.remove} />}
          actions={(
            <>
              <Button
                onClick={handleRemoveTag(removeTarget)}
              >
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button
                onClick={handleCancelRemoveTag}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { confirmRemove }
        </Banner>
      }
      { productTags.length
        ? <TagTable
          tags={productTags}
          pageCount={10}
          generateActions={generateRemoveActions}
        />
        : <Text>{ notAssigned }</Text>
      }
      { add
        ? <>
          <Segment title={localize(localeContentKeys.availableTags)}>
            <Form>
              <TextInput
                id='product-tags-search'
                label={localize(localeFormKeys.labels.search)}
                value={query}
                onChange={setQuery}
              />
            </Form>
            { availableProductTags.length
              ? <TagTable
                pageCount={5}
                tags={availableProductTags}
                generateActions={generateAddActions}
              />
              : <Text>{ notFound }</Text>
            }
            <Button
              colour={settings.colours.button.alert}
              onClick={handleCancel}
            >
              { localize(localeButtonKeys.close) }
            </Button>
          </Segment>
        </>
        : <Button
          onClick={handleAdd}
        >
          { localize(localeButtonKeys.edit) }
        </Button>
      }
    </Section>
  );
}

export default ProductTags;
