import React from 'react';

import { Customer } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.customers;

interface CustomerTableProps {
  customers : Customer[];
  pageCount? : number;
  generateActions? : (customer : Customer) => CellElement;
}

function CustomerTable({
  customers,
  pageCount = 20,
  generateActions = () => <TableCell />,
} : CustomerTableProps) {

  const tableKeys = [
    'id',
    'name',
    'email',
    'actions',
  ];

  function headGenerator(key : string) {
    switch (key) {
      case 'id':
        return <TableCell>{ localize(localKeys.headings.id) }</TableCell>;
      case 'name':
        return <TableCell>{ localize(localKeys.headings.name) }</TableCell>;
      case 'email':
        return <TableCell>{ localize(localKeys.headings.email) }</TableCell>;
      case 'action': return <TableCell />;
      default: return <TableCell />;
    }
  }

  function rowGenerator(key : string, index : number) {
    const customer = customers[index];
    switch (key) {
      case 'id': return <TableCell>{ `# ${customer.id}` }</TableCell>;
      case 'name': return <TableCell>{ customer.defaultName }</TableCell>;
      case 'email':
        const email = (Object.keys(customer.contactInfo).length > 0)
          ? Object.values(customer.contactInfo)[0].email
          : '';
        return <TableCell>{ email }</TableCell>;
      case 'actions':
        return generateActions(customer);
      default: return <TableCell />;
    }
  }

  return (
    <Table
      keys={tableKeys}
      length={customers.length}
      headGenerator={headGenerator}
      rowGenerator={rowGenerator}
      pageCount={pageCount}
    />
  );
}

export default CustomerTable;
