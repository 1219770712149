import React from 'react';

import { useParams } from '#hooks/useNavigation';

import TaxIndex from '#components/taxes/TaxIndex';
import TaxInfo from '#components/taxes/TaxInfo';

function Taxes() {
  const urlParams = useParams<{ taxId? : string }>();

  const parsedId = parseInt(urlParams.taxId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !id
        ? <TaxIndex />
        : <TaxInfo taxId={id} />
      }
    </>
  );
}

export default Taxes;
