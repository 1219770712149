import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Image, Category } from '#types';

import useNotifications from '#hooks/useNotifications';
import useCategories from '#hooks/useCategories';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Text from '#materials/Text';
import Button from '#materials/Button';
import UploadButton from '#materials/UploadButton';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ImageTable, {
  TABLE_KEYS,
  defaultTableKeys,
} from '#components/images/ImageTable';

import locale, { localize } from '#utils/locale';

const tableKeys = defaultTableKeys.filter((key) => (
  key !== TABLE_KEYS.variants
))

const localeContentKeys = locale.keys.content.categories.categoryImages;
const lcoaleNotificationsKeys = locale.keys.notifications.images;
const localeTableKeys = locale.keys.tables.images;
const localeButtonsKeys = locale.keys.buttons;

interface CategoryImagesProps {
  category : Category;
}

function CategoryImages({ category } : CategoryImagesProps) {
  const { createNotification } = useNotifications();
  const {
    refreshCategory,
    createCategoryImageUpload,
    pullCategoryImage,
    removeImageFromCategory,
  } = useCategories();

  const uploadImageId = useRef<number | null>(null);
  const [images, setImages] = useState<Image[]>(
    category.images ? Object.values(category.images) : []
  );
  const [removeTarget, setRemoveTarget] = useState<Image | null>(null);

  const handleInitRemove = useCallback((image : Image) => () => {
    setRemoveTarget(image);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleConfirmRemove = useCallback(async () => {
    if (!removeTarget) return;
    const success = await removeImageFromCategory(category, removeTarget);
    if (success) {
      createNotification({
        key : 'remove-image-success',
        message : localize(lcoaleNotificationsKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-image-error',
        message : localize(lcoaleNotificationsKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.alert,
      });
    }
    setRemoveTarget(null);
  }, [
    category,
    removeTarget,
    removeImageFromCategory,
    createNotification,
  ]);

  const handleSubmit = useCallback(async () => {
    async function pull() {
      if (!category.id || !uploadImageId.current) return null;
      const latestCategory = await refreshCategory(category.id);
      if (!latestCategory || !latestCategory.images) return null;

      const image = latestCategory.images[uploadImageId.current];
      if (!image) return null;
      return await pullCategoryImage(category, image);
    }

    const image = await pull();
    if (!!image) {
      createNotification({
        key : 'upload-image-success',
        message : localize(lcoaleNotificationsKeys.submit.success),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'upload-image-error',
        message : localize(lcoaleNotificationsKeys.submit.error),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [
    category,
    refreshCategory,
    pullCategoryImage,
    createNotification,
  ]);

  const getUploadUrl = useCallback(async () => {
    const upload = await createCategoryImageUpload(category);
    if (!upload) {
      createNotification({
        key : 'upload-image-error',
        message : localize(lcoaleNotificationsKeys.submit.error),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.alert,
      });
    }
    uploadImageId.current = upload?.id || null;
    return upload?.uploadUrl ?? '';
  }, [category, createCategoryImageUpload, createNotification]);

  const generateActions = useCallback((image : Image) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.remove)}
          onClick={handleInitRemove(image)}
          disabled={removeTarget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      </TableActionCell>
    );
  }, [removeTarget, handleInitRemove]);

  useEffect(() => {
    setImages(category.images ? Object.values(category.images) : []);
  }, [category]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { images
        ? (
          <>
            { !!removeTarget && (
              <Banner
                icon={(<Icon icon={settings.svgIcons.remove} />)}
                actions={(
                  <>
                    <Button onClick={handleConfirmRemove}>
                      {localize(localeButtonsKeys.remove)}
                    </Button>
                    <Button onClick={handleCancelRemove}>
                      {localize(localeButtonsKeys.cancel)}
                    </Button>
                  </>
                )}
                colour={settings.colours.alert.alert}
              >
                { localize(localeContentKeys.confirmRemove) +
                  ` (# ${removeTarget?.id})` }
              </Banner>
            ) }
            <ImageTable
              images={images}
              tableKeys={tableKeys}
              generateActions={generateActions}
            />
          </>
        ) : (<Text>{localize(localeContentKeys.notAssigned)}</Text>)
      }
      <UploadButton
        getUrl={getUploadUrl}
        icon={(<Icon icon={settings.svgIcons.cloudUpload} />)}
        onSubmit={handleSubmit}
      >
        { localize(localeButtonsKeys.upload) }
      </UploadButton>
    </Section>
  );
}

export default CategoryImages;
