import React, { useCallback, useEffect, useState } from 'react';

import { Service, Schedule } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useTimeSlots from '#hooks/useTimeSlots';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Icon from '#materials/Icon';
import Text from '#materials/Text';
import Button from '#materials/Button';
import { TableActionCell, Action } from '#materials/TableCell';
import Banner from '#materials/Banner';

import Section from '#components/dashboard/Section';
import ScheduleTable from '#components/schedules/ScheduleTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeButtonKeys = locale.keys.buttons;
const localeContentKeys = locale.keys.content.services.serviceSchedules;
const localeTableKeys = locale.keys.tables.services;
const localeNotificationKeys = locale.keys.notifications.schedules;

interface ServiceSchedulesProps {
  service : Service;
  onUpdate? : (schedule : Schedule, service : Service) => void;
}

function ServiceSchedules({
  service,
  onUpdate,
} : ServiceSchedulesProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    retrieveSchedules,
    addScheduleToService,
    removeScheduleFromService,
  } = useTimeSlots();

  const [serviceSchedules, setServiceSchedules] = useState<Schedule[]>([]);
  const [availableSchedules, setAvailableSchedules] = useState<Schedule[]>([]);
  const [edit, setEdit] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<Schedule | null>(null);

  const handleView = useCallback((schedule : Schedule) => () => {
    navigate(`/scheduling/${schedule.id}`);
  }, [navigate]);

  const handleAdd = useCallback(
    (schedule : Schedule) => {
      return async () => {
        const success = await addScheduleToService(schedule, service);
        if (success && onUpdate) onUpdate(schedule, service);
      }
  }, [service, addScheduleToService, onUpdate]);

  const handleRemove = useCallback((schedule : Schedule) => () => {
    setRemoveTarget(schedule);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemoveConfirm = useCallback(async () => {
    if (!removeTarget) return;
    const success = await removeScheduleFromService(removeTarget, service);
    if (success && onUpdate) {
      createNotification({
        key : 'remove-schedule-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.dateRange} />),
        colour : settings.colours.alert.primary,
      })
      onUpdate(removeTarget, service);
    } else {
      createNotification({
        key : 'remove-schedule-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.dateRange} />),
        colour : settings.colours.alert.alert,
      })
    }
    setRemoveTarget(null);
  }, [
    service,
    removeTarget,
    onUpdate,
    removeScheduleFromService,
    createNotification,
  ]);

  const handleEdit = useCallback(() => {
    setEdit(true);
  }, [setEdit]);

  const handleCancel = useCallback(() => {
    setEdit(false);
  }, [setEdit]);

  const generateScheduleActions = useCallback((schedule : Schedule) => {
    if (!schedule || !schedule.id) return (<></>);
    return (
      <TableActionCell>
        { edit
          ? service.id && schedule.serviceIds.includes(service.id)
            ? (<Action
              label={`${localize(localeTableKeys.actions.removeSchedule)}`+
              ` ${schedule.id}`}
              onClick={handleRemove(schedule)}
              disabled={!!removeTarget}
              colour={settings.colours.button.alert}
            >
              <Icon icon={settings.svgIcons.remove} />
            </Action>)
            : (<Action
              label={`${localize(localeTableKeys.actions.addSchedule)}` +
              ` ${schedule.id}`}
              onClick={handleAdd(schedule)}
              colour={settings.colours.button.primary}
            >
              <Icon icon={settings.svgIcons.add} />
            </Action>)
          : (<Action
            label={`${localize(localeTableKeys.actions.view)}  ${schedule.id}`}
            onClick={handleView(schedule)}
          >
            <Icon icon={settings.svgIcons.dateRange} />
          </Action>)
        }
      </TableActionCell>
    )
  }, [service, edit, removeTarget, handleRemove, handleAdd, handleView]);

  useEffect(() => {
    if (!service.id) return;

    const fetchSchedules = async () => {
      const retrievedSchedules = await retrieveSchedules();
      if (retrievedSchedules) {
        setServiceSchedules(listRecords(retrievedSchedules).filter(
          schedule => !!service.id && schedule.serviceIds
            && schedule.serviceIds.includes(service.id)
        ));
        setAvailableSchedules(listRecords(retrievedSchedules).filter(
          schedule => !!service.id && schedule.serviceIds
            && !(schedule.serviceIds.includes(service.id))
        ));
      }
    }

    fetchSchedules();
  }, [service, retrieveSchedules]);

  return (
    <>
      { service &&
        <Section
          title={localize(localeContentKeys.title)}
          text={localize(localeContentKeys.body)}
        >
          { !!removeTarget && (
            <Banner
              icon={(<Icon icon={settings.svgIcons.delete} />)}
              actions={
                <>
                  <Button
                    onClick={handleRemoveConfirm}
                    colour={settings.colours.button.alert}
                  >
                    {localize(localeButtonKeys.remove)}
                  </Button>
                  <Button
                    onClick={handleCancelRemove}
                  >
                    {localize(localeButtonKeys.cancel)}
                  </Button>
                </>
              }
              colour={settings.colours.alert.alert}
            >
              { localize(localeContentKeys.confirmRemove) +
                ` (${removeTarget.name})` }
            </Banner>
          ) }
          { !!(serviceSchedules && serviceSchedules.length)
            ? <ScheduleTable
              schedules={serviceSchedules}
              count={serviceSchedules.length}
              generateActions={generateScheduleActions}
            />
            : <Text>{ localize(localeContentKeys.noSchedules) }</Text>
          }
          { !edit
            ?
            <Button
              onClick={handleEdit}
            >
              {localize(localeButtonKeys.edit)}
            </Button>
            :
            <Segment
              title={ localize(localeContentKeys.availableSchedules) }
            >
              { !!(availableSchedules && availableSchedules.length)
                ? <ScheduleTable
                  schedules={availableSchedules}
                  count={availableSchedules.length}
                  pageCount={5}
                  generateActions={generateScheduleActions}
                />
                : <Text>{ localize(localeContentKeys.noSchedules) }</Text>
              }
              <Button
                onClick={handleCancel}
                colour={settings.colours.button.alert}
              >
                { localize(localeButtonKeys.close) }
              </Button>
            </Segment>
          }
        </Section>
      }
    </>
  );
}

export default ServiceSchedules;
