import React, { useCallback } from 'react';

import { Supplier } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.suppliers;

interface SupplierFormProps {
  supplier : Supplier;
}

function SupplierForm({ supplier : fallback } : SupplierFormProps) {

  const { state, dispatch, editing } = useForm<Supplier>();

  const setName = useCallback((name : string) => {
    dispatch({ name })
  }, [dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `supplier-${fallback?.id}`
    : 'supplier-new';

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localKeys.labels.name)}
        value={state ? state.name : fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.full}
      />
    </Form>
  );
}

export default SupplierForm;
