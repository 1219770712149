import React, { useCallback, useEffect, useState } from 'react';

import { Location } from '#types';

import useNotifications from '#hooks/useNotifications';
import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import { TableActionCell, Action } from '#materials/TableCell';
import Banner from '#materials/Banner';

import CreateLocation from '#components/locations/CreateLocation';
import Section from '#components/dashboard/Section';
import LocationsTable, { TABLE_KEYS }
  from '#components/locations/LocationsTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.locations;
const localeContentKeys = locale.keys.content.locations.locationIndex;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.locations;

interface LocationIndexProps {
  showHead? : boolean;
}

function LocationIndex(
  { showHead = true } : LocationIndexProps
) {
  const { createNotification } = useNotifications();
  const { retrieveLocations, deleteLocation } = useServices();
  const [
    locations,
    setLocations
  ] = useState<Location[]>([]);
  const [creatingNew, setCreatingNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<Location | null>(null);

  const tableLength = locations
    ? Object.keys(locations).length
    : 0;

  const refresh = useCallback(() => {
    async function retrieve() {
      const retrievedLocations = await retrieveLocations();
      if (retrievedLocations) {
        setLocations(listRecords(retrievedLocations));
      }
    }
    retrieve();
  }, [setLocations, retrieveLocations]);

  const handleCreateNew = useCallback(() => {
    setCreatingNew(true);
  }, [setCreatingNew]);

  const handleRetrieve = useCallback(() => {
    refresh();
  }, [refresh]);

  const handleCancelNew = useCallback(() => {
    setCreatingNew(false);
  }, [setCreatingNew]);

  const handleSave = useCallback(() => {
    setCreatingNew(false);
    refresh();
  }, [setCreatingNew, refresh]);

  const handleInitDelete = useCallback((location : Location) => () => {
    setDeleteTarget(location);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleConfirmDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteLocation(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-location-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.store} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-location-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.store} />),
        colour : settings.colours.alert.alert,
      });
    }
    setDeleteTarget(null);
  }, [deleteTarget, deleteLocation, createNotification]);

  const generateActions = useCallback((location : Location) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          href={`/locations/${location.id}`}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.store} />
        </Action>
        <Action
          label={localize(localeTableKeys.actions.delete)}
          onClick={handleInitDelete(location)}
          disabled={!!deleteTarget}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete} />
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      { showHead &&
        <>
          <Section
            title={localize(localeContentKeys.title)}
            text={localize(localeContentKeys.body)}
          >
            <Button
              onClick={handleRetrieve}
            >
              { localize(localeButtonKeys.refresh) }
            </Button>
            <Button
              onClick={handleCreateNew}
            >
              { localize(localeButtonKeys.new) }
            </Button>
          </Section>
          { creatingNew &&
            <CreateLocation
              onCancel={handleCancelNew}
              onSave={handleSave}
            />
          }
        </>
      }
      <Section
        title={localize(localeContentKeys.index.title)}
      >
        { !!deleteTarget && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.delete} />)}
            actions={
              <>
                <Button
                  onClick={handleConfirmDelete}
                  colour={settings.colours.button.alert}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                  colour={settings.colours.button.primary}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            }
            colour={settings.colours.alert.alert}
          >
            { `${localize(localeContentKeys.index.confirmDelete)} ` +
              `${deleteTarget?.name}?` }
          </Banner>
        ) }
        <LocationsTable
          locations={locations}
          count={tableLength}
          tableKeys={[
            TABLE_KEYS.id,
            TABLE_KEYS.name,
            TABLE_KEYS.actions,
          ]}
          generateActions={generateActions}
        />
      </Section>
    </>
  );
}

export default LocationIndex;
