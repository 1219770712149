import React, { useCallback, useEffect, useState } from 'react';

import { ExternalProduct, Integration } from '#types';

import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.productIntegrations;

export const TABLE_KEYS = {
  id : 'id' as const,
  integration : 'integrationId' as const,
  externalId : 'externalId' as const,
  name : 'name' as const,
  sku : 'sku' as const,
  price : 'price' as const,
  actions : 'actions' as const,
};
type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface ExternalProductsTableProps {
  externalProducts : ExternalProduct[];
  tableKeys? : TableKey[];
  generateActions? : (product : ExternalProduct) => CellElement;
}

function ExternalProductsTable({
  externalProducts: productIntegrations,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <TableCell />,
} : ExternalProductsTableProps) {
  const { retrieveIntegrations } = useIntegrations();

  const [
    integrations,
    setIntegrations,
  ] = useState<{ [key : string] : Integration | null }>({});
  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const retrieve = useCallback(async () => {
    const newIntegrations = await retrieveIntegrations();
    if (newIntegrations) setIntegrations(newIntegrations);
  }, [retrieveIntegrations]);

  const generateHead = useCallback(() => {
    function generate(key : TableKey) {
      switch (key) {
        case TABLE_KEYS.id:
          return (<TableCell>{ localize(localKeys.headings.id) }</TableCell>);
        case TABLE_KEYS.integration:
          return (
            <TableCell>{ localize(localKeys.headings.integration) }</TableCell>
          );
        case TABLE_KEYS.externalId:
          return (
            <TableCell>{ localize(localKeys.headings.externalId) }</TableCell>
          );
        case TABLE_KEYS.name:
          return (<TableCell>{ localize(localKeys.headings.name) }</TableCell>);
        case TABLE_KEYS.sku:
          return (<TableCell>{ localize(localKeys.headings.sku) }</TableCell>);
        default: return <TableCell />;
      }
    }

    return (
      <>
        { tableKeys.map((key) => (
          <React.Fragment key={`product-integrations-table-head-${key}`}>
            { generate(key) }
          </React.Fragment>
        )) }
      </>
    )
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    function generate(key : TableKey, product : ExternalProduct) {
      switch (key) {
        case TABLE_KEYS.externalId:
          return (
            <TableCell
              width={settings.dimensions.large}
            >
              { product.externalId }
            </TableCell>
          );
        case TABLE_KEYS.integration:
          const integration = product.integrationId
            ? integrations[product.integrationId]
            : undefined;
          return (
            <TableCell>{ integration ? integration.name : '' }</TableCell>
          );
        case TABLE_KEYS.name:
          return (
            <TableCell
              width={settings.dimensions.large}
            >
              { product.name }
            </TableCell>
          );
        case TABLE_KEYS.sku:
          return (
            <TableCell
              width={settings.dimensions.small}
            >
              { product.sku }
            </TableCell>
          );
        case TABLE_KEYS.actions:
          return generateActions(product);
        default: return <TableCell />;
      }
    }

    return productIntegrations.map((product, index) => (
      tableKeys.map((key) => (
        <React.Fragment
          key={`product-integrations-table-row-${index}-${key}`}
        >
          { generate(key, product) }
        </React.Fragment>
      ))
    ));
  }, [productIntegrations, tableKeys, generateActions, integrations]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  useEffect(() => {
    setHead(generateHead());
  }, [generateHead]);

  useEffect(() => {
    setRows(generateRows());
  }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={20}
    />
  );
}

export default ExternalProductsTable;
