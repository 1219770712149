import React from 'react';

import { useSearchParams } from '#hooks/useNavigation';

import Card  from '#components/cards/CardRegistration';

function CardRegistration() {
  const [urlParams] = useSearchParams();

  const customerId = parseInt(urlParams.get('customerId') || '0');
  const name = urlParams.get('name') || '';
  const email = urlParams.get('email') || '';

  return (
    <Card
      customerId={customerId}
      name={name}
      email={email}
    />
  );
}

export default CardRegistration;
