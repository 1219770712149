import React, { useCallback, useEffect, useState } from 'react';

import { Adjustment, Condition } from '#types';

import useAdjustments from '#hooks/useAdjustments';

import Segment from '#materials/Segment';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import CreateCondition from '#components/conditions/CreateCondition';
import ConditionDetails from '#components/conditions/ConditionDetails';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.adjustments.adjustmentConditions;
const localeButtonKeys = locale.keys.buttons;

interface AdjustmentConditionsProps {
  adjustment : Adjustment;
}

function AdjustmentConditions({ adjustment } : AdjustmentConditionsProps) {
  const {
    retrieveAdjustment,
    retrieveConditions,
  } = useAdjustments()

  const [conditions, setConditions] = useState<Condition[]>([]);
  const [creating, setCreating] = useState(false);

  const handleCreate = useCallback(() => setCreating(true), [setCreating])
  const handleCancel = useCallback(() => setCreating(false), [setCreating])

  useEffect(() => {
    const retrieveData = async () => {
      const conditionIds = adjustment?.conditionIds;

      const allConditions = await retrieveConditions()
      const conditionsFromAll = listRecords(allConditions)
        .filter((c) => c.id && conditionIds?.includes(c.id))

      setConditions(conditionsFromAll)
    }

    retrieveData()
  }, [retrieveAdjustment, retrieveConditions, adjustment])

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { conditions.map((condition, i) => (
        <React.Fragment key={condition.id}>
          { i > 0 && <Segment /> }
          <ConditionDetails condition={condition} />
        </React.Fragment>
      )) }
      { conditions.length !== 0 && <Segment /> }
      { creating
        ? (
          <CreateCondition
            adjustment={adjustment}
            onSave={handleCancel}
            onCancel={handleCancel}
          />
        )
        : (
          <Button onClick={handleCreate}>
            { localize(localeButtonKeys.new) }
          </Button>
        )
      }
    </Section>
  );
}

export default AdjustmentConditions;
