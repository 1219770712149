import React, { useCallback, useState, useRef } from 'react';

import { Address } from '#types';

import useDebounce from '#hooks/useDebounce';
import useAddresses from '#hooks/useAddresses';

import Select from '#materials/Select';

import { Dimension } from '#materials/types';

import { formatAddress } from '#utils/address';

const DEBOUNCE_DELAY = 1000; // ms

interface SearchAddressInputProps {
  label : string;
  address : Address | null;
  onChange : (address : Address | null) => void;
  disabled? : boolean;
  width? : Dimension;
}

function SearchAddressInput({
  label, address, onChange, disabled, width
} : SearchAddressInputProps) {
  const { debounce } = useDebounce({ delay: DEBOUNCE_DELAY });
  const { searchAddresses } = useAddresses();

  const searchQuery = useRef('');
  const [searchedAddresses, setSearchedAddresses] = useState<Address[]>([]);

  const handleSearch = useCallback(async (query : string) => {
    if (address) {
      const selected = [address];
      if (
        !(searchedAddresses.length === 1) ||
        !(searchedAddresses[0].id === address.id)
      ) {
        setSearchedAddresses(selected);
      }
      return selected;
    }

    if (query === searchQuery.current) return searchedAddresses;
    searchQuery.current = query;

    if (!query) return searchedAddresses;

    const results = await debounce(
      async () => {
        const addresses = await searchAddresses(searchQuery.current);
        if (!addresses) return null;
        setSearchedAddresses(Object.values(addresses));
        return Object.values(addresses);
      }
    );
    return results ?? searchedAddresses;
  },
  [
    searchedAddresses,
    debounce,
    searchAddresses,
    address
  ]);

  const safeSelection = searchedAddresses.find((add) => add.id === address?.id)
    ?? null;

  return (
    <Select
      label={label}
      selected={safeSelection}
      options={searchedAddresses}
      onChange={onChange}
      search={handleSearch}
      disabled={disabled}
      labelGenerator={(add) => add ? formatAddress(add) : ''}
      width={width}
    />
  );
}

export default SearchAddressInput;
