import React, { useEffect } from 'react';

import useNavigation from '#hooks/useNavigation';
import useAuth from '#hooks/useAuth';

import LoginComponent from '#components/auth/Login';

function Login() {
  const { navigate } = useNavigation();
  const { user, checkAuth } = useAuth();

  useEffect(() => {
    if (!!user && checkAuth({ redirect : null })) {
      navigate('/');
    }
  }, [user, checkAuth, navigate]);

  return (
    <LoginComponent />
  );
}

export default Login;
