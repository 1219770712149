import React, { useCallback } from 'react';

import { CreditCard } from '#types';

import { settings } from '#materials';
import Table from '#materials/Table';
import { TableCell, CellElement } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.cards;

export const TABLE_KEYS = {
  id : 'id',
  brand : 'brand',
  last4 : 'last4',
  actions : 'actions',
}
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface CreditCardTableProps {
  cards : CreditCard[],
  tableKeys? : TableKeys[],
  generateActions? : (card : CreditCard) => CellElement,
}

function CreditCardTable({
  cards,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <></>,
} : CreditCardTableProps) {
  const generateHead = useCallback(() => {
    return tableKeys.map((key) => {
      const mapKey = `cards-table-head-${key}`
      switch (key) {
        case TABLE_KEYS.id:
          return (
            <TableCell key={mapKey}>
              { localize(localeTableKeys.headings.id) }
            </TableCell>
          );
        case TABLE_KEYS.brand:
          return (
            <TableCell key={mapKey}>
              { localize(localeTableKeys.headings.brand) }
            </TableCell>
          );
        case TABLE_KEYS.last4:
          return (
            <TableCell key={mapKey}>
              { localize(localeTableKeys.headings.last4) }
            </TableCell>
          );
        default:
          return (<TableCell key={mapKey} />);
      }
    });
  }, [tableKeys]);

  const generateRow = useCallback((card : CreditCard) => {
    return tableKeys.map((key) => {
      const mapKey = `cards-table-row-${key}`
      switch (key) {
        case TABLE_KEYS.id:
          return (
            <TableCell key={mapKey}>
              { card.id }
            </TableCell>
          );
        case TABLE_KEYS.brand:
          return (
            <TableCell key={mapKey}>
              { `${card.brand.toUpperCase()}` }
            </TableCell>
          );
        case TABLE_KEYS.last4:
          return (
            <TableCell key={mapKey} font={settings.fonts.mono}>
              { `**** **** **** ${card.last4}` }
            </TableCell>
          );
        case TABLE_KEYS.actions:
          return (
            <React.Fragment key={mapKey}>
              { generateActions(card) }
            </React.Fragment>
          );
        default:
          return (<TableCell key={mapKey} />);
      }
    });
  }, [tableKeys, generateActions]);

  return (
    <Table
      head={generateHead()}
      rows={cards.map((card) => (
        <React.Fragment key={`route-table-row-${card.id}`}>
          { generateRow(card) }
        </React.Fragment>
      ))}
    />
  );
}

export default CreditCardTable;
