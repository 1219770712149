import React, { useCallback, useEffect, useState } from 'react';

import { Route } from '#types';

import useNotifications from '#hooks/useNotifications';
import useRoutes from '#hooks/useRoutes';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import RouteTable from '#components/routes/RouteTable';
import CreateRoute from '#components/routes/CreateRoute';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.routes.routeIndex;
const localeTableKeys = locale.keys.tables.routes;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.routes;

interface RouteIndexProps {
  showHead? : boolean;
}

function RouteIndex({ showHead = true } : RouteIndexProps) {
  const { createNotification } = useNotifications();
  const {
    refreshRoutes,
    retrieveRoutes,
    deleteRoute,
  } = useRoutes();

  const [routes, setRoutes] = useState<Route[]>([]);
  const [createNew, setCreateNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<Route | null>(null);

  const handleRefresh = useCallback(async () => {
    await refreshRoutes();
  }, [refreshRoutes]);

  const handleNew = useCallback(() => {
    setCreateNew(true);
  }, [setCreateNew]);

  const handleSave = useCallback((route : Route) => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleCancel = useCallback(() => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleInitDelete = useCallback((route : Route) => () => {
    setDeleteTarget(route);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleConfirmDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteRoute(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-route-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.route} />),
        colour : settings.colours.alert.primary,
      })
    } else {
      createNotification({
        key : 'delete-route-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.route} />),
        colour : settings.colours.alert.alert,
      })
    }
    setDeleteTarget(null);
  }, [deleteTarget, deleteRoute, createNotification]);

  const generateActions = useCallback((route : Route) => {
    return (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.view)} ${route.id}` }
          href={`/routes/${route.id}`}
        >
          <Icon icon={settings.svgIcons.route} />
        </Action>
        <Action
          label={`${localize(localeTableKeys.actions.delete)} ${route.id}` }
          onClick={handleInitDelete(route)}
          disabled={!!deleteTarget}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete} />
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  useEffect(() => {
    const retrieve = async () => {
      const newRoutes = await retrieveRoutes();
      if (newRoutes) setRoutes(listRecords(newRoutes));
    };
    retrieve();
  }, [retrieveRoutes, setRoutes]);

  return (
    <>
      { showHead && (
        <>
          <Section
            title={localize(localeContentKeys.title)}
            text={localize(localeContentKeys.body)}
          >
            <Button
              onClick={handleRefresh}
            >
              { localize(localeButtonKeys.refresh) }
            </Button>
            <Button
              onClick={handleNew}
              disabled={createNew}
            >
              { localize(localeButtonKeys.new) }
            </Button>
          </Section>
          { createNew && (
            <CreateRoute
              onSave={handleSave}
              onCancel={handleCancel}
            />
          ) }
        </>
      ) }
      <Section
        title={localize(localeContentKeys.index.title)}
      >
        { !!deleteTarget && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.delete} />)}
            actions={(
              <>
                <Button
                  onClick={handleConfirmDelete}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.index.confirmDelete) +
              ` (${deleteTarget.name})` }
          </Banner>
        ) }
        <RouteTable
          routes={routes}
          generateActions={generateActions}
        />
      </Section>
    </>
  );
}

export default RouteIndex;
