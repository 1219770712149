import React, { useContext } from 'react';

import SidebarContext from '#context/SidebarContext';

import FlexItem from '#materials/FlexItem';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import IconButton from '#materials/IconButton';
import Toolbar from '#materials/Toolbar';
import AppBar from '#materials/AppBar';
import { settings } from '#materials';

import SideBar from '#components/dashboard/SideBar';
import { NavigationItemElement } from './NavigationItem';

import locale from '#utils/locale';

interface AppBarProps {
  title? : string;
  navItems? : NavigationItemElement | NavigationItemElement[];
  children : React.ReactNode;
}

function Bar({ title, navItems, children } : AppBarProps) {
  const { open, setOpen } = useContext(SidebarContext);

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <>
      <AppBar open={open}>
        <Toolbar>
          <IconButton
            label={locale.localize(locale.keys.lables.openDrawer)}
            hidden={open}
            onClick={toggleOpen}
            colour={settings.colours.button.bg}
          >
            <Icon icon={settings.svgIcons.menu } />
          </IconButton>
          { !open && <FlexItem width={10}/> }
          { title &&
            <Text
              component={settings.components.h1}
              variant={settings.variants.typography.h5}
              font={settings.fonts.brand}
              spacing={settings.spacings.dense}
              adjustBaseline
              noWrap
            >
              { title }
            </Text>
          }
          { children }
        </Toolbar>
      </AppBar>
      <SideBar open={open} setOpen={setOpen}>
        { navItems }
      </SideBar>
    </>
  );
}

export default Bar;
