import React, { useCallback } from 'react';

import { ContactInfo } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';

import locale, { localize } from '#utils/locale';

const localeFormKeys = locale.keys.forms.contacts;

export function validate_full_name(contactInfo : ContactInfo) {
  if (contactInfo.name.length < 1) {
    return { 'name' : localize(localeFormKeys.errors.nameRequired) };
  }
  return null;
}

export function validate_nickname(contactInfo : ContactInfo) {
  if (contactInfo.nickname.length < 1) {
    return { 'nickname' : localize(localeFormKeys.errors.nicknameRequired) };
  }
  return null;
}

export function validate_email(contactInfo : ContactInfo) {
  if (contactInfo.email.length < 1) {
    return { 'email' : localize(localeFormKeys.errors.emailRequired) };
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(contactInfo.email)) {
    return { 'email' : localize(localeFormKeys.errors.invalidEmail) };
  }
  return null;
}

export function validate_phone(contactInfo : ContactInfo) {
  if (!contactInfo.phone) return null;
  if (!/^\+?\d{9,15}$/.test(contactInfo.phone)) {
    return { 'phone' : localize(localeFormKeys.errors.invalidPhone) };
  }
  return null;
}

interface ContactFormProps {
  contactInfo : ContactInfo;
}

function ContactForm({ contactInfo : fallback } : ContactFormProps) {
  const { state, dispatch, editing, errors } = useForm<ContactInfo>();

  const setName = useCallback((name : string) => {
    dispatch({ name })
  }, [dispatch]);
  const setNickname = useCallback((nickname : string) => {
    dispatch({ nickname })
  }, [dispatch]);
  const setEmail = useCallback((email : string) => {
    dispatch({ email })
  }, [dispatch]);
  const setPhone = useCallback((phone : string) => {
    dispatch({ phone })
  }, [dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `contactInfo-${fallback?.id}`
    : `contactInfo-new`

  return (
    <>
      <Form>
        <TextInput
          id={`${idPrefix}-name`}
          label={localize(localeFormKeys.labels.fullName)}
          value={state?.name || fallback.name}
          onChange={setName}
          errors={editing ? errors?.name : undefined}
          disabled={!editing}
          width={settings.dimensions.twoThirds}
        />
        <TextInput
          id={`${idPrefix}-nickname`}
          label={localize(localeFormKeys.labels.nickName)}
          value={state?.nickname || fallback.nickname}
          onChange={setNickname}
          errors={editing ? errors?.nickname : undefined}
          disabled={!editing}
          width={settings.dimensions.third}
        />
        <TextInput
          id={`${idPrefix}-phone`}
          label={localize(localeFormKeys.labels.phone)}
          value={state?.phone || fallback.phone}
          onChange={setPhone}
          errors={editing ? errors?.phone : undefined}
          disabled={!editing}
          width={settings.dimensions.third}
        />
        <TextInput
          id={`${idPrefix}-email`}
          label={localize(localeFormKeys.labels.email)}
          value={state?.email || fallback.email}
          onChange={setEmail}
          errors={editing ? errors?.email : undefined}
          disabled={!editing}
          width={settings.dimensions.twoThirds}
        />
      </Form>
    </>
  );
}

export default ContactForm;
