import React, { useCallback } from 'react';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useAuth from '#hooks/useAuth';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Section from '#components/dashboard/Section';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.settings.userSettings;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.auth;

function UserSettings() {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const { logout } = useAuth();

  const handleLogout = useCallback(async () => {
    await logout();

    createNotification({
      key : 'logout-success',
      message : localize(localeNotificationKeys.logout.success),
      colour : settings.colours.alert.primary,
      icon : <Icon icon={settings.svgIcons.person} />,
    });
    navigate('/login/');
  }, [logout, createNotification, navigate]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <Button
        onClick={handleLogout}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.logout) }
      </Button>
    </Section>
  );
}

export default UserSettings;
