import React, { useCallback, useEffect, useState } from 'react';

import { Tag } from '#types';

import useNotifications from '#hooks/useNotifications';
import useTags from '#hooks/useTags';
import useCategories from '#hooks/useCategories';
import useSuppliers from '#hooks/useSuppliers';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import TagTable from '#components/tags/TagTable';
import CreateTag from '#components/tags/CreateTag';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.tags.tagIndex;
const localeTableKeys = locale.keys.tables.tags;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.tags;

interface TagIndexProps {
  showHead? : boolean;
}

function TagIndex({ showHead = true } : TagIndexProps) {
  const { createNotification } = useNotifications();
  const {
    refreshTags,
    retrieveTags,
    deleteTag,
  } = useTags();
  const {
    retrieveCategories,
  } = useCategories();
  const {
    retrieveSuppliers,
  } = useSuppliers();

  const [tags, setTags] = useState<Tag[]>([]);
  const [createNew, setCreateNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<Tag | null>(null);

  const handleRefresh = useCallback(async () => {
    await refreshTags();
  }, [refreshTags]);

  const handleNew = useCallback(() => {
    setCreateNew(true);
  }, [setCreateNew]);

  const handleSave = useCallback((tag : Tag) => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleCancel = useCallback(() => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleInitDelete = useCallback((tag : Tag) => () => {
    setDeleteTarget(tag);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteTag(deleteTarget);
    if (success) {
      createNotification({
        key :  'delete-tag-succes',
        message : localize(localeNotificationKeys.delete.success),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key :  'delete-tag-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.alert,
      });
    }
    setDeleteTarget(null);
  }, [deleteTarget, createNotification, deleteTag]);

  const generateActions = useCallback((tag : Tag) => {
    return (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.view)} ${tag.id}`}
          href={`/tags/${tag.id}`}
        >
          <Icon icon={settings.svgIcons.localOffer} />
        </Action>
        <Action
          label={`${localize(localeTableKeys.actions.delete)} ${tag.id}`}
          onClick={handleInitDelete(tag)}
          disabled={deleteTarget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete} />
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  useEffect(
    () => {
      const retrieve = async () => {
        const [newCategories, newSuppliers, newTags] = await Promise.all(
          [
            retrieveCategories(),
            retrieveSuppliers(),
            retrieveTags(),
          ]
        );
        if (newTags && newCategories && newSuppliers) {
          const filteredTags = listRecords(newTags).filter(
            newTag => {
              const isCategoryTag = listRecords(newCategories).some(
                category => category.id === newTag.id);
              const isSupplierTag = listRecords(newSuppliers).some(
                supplier => supplier.id === newTag.id);
              return !(isCategoryTag || isSupplierTag);
            }
          );
          setTags(filteredTags);
        }
      };
      retrieve();
    },
    [
      retrieveTags,
      setTags,
      retrieveCategories,
      retrieveSuppliers,
    ],
  );

  return (
    <>
      <Section
        title={localize(localeContentKeys.title)}
        text={localize(localeContentKeys.body)}
      >
        <Button
          onClick={handleRefresh}
        >
          { localize(localeButtonKeys.refresh) }
        </Button>
        <Button
          onClick={handleNew}
          disabled={createNew}
        >
          { localize(localeButtonKeys.new) }
        </Button>
      </Section>
      { createNew && (
        <CreateTag
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) }
      <Section
        title={localize(localeContentKeys.index.title)}
      >
        { !!deleteTarget &&
          <Banner
            icon={<Icon icon={settings.svgIcons.delete} />}
            actions={(
              <>
                <Button
                  onClick={handleDelete}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.index.confirmDelete) +
              ` (${deleteTarget?.name})` }
          </Banner>
        }
        <TagTable
          tags={tags}
          generateActions={generateActions}
        />
      </Section>
    </>
  );
}

export default TagIndex;
