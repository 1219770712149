import React, { useCallback } from 'react';

import { Channel } from '#types';

import { FormProvider } from '#context/FormContext';

import Table from '#materials/Table';
import {
  CellElement,
  TableCell,
} from '#materials/TableCell';

import ChannelRow, { TABLE_KEYS } from '#components/channels/ChannelRow';

import locale, { localize } from '#utils/locale';

const localTableKeys = locale.keys.tables.channels;

interface ChannelTableProps {
  channels : Channel[];
  generateActions? : (channel : Channel) => CellElement;
  editing? : number[];
  setEditing? : (editing : number[]) => void;
  onUpdate? : (channel : Channel) => Promise<void>;
}

function ChannelTable({
  channels,
  generateActions = () => <TableCell />,
  editing = [],
  setEditing = () => {},
  onUpdate = async () => {},
} : ChannelTableProps) {

  const handleSave = useCallback((channel : Channel) => async () => {
    await onUpdate(channel);
    setEditing(editing.filter((id) => id !== channel.id));
  }, [editing, onUpdate, setEditing]);

  const handleCancel = useCallback((channel : Channel) => () => {
    setEditing(editing.filter((id) => id !== channel.id));
  }, [editing, setEditing]);

  function headGenerator(key : string) {
    switch (key) {
      case TABLE_KEYS.id:
        return (
          <TableCell>
            { localize(localTableKeys.headings.id) }
          </TableCell>
        );
      case TABLE_KEYS.name:
        return (
          <TableCell>
            { localize(localTableKeys.headings.name) }
          </TableCell>
        );
      case TABLE_KEYS.read:
        return(
          <TableCell>
            { localize(localTableKeys.headings.read) }
          </TableCell>
        );
      case TABLE_KEYS.write:
        return(
          <TableCell>
            { localize(localTableKeys.headings.write) }
          </TableCell>
        );
      case TABLE_KEYS.actions: return(<TableCell></TableCell>);
      default: return (<TableCell></TableCell>);
    }
  }

  return (
    <Table
      head={Object.values(TABLE_KEYS).map((key) =>
        <React.Fragment key={`channel-table-head-${key}`}>
          { headGenerator(key) }
        </React.Fragment>
      )}
      rows={channels.map((channel) =>
        <FormProvider
          key={`channel-table-row-${channel.id}`}
          init={channel}
          editing={!!channel.id && editing.includes(channel.id)}
        >
          <ChannelRow
            channel={channel}
            tableKeys={Object.values(TABLE_KEYS)}
            generateActions={generateActions}
            onSave={handleSave(channel)}
            onCancel={handleCancel(channel)}
          />
        </FormProvider>
      )}
    />
  );
}

export default ChannelTable;
