import React, { useCallback, useState } from 'react';

import { Route } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useRoutes from '#hooks/useRoutes';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import RouteForm from '#components/routes/RouteForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.routes.routeDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.routes;

interface RouteDetailsProps {
  route : Route;
  onSave? : (route : Route) => void;
}

function RouteDetailsControl({ route : init, onSave } : RouteDetailsProps) {
  const {
    state : route,
    reset,
    editing,
    setEditing,
  } = useFormContext<Route>();
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const { refreshRoute, updateRoute, deleteRoute } = useRoutes();

  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!route || !route.id) return;
    refreshRoute(route.id);
  }, [route, refreshRoute]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!route) return;

    const updatedRoute = await updateRoute(route);
    if (updatedRoute) {
      if (onSave) onSave(updatedRoute);
      setEditing(false);
    }
  }, [route, updateRoute, onSave, setEditing]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleConfirmDelete = useCallback(async () => {
    if (!route) return;
    const success = await deleteRoute(route);
    if (success) {
      createNotification({
        key : 'delete-route-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.delete} />),
        colour : settings.colours.alert.primary,
      });
      navigate('/routes');
    } else {
      createNotification({
        key : 'delete-route-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.delete} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [route, deleteRoute, createNotification, navigate]);

  return (
    <>
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button
                onClick={handleConfirmDelete}
                colour={settings.colours.button.alert}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (route && editing)
        ? <>
          <Button
            onClick={handleSave}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleRefresh}
            disabled={!!deleting}
          >
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button
            onClick={handleEdit}
            disabled={!!deleting}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={!!deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <RouteForm route={route || init} />
    </>
  );
}

function RouteDetails({ route, onSave } : RouteDetailsProps) {
  return (
    <FormProvider init={route} editingInit={false}>
      <RouteDetailsControl route={route} onSave={onSave} />
    </FormProvider>
  );
}

export default RouteDetails;
