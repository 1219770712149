import React from 'react';

import { settings } from '#materials/types';
import Flex from '#materials/Flex';

interface FormProps {
  onSubmit? : () => void;
  preventDefault? : boolean;
  children? : React.ReactNode;
}

function Form ({
  onSubmit,
  preventDefault = true,
  children,
} : FormProps) {
  return (
    <Flex
      onSubmit={onSubmit}
      preventDefault={preventDefault}
      component={settings.components.form}
      wrap
      margin={{
        top : settings.spacings.small,
      }}
    >
      { children }
      <button type='submit' style={{ display : 'none' }} />
    </Flex>
  );
}

export default Form;
