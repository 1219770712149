import React, { useEffect, useState } from 'react';

import { Schedule } from '#types';

import { FormProvider } from '#context/FormContext';

import useTimeSlots from '#hooks/useTimeSlots';

import Section from '#components/dashboard/Section';
import ScheduleForm from '#components/schedules/ScheduleForm';

import Button from '#materials/Button';

import { settings } from '#materials';

import locale, { localize } from '#utils/locale';

const localeScheduleKeys = locale.keys.content.schedules.createSchedule;
const localeButtonKeys = locale.keys.buttons;

interface CreateScheduleProps {
  onSave? : (schedule : Schedule) => void;
  onCancel? : () => void;
}

function CreateSchedule({ onCancel, onSave } : CreateScheduleProps) {
  const { createSchedule } = useTimeSlots();

  const [schedule, setSchedule] = useState<Schedule>({
    name : '',
    timeSlots : [],
    serviceIds : [],
  });

  const [formKey, setFormKey] = useState(true);
  const [saved, setSaved] = useState(true);
  const [cleared, setCleared] = useState(true);

  useEffect(() => {
    setSaved(false);
    setCleared(false);
  },[schedule] );

  function resetSchedule() {
    setSchedule({
      name : '',
      timeSlots : [],
      serviceIds : [],
    });
    setFormKey(!formKey);
  }

  async function handleSave() {
    if (schedule) {
      const newSchedule = await createSchedule(schedule);
      if (!newSchedule) return;

      setSaved(true);
      if (onSave) await onSave(newSchedule);
    }
  }

  function handleCancel() {
    if (onCancel) onCancel();
  }

  function handleClear() {
    resetSchedule();
    setCleared(true);
  }

  return (
    <Section title={localize(localeScheduleKeys.title)}>
      <FormProvider
        init={schedule}
        onChange={setSchedule}
        formKey={formKey ? '' : '_'}
      >
        <ScheduleForm
          schedule={schedule}
        />
      </FormProvider>

      <Button
        onClick={handleSave}
        disabled={saved}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={handleCancel}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
      <Button
        onClick={handleClear}
        disabled={cleared}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
    </Section>
  );
}

export default CreateSchedule;
