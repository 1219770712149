import React, { useCallback, useEffect, useState } from 'react';

import { Customer, CustomerUser } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useAuth from '#hooks/useAuth';

import { settings } from '#materials';
import Section from '#components/dashboard/Section';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import IconButton from '#materials/IconButton';
import Table from '#materials/Table';
import Banner from '#materials/Banner';
import { TableCell } from '#materials/TableCell';

import { copy } from '#utils/clipboard';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customers.customerUsers;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.customers
const localeNotificationKeys = locale.keys.notifications.auth;

interface CustomerUsersProps {
  customer : Customer;
}

function CustomerUsers({ customer } : CustomerUsersProps) {
  const { generateUrl } = useNavigation();
  const { createNotification } = useNotifications();
  const { retrievePasswordResetToken } = useAuth();
  const [rows, setRows] = useState<React.ReactNode[]>([]);
  const [copying, setCopying] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(async () => {
    const user  = customer?.users
      ? Object.values(customer.users)[0]
      : null;
    if (!user) return;

    setCopying(true);
    const token = await retrievePasswordResetToken(user.username);

    if (token) {
      copy(
        generateUrl(`/reset-password/`, { username : user.username, token })
      );
      createNotification({
        key : 'copy-password-token-success',
        message : localize(localeNotificationKeys.copyPasswordToken.success),
        icon : (<Icon icon={settings.svgIcons.link} />),
        colour : settings.colours.alert.primary,
      });
      setCopied(true);
    } else {
      createNotification({
        key : 'copy-password-token-error',
        message : localize(localeNotificationKeys.copyPasswordToken.error),
        icon : (<Icon icon={settings.svgIcons.link} />),
        colour : settings.colours.alert.alert,
      });
    }
    setCopying(false);
  }, [customer, retrievePasswordResetToken, createNotification, generateUrl]);

  const closeNotificaiton = useCallback(() => { setCopied(false); }, []);

  const generateRow = useCallback((user : CustomerUser) => {
    return (
      <>
        <TableCell>{ `# ${user.id}` }</TableCell>
        <TableCell font={settings.fonts.mono}>{ user.username }</TableCell>
      </>
    )
  }, []);

  useEffect(() => {
    if (!customer.users) return;
    setRows(Object.values(customer.users).map((user : CustomerUser) => (
      <React.Fragment key={user.id}>
        { generateRow(user) }
      </React.Fragment>
    )));
  }, [customer, generateRow]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { copied && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          actions={(
            <IconButton
              label={localize(localeButtonKeys.close)}
              onClick={closeNotificaiton}
            >
              <Icon icon={settings.svgIcons.clear} />
            </IconButton>
          )}
          colour={settings.colours.alert.secondary}
        >
          { localize(localeContentKeys.copiedWarning) }
        </Banner>
      ) }
      { rows.length
        ? (<>
          <Table
            head={(
              <>
                <TableCell>{ localize(localeTableKeys.headings.userId) }</TableCell>
                <TableCell>
                  { localize(localeTableKeys.headings.username) }
                </TableCell>
              </>
            )}
            rows={rows}
            pageCount={10}
          />
          <Button
            onClick={handleCopy}
            disabled={copying}
          >
            { localize(localeButtonKeys.copy) }
          </Button>
        </>)
        : (<Text>{ localize(localeContentKeys.notFound) }</Text>)
      }
    </Section>
  );
}

export default CustomerUsers;
