import React, { useCallback, useEffect, useState } from 'react';

import { Product, ProductIntegration } from '#types';

import useNotifications from '#hooks/useNotifications';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Form from '#materials/Form';
import Icon from '#materials/Icon';
import TextInput from '#materials/TextInput';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productIntegrationInfo;
const localeButtonKeys = locale.keys.buttons;
const localeProductFormKeys = locale.keys.forms.products;
const localeNotificationKeys = locale.keys.notifications;

interface ProductIntegrationInfoProps {
  productId? : number;
  productIntegrationId? : number;
}

function ProductIntegrationInfo({
  productId,
  productIntegrationId,
} : ProductIntegrationInfoProps) {
  const { createNotification } = useNotifications();
  const { retrieveProduct, syncProductIntegration } = useProducts();

  const [product, setProduct] = useState<Product | null>(null);
  const [
    productIntegration,
    setProductIntegration,
  ] = useState<ProductIntegration | null>(null);

  const retrieve = useCallback(async () => {
    if (!productId) return;
    const product = await retrieveProduct(productId, true);
    if (!product) return;

    setProduct(product);
    if (!product.integrations || !productIntegrationId ||
      !(productIntegrationId in product.integrations)) return;
    setProductIntegration(product.integrations[productIntegrationId]);
  }, [productId, productIntegrationId, retrieveProduct]);

  const sync = useCallback(async () => {
    if (!productIntegration) return;
    const synced = await syncProductIntegration(productIntegration);
    if (!synced) {
      createNotification({
        key : 'sync-product-error',
        message : localize(localeNotificationKeys.products.sync.error),
        icon : <Icon icon={settings.svgIcons.refresh} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [productIntegration, syncProductIntegration, createNotification]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  if (!product || !productIntegration) return (
    <Section
      title={localize(localeContentKeys.notFound)}
    />
  );

  return (
    <Section
      title={`${localize(localeContentKeys.title)} #${productIntegration.id}`}
      text={localize(localeContentKeys.text)}
    >
      <Button
        onClick={sync}
      >
        { localize(localeButtonKeys.refresh) }
      </Button>
      <Form>
        <TextInput
          id={`product-integration-${product.id}-name`}
          label={localize(localeProductFormKeys.labels.name)}
          value={product.name}
          disabled
          width={settings.dimensions.half}
        />
        <TextInput
          id={`product-integration-${product.id}-sku`}
          label={localize(localeProductFormKeys.labels.sku)}
          value={product.sku}
          disabled
          width={settings.dimensions.quarter}
        />
        <TextInput
          id={`product-integration-${product.id}-price`}
          label={localize(localeProductFormKeys.labels.price)}
          value={product.price.calculatedValue}
          inputType={settings.inputType.number}
          inputFormat={settings.inputFormat.currency}
          width={settings.dimensions.quarter}
          disabled
        />
      </Form>
      <Segment title={productIntegration.externalId}>
        <Form>
          { Object.entries(productIntegration.data).map(([key, value]) => (
            <TextInput
              key={`product-integration-${productIntegration.id}-data-${key}`}
              id={`product-integration-${productIntegration.id}-data-${key}`}
              label={key}
              value={`${value}`}
              onChange={() => {}}
              width={settings.dimensions.full}
              disabled
            />
          )) }
        </Form>
      </Segment>
    </Section>
  );
}

export default ProductIntegrationInfo;
