import React, { useCallback, useEffect, useState } from 'react';

import { Area, Route, isArea } from '#types';

import useAddresses from '#hooks/useAddresses';
import useRoutes from '#hooks/useRoutes';

import Segment from '#materials/Segment';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import AreaDetails from '#components/areas/AreaDetails';
import CreateArea from '#components/areas/CreateArea';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.routes.routeAreas;
const localeButtonKeys = locale.keys.buttons;

interface RouteAreasProps {
  route : Route;
}

function RouteAreas({ route } : RouteAreasProps) {
  const { refreshRoute, addAreaToRoute, removeAreaFromRoute } = useRoutes();
  const { retrieveArea, deleteArea } = useAddresses();

  const [areas, setAreas] = useState<Area[]>([]);
  const [createNew, setCreateNew] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!route.id) return;
    await refreshRoute(route.id);
  }, [route, refreshRoute]);

  const handleNew = useCallback(() => {
    setCreateNew(true);
  }, [setCreateNew]);

  const handleSave = useCallback(async (area : Area) => {
    const success = await addAreaToRoute(route, area);
    if (!success) deleteArea(area);
    setCreateNew(false);
  }, [route, addAreaToRoute, deleteArea, setCreateNew]);

  const handleCancel = useCallback(() => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleDelete = useCallback((area : Area) => async () => {
    if (!route) return false;
    return !!(await removeAreaFromRoute(route, area));
  }, [route, removeAreaFromRoute]);

  useEffect(() => {
    async function retrieve() {
      if (!route) return;
      const areaPromises = route.areaIds.map(id => retrieveArea(id));
      const newAreas = await Promise.all(areaPromises);
      setAreas(newAreas.filter<Area>(isArea));
    }
    retrieve();
  }, [route, retrieveArea]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
    >
      { areas.map((area, index) => (
        index === 0
          ? <AreaDetails
            key={area.id}
            area={area}
            enableDelete={areas.length > 1}
            beforeDelete={handleDelete(area)}
            onUpdate={handleRefresh}
          />
          : <Segment
            key={`route-${route.id}-area-${area.id}`}
          >
            <AreaDetails
              key={area.id}
              area={area}
              beforeDelete={handleDelete(area)}
              onUpdate={handleRefresh}
            />
          </Segment>
      )) }
      <Segment>
        { createNew
          ? <CreateArea onSave={handleSave} onCancel={handleCancel} />
          : <Button onClick={handleNew}>
            { localize(localeButtonKeys.new) }
          </Button>
        }
      </Segment>
    </Section>
  );
}

export default RouteAreas;
