import React, { useCallback, useContext, useState } from 'react';

import SidebarContext from '#context/SidebarContext';

import useNavigation from '#hooks/useNavigation';

import { settings } from '#materials';
import List from '#materials/List';
import ListItem from '#materials/ListItem';
import Icon from '#materials/Icon';
import IconButton from '#materials/IconButton';

interface NavigationItemProps {
  href? : string;
  icon? : React.ReactNode;
  selected? : boolean;
  onClick? : () => void;
  button? : React.ReactNode;
  inset? : number;
  children : React.ReactNode;
}

interface NavigationItemGroupProps extends NavigationItemProps {
  name : string;
  children : NavigationItemElement | NavigationItemElement[];
}

export type NavigationItemElement = React.ReactElement<NavigationItemProps> |
  React.ReactElement<NavigationItemGroupProps>;

function NavigationItem({
  href,
  selected,
  onClick,
  icon,
  button,
  inset = 0,
  children,
} : NavigationItemProps) {
  const { isRouteActive } = useNavigation();

  return (
    <ListItem
      href={href}
      selected={selected ?? (!!href && isRouteActive(href))}
      onClick={onClick}
      icon={icon}
      button={button}
      inset={inset}
    >
      { children }
    </ListItem>
  );
}

export function NavigationItemGroup({
  icon,
  name,
  href,
  selected,
  children,
} : NavigationItemGroupProps) {
  const { navigate, isRouteActive } = useNavigation();

  const { open : navListOpen } = useContext(SidebarContext);

  const [open, setOpen] = useState(false);

  const disabled = !navListOpen;

  const handleClick = useCallback(() => {
    if (!disabled) {
      setOpen(!open);
    }
    if (href) navigate(href);
  }, [disabled, href, open, navigate]);

  const expand = (open && !disabled) || (selected && disabled);

  const navItems = React.Children.map(children, (child) => {
    return React.cloneElement(child, { inset : (navListOpen ? 1 : 0) });
  });

  return (
    <>
      <NavigationItem
        icon={icon}
        selected={selected ?? (!!href && isRouteActive(href))}
        onClick={handleClick}
        button={ !disabled && (
          <IconButton
            label={`${open ? 'Collapse' : 'Expand'} ${name}`}
            size={settings.sizes.small}
          >
            <Icon icon={
              open
                ? settings.svgIcons.expandLess
                : settings.svgIcons.expandMore
              }
            />
          </IconButton>
        )}
      >
        { name }
      </NavigationItem>
        <List
          collapsable
          open={expand}
          showBorder={disabled}
        >
          { navItems }
        </List>
    </>
  );
}

export default NavigationItem;
