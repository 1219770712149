import React, { useCallback, useState } from 'react';

import { TimeSlot } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import useTimeSlots from '#hooks/useTimeSlots';

import { settings } from '#materials';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import TimeSlotForm from '#components/timeslots/TimeSlotForm';

import { timeScales, roundDateTime } from '#utils/date';
import locale, { localize } from '#utils/locale';

const localeTimeSlotKeys = locale.keys.content.timeSlots.createTimeSlot;
const localeButtonKeys = locale.keys.buttons;

interface CreateTimeSlotProps {
  onSave? : (timeSlot : TimeSlot) => void;
  onCancel? : () => void;
}

function CreateTimeSlotControl({ onCancel, onSave } : CreateTimeSlotProps) {
  const { state : timeSlot, reset } = useFormContext<TimeSlot>();
  const { createTimeSlot } = useTimeSlots();

  const handleSave = useCallback(async () => {
    if (!timeSlot) return;

    const newTimeSlot = await createTimeSlot(timeSlot);
    if (newTimeSlot && onSave) onSave(newTimeSlot);
  }, [timeSlot, createTimeSlot, onSave]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const handleClear = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <Section title={localize(localeTimeSlotKeys.title)}>
      { !!timeSlot && <TimeSlotForm timeSlot={timeSlot} /> }
      <Button
        onClick={handleSave}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={handleCancel}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
      <Button
        onClick={handleClear}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
    </Section>
  )
}

function CreateTimeSlot({ onSave, onCancel } : CreateTimeSlotProps) {
  const [start] = useState(roundDateTime(new Date(), timeScales.minute));

  return (
    <FormProvider<TimeSlot>
      init={{
        name: '',
        start: start,
        scale: 6.048e+8,
        period: 1,
        duration: 0,
        division: null,
        windows: {},
      }}
    >
      <CreateTimeSlotControl
        onSave={onSave}
        onCancel={onCancel}
      />
    </FormProvider>
  );
}

export default CreateTimeSlot;
