import React, { useCallback, useState } from 'react';

import { Supplier } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useSuppliers from '#hooks/useSuppliers';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import SupplierForm from '#components/suppliers/SupplierForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.suppliers.supplierDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.suppliers;

interface SupplierDetailsProps {
  supplier : Supplier;
  onSave? : (supplier : Supplier) => void;
}

function SupplierDetailsControl(
  { supplier : init, onSave } : SupplierDetailsProps
) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();

  const {
    state : supplier,
    reset,
    editing,
    setEditing,
  } = useFormContext<Supplier>();
  const { refreshSupplier, updateSupplier, deleteSupplier } = useSuppliers();

  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!supplier || !supplier.id) return;
    refreshSupplier(supplier.id);
  }, [supplier, refreshSupplier]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!supplier) return;

    const updatedSupplier = await updateSupplier(supplier);
    if (updatedSupplier) {
      if (onSave) onSave(updatedSupplier);
      setEditing(false);
    }
  }, [supplier, updateSupplier, onSave, setEditing]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleDelete = useCallback(async () => {
    if (!supplier) return;

    const success = await deleteSupplier(supplier);
    if (success) {
      createNotification({
        key : 'delete-supplier-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : <Icon icon={settings.svgIcons.agriculture} />,
        colour : settings.colours.alert.primary,
      });
      navigate('/suppliers/');
    } else {
      createNotification({
        key : 'delete-supplier-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : <Icon icon={settings.svgIcons.agriculture} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [supplier, deleteSupplier, navigate, createNotification]);

  return (
    <>
      { deleting && (
        <Banner
          icon={ <Icon icon={settings.svgIcons.delete} /> }
          actions={(
            <>
              <Button
                onClick={handleDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (supplier && editing)
        ? <>
          <Button
            onClick={handleSave}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleRefresh}
          >
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button
            onClick={handleEdit}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <SupplierForm supplier={supplier || init} />
    </>
  );
}

function SupplierDetails({ supplier, onSave } : SupplierDetailsProps) {
  return (
    <FormProvider init={supplier} editingInit={false}>
      <SupplierDetailsControl supplier={supplier} onSave={onSave} />
    </FormProvider>
  );
}

export default SupplierDetails;
