import React, { useCallback, useState } from 'react';

import { Condition } from '#types';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import useAdjustments from '#hooks/useAdjustments';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { useFormContext } from '#hooks/useForm';

import ConditionForm from '#components/conditions/ConditionForm';

import locale, { localize } from '#utils/locale';

const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.conditions;
const localeContentKeys = locale.keys.content.conditions.conditionDetails;

interface ConditionDetailsProps {
  condition : Condition
}

const ConditionDetailsControl = ({
  condition : fallback,
} : ConditionDetailsProps) => {
  const { createNotification } = useNotifications();
  const {
    deleteCondition,
    updateCondition,
  } = useAdjustments();
  const [deleting, setDeleting] = useState(false);

  const {
    state : condition,
    editing,
    setEditing,
    reset,
  } = useFormContext<Condition>();

  const handleInitEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing])

  const handleCancelEdit = useCallback(() => {
    reset();
    setEditing(false);
  }, [setEditing, reset])

  const handleSaveEdit = useCallback(async () => {
    if(!condition) return;

    const updated = await updateCondition(condition, {
      product: condition.productIds[0],
      service: condition.serviceIds[0]
    });
    if (updated) {
      createNotification({
        key : 'update-condition-success',
        message : localize(localeNotificationKeys.update.success),
        icon : <Icon icon={settings.svgIcons.loyalty} />,
        colour : settings.colours.alert.primary,
      });
      setEditing(false);
    } else {
      createNotification({
        key : 'update-condition-error',
        message : localize(localeNotificationKeys.update.error),
        icon : <Icon icon={settings.svgIcons.loyalty} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [condition, updateCondition, setEditing, createNotification]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [])

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [])

  const handleDeleteConditionById = useCallback(async () => {
    if(!fallback?.id) return;

    const success = await deleteCondition(fallback)
    if(success) {
			createNotification({
				key : 'delete-adjustment-success',
				message : localize(localeNotificationKeys.delete.success),
				icon : <Icon icon={settings.svgIcons.loyalty} />,
				colour : settings.colours.alert.primary,
			});
		} else {
			createNotification({
				key : 'delete-adjustment-error',
				message : localize(localeNotificationKeys.delete.error),
				icon : <Icon icon={settings.svgIcons.loyalty} />,
				colour : settings.colours.alert.alert,
			});
		}
  },
  [deleteCondition, fallback, createNotification])

  return (
    <>
      { deleting && (
        <Banner
          icon={ <Icon icon={settings.svgIcons.delete} /> }
          actions={(
            <>
              <Button
                onClick={handleDeleteConditionById}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
        )}
        colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete)}
        </Banner>
      ) }
      { editing
        ?  (<>
          <Button
            onClick={handleSaveEdit}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancelEdit}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>)
        : (
          <>
            <Button
              onClick={handleInitEdit}
            >
              { localize(localeButtonKeys.edit) }
            </Button>
            <Button
              onClick={handleInitDelete}
              disabled={deleting}
              colour={settings.colours.button.alert}
            >
              { localize(localeButtonKeys.delete) }
            </Button>
          </>
        )
      }
      <ConditionForm
        condition={condition || fallback}
      />
    </>
  );
}

const ConditionDetails = ({ condition } : ConditionDetailsProps) => {
  return (
    <FormProvider init={condition} editingInit={false}>
      <ConditionDetailsControl
        condition={condition}
      />
    </FormProvider>
  );
}

export default ConditionDetails;
