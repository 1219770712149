import React from 'react';

import { useParams } from '#hooks/useNavigation';

import ServiceChannelIndex
  from '#components/serviceChannels/ServiceChannelIndex';
import ServiceInfo from '#components/serviceChannels/ServiceChannelInfo';

function ServiceChannels() {
  const urlParams = useParams<{ serviceChannelId? : string }>();

  const parsedId = parseInt(urlParams.serviceChannelId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !!id
        ?
        <ServiceInfo serviceChannelId={id} />
        :
        <ServiceChannelIndex />
      }
    </>
  );
}

export default ServiceChannels;
