import React from 'react';

import AddressIndex from '#components/addresses/AddressIndex';

function Addresses() {
  return (
    <>
      <AddressIndex />
    </>
  );
}

export default Addresses;
