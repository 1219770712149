import React, { useCallback, useEffect, useState } from 'react';

import { Category } from '#types';

import useNotifications from '#hooks/useNotifications';
import useCategories from '#hooks/useCategories';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import CategoryTable from '#components/categories/CategoryTable';
import CreateCategory from '#components/categories/CreateCategory';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.categories.categoryIndex;
const localeTableKeys = locale.keys.tables.categories;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.categories;

interface CategoryIndexProps {
  showHead? : boolean;
}

function CategoryIndex({ showHead = true } : CategoryIndexProps) {
  const { createNotification } = useNotifications();
  const {
    refreshCategories,
    retrieveCategories,
    deleteCategory,
  } = useCategories();

  const [categories, setCategories] = useState<Category[]>([]);
  const [createNew, setCreateNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<Category | null>(null);

  const handleRefresh = useCallback(async () => {
    await refreshCategories();
  }, [refreshCategories]);

  const handleNew = useCallback(() => {
    setCreateNew(true);
  }, [setCreateNew]);

  const handleSave = useCallback((category : Category) => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleCancel = useCallback(() => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleInitDelete = useCallback((category : Category) => () => {
    setDeleteTarget(category);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteCategory(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-category-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : <Icon icon={settings.svgIcons.category}/>,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-category-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : <Icon icon={settings.svgIcons.category}/>,
        colour : settings.colours.alert.alert,
      });
    }
    setDeleteTarget(null);
  }, [deleteTarget, deleteCategory, createNotification]);

  const generateActions = useCallback((category : Category) => {
    return (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.view)} ${category.id}`}
          href={`/categories/${category.id}`}
        >
          <Icon icon={settings.svgIcons.category}/>
        </Action>
        <Action
          label={`${localize(localeTableKeys.actions.delete)}`}
          onClick={handleInitDelete(category)}
          disabled={deleteTarget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete}/>
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  useEffect(() => {
    const retrieve = async () => {
      const newCategories = await retrieveCategories();
      if (newCategories) {
        setCategories(listRecords(newCategories));
      }
    };
    retrieve();
  }, [retrieveCategories, setCategories]);

  return (
    <>
      <Section
        title={localize(localeContentKeys.title)}
        text={localize(localeContentKeys.body)}
      >
        <Button
          onClick={handleRefresh}
        >
          { localize(localeButtonKeys.refresh) }
        </Button>
        <Button
          onClick={handleNew}
          disabled={createNew}
        >
          { localize(localeButtonKeys.new) }
        </Button>
      </Section>
      { createNew && (
        <CreateCategory
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) }
      <Section
        title={localize(localeContentKeys.index.title)}
      >
        { deleteTarget && (
          <Banner
            icon={<Icon icon={settings.svgIcons.delete} />}
            actions={(
              <>
                <Button
                  onClick={handleDelete}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.index.confirmDelete) +
              ` (${deleteTarget.name})` }
          </Banner>
        ) }
        <CategoryTable
          categories={categories}
          showSubCategories={true}
          generateActions={generateActions}
        />
      </Section>
    </>
  );
}

export default CategoryIndex;
