import React from 'react';

import { DraftCustomOrder } from '#types';

import Section from '#components/dashboard/Section';
import ItemList from '#components/lineItems/ItemList';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.packingList;

interface PackingListProps {
  order : DraftCustomOrder;
}

function PackingList({ order } : PackingListProps) {

  return (
    <Section
      title={order.order?.id
        ? `${localize(localeContentKeys.title)} - ${localize(localeContentKeys.order)} # ${order.order.id}`
        : localize(localeContentKeys.title)}
    >
      <ItemList
        order={order}
        showFulfilmment
        mergeProducts
        mergePricing
      />
    </Section>
  );
}

export default PackingList;
