import React, { useCallback, useEffect, useState } from 'react';

import { Location, LocationIntegration, ExternalLocation } from '#types';

import useNotifications from '#hooks/useNotifications';
import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import LocationIntegrationsTable, {
  defatultTableKeys,
} from '#components/locations/LocationIntegrationsTable';
import ExternalLocationsSearch, {
  defaultTableKeys as defaultSearchTableKeys,
} from '#components/locations/ExternalLocationsSearch';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.locations.locationIntegrations;
const localeTableKeys = locale.keys.tables.locationIntegrations;
const localeNotificationKeys = locale.keys.notifications;
const localeButtonKeys = locale.keys.buttons;

interface LocationIntegrationsProps {
  location : Location;
}

function LocationIntegrations({ location } : LocationIntegrationsProps) {
  const { createNotification } = useNotifications();
  const {
    createLocationIntegration,
    deleteLocationIntegration,
  } = useServices();

  const [
    locationIntegrations,
    setLocationIntegrations,
  ] = useState<LocationIntegration[] | null>(
    location.integrations ? Object.values(location.integrations) : null,
  );

  const [
    removeTarget,
    setRemoveTarget,
  ] = useState<LocationIntegration | null>(null);
  const [managing, setManaging] = useState(false);

  const link = useCallback((ext : ExternalLocation) => async () => {
    const updatedLocation = await createLocationIntegration(location, ext);

    if (updatedLocation) {
      createNotification({
        key : 'link-location-success',
        message : localize(localeNotificationKeys.locations.link.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.store} />,
      });
    } else {
      createNotification({
        key : 'link-location-error',
        message : localize(localeNotificationKeys.locations.link.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.store} />,
      });
    }
  }, [createLocationIntegration, createNotification, location]);

  const initUnlink = useCallback((integration : LocationIntegration) => () => {
    setRemoveTarget(integration);
  }, []);

  const cancelUnlink = useCallback(() => {
    setRemoveTarget(null);
  }, []);

  const unlink = useCallback(async () => {
    if (!removeTarget) return;

    const updatedLocation = await deleteLocationIntegration(
      location,
      removeTarget,
    );

    if (updatedLocation) {
      createNotification({
        key : 'unlink-location-success',
        message : localize(localeNotificationKeys.locations.unlink.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.store} />,
      });
    } else {
      createNotification({
        key : 'unlink-location-error',
        message : localize(localeNotificationKeys.locations.unlink.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.store} />,
      });
    }
    setRemoveTarget(null);
  }, [createNotification, deleteLocationIntegration, location, removeTarget]);

  const edit = useCallback(() => { setManaging(true); }, []);
  const close = useCallback(() => { setManaging(false); }, []);

  const generateIntegrationActions = useCallback(
    (integration : LocationIntegration) => (
      managing
        ? (<TableActionCell>
          <Action
            label={localize(localeTableKeys.actions.unlink)}
            onClick={initUnlink(integration)}
            disabled={removeTarget !== null}
            colour={settings.colours.button.alert}
          >
            <Icon icon={settings.svgIcons.linkOff} />
          </Action>
        </TableActionCell>) : (<TableActionCell />)
    ),
    [managing, removeTarget, initUnlink],
  );

  const generateSearchActions = useCallback(
    (ext : ExternalLocation) => (
      managing
        ? (<TableActionCell>
          <Action
            label={localize(localeTableKeys.actions.link)}
            onClick={link(ext)}
            disabled={removeTarget !== null}
            colour={settings.colours.button.primary}
          >
            <Icon icon={settings.svgIcons.link} />
          </Action>
        </TableActionCell>) : (<TableActionCell />)
    ),
    [managing, removeTarget, link],
  );

  const filterExternal = useCallback((externalLocations : ExternalLocation[]) => {
    return externalLocations.filter((externalLocation) => {
      return !locationIntegrations?.some((locaitonIntegration) => {
        return locaitonIntegration.externalId === externalLocation.externalId;
      });
    });
  }, [locationIntegrations]);

  useEffect(() => {
    if (!location.integrations) return;
    setLocationIntegrations(Object.values(location.integrations));
  }, [location]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { removeTarget && (
        <Banner
          onClose={cancelUnlink}
          colour={settings.colours.alert.alert}
          icon={<Icon icon={settings.svgIcons.linkOff} />}
          actions={
            <>
              <Button onClick={unlink}>
                {localize(localeButtonKeys.remove)}
              </Button>
              <Button onClick={cancelUnlink}>
                {localize(localeButtonKeys.cancel)}
              </Button>
            </>
          }
        >
          { localize(localeContentKeys.confirmUnlink) +
            ` (${removeTarget.externalId})` }
        </Banner>
      ) }
      { locationIntegrations?.length
        ? (
          <LocationIntegrationsTable
            locationIntegrations={locationIntegrations ?? []}
            tableKeys={defatultTableKeys}
            generateActions={generateIntegrationActions}
          />
        ) : (
          <Text>{localize(localeContentKeys.notAssigned)}</Text>
        )
      }
      { managing
        ? (<>
          <Segment title={localize(localeContentKeys.availableExternal)} />
          <ExternalLocationsSearch
            filter={filterExternal}
            tableKeys={defaultSearchTableKeys}
            generateActions={generateSearchActions}
          />
          <Button onClick={close}>
            { localize(localeButtonKeys.close) }
          </Button>
        </>
        ) : (<>
          <Button onClick={edit}>
            { localize(localeButtonKeys.edit) }
          </Button>
        </>)
      }
    </Section>
  );
}

export default LocationIntegrations;
