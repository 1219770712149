import React, { useCallback, useEffect, useState } from 'react';

import {
  Location,
  ServiceChannel,
  TimeSlot,
  Schedule,
  ScheduleSelection,
  Route,
  Customer,
  DraftCustomOrder,
} from '#types';

import { FormProvider } from '#context/FormContext';

import useForm from '#hooks/useForm';
import useCustomers from '#hooks/useCustomers';
import useServices from '#hooks/useServices';
import useScheduling from '#hooks/useScheduling';
import useRoutes from '#hooks/useRoutes';
import useSubscriptions from '#hooks/useSubscriptions';

import { settings } from '#materials';
import Form from '#materials/Form';
import Select from '#materials/Select';

import TimeSlotPicker from '#components/timeslots/TimeSlotPicker';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeFormKeys = locale.keys.forms.orders;

interface FormErrors {
  location? : string[];
  route? : string[];
  timeSlot? : string[];
  fromDate? : string[];
  toDate? : string[];
}

interface FormState {
  serviceChannel? : ServiceChannel | null;
  location? : Location | null;
  route? : Route | false | null;
  timeSlot? : TimeSlot | null;
  customer? : Customer | null;
  fromDate? : Date | null;
  toDate? : Date | null;
}

interface OrdersSearchProps extends FormState {
  setCustomer? : (customer : Customer | null) => void;
  setServiceChannel? : (channel : ServiceChannel | null) => void;
  setLocation? : (location : Location | null) => void;
  setRoute? : (route : Route | false | null) => void;
  setTimeSlot? : (timeSlot : TimeSlot | null) => void;
  setFromDate? : (fromDate : Date | null) => void;
  setToDate? : (toDate : Date | null) => void;
  setOrders? : (orders : DraftCustomOrder[]) => void;
}

function OrdersSearchControl({
  setCustomer,
  setServiceChannel,
  setLocation,
  setRoute,
  setTimeSlot,
  setFromDate,
  setToDate,
  setOrders,
  ...props
} : OrdersSearchProps) {
  const { state, dispatch } = useForm<FormState>();
  const customer = state?.customer ?? props.customer ?? null;
  const serviceChannel = state?.serviceChannel ?? props.serviceChannel ?? null;
  const location = state?.location ?? props.location ?? null;
  const route = state?.route ?? props.route ?? null;
  const timeSlot = state?.timeSlot ?? props.timeSlot ?? null;
  const fromDate = state?.fromDate ?? props.fromDate ?? null;
  const toDate = state?.toDate ?? props.toDate ?? null;

  const { customers : allCustomers } = useCustomers();
  const {
    services,
    locations : allLocations,
    serviceChannels : allChannels,
    getChannelServices,
  } = useServices();
  const { getServiceSchedules, getScheduleTimeSlots } = useScheduling();
  const {
    retrieveAddressRoutesSync,
    getServiceRoutes,
    isServiceDelivery,
  } = useRoutes();
  const { projectedOrders : orders } = useSubscriptions();

  const [serviceChannels, setServiceChannels] = useState<ServiceChannel[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [routes, setRoutes] = useState<(Route | false)[]>([]);
  const [customers, setCustomers] = useState<Customer[]>(
    listRecords(allCustomers)
  );

  const [errors, setErrors] = useState<FormErrors>({});

  const [dateSelection, setDateSelection] = useState<ScheduleSelection[]>([]);

  const dispatchCustomer = useCallback((customer : Customer | null) => {
    dispatch({ customer });
    if (setCustomer) setCustomer(customer);
  }, [dispatch, setCustomer]);
  const dispatchChannel = useCallback((channel : ServiceChannel | null) => {
    dispatch({ serviceChannel : channel });
    if (setServiceChannel) setServiceChannel(channel);
  }, [dispatch, setServiceChannel]);
  const dispatchLocation = useCallback((location : Location | null) => {
    dispatch({ location });
    if (setLocation) setLocation(location);
  }, [dispatch, setLocation]);
  const dispatchRoute = useCallback((route : Route | false | null) => {
    dispatch({ route });
    if (setRoute) setRoute(route);
  }, [dispatch, setRoute]);
  const dispatchTimeSlot = useCallback((timeSlot : TimeSlot | null) => {
    dispatch({ timeSlot });
    if (setTimeSlot) setTimeSlot(timeSlot);
  }, [dispatch, setTimeSlot]);
  const dispatchFromDate = useCallback((fromDate : Date | null) => {
    dispatch({ fromDate });
    if (setFromDate) setFromDate(fromDate);
  }, [dispatch, setFromDate]);
  const dispatchToDate = useCallback((toDate : Date | null) => {
    dispatch({ toDate });
    if (setToDate) setToDate(toDate);
  }, [dispatch, setToDate]);

  useEffect(() => { setCustomers(listRecords(allCustomers)); }, [allCustomers]);
  useEffect(() => {
    setServiceChannels(listRecords(allChannels));
  }, [allChannels]);

  useEffect(() => {
    const channelServices = serviceChannel
      ? getChannelServices(serviceChannel)
      : listRecords(services);
    setLocations(listRecords(allLocations).filter(location => (
      channelServices.some(service => service.locationId === location.id)
    )));

    const locationServices = location
      ? channelServices.filter(service => service.locationId === location.id)
      : channelServices;
    const pickupAvailable = locationServices.some(
      service => !isServiceDelivery(service)
    );
    setRoutes([
      ...((pickupAvailable ? [false] : []) as (false)[]),
      ...getServiceRoutes(locationServices),
    ]);

    const routeServices = route === null
      ? locationServices
      : route === false
        ? locationServices.filter(service => !isServiceDelivery(service))
        : locationServices.filter(service => getServiceRoutes(service).some(
          r => r.id === route.id
        ));
    setSchedules(getServiceSchedules(routeServices));
    setTimeSlots(getScheduleTimeSlots(getServiceSchedules(routeServices)));
  }, [
    serviceChannel,
    location,
    route,
    services,
    allLocations,
    getChannelServices,
    isServiceDelivery,
    getServiceRoutes,
    getServiceSchedules,
    getScheduleTimeSlots,
  ]);

  useEffect(() => {
    if (!setOrders) return;
    setOrders((orders?.filter(order => (
      (order.complete || order.service)
        && (!serviceChannel || order.serviceChannel?.id === serviceChannel.id)
        && (!location || order.location?.id === location.id)
        && (route == null
          || (route === false
            ? order.address === null
            : (order.address
              && retrieveAddressRoutesSync(order.address)
                ?.some(r => route && r.id === route.id)))) &&
      (!timeSlot || order.timeSlot?.id === timeSlot.id) &&
      (!fromDate || dateSelection.some(
        s => s.timeSlot.id === order.timeSlot?.id
          && s.iteration === order.timeSlotIteration
      )) &&
      (!customer || order.customer?.id === customer.id)
    )) ?? []).sort(
      (a, b) => (a.time?.getTime() ?? 0) - (b.time?.getTime() ?? 0)
    ));
  }, [
    setOrders,
    orders,
    serviceChannel,
    location,
    route,
    timeSlot,
    fromDate,
    dateSelection,
    customer,
    retrieveAddressRoutesSync,
  ]);

  useEffect(() => {
    setErrors({
      location : (location && !locations.some(l => l.id === location.id))
        ? [localize(localeFormKeys.errors.invalidLocation)]
        : [],
      route : route !== null && ((route === false && !routes.includes(false))
        || (route !== false && !routes.some(r => r && r.id === route.id))
      ) ? [localize(localeFormKeys.errors.invalidRoute)] : [],
      timeSlot : timeSlot && !timeSlots.includes(timeSlot)
        ? [localize(localeFormKeys.errors.invalidTimeSlot)]
        : [],
      fromDate : (timeSlot
        && fromDate
        && !dateSelection.some(s => s.timeSlot.id === timeSlot.id))
          ? [localize(localeFormKeys.errors.invalidDate)]
          : [],
      toDate : (toDate && !fromDate)
        ? [localize(localeFormKeys.errors.fromDateRequired)]
        : [],
    });
  }, [
    location,
    route,
    timeSlot,
    fromDate,
    toDate,
    dateSelection,
    locations,
    routes,
    timeSlots,
  ]);

  return (
    <>
      <Form>
        <Select
          label={localize(localeFormKeys.labels.serviceChannel)}
          selected={serviceChannel}
          onChange={dispatchChannel}
          options={serviceChannels}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={service => service?.name ?? ''}
          keyGenerator={service => `${service?.id}` ?? ''}
          width={settings.dimensions.third}
        />
        <Select
          label={localize(localeFormKeys.labels.location)}
          selected={location}
          onChange={dispatchLocation}
          options={locations}
          errors={errors.location}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={location => location?.name ?? ''}
          keyGenerator={location => `${location?.id}` ?? ''}
          width={settings.dimensions.third}
        />
        <Select
          label={localize(localeFormKeys.labels.route)}
          selected={route}
          onChange={dispatchRoute}
          options={routes}
          errors={errors.route}
          isEqual={(a, b) => a === false
            ? b === false
            : (b !== false && a?.id === b?.id)
          }
          labelGenerator={route => route
            ? route?.name
            : localize(localeFormKeys.values.pickup)}
          keyGenerator={route => route === false
            ? 'pickup'
            : `${route?.id}` ?? ''}
          width={settings.dimensions.third}
        />
        <Select
          label={localize(localeFormKeys.labels.timeSlot)}
          selected={timeSlot}
          onChange={dispatchTimeSlot}
          options={timeSlots}
          errors={errors.timeSlot}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={timeSlot => timeSlot?.name ?? ''}
          keyGenerator={timeSlot => `${timeSlot?.id}` ?? ''}
          width={settings.dimensions.quarter}
        />
        <TimeSlotPicker
          date={fromDate}
          toDate={toDate}
          timeSlot={timeSlot}
          schedules={schedules}
          onChange={setDateSelection}
          onDateChange={dispatchFromDate}
          onToDateChange={dispatchToDate}
          selectTime={false}
          selectRange
          allowClear
          errors={errors.fromDate}
          toErrors={errors.toDate}
          width={settings.dimensions.quarter}
        />
        <Select
          label={localize(localeFormKeys.labels.customer)}
          selected={customer}
          onChange={dispatchCustomer}
          options={customers}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={customer => customer?.defaultName
            ?? localize(localeFormKeys.values.anonymous)}
          keyGenerator={customer => `${customer?.id}` ?? ''}
          width={settings.dimensions.quarter}
        />
      </Form>
    </>
  );
}

function OrdersSearch(props : OrdersSearchProps) {
  return (
    <FormProvider init={props as FormState}>
      <OrdersSearchControl {...props} />
    </FormProvider>
  );
}

export default OrdersSearch;
