import React, { useCallback, useEffect, useState } from 'react';

import { Address, Route } from '#types';

import useRoutes from '#hooks/useRoutes';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';
import { formatPostal } from '#utils/postal';

const localKeys = locale.keys.tables.addresses;

export const TABLE_KEYS = {
  id: 'id',
  street: 'street',
  city: 'city',
  postal: 'postal',
  routes: 'routes',
  actions: 'actions',
}
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

const defaultKeys = [
  TABLE_KEYS.id,
  TABLE_KEYS.street,
  TABLE_KEYS.city,
  TABLE_KEYS.postal,
  TABLE_KEYS.actions,
]

interface AddressTableProps {
  addresses : Address[];
  count : number;
  tableKeys? : TableKeys[];
  generateActions? : (address : Address) => CellElement;
}

function AddressTable({
  addresses,
  count,
  tableKeys = defaultKeys,
  generateActions = () => <TableCell />,
} : AddressTableProps) {
  const { retrieveAddressRoutes } = useRoutes();

  const [
    addressRoutes,
    setAddressRoutes,
  ] = useState<{ [id : number] : Route[] }>({});

  const headGenerator = useCallback((key : string) => {
    switch (key) {
      case 'id':
        return <TableCell>{ localize(localKeys.headings.id) }</TableCell>;
      case 'street':
        return <TableCell>{ localize(localKeys.headings.street) }</TableCell>;
      case 'city':
        return <TableCell>{ localize(localKeys.headings.city) }</TableCell>;
      case 'postal':
        return <TableCell>{ localize(localKeys.headings.postal) }</TableCell>;
      case 'routes':
        return <TableCell>{ localize(localKeys.headings.routes) }</TableCell>;
      case 'action': return <TableCell />;
      default: return <TableCell />;
    }
  }, []);

  const rowGenerator = useCallback((key : string, index : number) => {
    const address = addresses[index];
    switch (key) {
      case 'id': return <TableCell>{ `# ${address.id}` }</TableCell>;
      case 'street': return <TableCell>{ address.street }</TableCell>;
      case 'city': return <TableCell>{ address.city }</TableCell>;
      case 'postal':
        return <TableCell>{ formatPostal(address.postal) }</TableCell>;
      case 'routes':
        const routes = address.id ? addressRoutes[address.id] : undefined;
        if (!routes) return <TableCell />;
        return <TableCell>
          { routes.map(r => r.name).join(', ') }
        </TableCell>;
      case 'actions':
        return generateActions(address);
      default: return <TableCell />;
    }
  }, [addresses, addressRoutes, generateActions]);

  useEffect(() => {
    async function retrieveRoutes() {
      const newAddressRoutes : { [id : number] : Route[] } = {};
      addresses.forEach(async (address) => {
        if (!address.id) return;
        const routes = await retrieveAddressRoutes(address);
        if (!routes) return;
        newAddressRoutes[address.id] = routes;
      });
      setAddressRoutes(newAddressRoutes);
    }
    retrieveRoutes();
  }, [addresses, retrieveAddressRoutes]);

  return (
    <Table
      keys={tableKeys}
      length={count}
      headGenerator={headGenerator}
      rowGenerator={rowGenerator}
    />
  );
}

export default AddressTable;
