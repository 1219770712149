import React, { useCallback, useEffect, useState, useRef } from 'react';

import Box from '@mui/material/Box/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import {
  Alignment,
  Spacing,
  Dimension,
  InputType,
  InputFormat,
  convert,
  settings,
} from '#materials/types';
import FlexItem from '#materials/FlexItem';

import * as inputUtils from '#utils/input';

const DAY = 24 * 60 * 60 * 1000;

interface TextInputBaseProps {
  id : string;
  label? : string;
  autoComplete? : string;
  minRows? : number;
  maxRows? : number;
  errors? : string[];
  showErrorText? : boolean;
  password? : boolean;
  disabled? : boolean;
  textAlign? : Alignment;
  startAdornment? : React.ReactNode;
  endAdornment? : React.ReactNode;
  width? : Dimension;
  spacing? : Spacing;
}

interface StringInputProps extends TextInputBaseProps {
  value? : string;
  onChange? : (value: string) => void;
  maxLength? : number;
  inputType? : typeof settings.inputType.string;
}
interface NumberInputProps extends TextInputBaseProps {
  value : number | null;
  onChange? : (value: number | null) => void;
  inputType : typeof settings.inputType.number;
  inputFormat? : typeof settings.inputFormat.int |
    typeof settings.inputFormat.float |
    typeof settings.inputFormat.currency;
  inputPrecision? : number;
}
interface TimeInputProps extends TextInputBaseProps {
  value : number;
  max? : number;
  onChange? : (value: number) => void;
  inputType? : typeof settings.inputType.time;
}

interface TextInputProps extends TextInputBaseProps {
  value? : any;
  onChange? : any;
  maxLength? : number;
  inputType? : InputType;
  inputFormat? : InputFormat
}

interface ManagedInputProps extends TextInputProps {
  setInput? : inputUtils.InputHandler;
  cleanInput? : (input : string) => string | undefined;
  parseInput? : (input : string) => string | undefined;
  onKeyDown? : inputUtils.KeyDownHandler;
  onSelect? : inputUtils.SelectHandler;
  onBlur? : inputUtils.BlurHandler;
}
interface BaseInputProps extends TextInputProps {
  inputRef? : React.RefObject<HTMLInputElement>;
  onKeyDown? : (event : React.KeyboardEvent<HTMLInputElement>) => void;
  onSelect? : (event : React.FocusEvent<HTMLInputElement>) => void;
  onInput? : (event : React.ChangeEvent<HTMLInputElement>) => void;
  onBlur? : (event : React.FocusEvent<HTMLInputElement>) => void;
}

interface useTextInputProps {
  value : string,
  onChange? : (value: string) => void;
  cleanInput? : (input : string) => string | undefined;
  parseInput? : (input : string) => string | undefined;
  setInput? : inputUtils.InputHandler;
  onKeyDown? : inputUtils.KeyDownHandler;
  onSelect? : inputUtils.SelectHandler;
  onBlur? : inputUtils.BlurHandler;
  ref : React.RefObject<HTMLInputElement>;
}

function useTextInput({
  value,
  onChange,
  cleanInput,
  parseInput,
  setInput = inputUtils.setInput,
  onKeyDown,
  onSelect,
  onBlur,
  ref,
} : useTextInputProps) {
  const [deferedInput, setDeferedInput] = useState('');

  const handleChange = useCallback((value : string) => {
    if (!onChange) return;
    const cleaned = cleanInput ? cleanInput(value) : value;
    if (cleaned !== undefined) onChange(cleaned);
  }, [onChange, cleanInput]);

  const setInputAndChange = useCallback(
    (args : inputUtils.InputHandlerArgs) => {
      setInput({
        ...args,
        value : parseInput ? parseInput(args.value) ?? '' : args.value,
      });
      handleChange(args.value);
    },
    [setInput, handleChange, parseInput],
  );

  const handleInput = useCallback(
    (event : React.ChangeEvent<HTMLInputElement>) => {
      if (!ref.current) return;

      const input = event.target.value;
      const cleaned = (cleanInput ? cleanInput(input) : input) ?? value;
      if (input !== cleaned) {
        const cursor = ref.current.selectionStart
          ? ref.current.selectionStart
          : null;
        ref.current.value = cleaned;
        ref.current.selectionStart = cursor ? cursor - 1 : cursor;
        ref.current.selectionEnd = cursor ? cursor - 1 : cursor;
      } else if (cleaned !== value) {
        handleChange(cleaned);
      }
    },
    [value, ref, handleChange, cleanInput],
  );

  const handleKeyDown = useCallback(
    (event : React.KeyboardEvent<HTMLInputElement>) => onKeyDown
      ? onKeyDown({
        ref,
        event,
        setInput : setInputAndChange,
        deferedInput,
        setDeferedInput,
      })
      : undefined,
    [ref, setInputAndChange, onKeyDown, deferedInput, setDeferedInput],
  );

  const handleSelect = useCallback(
    (event : React.FocusEvent<HTMLInputElement>) => onSelect
      ? onSelect({
        ref,
        event,
        setInput : setInputAndChange,
        deferedInput,
        setDeferedInput,
      })
      : undefined,
      [ref, setInputAndChange, onSelect, deferedInput, setDeferedInput],
  );

  const handleBlur = useCallback(
    (event : React.FocusEvent<HTMLInputElement>) => onBlur
      ? onBlur({
        ref,
        event,
        setInput : setInputAndChange,
        deferedInput,
        setDeferedInput,
      })
      : undefined,
      [ref, setInputAndChange, onBlur, deferedInput, setDeferedInput],
  );

  useEffect(() => {
    if (ref && ref.current) ref.current.value = parseInput
      ? (parseInput(value) ?? '')
      : value;
  }, [value, ref, parseInput]);

  useEffect(() => {
    if (ref && ref.current) {
      if (
        !parseInput ||
        parseInput(value) !== parseInput(ref.current.value)
      ) {
        ref.current.value = parseInput ? (parseInput(value) ?? '') : value;
      }
    }
  }, [value, ref, parseInput]);

  return {
    handleChange,
    handleInput,
    handleKeyDown,
    handleSelect,
    handleBlur,
  }
}

function BaseInput({
  id,
  label,
  autoComplete,
  value,
  maxLength,
  minRows,
  maxRows,
  errors,
  showErrorText = true,
  inputRef,
  password = false,
  disabled = false,
  onChange,
  onKeyDown,
  onInput,
  onSelect,
  onBlur,
  textAlign = settings.alignments.left,
  startAdornment,
  endAdornment,
  width = settings.dimensions.full,
  spacing = settings.spacings.medium,
} : BaseInputProps) {
  const handleChange = useCallback(
    (event : React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(
        maxLength ? event.target.value.slice(0, maxLength) : event.target.value,
      );
    },
    [onChange, maxLength],
  );

  return (
    <FlexItem
      width={settings.dimensions.full}
      maxWidth={width}
    >
      <Box
        sx={{
          ...(spacing !== settings.spacings.dense
            ? { m : [1] }
            : {
              height : 48,
              m : [0],
              py : [0.6],
              boxSizing : 'border-box',
            }
          ),
        }}
      >
        <TextField
          id={`text-input-${id}`}
          type={password ? 'password' : 'text'}
          label={label}
          autoComplete={autoComplete}
          value={value}
          multiline={minRows !== undefined || maxRows !== undefined}
          minRows={minRows ? 1 + ((72 / 23) * (minRows - 1)) : undefined}
          maxRows={maxRows ? 1 + ((72 / 23) * (maxRows - 1)) : undefined}
          error={errors && errors.length > 0}
          helperText={showErrorText && errors && errors.length > 0
            ? errors.join('. ') + '.'
            : undefined
          }
          disabled={disabled}
          onChange={handleChange}
          onInput={onInput}
          onKeyDown={onKeyDown}
          onSelect={onSelect}
          onBlur={onBlur}
          inputRef={inputRef}
          size={spacing !== settings.spacings.dense ? "medium" : "small"}
          InputProps={{
            startAdornment : <InputAdornment position="start">
              { startAdornment }
            </InputAdornment>,
            endAdornment : <InputAdornment position="end">
              { endAdornment }
            </InputAdornment>,
          }}
          inputProps={{
            style : { textAlign : convert.textAlignment(textAlign) },
            autoComplete : autoComplete,
          }}
          sx = {{
            minWidth : convert.width(settings.dimensions.full),
            "& .MuiInputBase-input.Mui-disabled" : {
              WebkitTextFillColor : "#444",
            },
            ...(spacing === settings.spacings.dense && {
              "& .MuiInputBase-input" : {
                fontSize : "0.875rem",
              },
            }),
          }}
        />
      </Box>
    </FlexItem>
  );
}

function NumberInput({
  value,
  onChange,
  ...props
} : NumberInputProps) {
  const [input, setInput] = useState(value !== null ? value.toString() : '');

  const handleChange = useCallback((value : string) => {
    const n = Number(value);
    if (!isNaN(n) && onChange) onChange(n);
  }, [onChange]);

  useEffect(() => {
    setInput(value !== null ? value.toString() : '');
  }, [value]);

  return BaseInput({
    ...props,
    value : input,
    onChange : handleChange,
  });
}

export function ManagedInput({
  setInput,
  cleanInput,
  parseInput,
  onKeyDown,
  onSelect,
  onBlur,
  ...props
} : ManagedInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    handleChange,
    handleInput,
    handleKeyDown,
    handleSelect,
    handleBlur,
  } = useTextInput({
    ...props,
    value : props.value ?? '',
    setInput: setInput,
    cleanInput,
    parseInput,
    onKeyDown,
    onSelect,
    onBlur,
    ref : inputRef,
  });

  return (
    <BaseInput
      {...props}
      value={undefined}
      onChange={handleChange}
      onInput={handleInput}
      onKeyDown={handleKeyDown}
      onSelect={handleSelect}
      onBlur={handleBlur}
      inputRef={inputRef}
    />
  );
}

export function FloatInput({
  value,
  onChange,
  inputPrecision,
  ...props
} : NumberInputProps) {
  const [num, setNum] = useState(value
    ? inputUtils.printFloat(value, inputPrecision)
    : '0.0'
  );

  const handleChange = useCallback((input : string) => {
    if (onChange) onChange(parseFloat(input));
  }, [onChange]);

  const parseInput = useCallback((input : string) => {
    return inputUtils.cleanFloat(input, true, inputPrecision);
  }, [inputPrecision]);

  useEffect(() => {
    setNum(value ? inputUtils.printFloat(value, inputPrecision) : '0.0');
  }, [value, inputPrecision]);

  return ManagedInput({
    ...props,
    value : num,
    onChange : handleChange,
    cleanInput : inputUtils.cleanFloat,
    parseInput,
    onKeyDown : inputUtils.handleFloatKeyDown(inputPrecision),
    onSelect : inputUtils.handleFloatSelect,
    onBlur : inputUtils.handleFloatBlur,
  });
}

export function CurrencyInput({
  value,
  ...props
} : NumberInputProps) {
  return FloatInput({
    value,
    ...props,
    inputPrecision : 2,
    startAdornment : '$',
  });
}

export function TimeInput({
  value,
  max = DAY,
  onChange,
  ...props
} : TimeInputProps) {
  const [time, setTime] = useState(max === DAY
    ? inputUtils.printTime(value)
    : inputUtils.printDuration(value)
  );
  const handleChange = useCallback((time : string) => {
    const parsed = inputUtils.parseTime(time);
    if (parsed !== undefined) onChange?.(parsed);
  }, [onChange]);

  useEffect(() => {
    setTime(max === DAY
      ? inputUtils.printTime(value)
      : inputUtils.printDuration(value)
    );
  }, [value, max]);

  return ManagedInput({
    ...props,
    value : time,
    cleanInput : inputUtils.cleanTime,
    onChange : handleChange,
    onKeyDown : max === DAY
      ? inputUtils.handleTimeInput
      : inputUtils.handleDurationInput({ max }),
    onSelect : max === DAY
      ? inputUtils.handleTimeSelect
      : inputUtils.handleDurationSelect,
    textAlign : settings.alignments.right,
    endAdornment : 'hrs',
  });
}

function TextInput(props : StringInputProps) : JSX.Element;
function TextInput(props : NumberInputProps) : JSX.Element;
function TextInput(props : TimeInputProps) : JSX.Element;
function TextInput(props : TextInputProps) {
  switch (props.inputType) {
    case settings.inputType.number:
      switch (props.inputFormat) {
        case settings.inputFormat.int:
          return (
            <NumberInput
              {...props}
              value={props.value ?? null}
              inputType={settings.inputType.number}
            />
          );
        case settings.inputFormat.currency:
          return (
            <CurrencyInput
              {...props}
              value={props.value ?? null}
              inputType={settings.inputType.number}
            />
          );
        default:
          return (
            <FloatInput
              {...props}
              value={props.value ?? null}
              inputType={settings.inputType.number}
            />
          );
      }
    case settings.inputType.time:
      return (
        <TimeInput
          {...props}
          value={props.value ?? null}
          inputType={settings.inputType.time}
        />
      );
    default:
      return (
        <BaseInput {...props} inputType={undefined}/>
      );
  }
}

export default TextInput;
