import React, { useCallback, useEffect, useState } from 'react';

import { Service } from '#types';

import useServices from '#hooks/useServices';

import Section from '#components/dashboard/Section';
import ServiceDetails from '#components/services/ServiceDetails';
import ServiceSchedules from '#components/services/ServiceSchedules';
import ServiceRoutes from '#components/services/ServiceRoutes';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.services.serviceInfo;

interface ServiceInfoProps {
  serviceId : number;
}

function ServiceInfo({ serviceId } : ServiceInfoProps) {
  const { retrieveService } = useServices();

  const [service, setService] = useState<Service | null>(null);

  const retrieve = useCallback(async () => {
    const service = await retrieveService(serviceId);
    setService(service);
  }, [serviceId, retrieveService, setService]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <>
      <Section
        title={`${service ? service?.name : ''} (#${serviceId})`}
        text={service ? '' : localize(localeContentKeys.loading)}
      >
        { !!service &&
          <ServiceDetails service={service} />
        }
      </Section>
      { !!service &&
        <>
          <ServiceSchedules service={service} onUpdate={retrieve} />
          <ServiceRoutes service={service} />
        </>
      }
    </>
  )
}

export default ServiceInfo;
