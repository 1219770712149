import React from 'react';

declare module '@mui/material/styles' {
  interface Theme {
    layout : {
      barHeight : number;
      drawerWidth : number;
    };
  }

  interface ThemeOptions {
    layout? : {
      barHeight? : number;
      drawerWidth? : number;
    };
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    alert : Palette['primary'],
    bg : Palette['primary'],
  }

  interface PaletteOptions {
    alert? : PaletteOptions['primary']
    bg? : PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    success: true;
    alert: true;
    bg: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    success: true;
    alert: true;
    bg: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    success: true;
    alert: true;
    bg: true;
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    primary: true;
    secondary: true;
    bg: true;
    success: true;
    alert: true;
  }
}

export type OptionalChild<Element> = Element | null | false;
export type OptionalChildren<Element> = OptionalChild<Element>
  | OptionalChild<Element>[];

type Fragment<ET extends React.ReactElement> = React.ReactElement<{
  children: ET | ET[];
}>

export type OptionalFragment<ET extends React.ReactElement> = ET |
  ET[] |
  Fragment<ET>;

export function cloneElements<
  ET extends React.ReactElement<PT>,
  PT extends {} = {}
> (
  element? : OptionalFragment<ET>,
  props? : Partial<PT>,
  predictate? :
    (element: any) => element is ET
) : React.ReactNode {
  if (!element) return undefined;

  const isElement = predictate ? predictate : React.isValidElement<PT>;
  if (isElement(element)) return React.cloneElement(element, props)
  if (Array.isArray(element)) {
    return element.map((child) => cloneElements(child, props, predictate));
  }

  return React.Children.toArray(element.props.children).map((child) => {
    return isElement(child) ? React.cloneElement(child, props) : undefined
});
}

const components = {
  h1 : 'h1' as const,
  h2 : 'h2' as const,
  h3 : 'h3' as const,
  main : 'main' as const,
  form : 'form' as const,
  label : 'label' as const,
  button : 'button' as const,
  div : 'div' as const,
};
type ComponentKey = keyof typeof components;
export type Component = typeof components[ComponentKey];

const variants = {
  typography : {
    h1 : 'h1' as const,
    h2 : 'h2' as const,
    h3 : 'h3' as const,
    h4 : 'h4' as const,
    h5 : 'h5' as const,
    h6 : 'h6' as const,
    body1 : 'body1' as const,
    body2 : 'body2' as const,
  },
  button : {
    full : 'full' as const,
    inline : 'inline' as const,
    table : 'table' as const,
    text : 'text' as const,
  }
};
type VariantTypographyKey = keyof typeof variants.typography;
type VariantButtonKey = keyof typeof variants.button;
export type TypographyVariant = typeof variants.typography[VariantTypographyKey];
export type ButtonVariant = typeof variants.button[VariantButtonKey];

const sizes = {
  xsmall : 'xsmall' as const,
  small : 'small' as const,
  medium : 'medium' as const,
  large : 'large' as const,
}
type SizeKey = keyof typeof sizes;
export type Size = typeof sizes[SizeKey];

const directions = {
  row : 'row' as const,
  column : 'column' as const,
}
type DirectionKey = keyof typeof directions;
export type Direction = typeof directions[DirectionKey];

const dimensions = {
  auto : 'auto' as const,
  ...sizes,
  quarter : 'quarter' as const,
  third : 'third' as const,
  half : 'half' as const,
  twoThirds : 'twoThirds' as const,
  threeQuarters : 'threeQuarters' as const,
  full : 'full' as const,
  fullview : 'fullview' as const,
  row : 'row' as const,
}
type DimensionKey = keyof typeof dimensions;
export type Dimension = typeof dimensions[DimensionKey];

const spacings = {
  dense : 'dense' as const,
  small : 'sm' as const,
  medium : 'md' as const,
  large : 'lg' as const,
  xlarge : 'xl' as const,
  xxlarge : 'xxl' as const,
}
type SpacingKey = keyof typeof spacings;
export type Spacing = typeof spacings[SpacingKey];

export interface Margin {
  x? : Spacing;
  y? : Spacing;
  top? : Spacing;
  left? : Spacing;
  bottom? : Spacing;
  right? : Spacing;
}
export interface Padding extends Margin {};

export type Colour = string;
export const colours = {
  text : {
      primary : 'text.primary' as Colour,
      secondary : 'text.secondary' as Colour,
  },
  button : {
    success : 'success' as const,
    primary : 'primary' as const,
    secondary : 'secondary' as const,
    alert : 'alert' as const,
    bg : 'bg' as const,
  },
  alert : {
    success : 'success' as const,
    primary : 'success' as const,
    secondary : 'secondary' as const,
    alert : 'alert' as const,
  }
};
type ButtonColourKey = keyof typeof colours.button;
export type ButtonColour = typeof colours.button[ButtonColourKey];
type AlertColourKey = keyof typeof colours.alert;
export type AlertColour = typeof colours.alert[AlertColourKey];

const alignments = {
    start : 'start' as const,
    left : 'start' as const,
    center : 'center' as const,
    right : 'end' as const,
    end : 'end' as const,
    spaceBetween : 'space-between' as const,
};
type AlignmentKey = keyof typeof alignments;
export type Alignment = typeof alignments[AlignmentKey];

const fonts = {
  default : 'default' as const,
  mono : 'mono' as const,
  brand : 'brand' as const,
}
type FontKey = keyof typeof fonts;
export type Font = typeof fonts[FontKey];

const svgIcons = {
  accessTime : 'accessTime' as const,
  add : 'add' as const,
  agriculture : 'agriculture' as const,
  assignment : 'assignment' as const,
  callSplit : 'callSplit' as const,
  category : 'category' as const,
  check : 'check' as const,
  chevronLeft : 'chevronLeft' as const,
  clear : 'clear' as const,
  cloudUpload : 'cloudUpload' as const,
  copy : 'copy' as const,
  delete : 'delete' as const,
  dateRange : 'dateRange' as const,
  download : 'download' as const,
  edit : 'edit' as const,
  expandLess : 'expandLess' as const,
  expandMore : 'expandMore' as const,
  extension : 'extension' as const,
  menu : 'menu' as const,
  image : 'image' as const,
  info : 'info' as const,
  integrationInstructions : 'integrationInstructions' as const,
  keyboardArrowUp : 'keyboardArrowUp' as const,
  keyboardArrowDown : 'keyboardArrowDown' as const,
  keyboardDoubleArrowUp : 'keyboardDoubleArrowUp' as const,
  keyboardDoubleArrowDown : 'keyboardDoubleArrowDown' as const,
  link : 'link' as const,
  linkOff : 'linkOff' as const,
  localOffer : 'localOffer' as const,
  localShipping : 'localShipping' as const,
  loyalty : 'loyalty' as const,
  percent : 'percent' as const,
  person : 'person' as const,
  pinDrop : 'pinDrop' as const,
  pointOfSale : 'pointOfSale' as const,
  receipt: 'receipt' as const,
  refresh : 'refresh' as const,
  remove : 'remove' as const,
  route : 'route' as const,
  settings : 'settings' as const,
  settingsInputComponent : 'settingsInputComponent' as const,
  shoppingBag : 'shoppingBag' as const,
  store : 'store' as const,
  upload : 'upload' as const,
  work : 'work' as const,
}
type SvgIconKey = keyof typeof svgIcons;
export type SvgIcon = typeof svgIcons[SvgIconKey];

const inputType = {
  string : 'string',
  number : 'number',
  time : 'time',
} as const;
type InputTypeKey = keyof typeof inputType;
export type InputType = typeof inputType[InputTypeKey];

const inputFormat = {
  int : 'int',
  float : 'float',
  currency : 'currency',
} as const;
type InputFormatKey = keyof typeof inputFormat;
export type InputFormat = typeof inputFormat[InputFormatKey];

export const settings = {
  components,
  variants,
  sizes,
  directions,
  dimensions,
  spacings,
  alignments,
  colours,
  fonts,
  svgIcons,
  inputType,
  inputFormat,
}

export const convert = {
  size : (size : Size | undefined) => {
    switch (size) {
      case sizes.xsmall:
        return 'small';
      default:
        return size;
    }
  },
  width : (width : Dimension | number | undefined) => {
    if (typeof width == 'number') return width;

    switch (width) {
      case dimensions.xsmall:
        return '150px';
      case dimensions.small:
        return '300px';
      case dimensions.medium:
        return '600px';
      case dimensions.large:
        return '1200px';
      case dimensions.quarter:
        return '25%';
      case dimensions.third:
        return '33.33%';
      case dimensions.half:
        return '50%';
      case dimensions.twoThirds:
        return '66.66%';
      case dimensions.threeQuarters:
        return '75%';
      case dimensions.full:
          return '100%';
      default:
        return 'auto';
    }
  },
  height : (height : Dimension | undefined) => {
    switch (height) {
      case dimensions.xsmall:
        return '100px';
      case dimensions.small:
        return '150px';
      case dimensions.medium:
        return '300px';
      case dimensions.large:
        return '600px';
      case dimensions.full:
        return '100%';
      case dimensions.fullview:
        return '100vh';
      default:
        return 'auto';
    }
  },
  margin : (margin : Margin | undefined) => {
    function convert(spacing : Spacing | undefined) {
      switch (spacing) {
        case spacings.dense:
          return '0px';
        case spacings.small:
          return '8px';
        case spacings.medium:
          return '16px';
        case spacings.large:
          return '32px';
        case spacings.xlarge:
          return '64px';
        case spacings.xxlarge:
          return '96px';
        default:
          return 'auto';
      }
    }
    return {
      top : margin?.top ? convert(margin.top) : convert(margin?.y),
      right : margin?.right ? convert(margin.right) : convert(margin?.x),
      bottom : margin?.bottom ? convert(margin.bottom) : convert(margin?.y),
      left : margin?.left ? convert(margin.left) : convert(margin?.x),
    }
  },
  padding : (padding : Padding | undefined) => {
    function convert(spacing : Spacing | undefined) {
      switch (spacing) {
        case spacings.dense:
          return '0px';
        case spacings.small:
          return '8px';
        case spacings.medium:
          return '16px';
        case spacings.large:
          return '32px';
        case spacings.xlarge:
          return '64px';
        case spacings.xxlarge:
          return '96px';
        default:
          return 'auto';
      }
    }
    return {
      top : padding?.top ? convert(padding.top) : convert(padding?.y),
      right : padding?.right ? convert(padding.right) : convert(padding?.x),
      bottom : padding?.bottom ? convert(padding.bottom) : convert(padding?.y),
      left : padding?.left ? convert(padding.left) : convert(padding?.x),
    }
  },
  alignment : (alignment : Alignment | undefined) => {
    switch (alignment) {
      case alignments.left:
        return 'left';
      case alignments.center:
        return 'center';
      case alignments.right:
        return 'right';
      default:
        return undefined;
    }
  },
  flexAlignment : (alignment : Alignment | undefined) => {
    switch (alignment) {
      case alignments.start:
        return 'flex-start';
      case alignments.center:
        return 'center';
      case alignments.end:
        return 'flex-end';
      default:
        return 'auto';
    }
  },
  flexJustification : (alignment : Alignment | undefined) => {
    switch (alignment) {
      case alignments.spaceBetween:
        return 'space-between';
      default:
        return convert.flexAlignment(alignment);
    }
  },
  textAlignment : (alignment : Alignment | undefined) => {
    switch (alignment) {
      case alignments.start:
        return 'left';
      case alignments.center:
        return 'center';
      case alignments.end:
        return 'right';
      default:
        return 'left';
    }
  },
  columnWidth : (width : Dimension | undefined) => {
    switch (width) {
      case dimensions.xsmall:
        return '50px';
      case dimensions.small:
        return '150px';
      case dimensions.medium:
        return '200px';
      case dimensions.large:
        return '300px';
      case dimensions.full:
        return '100%';
      default:
        return 'auto';
    }
  },
  colours : {
    alertToButton : (colour : AlertColour | undefined) => {
      switch (colour) {
        case colours.alert.primary:
          return colours.button.success;
        case colours.alert.secondary:
          return colours.button.secondary;
        case colours.alert.alert:
          return colours.button.alert;
        default:
          return colours.button.primary;
      }
    }
  },
  font : (font : Font | undefined) => {
    switch (font) {
      case fonts.brand:
        return 'Brand Font, sans-serif';
      case fonts.mono:
        return 'Roboto Mono, monospace'
      default:
        return 'Roboto, Helvetica, Arial, sans-serif;';
    }
  }
}
