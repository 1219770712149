import React, { useCallback, useEffect, useState } from 'react';

import { Integration, LocationIntegration } from '#types';

import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localeKeys = locale.keys.tables.locationIntegrations;

export const TABLE_KEYS = {
  id : 'id' as const,
  integration : 'integrationId' as const,
  externalId : 'externalId' as const,
  actions : 'actions' as const,
};
type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defatultTableKeys = Object.values(TABLE_KEYS);

interface LocationIntegrationsTableProps {
  locationIntegrations : LocationIntegration[];
  tableKeys? : TableKey[];
  generateActions? : (location : LocationIntegration) => CellElement;
}

function LocationIntegrationsTable({
  locationIntegrations,
  tableKeys = defatultTableKeys,
  generateActions,
} : LocationIntegrationsTableProps) {
  const { retrieveIntegrations } = useIntegrations();

  const [
    integrations,
    setIntegrations,
  ] = useState<{ [key : string] : Integration | null }>({});

  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const retrieve = useCallback(async () => {
    const newIntegrations = await retrieveIntegrations();
    if (newIntegrations) setIntegrations(newIntegrations);
  }, [retrieveIntegrations]);

  const generateHead = useCallback(() => {
    function generate(key : TableKey) {
      switch (key) {
        case TABLE_KEYS.id:
          return (
            <TableCell
              key={key}
              width={settings.dimensions.small}
            >
              { localize(localeKeys.headings.id) }
            </TableCell>
          );
        case TABLE_KEYS.integration:
          return (
            <TableCell key={key}>
              { localize(localeKeys.headings.integration) }
            </TableCell>
          );
        case TABLE_KEYS.externalId:
          return (
            <TableCell key={key}>
              { localize(localeKeys.headings.externalId) }
            </TableCell>
          );
        default:
          return <TableCell key={key}/>;
      }
    }

    setHead(tableKeys.map((key) => generate(key)));
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    function generate(key : TableKey, location : LocationIntegration) {
      switch (key) {
        case TABLE_KEYS.id:
          return (<TableCell key={key}>{ location.id }</TableCell>);
        case TABLE_KEYS.integration:
          return (
            <TableCell key={key}>
              { integrations[location.integrationId]?.name }
            </TableCell>
          );
        case TABLE_KEYS.externalId:
          return (<TableCell key={key}>{ location.externalId }</TableCell>);
        case TABLE_KEYS.actions:
          return (
            <React.Fragment key={key}>
              { generateActions?.(location) }
            </React.Fragment>
          );
        default:
          return <TableCell key={key} />;
      }
    }

    setRows(locationIntegrations.map((location) => (
      <React.Fragment key={location.id}>
        { tableKeys.map((key) => generate(key, location)) }
      </React.Fragment>
    )));
  }, [generateActions, integrations, locationIntegrations, tableKeys]);

  useEffect(() => { retrieve(); }, [retrieve]);
  useEffect(() => { generateHead(); }, [generateHead]);
  useEffect(() => { generateRows(); }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={5}
    />
  );
}

export default LocationIntegrationsTable;
