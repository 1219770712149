import React, { useCallback } from 'react';

import { Tag } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import useTags from '#hooks/useTags';

import { settings } from '#materials';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import TagForm from '#components/tags/TagForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.tags.createTag;
const localeButtonKeys = locale.keys.buttons;

interface CreateTagProps {
  onSave? : (tag : Tag) => void;
  onCancel? : () => void;
}

function CreateTagControl({ onSave, onCancel } : CreateTagProps) {
  const { state : tag, reset } = useFormContext<Tag>();
  const { createTag } = useTags();

  const handleSave = useCallback(async () => {
    if (!tag) return;

    const newTag = await createTag(tag);
    if (newTag && onSave) onSave(newTag);
  }, [tag, createTag, onSave]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Section title={localize(localeContentKeys.title)}>
      { !!tag && <TagForm tag={tag} /> }
      <Button
        onClick={handleSave}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
    </Section>
  );
}

function CreateTag({ onSave, onCancel } : CreateTagProps) {
  return (
    <FormProvider
      init={{
        name : '',
        productIds : [],
        availableChannelIds : [],
      }}
      editing={true}
    >
      <CreateTagControl onSave={onSave} onCancel={onCancel} />
    </FormProvider>
  );
}

export default CreateTag;
