import React, { useCallback, useState } from 'react';

import { Tag } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useTags from '#hooks/useTags';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import TagForm from '#components/tags/TagForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.tags.tagDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.tags;

interface TagDetailsProps {
  tag : Tag;
  onSave? : (tag : Tag) => void;
}

function TagDetailsControl({ tag : init, onSave } : TagDetailsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();

  const {
    state : tag,
    reset,
    editing,
    setEditing,
  } = useFormContext<Tag>();
  const { refreshTag, updateTag, deleteTag } = useTags();

  const [ deleting, setDeleting ] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!tag || !tag.id) return;
    refreshTag(tag.id);
  }, [tag, refreshTag]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!tag) return;

    const updatedTag = await updateTag(tag);
    if (updatedTag) {
      if (onSave) onSave(updatedTag);
      setEditing(false);
    }
  }, [tag, updateTag, onSave, setEditing]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const confirmDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const cancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleDelete = useCallback(async () => {
    if (!tag) return;
    const success = await deleteTag(tag);
    if (success) {
      createNotification({
        key : 'delete-tag-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.primary,
      });
      navigate('/tags/');
    } else {
      createNotification({
        key : 'delete-tag-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [tag, deleteTag, navigate, createNotification]);

  return (
    <>
      { deleting && (
        <Banner
          icon={<Icon icon={settings.svgIcons.delete} />}
          actions={(
            <>
              <Button
                onClick={handleDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={cancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (tag && editing)
        ? <>
          <Button
            onClick={handleSave}
          >
            {localize(localeButtonKeys.save)}
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleRefresh}
          >
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button
            onClick={handleEdit}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={confirmDelete}
            disabled={deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <TagForm tag={tag || init} />
    </>
  );
}

function TagDetails({ tag, onSave } : TagDetailsProps) {
  return (
    <FormProvider init={tag} editingInit={false}>
      <TagDetailsControl tag={tag} onSave={onSave} />
    </FormProvider>
  );
}

export default TagDetails;
