import React from 'react';

import { useParams } from '#hooks/useNavigation';

import useIntegrations from '#hooks/useIntegrations';

import IntegrationIndex from '#components/integrations/IntegrationIndex';
import IntegrationInfo from '#components/integrations/IntegrationInfo';

function Integrations() {
  const urlParams = useParams<{ integrationId? : string; }>();
  const { checkIntegrationPermissions } = useIntegrations();

  const parsedId = parseInt(urlParams.integrationId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  checkIntegrationPermissions({ redirect : '/' })

  return (
    <>
      { !!id
        ? <IntegrationInfo integrationId={id} />
        : <IntegrationIndex />
      }
    </>
  );
}

export default Integrations;
