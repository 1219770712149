import React from 'react';

import { Schedule } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.schedules;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  actions : 'actions',
};
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface ScheduleTableProps {
  schedules : Schedule[];
  tableKeys? : TableKeys[];
  count : number;
  pageCount? : number;
  generateActions? : (schedule : Schedule) => CellElement;
}

function ScheduleTable({
  schedules,
  tableKeys = Object.values(TABLE_KEYS),
  count,
  pageCount = 20,
  generateActions = () => <TableCell />,
} : ScheduleTableProps) {

  function headGenerator(key : string) {
    switch (key) {
      case TABLE_KEYS.id:
        return <TableCell>{ localize(localKeys.headings.id) }</TableCell>;
      case TABLE_KEYS.name:
        return <TableCell>{ localize(localKeys.headings.name) }</TableCell>;
      case TABLE_KEYS.actions:
        return <TableCell />;
      default: return <TableCell />;
    }
  }

  function rowGenerator(key : string, index : number) {
    const schedule = schedules[index];
    switch (key) {
      case TABLE_KEYS.id: return <TableCell>{ `# ${schedule.id}` }</TableCell>
      case TABLE_KEYS.name: return <TableCell>{ schedule.name }</TableCell>
      case TABLE_KEYS.actions: return generateActions(schedule);
      default: return <TableCell />;
    }
  }

  return (
    <Table
      keys={tableKeys}
      length={count}
      headGenerator={headGenerator}
      rowGenerator={rowGenerator}
      pageCount={pageCount}
    />
  );
}

export default ScheduleTable;
