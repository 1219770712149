import React, { useCallback, useEffect, useState } from 'react';

import { ExternalCustomer, Integration } from '#types';

import useIntegrations from '#hooks/useIntegrations';
import useCustomers from '#hooks/useCustomers';

import { settings } from '#materials';
import Text from '#materials/Text';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';
import Select from '#materials/Select';
import { CellElement } from '#materials/TableCell';

import ExternalCustomerTable from '#components/customers/ExternalCustomerTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localTableKeys = locale.keys.tables.externalCustomers;

const CUSTOMER_CHANNEL = 'customers';

interface ExternalCustomerSearchProps {
  integration? : Integration | null;
  setIntegration? : (integration : Integration | null) => void;
  filter? :
    (externalCustomers : ExternalCustomer[]) => ExternalCustomer[];
  generateActions? : (customer : ExternalCustomer) => CellElement;
}

function noChange(integration : Integration | null) {}

function ExternalCustomerSearch({
  integration = null,
  setIntegration = noChange,
  filter = (externalCustomers : ExternalCustomer[]) => externalCustomers,
  generateActions = () => <></>,
} : ExternalCustomerSearchProps) {
  const { retrieveIntegrations, retrieveChannels } = useIntegrations();
  const { retrieveExternalCustomers } = useCustomers();

  const [
    externalCustomers,
    setExternalCustomers,
  ] = useState<ExternalCustomer[] | null>(null);
  const [integrations, setIntegrations] = useState<Integration[] | null>(null);
  const [query, setQuery] = useState<string>('');
  const [
    filteredCustomers,
    setFilteredCustomers,
  ] = useState<ExternalCustomer[]>([]);

  const retrieve = useCallback(async () => {
    const [newIntegrations, newChannels, extCustomers] = await Promise.all([
      retrieveIntegrations(),
      retrieveChannels(),
      retrieveExternalCustomers(),
    ]);

    if (!newChannels) return;
    const channel = listRecords(newChannels).find(
      (channel) => channel.name === CUSTOMER_CHANNEL
    ) ?? null
    if (!channel) return;

    if (newIntegrations) setIntegrations(
      listRecords(newIntegrations).filter((integration) => {
        return channel?.id && integration.channelIds.includes(channel?.id);
      })
    );

    if (!extCustomers) return
    const ext = Object.values(extCustomers).reduce((acc, cur) => {
      return acc.concat(Object.values(cur));
    }, [] as ExternalCustomer[]);
    if (extCustomers) setExternalCustomers(ext);
  }, [
    retrieveIntegrations,
    retrieveChannels,
    retrieveExternalCustomers,
    setExternalCustomers,
  ]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  useEffect(() => {
    if (!externalCustomers) return;

    if (integration === null) {
      setFilteredCustomers([]);
      return;
    }

    let filtered = externalCustomers.filter(
      (ext) => ext.integrationId === integration.id,
    );

    if (query.length !== 0) {
      const q = query.toLowerCase().trim();
      filtered = filtered.filter(
        (ext) => ext?.email?.toLowerCase().includes(q)
      );
    }

    setFilteredCustomers(filter(filtered));
  }, [filter, externalCustomers, integration, query, setFilteredCustomers]);

  return (
    <>
      <Form>
        <Select
          label="Integration"
          selected={integration}
          options={integrations ? integrations : []}
          onChange={setIntegration}
          labelGenerator={(integration) => integration?.name ?? ''}
          width={settings.dimensions.third}
        />
        <TextInput
          id="integration-customer-search-query"
          label="Search"
          value={query}
          onChange={setQuery}
          width={settings.dimensions.twoThirds}
        />
      </Form>
      { integration && <>
        <ExternalCustomerTable
          customerIntegrations={filteredCustomers}
          generateActions={generateActions}
        />
        { !filteredCustomers.length &&
          <Text>
            { localize(localTableKeys.notFound) }
          </Text>
        }
      </> }
    </>
  );
}

export default ExternalCustomerSearch;
