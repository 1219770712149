import React from 'react';

import { useParams } from '#hooks/useNavigation';

import ServiceIndex from '#components/services/ServiceIndex';
import ServiceInfo from '#components/services/ServiceInfo';

function Services() {
  const urlParams = useParams<{ serviceId? : string; }>();

  const parsedId = parseInt(urlParams.serviceId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !!id
        ?
        <ServiceInfo serviceId={id} />
        :
        <ServiceIndex />
      }
    </>
  );
}

export default Services;
