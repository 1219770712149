import React, { useCallback, useEffect, useState } from 'react';

import { Product, ProductIntegration, ExternalProduct } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ProductIntegrationTable, {
  TABLE_KEYS,
} from '#components/products/ProductIntegrationsTable';
import ExternalProductsSearch from
  '#components/products/ExternalProductsSearch';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productIntegrations;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.productIntegrations;
const localeNotificationKeys = locale.keys.notifications;

interface ProductIntegrationsProps {
  product : Product;
}

function ProductIntegrations({ product } : ProductIntegrationsProps) {
  const { openTab } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    createProductIntegration,
    deleteProductIntegration,
    syncProductIntegration,
    pushProductIntegration,
    pullProductIntegration,
  } = useProducts();

  const [
    productIntegrations,
    setProductIntegrations,
  ] = useState<ProductIntegration[] | null>(null);
  const [
    removeTarget,
    setRemoveTarget,
  ] = useState<ProductIntegration | null>(null);
  const [manage, setManage] = useState(false);

  const initUnlink = useCallback(
    (productIntegration : ProductIntegration) => async () => {
    setRemoveTarget(productIntegration);
  }, [setRemoveTarget]);

  const cancelUnlink = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const sync = useCallback(
    (productIntegration : ProductIntegration) => async () => {
      const success = await syncProductIntegration(productIntegration);
      if (success) {
        createNotification({
          key : 'sync-product-success',
          message : localize(localeNotificationKeys.products.sync.success),
          colour : settings.colours.alert.primary,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        });
      } else {
        createNotification({
          key : 'sync-product-error',
          message : localize(localeNotificationKeys.products.sync.error),
          colour : settings.colours.alert.alert,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        });
      }
    },
    [syncProductIntegration, createNotification]
  );

  const push = useCallback(
    (productIntegration : ProductIntegration) => async () => {
      const success = await pushProductIntegration(productIntegration);
      if (success) {
        createNotification({
          key : 'push-product-success',
          message : localize(localeNotificationKeys.products.push.success),
          colour : settings.colours.alert.primary,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        });
      } else {
        createNotification({
          key : 'push-product-error',
          message : localize(localeNotificationKeys.products.push.error),
          colour : settings.colours.alert.alert,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        });
      }
    },
    [pushProductIntegration, createNotification]
  );

  const pull = useCallback(
    (productIntegration : ProductIntegration) => async () => {
      const success = await pullProductIntegration(productIntegration);
      if (success) {
        createNotification({
          key : 'pull-product-success',
          message : localize(localeNotificationKeys.products.pull.success),
          colour : settings.colours.alert.primary,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        });
      } else {
        createNotification({
          key : 'pull-product-error',
          message : localize(localeNotificationKeys.products.pull.error),
          colour : settings.colours.alert.alert,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        });
      }
    },
    [pullProductIntegration, createNotification]
  );

  const unlink = useCallback(async () => {
    if (!removeTarget) return;
    const success = await deleteProductIntegration(removeTarget);
    if (success) {
      createNotification({
        key : 'remove-product-success',
        message : localize(localeNotificationKeys.products.unlink.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      });
    } else {
      createNotification({
        key : 'remove-product-error',
        message : localize(localeNotificationKeys.products.unlink.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      });
    }
    setRemoveTarget(null);
  }, [removeTarget, deleteProductIntegration, createNotification]);

  const link = useCallback(
    (product : Product, externalProduct : ExternalProduct) => async () => {
      const success = await createProductIntegration(product, externalProduct);
      if (success) {
        createNotification({
          key : 'link-product-success',
          message : localize(localeNotificationKeys.products.link.success),
          colour : settings.colours.alert.primary,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        });
      } else {
        createNotification({
          key : 'link-product-error',
          message : localize(localeNotificationKeys.products.link.error),
          colour : settings.colours.alert.alert,
          icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        });
      }
    },
    [createProductIntegration, createNotification],
  );

  const view = useCallback((productIntegration : ProductIntegration) =>
    () => {
      openTab(`/products/${product.id}/` +
        `integrations/${productIntegration.id}`);
    },
    [openTab, product],
  );

  const openManage = useCallback(() => setManage(true), [setManage]);
  const closeManage = useCallback(() => setManage(false), [setManage]);

  const generateIntegrationActions = useCallback(
    (productIntegration : ProductIntegration) => (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          onClick={view(productIntegration)}
        >
          <Icon icon={settings.svgIcons.info} />
        </Action>
        { manage
          ? (
            <Action
              label={localize(localeTableKeys.actions.unlink)}
              onClick={initUnlink(productIntegration)}
              disabled={removeTarget !== null}
              colour={settings.colours.button.alert}
            >
              <Icon icon={settings.svgIcons.linkOff} />
            </Action>
          ) : (
            <>
              <Action
                label={localize(localeTableKeys.actions.sync)}
                onClick={sync(productIntegration)}
              >
                <Icon icon={settings.svgIcons.refresh} />
              </Action>
              <Action
                label={localize(localeTableKeys.actions.push)}
                onClick={push(productIntegration)}
              >
                <Icon icon={settings.svgIcons.upload} />
              </Action>
              <Action
                label={localize(localeTableKeys.actions.pull)}
                onClick={pull(productIntegration)}
              >
                <Icon icon={settings.svgIcons.download} />
              </Action>
            </>
          )
        }
      </TableActionCell>
    ),
    [removeTarget, manage, view, initUnlink, sync, push, pull],
  );

  const generateExternalActions = useCallback(
    (externalProduct : ExternalProduct) => (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.link)}
          onClick={link(product, externalProduct)}
        >
          <Icon icon={settings.svgIcons.link} />
        </Action>
      </TableActionCell>
    ),
    [product, link],
  );

  const filterExternal = useCallback((externalProducts : ExternalProduct[]) => {
    return externalProducts.filter((externalProduct) => {
      return !productIntegrations?.some((productIntegration) => {
        return productIntegration.externalId === externalProduct.externalId;
      });
    });
  }, [productIntegrations]);

  useEffect(() => {
    if (!product.integrations) return;
    setProductIntegrations(Object.values(product.integrations));
  }, [product]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { removeTarget && (
        <Banner
          onClose={cancelUnlink}
          colour={settings.colours.alert.alert}
          icon={<Icon icon={settings.svgIcons.linkOff} />}
          actions={
            <>
              <Button onClick={unlink}>
                {localize(localeButtonKeys.remove)}
              </Button>
              <Button onClick={cancelUnlink}>
                {localize(localeButtonKeys.cancel)}
              </Button>
            </>
          }
        >
          { localize(localeContentKeys.confirmUnlink) +
            ` (${removeTarget.externalId})` }
        </Banner>
      ) }
      <ProductIntegrationTable
        productIntegrations={productIntegrations ?? []}
        tableKeys={[
          TABLE_KEYS.integration,
          TABLE_KEYS.name,
          TABLE_KEYS.sku,
          TABLE_KEYS.actions,
        ]}
        generateActions={generateIntegrationActions}
      />
      { manage && (
        <Segment title={localize(localeContentKeys.availableIntegrations)}>
          <ExternalProductsSearch
            filter={filterExternal}
            generateActions={generateExternalActions}
            onCancel={closeManage}
          />
        </Segment>
      ) }
      { !manage && (
        <Button onClick={openManage}>
          {localize(localeButtonKeys.edit)}
        </Button>
      ) }
    </Section>
  );
}

export default ProductIntegrations;
