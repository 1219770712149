import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Integration, ExternalTax } from '#types';

import useIntegrations from '#hooks/useIntegrations';
import useTaxes from '#hooks/useTaxes';

import { settings } from '#materials';
import Text from '#materials/Text';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';
import Select from '#materials/Select';
import { CellElement } from '#materials/TableCell';

import ExternalTaxesTable, {
  TABLE_KEYS,
} from '#components/taxes/ExternalTaxesTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const tableKeys = Object.values(TABLE_KEYS).filter(
  (key) => key !== TABLE_KEYS.integration,
);

const localeFormKeys = locale.keys.forms.taxesExternal;
const localeTableKeys = locale.keys.tables.externalTaxes;

interface ExternalTaxesSearchProps {
  filter? : (externalTaxes : ExternalTax) => boolean;
  generateActions? : (tax : ExternalTax) => CellElement;
  setIntegration? : (integration : Integration | null) => void;
}

function ExternalTaxesSearch({
  filter,
  generateActions,
  setIntegration : selectIntegration,
} : ExternalTaxesSearchProps) {
  const { retrieveChannels, retrieveIntegrations } = useIntegrations();
  const { retrieveExternalTaxes } = useTaxes();

  const initialized = useRef(false);
  const [integrations, setIntegrations] = useState<Integration[] | null>(null);
  const [
    externalTaxes,
    setExternalTaxes,
  ] = useState<ExternalTax[]>([]);
  const [integration, setIntegration] = useState<Integration | null>(null);
  const [query, setQuery] = useState<string>('');
  const [
    filteredTaxes,
    setFilteredTaxes,
  ] = useState<ExternalTax[]>([]);

  const retrieveExternal = useCallback(async () => {
    if (initialized.current) return;
    initialized.current = true;

    const newTaxes = await retrieveExternalTaxes();
    if (!newTaxes) return;
    setExternalTaxes(Object.values(newTaxes).reduce((acc, cur) => {
      return cur ? acc.concat(Object.values(cur)) : acc;
    }, [] as ExternalTax[]));
  }, [initialized, retrieveExternalTaxes]);

  const retrieveConfig = useCallback(async () => {
    const [
      newChannels,
      newIntegrations,
    ] = await Promise.all([
      retrieveChannels(),
      retrieveIntegrations(),
    ]);

    const channelId = listRecords(newChannels).find(
      (channel) => channel.name === 'taxes'
    )?.id;
    if (!channelId) return;

    setIntegrations(listRecords(newIntegrations).filter(
      (integration) => integration.channelIds.includes(channelId),
    ));
  }, [
    retrieveChannels,
    retrieveIntegrations,
  ]);

  const handleIntegrationChange = useCallback((int : Integration | null) => {
    setIntegration(int);
    if (selectIntegration) selectIntegration(int);
  }, [selectIntegration]);

  useEffect(() => { retrieveExternal(); }, [retrieveExternal]);
  useEffect(() => { retrieveConfig(); }, [retrieveConfig]);

  useEffect(() => {
    if (!externalTaxes || !integration) {
      setFilteredTaxes([]);
      return;
    }

    setFilteredTaxes(externalTaxes.filter((tax) => {
      return (tax.integrationId === integration.id)
        && (!filter || filter(tax))
        && (!query || (
          tax.name.toLowerCase().includes(query.toLowerCase())
        ));
    }));
  }, [externalTaxes, integration, filter, query]);

  return (
    <>
      <Form>
        <Select
          label={localize(localeFormKeys.labels.integration)}
          selected={integration}
          options={integrations ?? (integration ? [integration] : [])}
          onChange={handleIntegrationChange}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={(integration) => integration?.name ?? ''}
          key={integration?.id}
          width={settings.dimensions.half}
        />
        <TextInput
          id="external-taxes-search-query"
          label={localize(localeFormKeys.labels.search)}
          value={query}
          onChange={setQuery}
          width={settings.dimensions.half}
        />
      </Form>
      { integration && (
        !!filteredTaxes.length
          ? (
            <ExternalTaxesTable
              externalTaxes={filteredTaxes}
              tableKeys={tableKeys}
              generateActions={generateActions}
            />
          ) : (
            <Text>{localize(localeTableKeys.notFound)}</Text>
          )
      )}
    </>
  );
}

export default ExternalTaxesSearch;
