import React from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Component, Dimension, Margin, convert, settings } from './types';

interface FlexItemProps {
  component? : Component;
  grow? : boolean;
  shrink? : boolean;
  overflow? : boolean;
  fill? : boolean;
  width? : Dimension | number;
  height? : Dimension;
  maxWidth? : Dimension | number;
  maxHeight? : Dimension;
  margin? : Margin;
  fitContent?  : boolean;
  children? : React.ReactNode;
}

function Flex({
  component = settings.components.div,
  grow = false,
  shrink = false,
  overflow = false,
  fill = false,
  width,
  height,
  maxWidth,
  maxHeight,
  margin,
  fitContent = false,
  children,
} : FlexItemProps) {
  const theme = useTheme();

  return (
    <Box
      component={component}
      sx={{
        ...(width && { width : convert.width(width) }),
        height : convert.height(height),
        ...(fitContent && { minWidth : 'fit-content' }),
        ...(maxWidth && { maxWidth : convert.width(maxWidth) }),
        ...(maxHeight && { maxHeight : convert.height(maxHeight)}),
        ...(grow && { flexGrow : 1 }),
        ...(shrink && { flexShrink : 1 }),
        overflow : overflow ? 'auto' : 'hidden',
        ...(fill && { backgroundColor : theme.palette.bg.main }),
        ...(margin && {
          mt : convert.margin(margin).top,
          mr : convert.margin(margin).right,
          mb : convert.margin(margin).bottom,
          ml : convert.margin(margin).left,
        }),
        '@media print' : {
          overflow : 'visible',
          height : 'auto',
        },
      }}
    >
      { children }
    </Box>
  );
}

export default Flex;
