import React, { useCallback, useEffect, useState } from 'react';

import { Integration, ExternalLocation } from '#types';

import useIntegrations from '#hooks/useIntegrations';
import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Text from '#materials/Text';
import Form  from '#materials/Form';
import TextInput from '#materials/TextInput';
import Select from '#materials/Select';
import { CellElement } from '#materials/TableCell';

import ExternalLocationsTable, {
  TableKey,
  defatultTableKeys,
} from '#components/locations/ExternalLocationsTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

export const defaultTableKeys = defatultTableKeys;

const localeFormKeys = locale.keys.forms.integrations;
const localeTableKeys = locale.keys.tables.locationIntegrations;

interface ExternalLocationsSearchProps {
  tableKeys? : TableKey[];
  filter? :
    (externalLocations : ExternalLocation[]) => ExternalLocation[];
  generateActions? : (location : ExternalLocation) => CellElement;
  setIntegration? : (integration : Integration | null) => void;
}

function ExternalLocationsSearch({
  tableKeys = defatultTableKeys,
  filter,
  generateActions = () => <></>,
  setIntegration : selectIntegration = () => {},
} : ExternalLocationsSearchProps) {
  const { retrieveChannels, retrieveIntegrations } = useIntegrations();
  const { retrieveExternalLocations } = useServices();

  const [integrations, setIntegrations] = useState<Integration[] | null>(null);
  const [
    externalLocations,
    setExternalLocations,
  ] = useState<ExternalLocation[] | null>(null);
  const [integration, setIntegration] = useState<Integration | null>(null);
  const [query, setQuery] = useState<string>('');
  const [
    filteredLocations,
    setFilteredLocations,
  ] = useState<ExternalLocation[]>([]);

  const retrieve = useCallback(async () => {
    const [
      newChannels,
      newIntegrations,
      newLocations,
    ] = await Promise.all([
      retrieveChannels(),
      retrieveIntegrations(),
      retrieveExternalLocations(),
    ]);

    if (!newChannels || !newIntegrations || !newLocations) return;

    const channel = listRecords(newChannels).find(
      (channel) => channel.name === 'locations'
    ) ?? null;
    if (!channel) return;

    setIntegrations(listRecords(newIntegrations).filter((integration) => {
      return channel?.id && integration.channelIds.includes(channel?.id);
    }));

    setExternalLocations(
      Object.values(newLocations).reduce((acc, cur) => {
        return acc.concat(Object.values(cur));
      }, [] as ExternalLocation[])
    );
  }, [retrieveChannels, retrieveIntegrations, retrieveExternalLocations]);

  const handleIntegrationChange = useCallback(
    (integration : Integration | null) => {
      setIntegration(integration);
      selectIntegration(integration);
    },
    [selectIntegration],
  );

  useEffect(() => { retrieve(); }, [retrieve]);

  useEffect(() => {
    if (!externalLocations) return;
    if (!integration) {
      setFilteredLocations(externalLocations);
      return;
    }

    let filtered = externalLocations.filter((location) => {
      return location.integrationId === integration.id;
    })

    if (filter) filtered = filter(filtered);

    if (query) {
      const q = query.toLowerCase();
      filtered = filtered.filter((loc) => {
        return loc.name.toLowerCase().includes(q);
      });
    }

    setFilteredLocations(filtered);
  }, [externalLocations, integration, filter, query]);

  return (
    <>
      <Form>
        <Select
          label={localize(localeFormKeys.labels.integration)}
          selected={integration}
          options={integrations ?? []}
          onChange={handleIntegrationChange}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={(integration) => integration?.name ?? ''}
          width={settings.dimensions.half}
        />
        <TextInput
          id="external-locations-search-query"
          label={localize(localeFormKeys.labels.search)}
          value={query}
          onChange={setQuery}

          width={settings.dimensions.half}
        />
        { (integration && (filteredLocations.length > 0))
          ? (
            <ExternalLocationsTable
              externalLocations={filteredLocations}
              tableKeys={tableKeys}
              generateActions={generateActions}
            />
          ) : (
            integration && (
              <Text>
                {localize(localeTableKeys.messages.notFound)}
              </Text>
            )
          )
        }
      </Form>
    </>
  );
}

export default ExternalLocationsSearch;
