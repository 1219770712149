import React, { useState, useEffect, useCallback } from 'react';

import { Location } from '#types';

import { FormProvider } from '#context/FormContext';

import useServices from '#hooks/useServices';

import Section from '#components/dashboard/Section';
import LocationForm
  from '#components/locations/LocationForm';

import { settings } from '#materials';
import Button from '#materials/Button';

import locale, { localize } from '#utils/locale';

const localeLocationKeys = (
  locale.keys.content.locations.locationIndex
);
const localeButtonKeys = locale.keys.buttons;

interface LocationIndexProps {
  onSave? : (location : Location) => void;
  onCancel? : () => void;
}

function CreateLocation({ onCancel, onSave, } : LocationIndexProps) {

  const { createLocation } = useServices();

  const [location, setLocation] = useState<Location>({
    name : '',
    addressId : null,
    description : '',
  });

  const [formKey, setFormKey] = useState(true);
  const [saved, setSaved] = useState(true);
  const [cleared, setCleared] = useState(true);

  useEffect(() => {
    setSaved(false);
    setCleared(false);
  }, [location] );

  const resetLocation = useCallback(() => {
    setLocation({ name : '', addressId : null, description : '' });
    setFormKey(!formKey);
  }, [setLocation, formKey, setFormKey]);

  const handleSave = useCallback(async () => {
    if (location) {
      const newLocation = await createLocation(location);
      if (!newLocation) return;

      setSaved(true);
      if (onSave) onSave(newLocation);
    }
  }, [location, createLocation, setSaved, onSave]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const handleClear = useCallback(() => {
    resetLocation();
    setCleared(true);

  }, [resetLocation, setCleared]);

  return (
    <Section title={localize(localeLocationKeys.title)}>
      <FormProvider
        init={location}
        onChange={setLocation}
        formKey={formKey ? '' : '_'}
      >
        <LocationForm
          location={location}
        />
      </FormProvider>

      <Button
        onClick={handleSave}
        disabled={saved}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={handleCancel}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
      <Button
        onClick={handleClear}
        disabled={cleared}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
    </Section>
  );
}

export default CreateLocation;
