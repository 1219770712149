import React, { useCallback, useState } from 'react';

import { Customer, ContactInfo } from '#types';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useCustomers from '#hooks/useCustomers';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import ContactForm, {
  validate_full_name,
  validate_nickname,
  validate_email,
  validate_phone,
} from '#components/customers/ContactForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customers.createContact;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.contactInfo;

interface CreateContactProps {
  customer : Customer;
  onSave : (contactInfo : ContactInfo) => void;
  onCancel : () => void;
  slice? : boolean;
}

interface CreateContactControlProps extends CreateContactProps {
  contactInfo : ContactInfo;
}

function CreateContactContainer(
  { slice, children } : { slice : boolean, children : React.ReactNode }
) {
  return slice
    ? (<Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
      >
        { children }
      </Section>
    )
    : (<>{ children }</>);
}

function CreateContactControl({
  customer,
  contactInfo : initContactInfo,
  onSave,
  onCancel,
  slice = true,
} : CreateContactControlProps) {
  const { createNotification } = useNotifications();
  const { state : contactInfo, valid } = useFormContext<ContactInfo>();
  const { createContactInfo } = useCustomers();

  const handleSave = useCallback(async () => {
    if (!contactInfo) return;

    const newContactInfo = await createContactInfo(customer, contactInfo);
    if (!!newContactInfo) {
      createNotification({
        key : 'create-contact-info-success',
        message : localize(localeNotificationKeys.create.success),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.primary,
      });
      if (onSave) onSave(newContactInfo);
    } else {
      createNotification({
        key : 'create-contact-info-error',
        message : localize(localeNotificationKeys.create.error),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [customer, onSave, contactInfo, createContactInfo, createNotification]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <CreateContactContainer slice={slice}>
      <Button onClick={handleSave} disabled={!valid}>
        { localize(localeButtonKeys.save) }
      </Button>
      <Button onClick={handleCancel} colour={settings.colours.button.alert}>
        { localize(localeButtonKeys.cancel) }
      </Button>
      <ContactForm contactInfo={initContactInfo} />
    </CreateContactContainer>
  );
}

function CreateContact(props : CreateContactProps) {
  const { defaultContactInfo } = useCustomers()
  const [init] = useState<ContactInfo>(defaultContactInfo());

  return (
    <FormProvider
      init={init}
      validators={[
        validate_full_name,
        validate_nickname,
        validate_email,
        validate_phone,
      ]}
    >
      <CreateContactControl contactInfo={init} {...props} />
    </FormProvider>
  );
}

export default CreateContact;
