import React from 'react';

import { useParams } from '#hooks/useNavigation';

import TimeSlotIndex from '#components/timeslots/TimeSlotIndex';
import TimeSlotInfo from '#components/timeslots/TimeSlotInfo';

function Timeslots() {
  const urlParams = useParams<{ timeSlotId? : string; }>();

  const parsedId = parseInt(urlParams.timeSlotId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !id
        ? <TimeSlotIndex />
        : <TimeSlotInfo timeSlotId={id} />
      }
    </>
  )
}

export default Timeslots;
