import React from 'react';

import LogSearch from '#components/logs/LogSearch';

function Logs() {
  return (
   <LogSearch />
  );
}

export default Logs;
