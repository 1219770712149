import React, { useCallback, useState } from 'react';

import { LogEntry } from '#types';

import { FormProvider } from '#context/FormContext';

import useForm from '#hooks/useForm';
import useNotifications from '#hooks/useNotifications';
import useReports from '#hooks/useReports';

import { settings } from '#materials';
import Form from '#materials/Form';
import Text from '#materials/Text';
import TextInput from '#materials/TextInput';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import LogEntryTable from '#components/logs/LogEntryTable';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.logs.logSearch;
const localeFormKeys = locale.keys.forms.logs;
const localeButtonKeys = locale.keys.buttons;

const validators = [
  ({ key, value } : { key : string, value : string }) => {
    const errors : { [key : string] : string } = {};
    if (!key) errors.key = localize(localeFormKeys.errors.noSearchKey);
    if (!value) {
      errors.value = localize(localeFormKeys.errors.noSearchValue);
    }
    return Object.keys(errors).length > 0 ? errors : null;
  }
]

interface LogSearchQuery {
  key : string;
  value : string;
};

function LogSearchControl() {
  const { retrieveLogs } = useReports();
  const { createNotification } = useNotifications();
  const {
    state : query,
    dispatch,
    reset,
    valid,
    errors,
  } = useForm<LogSearchQuery>({ validators });

  const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const retrieve = useCallback(async () => {
    const newLogEntries = await retrieveLogs({
      eventCode : query?.key === 'key' ? query?.value : undefined,
      query : (query && query?.key !== 'key')
        ? { [query?.key] : query?.value }
        : undefined,
    });
    if (newLogEntries) {
      if (!Object.values(newLogEntries).length) {
        createNotification({
          message: localize(localeContentKeys.notFound),
          key: 'log-entries-no-results',
          colour: settings.colours.alert.secondary,
        });
      }
      setLogEntries(Object.values(newLogEntries));
    }
  }, [query, retrieveLogs, createNotification]);

  const handleClear = useCallback(() => {
    setLogEntries([]);
    reset();
    setShowErrors(false);
  }, [reset, setShowErrors]);

  const setSearchKey = useCallback((key : string) => {
    dispatch({ key });
    if (!showErrors) setShowErrors(true);
  }, [dispatch, showErrors]);

  const setSearchValue = useCallback((value : string) => {
    dispatch({ value });
    if (!showErrors) setShowErrors(true);
  }, [dispatch, showErrors]);

  return (<>
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <Form>
        <TextInput
          id='log-search-key'
          label={localize(localeFormKeys.labels.searchKey)}
          value={query?.key ?? ''}
          onChange={setSearchKey}
          width={settings.dimensions.half}
          errors={showErrors ? errors?.key : undefined}
        />
        <TextInput
          id='log-search-value'
          label={localize(localeFormKeys.labels.searchValue)}
          value={query?.value ?? ''}
          onChange={setSearchValue}
          width={settings.dimensions.half}
          errors={showErrors ? errors?.value : undefined}
        />
      </Form>
      <Button
        onClick={retrieve}
        disabled={!valid}
      >
        { localize(localeButtonKeys.search) }
      </Button>
      <Button
        onClick={handleClear}
        colour={settings.colours.button.alert}
        disabled={!query?.key && !query?.value}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
    </Section>
    <Section
      title={localize(localeContentKeys.results)}
    >
      { logEntries.length > 0
        ? (
        <LogEntryTable
          logEntries={logEntries}
        />
        )
        : (<Text>{ localize(localeContentKeys.notFound) }</Text>)
      }
    </Section>
  </>);
}

function LogSearch() {
  return (
    <FormProvider
      init={{ key : '', value : '' }}
      validInit={true}
    >
      <LogSearchControl />
    </FormProvider>
  );
}

export default LogSearch;
