import React, { useCallback, useEffect, useState } from 'react';

import { Customer } from '#types';

import useCustomers from '#hooks/useCustomers';

import Form from '#materials/Form';
import TextInput from '#materials/TextInput';
import { CellElement } from '#materials/TableCell';

import { listRecords } from '#utils/data';
import CustomerTable from '#components/customers/CustomerTable';

interface CustomerSearchProps {
  pageCount? : number;
  generateActions? : (customer : Customer) => CellElement
}

function CustomerSearch({
  pageCount = 20,
  generateActions,
} : CustomerSearchProps) {
  const { retrieveCustomers, searchCustomers } = useCustomers();
  const [query, setQuery] = useState('');
  const [customers, setCustomers] = useState<Customer[]>([]);

  const retrieve = useCallback(async () => {
    const customerIndex = await retrieveCustomers();
    if (!customerIndex) return;

    setCustomers(listRecords(customerIndex));
  }, [retrieveCustomers, setCustomers]);

  useEffect(() => {
    if (!query) {
      retrieve();
    } else {
      const results = searchCustomers(query);
      if (results !== null) {
        setCustomers(Object.values(results.results).map(
          result => result.record
        ));
      };
    }
  }, [query, retrieve, searchCustomers, setCustomers]);

  return (
    <>
      <Form>
        <TextInput
          id="customer-search-query"
          label="Search"
          value={query}
          onChange={setQuery}
        />
      </Form>
      <CustomerTable
        customers={customers}
        generateActions={generateActions}
        pageCount={pageCount}
      />
    </>
  );
}

export default CustomerSearch;
