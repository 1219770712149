import React, { useCallback, useEffect, useState } from 'react';

import { ServiceChannel } from '#types';

import useNotifications from '#hooks/useNotifications';
import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import CreateServiceChannel
  from '#components/serviceChannels/CreateServiceChannel';
import ServiceChannelsTable
  from '#components/serviceChannels/ServiceChannelsTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys =
  locale.keys.content.serviceChannels.serviceChannelIndex;
const localeTableKeys = locale.keys.tables.serviceChannels;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.serviceChannels;

interface ServiceChannelIndexProps {
  showHead? : boolean;
}

function ServiceChannelIndex({ showHead = true } : ServiceChannelIndexProps) {
  const { createNotification } = useNotifications();
  const { retrieveServiceChannels, deleteServiceChannel } = useServices();

  const [serviceChannels, setServiceChannels] = useState<ServiceChannel[]>([]);
  const [creatingNew, setCreatingNew] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<ServiceChannel | null>(null);

  const tableLength = serviceChannels ? Object.keys(serviceChannels).length : 0;

  const refresh = useCallback(() => {
    async function retrieve() {
      const retrievedServiceChannels = await retrieveServiceChannels();
      if (retrievedServiceChannels) {
        setServiceChannels(listRecords(retrievedServiceChannels));
      }
    }

    retrieve();
  }, [setServiceChannels, retrieveServiceChannels]);

  const handleCreateNew = useCallback(() => {
    setCreatingNew(true);
  }, [setCreatingNew]);

  const handleRetrieve = useCallback(() => {
    refresh();
  }, [refresh]);

  const handleCancelNew = useCallback(() => {
    setCreatingNew(false);
  }, [setCreatingNew]);

  const handleSave = useCallback(() => {
    setCreatingNew(false);
    refresh();
  }, [setCreatingNew, refresh]);

  const handleInitDelete = useCallback(
    (serviceChannel : ServiceChannel) => () => {
      setRemoveTarget(serviceChannel);
    },
    [setRemoveTarget]
  );

  const handleCancelDelete = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleConfirmDelete = useCallback(async () => {
    if (!removeTarget) return;
    const success = await deleteServiceChannel(removeTarget);
    if (success) {
      createNotification({
        key : 'delete-service-channel-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.callSplit} />),
        colour : settings.colours.alert.primary,
      })
      refresh();
    } else {
      createNotification({
        key : 'delete-service-channel-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.callSplit} />),
        colour : settings.colours.alert.alert,
      })
    }
    setRemoveTarget(null);
  }, [
    removeTarget,
    setRemoveTarget,
    deleteServiceChannel,
    createNotification,
    refresh
  ]);

  const generateActions = useCallback((serviceChannel : ServiceChannel) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          href={`/service-channels/${serviceChannel.id}`}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.callSplit}/>
        </Action>
        <Action
          label={localize(localeTableKeys.actions.delete)}
          onClick={handleInitDelete(serviceChannel)}
          disabled={!!removeTarget}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete}/>
        </Action>
      </TableActionCell>
    )
  }, [removeTarget, handleInitDelete]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      { showHead &&
        <>
          <Section
            title={localize(localeContentKeys.title)}
            text={localize(localeContentKeys.body)}
          >
            <Button
              onClick={handleRetrieve}
            >
              { localize(localeButtonKeys.refresh) }
            </Button>
            <Button
              onClick={handleCreateNew}
            >
              { localize(localeButtonKeys.new) }
            </Button>
          </Section>
          { creatingNew &&
            <CreateServiceChannel
              onCancel={handleCancelNew}
              onSave={handleSave}
            />
          }
        </>
      }
      <Section
        title={localize(localeContentKeys.index.title)}
      >
        { !!removeTarget && (
          <Banner
            icon={<Icon icon={settings.svgIcons.delete} />}
            actions={(
              <>
                <Button
                  onClick={handleConfirmDelete}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.index.confirmDelete) }
          </Banner>
        )}
        <ServiceChannelsTable
          serviceChannels={serviceChannels}
          count={tableLength}
          generateActions={generateActions}
        />
      </Section>
    </>
  );
}

export default ServiceChannelIndex;
