import React, { useCallback, useEffect, useState } from 'react';

import { Integration } from '#types';

import useNotifications from '#hooks/useNotifications';
import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import IntegrationTable from '#components/integrations/IntegrationTable';
import CreateIntegration from '#components/integrations/CreateIntegration';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.integrations.integrationIndex;
const localeTableKeys = locale.keys.tables.integrations;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.integrations;

interface IntegrationIndexProps {
  showHead? : boolean;
}

function IntegrationIndex({ showHead = true } : IntegrationIndexProps) {
  const { createNotification } = useNotifications();
  const {
    retrieveIntegrations,
    deleteIntegration
  } = useIntegrations();

  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [creatingNew, setCreatingNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<Integration | null>(null);

  const refresh = useCallback(async () => {
    async function retrieve() {
      const retrievedIntegrations = await retrieveIntegrations();
      if (retrievedIntegrations) {
        setIntegrations(listRecords(retrievedIntegrations));
      }
    }
    retrieve();
  }, [setIntegrations, retrieveIntegrations]);

  const handleRetrieve = useCallback(() => {
    refresh();
  }, [refresh]);

  const handleCreateNew = useCallback(() => {
    setCreatingNew(true);
  }, [setCreatingNew]);

  const handleCancelNew = useCallback(() => {
    setCreatingNew(false);
  }, [setCreatingNew]);

  const handleSave = useCallback(() => {
    setCreatingNew(false);
    refresh();
  }, [setCreatingNew, refresh]);

  const handleInitDelete = useCallback((integration : Integration) => () => {
    setDeleteTarget(integration);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteIntegration(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-integration-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-integration-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.alert,
      });
    }
    setDeleteTarget(null);
  }, [deleteTarget, deleteIntegration, createNotification]);

  const generateActions = useCallback((integration : Integration) => {
    return (
      <TableActionCell>
        <Action
          label="View integration channels"
          href={`/integrations/${integration.id}`}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.integrationInstructions}/>
        </Action>
        <Action
          label={`${localize(localeTableKeys.actions.delete)} ${integration.id}`}
          onClick={handleInitDelete(integration)}
          disabled={deleteTarget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete}/>
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  useEffect(() => {
    refresh();
  }, [refresh])

  return (
    <>
      {
        showHead &&
        <>
          <Section
            title={localize(localeContentKeys.title)}
            text={localize(localeContentKeys.body)}
          >
            <Button
              onClick={handleRetrieve}
            >
              { localize(localeButtonKeys.refresh) }
            </Button>
          </Section>
          { creatingNew &&
            <CreateIntegration onCancel={handleCancelNew} onSave={handleSave} />
          }
        </>
      }
      <Section
        title={localize(localeContentKeys.index.title)}
      >
        { deleteTarget && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.delete} />)}
            actions={(
              <>
                <Button onClick={handleDelete}>
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button onClick={handleCancelDelete}>
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.index.confirmDelete) +
              ` (${deleteTarget.name})` }
          </Banner>
        ) }
        <Button
          onClick={handleCreateNew}
        >
          { localize(localeButtonKeys.new) }
        </Button>
        <IntegrationTable
          integrations={integrations}
          generateActions={generateActions}
          pageCount={10}
        />
      </Section>
    </>
  )
}

export default IntegrationIndex;
