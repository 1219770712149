import React from 'react';

import { useParams } from '#hooks/useNavigation';

import CategoryIndex from '#components/categories/CategoryIndex';
import CategoryInfo from '#components/categories/CategoryInfo';

function Categories() {
  const urlParams = useParams<{ categoryId? : string; }>();

  const parsedId = parseInt(urlParams.categoryId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !id
        ? <CategoryIndex />
        : <CategoryInfo categoryId={id} />
      }
    </>
  );
}

export default Categories;
