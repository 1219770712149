import React from 'react';

import { settings } from '#materials/types';
import Flex from '#materials/Flex';
import FlexItem from '#materials/FlexItem';

interface SplitProps {
  left? : React.ReactNode;
  right? : React.ReactNode;
}

function Split({ left, right } : SplitProps) {
  return (
    <Flex justification={settings.alignments.spaceBetween}>
      <FlexItem>
        { left }
      </FlexItem>
      <FlexItem>
        { right }
      </FlexItem>
    </Flex>
  );
}

export default Split;
