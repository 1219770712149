import React, { useCallback, useEffect, useState } from 'react';

import { ServiceChannel, Tag } from '#types';

import useNotifications from '#hooks/useNotifications';
import useTags from '#hooks/useTags';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Icon from '#materials/Icon';
import Text from '#materials/Text';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import TagTable from '#components/tags/TagTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.serviceChannels.serviceChannelTags;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.tags;
const localeNotificationKeys = locale.keys.notifications.tags;

interface ServiceChannelTagsProps {
  serviceChannel : ServiceChannel;
}

function ServiceChannelTags({ serviceChannel } : ServiceChannelTagsProps) {
  const { createNotification } = useNotifications();
  const {
    retrieveTags,
    addServiceChannelToTag,
    removeServiceChannelFromTag,
  } = useTags();

  const [channelTags, setChannelTags] = useState<Tag[]>([]);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [removeTarget, setRemoveTarget] = useState<Tag | null>(null);

  const retrieve = useCallback(async () => {
    const serviceChannelId = serviceChannel.id;
    if (!serviceChannelId) return;

    const retrievedTags = await retrieveTags();
    if (!retrievedTags) return;

    setChannelTags(listRecords(retrievedTags).filter(tag => (
      tag.availableChannelIds &&
      tag.availableChannelIds.includes(serviceChannelId)
    )));
    setAvailableTags(listRecords(retrievedTags).filter(tag => (
      tag.availableChannelIds &&
      !tag.availableChannelIds.includes(serviceChannelId)
    )));
  }, [retrieveTags, setChannelTags, setAvailableTags, serviceChannel]);

  const handleEdit = useCallback(() => {
    setEdit(true);
  }, [setEdit]);

  const handleCancel = useCallback(() => {
    setEdit(false);
  }, [setEdit]);

  const handleAdd = useCallback((tag : Tag) => async () => {
    const success = await addServiceChannelToTag(tag, serviceChannel);
    if (success) {
      createNotification({
        key : 'add-tag-success',
        message : localize(localeNotificationKeys.add.success),
        icon : (<Icon icon={settings.svgIcons.localOffer} />),
        colour : settings.colours.alert.primary,
      })
    } else {
      createNotification({
        key : 'add-tag-error',
        message : localize(localeNotificationKeys.add.error),
        icon : (<Icon icon={settings.svgIcons.localOffer} />),
        colour : settings.colours.alert.alert,
      })
    }
  }, [serviceChannel, addServiceChannelToTag, createNotification]);

  const handleInitRemove = useCallback((tag : Tag) => () => {
    setRemoveTarget(tag);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleConfrimRemove = useCallback(async () => {
    if (!removeTarget) return;

    const success = await removeServiceChannelFromTag(removeTarget, serviceChannel);
    if (success) {
      createNotification({
        key : 'remove-tag-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.localOffer} />),
        colour : settings.colours.alert.primary,
      })
    } else {
      createNotification({
        key : 'remove-tag-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.localOffer} />),
        colour : settings.colours.alert.alert,
      })
    }

    setRemoveTarget(null);
  }, [
    removeTarget,
    serviceChannel,
    removeServiceChannelFromTag,
    createNotification,
  ]);

  const generateRemoveActions = useCallback((tag : Tag) => {
    return (
      <TableActionCell>
        { edit
           ? (<Action
            label={`${localize(localeTableKeys.actions.removeTag)} ${tag.id}`}
            onClick={handleInitRemove(tag)}
            disabled={!!removeTarget}
            colour={settings.colours.button.alert}
          >
            <Icon icon={settings.svgIcons.remove} />
          </Action>)
          : (<Action
            label={`${localize(localeTableKeys.actions.view)} ${tag.id}`}
            href={`/tags/${tag.id}`}
          >
            <Icon icon={settings.svgIcons.localOffer} />
          </Action>)
        }
      </TableActionCell>
    );
  }, [edit, removeTarget, handleInitRemove]);

  const generateAddActions = useCallback((tag : Tag) => {
    return (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.addTag)} ${tag.id}`}
          onClick={handleAdd(tag)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAdd]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { !!removeTarget && (
        <Banner
          icon={<Icon icon={settings.svgIcons.remove} />}
          actions={(
            <>
              <Button
                onClick={handleConfrimRemove}
              >
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button
                onClick={handleCancelRemove}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmRemove) +
            ` (${removeTarget.name})` }
        </Banner>
      ) }
      { !!channelTags.length
        ? (<TagTable
          tags={channelTags}
          generateActions={generateRemoveActions}
          pageCount={10}
        />)
        : (<Text>{ localize(localeContentKeys.notAssigned) }</Text>)
      }
      { edit && (
        <Segment title={localize(localeContentKeys.availableTags)}>
          { !!availableTags.length
            ? (<TagTable
              tags={availableTags}
              generateActions={generateAddActions}
              pageCount={20}
            />)
            : (<Text>{ localize(localeContentKeys.notFound) }</Text>)
          }
        </Segment>
      ) }
      { edit
        ? (<Button
          onClick={handleCancel}
          colour={settings.colours.button.alert}
        >
          { localize(localeButtonKeys.close) }
        </Button>)
        : (<Button
          onClick={handleEdit}
        >
          { localize(localeButtonKeys.edit) }
        </Button>)
      }
    </Section>
  );
}

export default ServiceChannelTags;
