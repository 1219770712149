import React, { useCallback } from 'react';

import { Route } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import useRoutes from '#hooks/useRoutes';

import { settings } from '#materials';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import RouteForm from '#components/routes/RouteForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.routes.createRoute;
const localeButtonKeys = locale.keys.buttons;

interface CreateRouteProps {
  onSave? : (route : Route) => void;
  onCancel? : () => void;
}

function CreateRouteControl({ onSave, onCancel } : CreateRouteProps) {
  const { state : route, reset } = useFormContext<Route>();
  const { createRoute } = useRoutes();

  const handleSave = useCallback(async () => {
    if (!route) return;

    const newRoute = await createRoute(route);
    if (newRoute && onSave) onSave(newRoute);
  }, [route, createRoute, onSave]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Section title={localize(localeContentKeys.title)}>
      { !!route && <RouteForm route={route} /> }
      <Button
        onClick={handleSave}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
    </Section>
  );
}

function CreateRoute({ onSave, onCancel } : CreateRouteProps) {
  return (
    <FormProvider
      init={{
        name : '',
        areaIds : [],
      }}
      editing={true}
    >
      <CreateRouteControl onSave={onSave} onCancel={onCancel} />
    </FormProvider>
  );
}

export default CreateRoute;
