import React, { useState, useEffect, useCallback} from 'react';

import { Schedule, TimeSlot } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useTimeSlots from '#hooks/useTimeSlots';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import TimeSlotTable, { TABLE_KEYS } from '#components/timeslots/TimeSlotTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeButtonKeys = locale.keys.buttons;
const localeContentKeys = locale.keys.content.schedules.scheduleTimeSlots;
const localeTableKeys = locale.keys.tables.timeSlots;
const localeNotificationKeys = locale.keys.notifications.timeSlots;

interface ScheduleTimeslotsProps {
  schedule : Schedule;
  onUpdate? : (timeSlot : TimeSlot, schedule : Schedule) => void;
}

function ScheduleTimeslots({
  schedule,
  onUpdate,
} : ScheduleTimeslotsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    retrieveTimeSlots,
    addTimeSlotToSchedule,
    removeTimeSlotFromSchedule,
  } = useTimeSlots();

  const [scheduleTimeslots, setScheduleTimeslots] = useState<TimeSlot[]>([]);
  const [availableTimeslots, setAvailableTimeslots] = useState<TimeSlot[]>([]);
  const [editing, setEditing] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<TimeSlot | null>(null);

  const viewTimeSlot = useCallback((timeSlot : TimeSlot) => () => {
    navigate(`/time-slots/${timeSlot.id}`);
  }, [navigate]);

  const handleAdd = useCallback(
    (timeSlot : TimeSlot, schedule : Schedule) => async () =>{
      const success = await addTimeSlotToSchedule(schedule, timeSlot);
      if (success && onUpdate) onUpdate(timeSlot, schedule);
    },
    [onUpdate, addTimeSlotToSchedule],
  );

  const handleInitRemove = useCallback((timeSlot : TimeSlot) => () => {
    setRemoveTarget(timeSlot);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleConfirmRemove = useCallback(async () => {
    if (!schedule || !removeTarget) return;
    const success = await removeTimeSlotFromSchedule(schedule, removeTarget);
    if (success) {
      createNotification({
        key : 'remove-time-slot-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.accessTime} />),
        colour : settings.colours.alert.primary,
      })
      if (!!onUpdate) onUpdate(removeTarget, schedule);
    } else {
      createNotification({
        key : 'remove-time-slot-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.accessTime} />),
        colour : settings.colours.alert.alert,
      })
    }
    setRemoveTarget(null);
  }, [
    schedule,
    onUpdate,
    removeTarget,
    setRemoveTarget,
    removeTimeSlotFromSchedule,
    createNotification,
  ]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleCancel = useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  const generateTimeSlotActions = useCallback((timeSlot : TimeSlot) => {
    if (!schedule) return (<></>);
    return (
      <TableActionCell>
        { editing
          ? (!!timeSlot.id && (timeSlot.id in schedule.timeSlots))
            ? <Action
              label={`${localize(localeTableKeys.actions.remove)}` +
              ` ${timeSlot.id}`}
              onClick={handleInitRemove(timeSlot)}
              disabled={!!removeTarget}
              colour={settings.colours.button.alert}
            >
              <Icon icon={settings.svgIcons.remove} />
            </Action>
            : <Action
              label={`${localize(localeTableKeys.actions.add)}` +
              ` ${timeSlot.id}`}
              onClick={handleAdd(timeSlot, schedule)}
              colour={settings.colours.button.primary}
            >
                <Icon icon={settings.svgIcons.add} />
            </Action>
          : <Action
            label={`${localize(localeTableKeys.actions.view)} ${timeSlot.id}`}
            onClick={viewTimeSlot(timeSlot)}
          >
            <Icon icon={settings.svgIcons.accessTime} />
          </Action>
        }
      </TableActionCell>
    );
  }, [
    schedule,
    editing,
    removeTarget,
    viewTimeSlot,
    handleInitRemove,
    handleAdd,
  ]);

  useEffect(() => {
    async function fetchTimeSlots() {
      const retrievedTimeSlots = await retrieveTimeSlots();
      if (retrievedTimeSlots) {
        setScheduleTimeslots(listRecords(retrievedTimeSlots).filter(
          timeSlot => (timeSlot?.id as number) in schedule.timeSlots
        ));
        setAvailableTimeslots(listRecords(retrievedTimeSlots).filter(
          timeSlot => !(timeSlot.id &&
            (timeSlot.id as number) in schedule.timeSlots)
        ));
      }
    }
    fetchTimeSlots();
  }, [schedule, retrieveTimeSlots]);

  return (
    <>
      { schedule &&
        <Section
          title={localize(localeContentKeys.title)}
        >
          { !!removeTarget && (
            <Banner
              icon={(<Icon icon={settings.svgIcons.remove} />)}
              actions={(
                <>
                  <Button
                    onClick={handleConfirmRemove}
                  >
                    { localize(localeButtonKeys.remove) }
                  </Button>
                  <Button
                    onClick={handleCancelRemove}
                  >
                    { localize(localeButtonKeys.cancel) }
                  </Button>
                </>
              )}
              colour={settings.colours.alert.alert}
            >
              { `${localize(localeContentKeys.confirmRemove)} ` +
                ` (${removeTarget.name})` }
            </Banner>
          ) }
          { !!(scheduleTimeslots && scheduleTimeslots.length)
            ? <TimeSlotTable
              timeSlots={scheduleTimeslots}
              count={scheduleTimeslots.length}
              generateActions={generateTimeSlotActions}
              tableKeys={Object.values(TABLE_KEYS).filter(
                k => k !== TABLE_KEYS.start
              )}
            />
            : <Text>{ localize(localeContentKeys.notAssigned) }</Text>
          }
          { !editing &&
            <Button
              onClick={handleEdit}
            >
              { localize(localeButtonKeys.add) }
            </Button>
          }
          { editing &&
            <Segment
              title={localize(localeContentKeys.availableTimeSlots)}
            >
              { !!(availableTimeslots && availableTimeslots.length)
                ? <TimeSlotTable
                  timeSlots={availableTimeslots}
                  count={availableTimeslots.length}
                  generateActions={generateTimeSlotActions}
                  tableKeys={Object.values(TABLE_KEYS).filter(
                    k => k !== TABLE_KEYS.start
                  )}
                />
                : <Text>{ localize(localeContentKeys.notFound) }</Text>
              }
              <Button onClick={handleCancel}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </Segment>
          }
        </Section>
      }
    </>
  );
}

export default ScheduleTimeslots;
