import React, { useCallback } from 'react';

import { Category } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localeKeys = locale.keys.forms.categories;

interface CategoryFormProps {
  category : Category;
}

function CategoryForm({ category : fallback } : CategoryFormProps) {

  const { state, dispatch, editing } = useForm<Category>();

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);

  const setDescription = useCallback((description : string) => {
    dispatch({ description });
  }, [dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `category-${fallback?.id}`
    : 'category-new';

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localeKeys.labels.name)}
        value={state ? state.name : fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.full}
      />
      <TextInput
        id={`${idPrefix}-description`}
        label={localize(localeKeys.labels.description)}
        value={state ? state.description : fallback.description}
        onChange={setDescription}
        disabled={!editing}
        width={settings.dimensions.full}
        minRows={2}
      />
    </Form>
  );
}

export default CategoryForm;
