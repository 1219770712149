import React, { useEffect, useState, useCallback } from 'react';

import { Service, ServiceChannel, Location } from '#types';

import useServices from '#hooks/useServices';
import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';
import Select from '#materials/Select';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeFormHeaderKeys = locale.keys.forms.services.labels;

const MAX_LEAD_TIME = 4 * 7 * 24 * 60 * 60 * 1000;

interface ServiceFormProps {
  service : Service;
  onChange? : (service : Service) => void;
}

function ServiceForm({
  service : fallback,
  onChange,
} : ServiceFormProps) {
  const { state, dispatch, editing } = useForm<Service>({ onChange });

  const {
    retrieveServiceChannels,
    retrieveLocations,
  } = useServices();

  const [
    serviceChannels,
    setServiceChannels
  ] = useState<ServiceChannel[] | null>(null);
  const [
    locations,
    setLocations
  ] = useState<Location[] | null>(null);

  const setServiceChannel = useCallback(
    async (serviceChannel : ServiceChannel | null) => {
      if (serviceChannel) {
        dispatch({ serviceChannelId : serviceChannel.id });
      } else {
        dispatch({ serviceChannelId : null });
      }
    },
    [dispatch],
  );

  const setLocation = useCallback(
    async (location : Location | null) => {
      if (location) {
        dispatch({ locationId : location.id });
      } else {
        dispatch({ locationId : null });
      }
    },
    [dispatch],
  );

  const setName = useCallback(
    async (name : string) => {
      dispatch({ name });
    }, [dispatch]
  );

  const setLeadTime = useCallback(
    async (newLeadTime : number | null) => {
      if (newLeadTime === null) return;
      dispatch({ leadTime : newLeadTime ?? null });
    },
    [dispatch]
  );

  const setCutoffTime = useCallback(
    async (newCutoffTime : number | null) => {
      if (newCutoffTime === null) return;
      dispatch({ cutoffTime : newCutoffTime ?? null });
    },
    [dispatch]
  );

  const retrieve = useCallback(
    async () => { const newServiceChannels = await retrieveServiceChannels();
      if (newServiceChannels) {
        setServiceChannels(listRecords(newServiceChannels));
      }
      const newLocations = await retrieveLocations();
      if (newLocations) {
        setLocations(listRecords(newLocations));
      }
    },
    [
      retrieveServiceChannels,
      setServiceChannels,
      retrieveLocations,
      setLocations
    ]
  );

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  const idPrefix = (fallback && fallback.id)
    ? `service-${fallback?.id}`
    : 'service-new';

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localeFormHeaderKeys.serviceName)}
        value={state?.name ?? fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.half}
      />
      <Select
        label={localize(localeFormHeaderKeys.serviceChannel)}
        selected={serviceChannels?.find(
          (sc) => sc.id === state?.serviceChannelId
        ) ?? null}
        options={serviceChannels ? serviceChannels : []}
        onChange={setServiceChannel}
        keyGenerator={(serviceChannel : ServiceChannel | null) =>
          `${serviceChannel?.id}-${serviceChannel?.name}`}
        labelGenerator={(serviceChannel) => serviceChannel?.name ?? ''}
        disabled={!editing}
        width={settings.dimensions.half}
      />
      <Select
        label={localize(localeFormHeaderKeys.location)}
        selected={locations?.find(
          (sl) => sl.id === state?.locationId
        ) ?? null}
        options={locations ? locations : []}
        onChange={setLocation}
        keyGenerator={(location : Location | null) =>
          `${location?.id}-${location?.name}`}
        labelGenerator={(location) => location?.name ?? ''}
        disabled={!editing}
        width={settings.dimensions.half}
      />
      <TextInput
        id={`${idPrefix}-leadTime`}
        label={localize(localeFormHeaderKeys.leadTime)}
        inputType={settings.inputType.time}
        value={state?.leadTime ?? fallback.leadTime ?? 0}
        max={MAX_LEAD_TIME + 10 * 60 * 1000}
        onChange={setLeadTime}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      <TextInput
        id={`${idPrefix}-cutoffTime`}
        label={localize(localeFormHeaderKeys.cutoffTime)}
        inputType={settings.inputType.time}
        value={state?.cutoffTime ?? fallback.cutoffTime ?? 0}
        max={MAX_LEAD_TIME}
        onChange={setCutoffTime}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
    </Form>
  );
}

export default ServiceForm;
