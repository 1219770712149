import React, { useState, useEffect } from 'react';

import { ServiceChannel } from '#types';

import { FormProvider } from '#context/FormContext';

import useServices from '#hooks/useServices';

import Section from '#components/dashboard/Section';
import ServiceChannelForm from '#components/serviceChannels/ServiceChannelForm';

import { settings } from '#materials';
import Button from '#materials/Button';

import locale, { localize } from '#utils/locale';

const localeServiceChannelKeys = (
  locale.keys.content.serviceChannels.createServiceChannel);
const localeButtonKeys = locale.keys.buttons;

interface CreateServiceChannelProps {
  onSave? : (serviceChannel : ServiceChannel) => void;
  onCancel? : () => void;
}

function CreateServiceChannel({
  onCancel,
  onSave } : CreateServiceChannelProps) {

  const { createServiceChannel } = useServices();

  const [serviceChannel, setServiceChannel] = useState<ServiceChannel>({
    name : '',
    active : false,
    visible : false,
    requireCheckout : false,
    description : '',
  });

  const [formKey, setFormKey] = useState(true);
  const [saved, setSaved] = useState(true);
  const [cleared, setCleared] = useState(true);

  useEffect(() => {
    setSaved(false);
    setCleared(false);
  }, [serviceChannel] );

  function resetServiceChannel() {
    setServiceChannel({
      name : '',
      active : false,
      visible : false,
      requireCheckout : false,
      description : '',
    });
    setFormKey(!formKey);
  }

  async function handleSave() {
    if (serviceChannel) {
      const newServiceChannel = await createServiceChannel(serviceChannel);
      if (!newServiceChannel) return;

      setSaved(true);
      if (onSave) await onSave(newServiceChannel);
    }
  }

  function handleCancel() {
    if (onCancel) onCancel();
  }

  function handleClear() {
    resetServiceChannel();
    setCleared(true);
  }

  return (
    <Section title={localize(localeServiceChannelKeys.title)}>
      <FormProvider
        init={serviceChannel}
        onChange={setServiceChannel}
        formKey={formKey ? '' : '_'}
      >
        <ServiceChannelForm
          serviceChannel={serviceChannel}
        />
      </FormProvider>

      <Button
        onClick={handleSave}
        disabled={saved}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={handleCancel}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
      <Button
        onClick={handleClear}
        disabled={cleared}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
    </Section>
  );
}

export default CreateServiceChannel;
