import React from 'react';

import Collapse from '@mui/material/Collapse';
import MuiList from '@mui/material/List';

interface ListProps {
  collapsable? : boolean;
  open? : boolean;
  disabled? : boolean;
  showBorder? : boolean;
  children : React.ReactNode
}

function ListWrapper({
  collapsable,
  open,
  disabled,
  children,
} : ListProps) {
  if (collapsable && !disabled) {
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        { children }
      </Collapse>
    );
  } else {
    return (
      <>
        { children }
      </>
    );
  }
}

function List({
  children,
  collapsable,
  open,
  showBorder = false,
  disabled = false,
} : ListProps) {
  return (
    <ListWrapper collapsable={collapsable} open={open} disabled={disabled}>
      <MuiList
        sx={{
          p : [0],
          ...(showBorder && {
            borderTop : '1px solid rgba(0, 0, 0, 0.12)',
            borderBottom : '1px solid rgba(0, 0, 0, 0.12)'
          }),
        }}
      >
        { children }
      </MuiList>
    </ListWrapper>
  )
}

export default List;
