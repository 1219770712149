import React, { useCallback, useEffect, useState } from 'react';

import { Tax, TaxIntegration, ExternalTax } from '#types';

import useNotifications from '#hooks/useNotifications';
import useTaxes from '#hooks/useTaxes';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Icon from '#materials/Icon';
import Text from '#materials/Text';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import TaxIntegrationsTable from '#components/taxes/TaxIntegrationsTable';
import ExternalTaxesSearch from '#components/taxes/ExternalTaxesSearch';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.taxes.taxIntegrations;
const localeTableKeys = locale.keys.tables.taxIntegrations;
const localeNotificationKeys = locale.keys.notifications;
const localeButtonKeys = locale.keys.buttons;

interface TaxIntegrationsProps {
  tax : Tax;
}

function TaxIntegrations({ tax } : TaxIntegrationsProps) {
  const { createNotification } = useNotifications();
  const {
    createTaxIntegration,
    deleteTaxIntegration,
    pushTaxIntegration,
    pullTaxIntegration
  } = useTaxes();

  const [
    taxIntegrations,
    setTaxIntegrations,
  ] = useState<TaxIntegration[]>([]);
  const [manage, setManage] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<TaxIntegration | null>(null);

  const link = useCallback((externalTax : ExternalTax) => async () => {
    const newTax = await createTaxIntegration(tax, externalTax);
    if (newTax) {
      setTaxIntegrations(newTax.integrations
        ? Object.values(newTax.integrations)
        : []);
      createNotification({
        key : 'link-tax-success',
        message : localize(localeNotificationKeys.taxes.link.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.percent} />,
      });
    } else {
      createNotification({
        key : 'link-tax-failure',
        message : localize(localeNotificationKeys.taxes.link.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.percent} />,
      });
    }
  }, [tax, createTaxIntegration, createNotification]);

  const initUnlink = useCallback((taxIntegration : TaxIntegration) => () => {
    setRemoveTarget(taxIntegration);
  }, []);

  const cancelUnlink = useCallback(() => {
    setRemoveTarget(null);
  }, []);

  const unlink = useCallback(async () => {
    if (!removeTarget) return;
    const newTax = await deleteTaxIntegration(removeTarget);
    if (newTax) {
      setTaxIntegrations(newTax.integrations
        ? Object.values(newTax.integrations)
        : []);
      createNotification({
        key : 'unlink-tax-success',
        message : localize(localeNotificationKeys.taxes.unlink.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.percent} />,
      });
    } else {
      createNotification({
        key : 'unlink-tax-failure',
        message : localize(localeNotificationKeys.taxes.unlink.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.percent} />,
      });
    }
    setRemoveTarget(null);
  }, [removeTarget, deleteTaxIntegration, createNotification]);

  const push = useCallback(
    (taxIntegration : TaxIntegration) => async () => {
      const newTax = await pushTaxIntegration(taxIntegration);
      if (newTax) {
        setTaxIntegrations(newTax.integrations
          ? Object.values(newTax.integrations)
          : []);
        createNotification({
          key : 'push-tax-success',
          message : localize(localeNotificationKeys.taxes.push.success),
          colour : settings.colours.alert.primary,
          icon : <Icon icon={settings.svgIcons.percent} />,
        });
      } else {
        createNotification({
          key : 'push-tax-failure',
          message : localize(localeNotificationKeys.taxes.push.error),
          colour : settings.colours.alert.alert,
          icon : <Icon icon={settings.svgIcons.percent} />,
        });
      }
    },
    [pushTaxIntegration, createNotification],
  );

  const pull = useCallback(
    (taxIntegration : TaxIntegration) => async () => {
      const newTax = await pullTaxIntegration(taxIntegration);
      if (newTax) {
        setTaxIntegrations(newTax.integrations
          ? Object.values(newTax.integrations)
          : []);
        createNotification({
          key : 'pull-tax-success',
          message : localize(localeNotificationKeys.taxes.pull.success),
          colour : settings.colours.alert.primary,
          icon : <Icon icon={settings.svgIcons.percent} />,
        });
      } else {
        createNotification({
          key : 'pull-tax-failure',
          message : localize(localeNotificationKeys.taxes.pull.error),
          colour : settings.colours.alert.alert,
          icon : <Icon icon={settings.svgIcons.percent} />,
        });
      }
    },
    [pullTaxIntegration, createNotification],
  );

  const openManage = useCallback(() => { setManage(true); }, []);
  const closeManage = useCallback(() => { setManage(false); }, []);

  const generateIntegrationActions = useCallback(
    (taxIntegration : TaxIntegration) => (
      <TableActionCell>
        { manage
          ? (
            <Action
              label={localize(localeTableKeys.actions.unlink)}
              onClick={initUnlink(taxIntegration)}
              disabled={!!removeTarget}
              colour={settings.colours.button.alert}
            >
              <Icon icon={settings.svgIcons.linkOff} />
            </Action>
          ) : (
            <>
              <Action
                label={localize(localeTableKeys.actions.push)}
                onClick={push(taxIntegration)}
              >
                <Icon icon={settings.svgIcons.upload} />
              </Action>
              <Action
                label={localize(localeTableKeys.actions.pull)}
                onClick={pull(taxIntegration)}
              >
                <Icon icon={settings.svgIcons.download} />
              </Action>
            </>
          )
        }
      </TableActionCell>
    ),
    [manage, removeTarget, initUnlink, push, pull],
  );

  const generateExternalActions = useCallback(
    (externalTax : ExternalTax) => (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.link)}
          onClick={link(externalTax)}
        >
          <Icon icon={settings.svgIcons.link} />
        </Action>
      </TableActionCell>
    ),
    [link],
  );

  const filterExternal = useCallback(
    (externalTaxes : ExternalTax) => (tax.integrations
      ? !Object.values(tax.integrations).some(
        (taxIntegration) => (
          taxIntegration.externalId === externalTaxes.externalId
            && taxIntegration.integrationId === externalTaxes.integrationId
        ),
      )
      : true),
    [tax],
  );

  useEffect(() => {
    if (!tax.integrations) setTaxIntegrations([]);
    else setTaxIntegrations(Object.values(tax.integrations));
  }, [tax]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { removeTarget && (
        <Banner
          colour={settings.colours.alert.alert}
          icon={<Icon icon={settings.svgIcons.linkOff} />}
          actions={
            <>
              <Button onClick={unlink}>
                {localize(localeButtonKeys.remove)}
              </Button>
              <Button onClick={cancelUnlink}>
                {localize(localeButtonKeys.cancel)}
              </Button>
            </>
          }
        >
          { localize(localeContentKeys.confirmUnlink) +
            ` ${removeTarget.externalId}?` }
        </Banner>
      ) }
      { !!taxIntegrations.length
        ? (
          <TaxIntegrationsTable
            taxIntegrations={taxIntegrations}
            generateActions={generateIntegrationActions}
          />
        ) : (
          <Text>{localize(localeContentKeys.notAssigned)}</Text>
        )
      }
      { manage && (
        <>
          <Segment title={localize(localeContentKeys.availableIntegrations)} />
          <ExternalTaxesSearch
            filter={filterExternal}
            generateActions={generateExternalActions}
          />
        </>
      ) }
      { manage
        ? (
          <Button
            onClick={closeManage}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.close) }
          </Button>
        ) : (
          <Button onClick={openManage}>
            { localize(localeButtonKeys.edit) }
          </Button>
        )
      }
    </Section>
  );
}

export default TaxIntegrations;
