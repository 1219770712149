import React, { useCallback } from 'react';

import { Area } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';
import { Action, TableActionCell } from '#materials/TableCell';

import VertexTable from './VertexTable';

import locale, { localize } from '#utils/locale';

const localeKeys = locale.keys.forms.areas;
const localeTableKeys = locale.keys.tables.areaVertices;

interface AreaFormProps {
  area : Area;
}

function AreaForm({ area : fallback } : AreaFormProps) {
  const { state, dispatch, editing } = useForm<Area>();

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);

  const generateActions = useCallback((index : number) => {
    function removeVertex() {
      if (!state) return;
      const newVertices = [...state.vertices];
      newVertices.splice(index, 1);
      dispatch({ vertices : newVertices });
    }

    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.remove)}
          onClick={removeVertex}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      </TableActionCell>
    );
  }, [state, dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `area-${fallback?.id}`
    : 'area-new';

  return (
    <>
      <Form>
        <TextInput
          id={`${idPrefix}-name`}
          label={localize(localeKeys.labels.name)}
          value={state ? state.name : fallback.name}
          onChange={setName}
          disabled={!editing}
          width={settings.dimensions.full}
        />
      </Form>
      <VertexTable
        area={fallback}
        generateActions={generateActions}
      />
    </>
  );
}

export default AreaForm;
