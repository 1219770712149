import React, { useEffect, useState } from 'react';

import { Address } from '#types';

import useAddresses from '#hooks/useAddresses';

import Section from '#components/dashboard/Section';
import AddressForm from '#components/addresses/AddressForm';

import { settings } from '#materials';
import Button from '#materials/Button';

interface CreateAddressProps {
  onSave? : (address : Address | null) => void;
  onCancel? : () => void;
}

function CreateAddress({ onSave, onCancel } : CreateAddressProps) {
  const { createAddress } = useAddresses();

  const [address, setAddress] = useState<Address>({
    street : '',
    city : '',
    postal : '',
  });
  const [formKey, setFormKey] = useState(true);
  const [saved, setSaved] = useState(true);
  const [cleared, setCleared] = useState(true);

  useEffect(() => {
    setSaved(false);
    setCleared(false);
  }, [address] );

  function resetAddress() {
    setAddress({
      street : '',
      city : '',
      postal : '',
    });
    setFormKey(!formKey);
  }

  function handleSave() {
    async function save() {
      if (address) {
        const newAddress = await createAddress(address);

        setSaved(true);
        if (onSave) onSave(newAddress);
      }
    }

    save();
  }

  function handleCancel() {
    if (onCancel) onCancel();
  }

  function handleClear() {
    resetAddress();
    setCleared(true);
  }

  return (
    <Section title="Create Address">
      <AddressForm
        id='create-address'
        address={ address }
        onChange={ setAddress }
        formKey={ formKey ? '' : '_' }
      />
      <Button
        onClick={ handleSave }
        disabled={ saved }
      >
        Save
      </Button>
      <Button
        onClick={ handleCancel }
      >
        Cancel
      </Button>
      <Button
        onClick={ handleClear }
        disabled={ cleared }
        colour={ settings.colours.button.alert }
      >
        Clear
      </Button>
    </Section>
  );
}

export default CreateAddress;
