import React, { useCallback, useEffect } from 'react';

import {
  Customer,
  ServiceChannel,
  Location,
  Route,
  Product,
  Category,
} from '#types';

import useNavigation, { useSearchParams } from '#hooks/useNavigation';
import useCustomers from '#hooks/useCustomers';
import useServices from '#hooks/useServices';
import useRoutes from '#hooks/useRoutes';
import useProducts from '#hooks/useProducts';
import useCategories from '#hooks/useCategories';
import useOrders from '#hooks/useOrders';

import { parseDateTime } from '#utils/date';

import Report from '#components/orders/OrdersReport';

function OrdersReport() {
  const [urlParams] = useSearchParams();
  const { adjustSearchParams } = useNavigation();

  const { customers } = useCustomers();
  const { locations, serviceChannels } = useServices();
  const { routes } = useRoutes();
  const { products } = useProducts();
  const {
    categories,
    load : loadCategories,
    loaded : categoriesLoaded,
  } = useCategories();
  const { load, loaded } = useOrders();

  const setCustomer = useCallback((customer : Customer | null) => {
    adjustSearchParams({ customer : customer?.id });
  }, [adjustSearchParams]);
  const setServiceChannel = useCallback((channel : ServiceChannel | null) => {
    adjustSearchParams({ serviceChannel : channel?.id });
  }, [adjustSearchParams]);
  const setLocation = useCallback((location : Location | null) => {
    adjustSearchParams({ location : location?.id });
  }, [adjustSearchParams]);
  const setRoute = useCallback((route : Route | false | null) => {
    adjustSearchParams({ route : route === false ? false : route?.id });
  }, [adjustSearchParams]);
  const setFromDate = useCallback((date : Date | null) => {
    adjustSearchParams({ from : date?.toISOString() });
  }, [adjustSearchParams]);
  const setToDate = useCallback((date : Date | null) => {
    adjustSearchParams({ to : date?.toISOString() });
  }, [adjustSearchParams]);
  const setProduct = useCallback((product : Product | null) => {
    adjustSearchParams({ product : product?.id });
  }, [adjustSearchParams]);
  const setCategory = useCallback((category : Category | null) => {
    adjustSearchParams({ category : category?.id });
  }, [adjustSearchParams]);

  useEffect(() => { load(); loadCategories(); }, [load, loadCategories]);

  if (!loaded || !categoriesLoaded) return null;

  const customerId = parseInt(urlParams.get('customer') ?? '');
  const serviceChannelId = parseInt(urlParams.get('serviceChannel') ?? '');
  const locationId = parseInt(urlParams.get('location') ?? '')
  const routeId = urlParams.get('route') === 'false'
    ? false
    : parseInt(urlParams.get('route') ?? '');

  const fromDate = parseDateTime(urlParams.get('from') ?? '');
  const toDate = parseDateTime(urlParams.get('to') ?? '');

  const product = parseInt(urlParams.get('product') ?? '');
  const category = parseInt(urlParams.get('category') ?? '');

  const props = {
    ...(!isNaN(customerId) && { customer : customers?.[customerId] }),
    ...(!isNaN(serviceChannelId)
      && { serviceChannel : serviceChannels?.[serviceChannelId] }),
    ...(!isNaN(locationId) && { location : locations?.[locationId] }),
    ...(routeId === false
      ? { route : false as false }
      : !isNaN(routeId) && { route : routes?.[routeId] }),
    ...(!isNaN(fromDate.getTime()) && { fromDate }),
    ...(!isNaN(toDate.getTime()) && { toDate }),
    ...(!isNaN(product) && { product : products?.[product] }),
    ...(!isNaN(category) && { category : categories?.[category] }),
  }

  return (
    <Report
      setCustomer={setCustomer}
      setServiceChannel={setServiceChannel}
      setLocation={setLocation}
      setRoute={setRoute}
      setFromDate={setFromDate}
      setToDate={setToDate}
      setProduct={setProduct}
      setCategory={setCategory}
      {...props}
    />
  );
}

export default OrdersReport;
