import React, { useCallback, useState } from 'react';

import { Customer } from '#types';

import Segment from '#materials/Segment';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import ContactDetails from '#components/customers/ContactDetails';
import CreateContact from '#components/customers/CreateContact';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customers.customerContactInfo;
const localeButtonKeys = locale.keys.buttons;

interface CustomerContactInfoProps {
  customer : Customer;
}

function CustomerContactInfo({ customer } : CustomerContactInfoProps) {
  const [creating, setCreating] = useState(false);

  const handleNew = useCallback(() => {
    setCreating(true);
  }, [setCreating]);

  const handleClose = useCallback(() => {
    setCreating(false);
  }, [setCreating]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { !!customer && Object.values(customer.contactInfo).map(
        (contactInfo, i) => (i <= 0)
          ? (<ContactDetails
            key={contactInfo.id}
            contactInfo={contactInfo}
            customer={customer}
            enableDelete={Object.values(customer.contactInfo).length > 1}
            slice={false}
          />)
          : (<Segment key={contactInfo.id}>
            <ContactDetails
              key={contactInfo.id}
              contactInfo={contactInfo}
              customer={customer}
              enableDelete={Object.values(customer.contactInfo).length > 1}
              slice={false}
            />
          </Segment>)
        ) }
      <Segment>
        { creating
          ? (<CreateContact
            customer={customer}
            onSave={handleClose}
            onCancel={handleClose}
            slice={false}
          />)
          : (<Button onClick={handleNew}>
            { localize(localeButtonKeys.new) }
          </Button>)
        }
      </Segment>
    </Section>
  );
}

export default CustomerContactInfo;
