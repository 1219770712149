import React, { useCallback, useState } from 'react';

import { Product } from '#types';

import useNotifications from '#hooks/useNotifications';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ProductSearch from '#components/products/ProductSearch';
import CreateProduct from '#components/products/CreateProduct';
import ImportProduct from '#components/products/ImportProduct';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productIndex;
const localeTableKeys = locale.keys.tables.products;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications;

interface ProductIndexProps {
  showHead? : boolean;
}

function ProductIndex({ showHead = true } : ProductIndexProps) {
  const { createNotification } = useNotifications();
  const {
    refreshProducts,
    deleteProduct,
    regenerateProductIndex,
  } = useProducts();

  const [regenerating, setRegenerating] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [importNew, setImportNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<Product | null>(null);

  const handleRefresh = useCallback(async () => {
    const freshProducts = await refreshProducts();
    if (!freshProducts) {
      createNotification({
        key : 'refresh-products-error',
        message : localize(localeNotificationKeys.products.bulkRetrieved.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [refreshProducts, createNotification]);

  const handleRegenerate = useCallback(async () => {
    setRegenerating(true);
    const success = await regenerateProductIndex();
    if (success) {
      createNotification({
        key : 'regenerate-product-index-success',
        message : localize(localeNotificationKeys.products.regenerate.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    } else {
      createNotification({
        key : 'regenerate-product-index-error',
        message : localize(localeNotificationKeys.products.regenerate.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
    setRegenerating(false);
  }, [regenerateProductIndex, createNotification, setRegenerating]);

  const handleNew = useCallback(() => {
    setCreateNew(true);
  }, [setCreateNew]);

  const handleImport = useCallback(() => {
    setImportNew(true);
  }, [setImportNew]);

  const handleSave = useCallback((product : Product) => {
    setCreateNew(false);
    setImportNew(false);
  }, [setCreateNew, setImportNew]);

  const handleCancel = useCallback(() => {
    setCreateNew(false);
    setImportNew(false);
  }, [setCreateNew]);

  const handleInitDelete = useCallback((product : Product) => () => {
    setDeleteTarget(product);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleDelete = useCallback(async () => {
    if (!deleteTarget) return;
    if (await deleteProduct(deleteTarget)) {
      createNotification({
        key : 'delete-product-success',
        message : localize(localeNotificationKeys.products.deleted.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    } else {
      createNotification({
        key : 'delete-product-error',
        message : localize(localeNotificationKeys.products.deleted.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
    setDeleteTarget(null);
  }, [deleteProduct, createNotification, deleteTarget, setDeleteTarget]);

  const generateActions = useCallback((product : Product) => {
    return (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.view)} ${product.id}`}
          href={`/products/${product.id}`}
        >
          <Icon icon={settings.svgIcons.shoppingBag} />
        </Action>
        <Action
          label={`${localize(localeTableKeys.actions.delete)} ${product.id}`}
          onClick={handleInitDelete(product)}
          disabled={deleteTarget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete} />
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  return (
    <>
      <Section
        title={localize(localeContentKeys.title)}
        text={localize(localeContentKeys.body)}
      >
        <Button
          onClick={handleRefresh}
        >
          {localize(localeButtonKeys.refresh)}
        </Button>
        <Button
          onClick={handleRegenerate}
          disabled={regenerating}
        >
          {localize(localeButtonKeys.regenerateIndex)}
        </Button>
      </Section>
      { createNew && (
        <CreateProduct
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) }
      { importNew && (
        <ImportProduct
          onImport={handleSave}
          onCancel={handleCancel}
        />
      ) }
      <Section
        title={localize(localeContentKeys.index.title)}
        text={localize(localeContentKeys.index.body)}
      >
        { !!deleteTarget && (
          <Banner
            icon={<Icon icon={settings.svgIcons.delete} />}
            actions={
              <>
                <Button
                  onClick={handleDelete}
                >
                  {localize(localeButtonKeys.delete)}
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  {localize(localeButtonKeys.cancel)}
                </Button>
              </>
            }
            colour={settings.colours.alert.alert}
          >
            {localize(localeContentKeys.confirmDelete)}
          </Banner>
        ) }
        <Button
          onClick={handleNew}
          disabled={createNew || importNew}
        >
          {localize(localeButtonKeys.new)}
        </Button>
        <Button
          onClick={handleImport}
          disabled={importNew || createNew}
        >
          {localize(localeButtonKeys.import)}
        </Button>
        <ProductSearch
          generateActions={generateActions}
        />
      </Section>
    </>
  );
}

export default ProductIndex;
