import React, { useCallback, useState } from 'react';

import { Collection, Product } from '#types';

import { FormProvider } from '#context/FormContext';
import useNotifications from '#hooks/useNotifications';

import useNavigation from '#hooks/useNavigation';
import { useFormContext } from '#hooks/useForm';
import useOptions from '#hooks/useOptions';

import Segment from '#materials/Segment';
import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import CollectionForm from '#components/assemblies/CollectionForm';
import CollectionTags from '#components/assemblies/CollectionTags';
import CollectionProducts from '#components/assemblies/CollectionProducts';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.collections.collectionDetails;
const localeFormKeys = locale.keys.forms.collections;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.collections;

interface CollectionDetailsProps {
  product : Product;
  collection : Collection;
  onSave? : (collection : Collection) => void;
}

function CollectionDetailsControl({
  product,
  collection : init,
  onSave
} : CollectionDetailsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();

  const {
    state : collection,
    reset,
    editing,
    setEditing,
  } = useFormContext<Collection>();
  const {
    refreshCollection,
    updateCollection,
    deleteCollection
  } = useOptions();

  const [confirmDelete, setConfirmDelete]= useState(false);

  const refresh = useCallback(async (collection : Collection) => {
    if (!collection.id) return;
    refreshCollection(collection.id);
  }, [refreshCollection]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!collection) return;

    const updatedCollection = await updateCollection(collection);
    if (updatedCollection) {
      createNotification({
        key : 'update-collection-success',
        message : localize(localeNotificationKeys.updated.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
      if (onSave) onSave(updatedCollection);
      setEditing(false);
    } else {
      createNotification({
        key : 'update-collection-error',
        message : localize(localeNotificationKeys.updated.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [onSave, collection, setEditing, updateCollection, createNotification]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleDelete = useCallback(async () => {
    if (!collection) return;
    const success = await deleteCollection(collection);
    if (success) {
      createNotification({
        key : 'delete-collection-success',
        message : localize(localeNotificationKeys.deleted.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.agriculture} />,
      })
      navigate(`/products/${product.id}`);
    } else {
      createNotification({
        key : 'delete-collection-error',
        message : localize(localeNotificationKeys.deleted.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.agriculture} />,
      })
    }
  }, [product, collection, deleteCollection, createNotification, navigate]);

  const initDelete = useCallback(
    () => setConfirmDelete(true),
    [setConfirmDelete],
  );

  const cancelDelete = useCallback(
    () => setConfirmDelete(false),
    [setConfirmDelete],
  );

  return (
    <>
      <Segment/>
      { confirmDelete && (
        <Banner
          onClose={cancelDelete}
          colour={settings.colours.alert.alert}
          icon={ <Icon icon={settings.svgIcons.delete} /> }
          actions={
            <>
              <Button onClick={handleDelete}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={cancelDelete} >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          }
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (collection && editing)
        ? <>
          <Button
            onClick={handleSave}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleEdit}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={initDelete}
            colour={settings.colours.button.alert}
            disabled={confirmDelete}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <CollectionForm
        collection={collection || init}
        onSubmit={handleSave}
      />
      <CollectionTags collection={collection || init} onUpdate={refresh}/>
      <CollectionProducts collection={collection || init} onUpdate={refresh}/>
    </>
  )
}

function CollectionDetails({
  product,
  collection,
  onSave
} : CollectionDetailsProps) {
  const validate = useCallback((collection : Collection) => {
    const errors : { [key : string] : string } = {};

    if (collection.max < collection.min) {
      errors.max = localize(localeFormKeys.errors.invalidMax);
    }

    if (collection.starting && collection.ending) {
      if (collection.starting > collection.ending) {
        errors.ending = localize(localeFormKeys.errors.invalidEnding);
      }
    }

    return errors;
  }, []);

  return (
    <FormProvider
      init={collection}
      validators={[validate]}
      editingInit={false}
    >
      <CollectionDetailsControl
        product={product}
        collection={collection}
        onSave={onSave}
      />
    </FormProvider>
  );
}

export default CollectionDetails;
