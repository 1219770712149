import React, { useCallback, useEffect, useState } from 'react';

import { TimeSlot, Schedule, ScheduleSelection } from '#types';

import useScheduling from '#hooks/useScheduling';

import { Dimension, settings } from '#materials';
import DatePicker from '#materials/DatePicker';
import DateTimePicker from '#materials/DateTimePicker';

import {
  timeScales,
  roundDateTime,
  shiftLocalDateTime,
} from '#utils/date';
import locale, { localize } from '#utils/locale';

const localeFormKeys = locale.keys.forms.orders;

interface TimeSlotPickerProps {
  label? : string;
  date? : Date | null;
  toDate? : Date | null;
  timeSlot? : TimeSlot | null;
  schedules? : Schedule | Schedule[];
  selectTime? : boolean;
  selectRange? : boolean;
  onChange? : (slots : ScheduleSelection[]) => void;
  onDateChange? : (date : Date | null) => void;
  onToDateChange? : (date : Date | null) => void;
  allowClear? : boolean;
  errors? : string[];
  toErrors? : string[];
  disabled? : boolean;
  width? : Dimension
}
interface TimeSlotPickerTimeProps extends TimeSlotPickerProps {
  toDate? : undefined;
  selectTime : true;
  selectRange? : false | undefined;
  toErrors? : undefined;
}
interface TimeSlotRangePickerProps extends TimeSlotPickerProps {
  selectTime? : false | undefined;
  selectRange : true;
}

function TimeSlotPicker(props : TimeSlotPickerProps) : JSX.Element;
function TimeSlotPicker(props : TimeSlotPickerTimeProps) : JSX.Element;
function TimeSlotPicker(props : TimeSlotRangePickerProps) : JSX.Element;
function TimeSlotPicker({
  label = '',
  date : fDate,
  toDate : tDate,
  timeSlot = null,
  schedules,
  selectRange = false,
  selectTime = !selectRange,
  allowClear = false,
  onChange,
  onDateChange,
  onToDateChange,
  errors,
  toErrors,
  disabled = false,
  width = settings.dimensions.full,
} : TimeSlotPickerProps) {
  const {
    validateTimeSlotDay,
    validateTimeSlotDateTime,
    validateScheduleDay,
    validateScheduleTime,
    calculateScheduleMinStep,
    findSelections,
    checkSchedule,
  } = useScheduling();

  const [fromDate, setFromDate] = useState<Date | null>(fDate ?? null);
  const [toDate, setToDate] = useState<Date | null>(tDate ?? null);
  const [sched, setSched] = useState(schedules
    ? (schedules instanceof Array ? schedules : [schedules])
    : []);
  const [minStep, setMinStep] = useState(calculateScheduleMinStep(sched));

  const handleFromDateChange = useCallback((newDate : Date | null) => {
    setFromDate(newDate);
    if (onDateChange) onDateChange(newDate);

    if (!onChange) return;
    if (!newDate && (!selectRange || !toDate)) {
      onChange([]);
      return;
    }

    if (!newDate) return;
    if (selectRange) {
      const nextDay = shiftLocalDateTime(newDate, 86400000);
      onChange(sched.map(
        (s) => findSelections(s, newDate, toDate ?? nextDay)
      ).flat());
      return;
    }

    if (selectTime) {
      const selections = sched.map((s) => checkSchedule(s, newDate)).filter(
        (r) => r,
      ) as ScheduleSelection[];
      if (selections.length) {
        onChange(selections);
        return;
      }
    }

    const start = roundDateTime(newDate, timeScales.day);
    const end = shiftLocalDateTime(start, 86400000);
    onChange(sched.map((s) => findSelections(s, start, end)).flat());
  }, [
    toDate,
    sched,
    selectTime,
    selectRange,
    findSelections,
    checkSchedule,
    onChange,
    onDateChange,
  ]);

  const handleToDateChange = useCallback((newDate : Date | null) => {
    if (!selectRange) return;
    setToDate(newDate);
    if (onToDateChange) onToDateChange(newDate);

    if (!onChange) return;
    if (!fromDate) {
      onChange([]);
      return;
    }

    const nextDay = shiftLocalDateTime(fromDate, 86400000);
    onChange(sched.map(
      (s) => findSelections(s, fromDate, newDate ?? nextDay)
    ).flat());
  }, [sched, fromDate, selectRange, onChange, onToDateChange, findSelections]);

  const validateDate = useCallback(
    (date : Date) => validateScheduleDay(sched, date)
      && (!timeSlot || validateTimeSlotDay(timeSlot, date)),
    [sched, timeSlot, validateScheduleDay, validateTimeSlotDay],
  );

  const validateToDate = useCallback(
    (date : Date) => validateDate(date) && (!fromDate || date >= fromDate),
    [validateDate, fromDate],
  );

  const validateTime = useCallback(
    (time : Date) => validateScheduleTime(sched, time)
      && (!timeSlot || validateTimeSlotDateTime(timeSlot, time)),
    [sched, timeSlot, validateScheduleTime, validateTimeSlotDateTime],
  );

  useEffect(() => {
    if (tDate?.getTime() !== toDate?.getTime()) setToDate(tDate ?? null);
    if (fDate?.getTime() !== fromDate?.getTime()) setFromDate(fDate ?? null);
  }, [fDate, tDate, fromDate, toDate]);

  useEffect(() => {
    const s = schedules
      ? (schedules instanceof Array ? schedules : [schedules])
      : [];
    setSched(s);
    setMinStep(calculateScheduleMinStep(s));
  }, [schedules, calculateScheduleMinStep]);

  return (
    <>
      { selectTime
        ? (<DateTimePicker
          dateLabel={`${label} ${localize(localeFormKeys.labels.date)}`.trim()}
          timeLabel={`${label} ${localize(localeFormKeys.labels.time)}`.trim()}
          value={fromDate}
          setValue={handleFromDateChange}
          minuteStep={minStep / 60000}
          validateDate={validateDate}
          validateTime={validateTime}
          allowClear={allowClear}
          errors={errors}
          disabled={disabled}
          width={width}
        />) : (<DatePicker
          label={selectRange
            ? `${label} ${localize(localeFormKeys.labels.fromDate)}`.trim()
            : `${label} ${localize(localeFormKeys.labels.date)}`.trim()}
          value={fromDate}
          setValue={handleFromDateChange}
          validate={validateDate}
          allowClear={allowClear}
          round
          errors={errors}
          disabled={disabled}
          width={width}
        />)
      }
      { selectRange && (
        <DatePicker
          label={`${label} ${localize(localeFormKeys.labels.toDate)}`.trim()}
          value={toDate}
          setValue={handleToDateChange}
          validate={validateToDate}
          allowClear={allowClear}
          round
          errors={toErrors}
          disabled={disabled}
          width={width}
        />
      )}
    </>
  );
}

export default TimeSlotPicker;
