import React, { useCallback } from 'react';

import { Supplier } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.suppliers;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface SupplierTableProps {
  suppliers : Supplier[];
  tableKeys? : TableKeys[];
  generateActions? : (supplier : Supplier) => CellElement;
}

function SupplierTable({
  suppliers,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <></>,
} : SupplierTableProps) {

  const generateHead = useCallback(() => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `supplier-table-head=${key}`
        switch(key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.id) }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.name) }
            </TableCell>;
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [tableKeys]);

  const generateRow = useCallback((supplier : Supplier) => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `supplier-table-row-${supplier.id}-${key}`
        switch(key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { `# ${supplier.id}` }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { supplier.name }
            </TableCell>;
          case TABLE_KEYS.actions:
            return (
              <React.Fragment key={mapKey}>
                { generateActions(supplier) }
              </React.Fragment>
            );
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [generateActions, tableKeys]);

  return (
    <Table
      head={generateHead()}
      rows={suppliers.map((supplier) => (
        <React.Fragment key={`supplier-table-row-${supplier.id}`}>
          { generateRow(supplier) }
        </React.Fragment>
      ))}
    />
  );
}

export default SupplierTable;
