import React, { useEffect, useState } from 'react';

import { Customer, ContactInfo, Partial } from '#types'

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.customer;

export type CustomerInfo = Partial<Customer & ContactInfo>;

function CustomerForm() {
  const { state, dispatch, errors } = useForm<CustomerInfo>();
  const [cleared, setCleared] = useState(true);

  function setName(name : string) { dispatch({ name }) };
  function setNickname(nickname : string) { dispatch({ nickname }) };
  function setEmail(email : string) { dispatch({ email }) };
  function setPhone(phone : string) { dispatch({ phone }) };

  useEffect(() => {
    // prevent bouncing
    setTimeout(() => setCleared(
      !state?.name &&
      !state?.nickname &&
      !state?.email &&
      !state?.phone
    ), 0);
  }, [state]);

  return (
    <Form>
      <TextInput
        id={'customer-name'}
        label={localize(localKeys.labels.fullName)}
        value={state?.name ?? ''}
        onChange={setName}
        errors={!cleared ? errors?.name : undefined}
        width={settings.dimensions.twoThirds}
      />
      <TextInput
        id={'customer-nickname'}
        label={localize(localKeys.labels.displayName)}
        value={state?.nickname ?? ''}
        onChange={setNickname}
        errors={!cleared ? errors?.nickname : undefined}
        width={settings.dimensions.third}
      />

      <TextInput
        id={'customer-email'}
        label={localize(localKeys.labels.email)}
        value={state?.email ?? ''}
        onChange={setEmail}
        errors={!cleared ? errors?.email : undefined}
        width={settings.dimensions.third}
      />
      <TextInput
        id={'customer-phone'}
        label={localize(localKeys.labels.phone)}
        value={state?.phone ?? ''}
        onChange={setPhone}
        errors={!cleared ? errors?.phone : undefined}
        width={settings.dimensions.twoThirds}
      />
    </Form>
  );
}

export default CustomerForm;
