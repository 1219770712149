import React, { useCallback, useEffect, useState } from 'react';

import { Integration, Engine } from '#types';

import useIntegrations from '#hooks/useIntegrations';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.integrations;

const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  engine : 'engine',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultKeys = Object.values(TABLE_KEYS);

interface IntegrationTableProps {
  integrations : Integration[];
  tableKeys? : TableKeys[];
  generateActions? : (integration : Integration) => CellElement;
  pageCount? : number;
}

function IntegrationTable({
  integrations,
  tableKeys = defaultKeys,
  generateActions,
  pageCount = 20,
} : IntegrationTableProps) {
  const { retrieveEngines } = useIntegrations();
  const [engines, setEngines] = useState<{ [id : number] : Engine | null }>([]);
  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const retrieve = useCallback(async () => {
    const newEngines = await retrieveEngines();
    if (newEngines) setEngines(newEngines);
  }, [setEngines, retrieveEngines]);

  const generateHead = useCallback(() => {
    setHead(
      <>
        { tableKeys.map((key : TableKeys) => {
          switch (key) {
            case TABLE_KEYS.id:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.id) }
              </TableCell>);
            case TABLE_KEYS.name:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.name) }
              </TableCell>);
            case TABLE_KEYS.engine:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.engineName) }
              </TableCell>);
            default: return (<TableCell key={key} />);
          }
        }) }
      </>
    );
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    setRows(integrations.map((integration, index) => (
      <React.Fragment key={integration.id ?? 'new'}>
        { tableKeys.map((key) => {
          switch (key) {
            case TABLE_KEYS.id:
              return (<TableCell key={key}>
                { `# ${integration.id}` }
              </TableCell>);
            case TABLE_KEYS.name:
              return (<TableCell key={key}>
                { integration.name }
              </TableCell>);
            case TABLE_KEYS.engine:
              return (<TableCell key={key}>
                { integration.engineId in engines
                  ? engines[integration.engineId]?.name
                  : ''
                }
              </TableCell>);
            case TABLE_KEYS.actions:
              return (<React.Fragment key={key}>
                { generateActions
                  ? generateActions(integration)
                  : <TableCell /> }
              </React.Fragment>);
            default: return <TableCell key={key} />;
          }
        }) }
      </React.Fragment>
    )));
  }, [integrations, generateActions, tableKeys, engines]);

  useEffect(() => { retrieve(); }, [retrieve]);
  useEffect(() => { generateHead(); }, [generateHead]);
  useEffect(() => { generateRows(); }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={pageCount}
    />
  );
}

export default IntegrationTable;
