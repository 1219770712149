import { useTheme as useMaterialTheme } from '#materials';

function useTheme() {
  const theme = useMaterialTheme();

  return {
    theme,
  };
}

export default useTheme;
