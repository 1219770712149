import React, { useCallback } from 'react';

import { Route } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.routes;

interface RouteFormProps {
  route : Route;
}

function RouteForm({ route : fallback } : RouteFormProps) {

  const { state, dispatch, editing } = useForm<Route>();

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `schedule-${fallback?.id}`
    : 'schedule-new';

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localKeys.labels.name)}
        value={state ? state.name : fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.full}
      />
    </Form>
  );
}

export default RouteForm;
