import React, { useCallback, useEffect, useState } from 'react';

import { TaxIntegration, Integration } from '#types';

import useIntegrations from '#hooks/useIntegrations';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.taxIntegrations;

export const TABLE_KEYS = {
  'integration' : 'integrationId' as const,
  'externalId' : 'externalId' as const,
  'actions' : 'actions' as const,
};
type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
const defaultTableKeys = Object.values(TABLE_KEYS);

interface TaxIntegrationsTableProps {
  taxIntegrations : TaxIntegration[];
  tableKeys? : TableKey[];
  generateActions? : (tax : TaxIntegration) => CellElement;
}

function TaxIntegrationsTable({
  taxIntegrations,
  tableKeys = defaultTableKeys,
  generateActions,
} : TaxIntegrationsTableProps) {
  const { retrieveIntegrations } = useIntegrations();

  const [
    integrations,
    setIntegrations,
  ] = useState<{ [key : string] : Integration | null }>({});
  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const retrieve = useCallback(async () => {
    const newIntegrations = await retrieveIntegrations();
    if (newIntegrations) setIntegrations(newIntegrations);
  }, [retrieveIntegrations]);

  const generateHead = useCallback(() => {
    function generate(key : TableKey) {
      switch (key) {
        case TABLE_KEYS.integration:
          return (<TableCell key={key}>
            { localize(localeTableKeys.headings.integration) }
          </TableCell>);
        case TABLE_KEYS.externalId:
          return (<TableCell key={key}>
            { localize(localeTableKeys.headings.externalId) }
          </TableCell>);
        default:
          return <></>;
      }
    }

    return tableKeys.map((key) => generate(key));
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    function generate(key : TableKey, tax : TaxIntegration) {
      switch (key) {
        case TABLE_KEYS.integration:
          return (<TableCell key={key}>
            { integrations[tax.integrationId]?.name }
          </TableCell>);
        case TABLE_KEYS.externalId:
          return (<TableCell key={key}>{ tax.externalId }</TableCell>);
        case TABLE_KEYS.actions:
          return (
            <React.Fragment key={key}>
              { generateActions ? generateActions(tax) : <></> }
            </React.Fragment>
          );
        default:
          return <TableCell key={key} />;
      }
    }

    return taxIntegrations.map((tax) => (
      tableKeys.map((key) => (
        <React.Fragment key={`${tax.integrationId}-${tax.externalId}-${key}`}>
          { generate(key, tax) }
        </React.Fragment>
      ))
    ));
  }, [taxIntegrations, tableKeys, integrations, generateActions]);

  useEffect(() => { retrieve(); }, [retrieve]);
  useEffect(() => { setHead(generateHead()); }, [generateHead]);
  useEffect(() => { setRows(generateRows()); }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={20}
    />
  );
}

export default TaxIntegrationsTable;
