import React, { useCallback, useEffect, useState } from 'react';

import { Category } from '#types';

import useNotifications from '#hooks/useNotifications';
import useCategories from '#hooks/useCategories';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import CategoryTable from '#components/categories/CategoryTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.categories.categorySubCategories;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.categories;
const localeNotificationKeys = locale.keys.notifications.categories;

interface CategorySubCategoriesProps {
  category : Category;
}

function CategorySubCategories({
  category,
} : CategorySubCategoriesProps) {
  const { createNotification } = useNotifications();
  const {
    retrieveCategories,
    retrieveCategorySubcategories,
    addSubcategoryToCategory,
    removeSubcategoryFromCategory,
  } = useCategories();

  const [add, setAdd] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<Category | null>(null);
  const [
    categorySubCategories,
    setCategorySubCategories
  ] = useState<Category[]>([]);
  const [
    availableCategorySubCategories,
    setAvailableCategorySubCategories
  ] = useState<Category[]>([]);

  const handleAdd = useCallback(() => {
    setAdd(true);
  }, []);

  const handleRemove = useCallback(() => {
    setAdd(false);
  }, []);

  const handleAddCategory = useCallback(
    (subcategory : Category) => async () => {
      await addSubcategoryToCategory(category, subcategory);
    },
    [addSubcategoryToCategory, category],
  );

  const handleInitRemove = useCallback((category : Category) => () => {
    setRemoveTarget(category);
  }, [setRemoveTarget]);

  const handleMove = useCallback(
    async (subcategory : Category, beforeIndex : number) => {
      const before = categorySubCategories[beforeIndex];
      const success = !!(await addSubcategoryToCategory(
        category,
        subcategory,
        before,
      ));
      if (success) {
        createNotification({
          key : 'move-subcategory-success',
          message : localize(localeNotificationKeys.move.success),
          icon : <Icon icon={settings.svgIcons.category} />,
          colour : settings.colours.alert.primary,
        })
      } else {
        createNotification({
          key : 'move-subcategory-error',
          message : localize(localeNotificationKeys.move.error),
          icon : <Icon icon={settings.svgIcons.category} />,
          colour : settings.colours.alert.alert,
        })
      }
    },
    [
      category,
      categorySubCategories,
      addSubcategoryToCategory,
      createNotification,
    ]
  );

  const handleMoveUp = useCallback((subcategory : Category) => async () => {
    const index = categorySubCategories.findIndex(sc => sc.id === subcategory.id);
    if (index === -1 || index === 0) return;
    await handleMove(subcategory, index - 1);
  }, [categorySubCategories, handleMove]);

  const handleMoveDown = useCallback((subcategory : Category) => async () => {
    const index = categorySubCategories.findIndex(sc => sc.id === subcategory.id);
    if (index === -1 || index === categorySubCategories.length-1) return;
    await handleMove(subcategory, index + 2);
  }, [categorySubCategories, handleMove]);

  const handleMoveLast = useCallback((subcategory : Category) => async () => {
    await handleMove(subcategory, categorySubCategories.length);
  }, [categorySubCategories, handleMove]);

  const handleMoveFirst = useCallback((subcategory : Category) => async () => {
    await handleMove(subcategory, 0);
  }, [handleMove,]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemoveCategory = useCallback(async () => {
    if (!removeTarget) return;
    const success = await removeSubcategoryFromCategory(category, removeTarget);
    if (success) {
      createNotification({
        key : 'remove-subcategory-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : <Icon icon={settings.svgIcons.category} />,
        colour : settings.colours.alert.primary,
      })
    } else {
      createNotification({
        key : 'remove-subcategory-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : <Icon icon={settings.svgIcons.category} />,
        colour : settings.colours.alert.alert,
      })
    }
    setRemoveTarget(null);
  }, [
    category,
    removeTarget,
    removeSubcategoryFromCategory,
    createNotification,
  ]);

  const generateRemoveActions = useCallback(
    (subcategory : Category) => {
      const currentIndex = categorySubCategories.indexOf(subcategory);
      const firstIndex = currentIndex === 0;
      const lastIndex = currentIndex === categorySubCategories.length-1;
      return (add
        ?
        <TableActionCell>
          <Action
            label={localize(localeTableKeys.actions.view)}
            onClick={handleMoveFirst(subcategory)}
            disabled={removeTarget !== null || firstIndex}
            colour={settings.colours.button.primary}
          >
            <Icon icon={settings.svgIcons.keyboardDoubleArrowUp} />
          </Action>
          <Action
            label={localize(localeTableKeys.actions.view)}
            onClick={handleMoveUp(subcategory)}
            disabled={removeTarget !== null || firstIndex}
            colour={settings.colours.button.primary}
          >
            <Icon icon={settings.svgIcons.keyboardArrowUp} />
          </Action>
          <Action
            label={localize(localeTableKeys.actions.view)}
            onClick={handleMoveDown(subcategory)}
            disabled={removeTarget !== null || lastIndex}
            colour={settings.colours.button.primary}
          >
            <Icon icon={settings.svgIcons.keyboardArrowDown} />
          </Action>
          <Action
            label={localize(localeTableKeys.actions.view)}
            onClick={handleMoveLast(subcategory)}
            disabled={removeTarget !== null || lastIndex}
            colour={settings.colours.button.primary}
          >
            <Icon icon={settings.svgIcons.keyboardDoubleArrowDown} />
          </Action>
          <Action
            label={localize(localeTableKeys.actions.removeCategory)}
            onClick={handleInitRemove(subcategory)}
            disabled={removeTarget !== null}
            colour={settings.colours.button.alert}
          >
            <Icon icon={settings.svgIcons.remove} />
          </Action>
        </TableActionCell>
        :
        <TableActionCell>
          <Action
            label={localize(localeTableKeys.actions.view)}
            href={`/categories/${subcategory.id}`}
          >
            <Icon icon={settings.svgIcons.category} />
          </Action>
        </TableActionCell>
      )
    },
    [
      add,
      categorySubCategories,
      removeTarget,
      handleInitRemove,
      handleMoveUp,
      handleMoveDown,
      handleMoveFirst,
      handleMoveLast
    ]
  );

  const generateAddActions = useCallback((subcategory : Category) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.addCategory)}
          onClick={handleAddCategory(subcategory)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAddCategory]);

  useEffect(() => {
    if (!category.id) return;

    async function fetchCategories() {
      const retrievedCategories = await retrieveCategories();
      const retrievedCategorySubcategories = retrieveCategorySubcategories(category);

      if (!retrievedCategorySubcategories) return;
      if (!retrievedCategories) return;

      const categoryId = category.id;
      if (categoryId !== undefined) {
        setCategorySubCategories(retrievedCategorySubcategories);

        const availableCategories = listRecords(retrievedCategories).filter(
          subcategory => (
            subcategory.id &&
            !category.subcategoryIds?.includes(subcategory.id) &&
            subcategory.id !== categoryId
          )
        );
        setAvailableCategorySubCategories(availableCategories);
      }
    }
    fetchCategories();
  }, [category, retrieveCategories, retrieveCategorySubcategories]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
    >
      { !!removeTarget && (
        <Banner
          icon={<Icon icon={settings.svgIcons.remove} />}
          actions={(
            <>
              <Button
                onClick={handleRemoveCategory}
              >
                {localize(localeButtonKeys.remove)}
              </Button>
              <Button
                onClick={handleCancelRemove}
              >
                {localize(localeButtonKeys.cancel)}
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmRemove) +
            ` (${removeTarget.name})` }
        </Banner>
      ) }
      { categorySubCategories.length
        ? <CategoryTable
          categories={categorySubCategories}
          showSubCategories={false}
          generateActions={generateRemoveActions}
        />
        : <Text>{localize(localeContentKeys.notAssigned)}</Text>
      }
      { add
        ? <>
          <Segment title={localize(localeContentKeys.availableSubCategories)}>
            { availableCategorySubCategories.length
              ? (<CategoryTable
                categories={availableCategorySubCategories}
                showSubCategories={false}
                generateActions={generateAddActions}
              />)
              : <Text>{ localize(localeContentKeys.notFound) }</Text>
            }
            <Button
              onClick={handleRemove}
            >
              {localize(localeButtonKeys.cancel)}
            </Button>
          </Segment>
        </>
        : <Button
          onClick={handleAdd}
        >
          {localize(localeButtonKeys.add)}
        </Button>
      }
    </Section>
  );
}

export default CategorySubCategories;
