import React, { useCallback, useEffect, useState, useRef } from 'react';

import { PaymentToken } from '#types';

import useTheme from '#hooks/useTheme';
import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Center from '#materials/Center';

import locale, { localize } from "#utils/locale";

const localeErrorKeys = locale.keys.errors;

const SQR_SCRIPT_URL = 'https://sandbox.web.squarecdn.com/v1/square.js';
const CONTAINER_ID = 'card-verification';
const SCRIPT_ID = 'square-payments';

declare global {
  interface Window {
    Square: any;
  }
}

interface CardStyle {
  focusColour? : string,
}

interface InitArgs {
  appId : string,
  locationId : string,
  containerId : string,
  cardStyle? : CardStyle,
}

async function initSquareCard({
  appId,
  locationId,
  containerId,
  cardStyle,
} : InitArgs) {
  const payments = window.Square.payments(appId, locationId);
  const card = await payments.card({
    ...(cardStyle && { 'style' : {
      ...(cardStyle.focusColour && { '.input-container.is-focus' : {
        borderColor : cardStyle.focusColour
      } }),
    } }),
  });
  card.attach('#' + containerId);
  return card;
}

function disposeSquareCard(card : any) {
  card.destroy();
};

function loadSquare(
  scriptId : string,
  url : string,
  init : () => void,
) {
  const script = document.createElement('script');
  script.id = scriptId;
  script.src = url;
  script.crossOrigin = 'anonymous';
  script.onload = () => init();
  script.onerror = () => alert(
    `${localize(localeErrorKeys.scriptFailed)} (${scriptId})`
  );
  document.getElementsByTagName('head')[0].appendChild(script);
}

function checkSquare(scriptId : string) {
  return !!document.getElementById(scriptId);
}

function unloadSquare(scriptId : string) {
  const script = document.getElementById(scriptId);
  if (script) {
    script.remove();
  }
}

async function verify(cardInterface : any) {
  if (cardInterface) {
    const result = await cardInterface.tokenize();
    if (result.status === 'OK') {
      return {
        token : result.token,
        brand : result.details.card.brand,
        last4 : result.details.card.last4,
        expMonth : result.details.card.expMonth,
        expYear : result.details.card.expYear,
        postal : result.details.billing.postalCode,
      };
    }
  }
  return null;
}

interface SquareFormProps {
  setTokenize : (tokenize : () => Promise<PaymentToken | null>) => void;
}

function SquareForm({ setTokenize } : SquareFormProps) {
  const { theme } = useTheme();
  const { getIntegration } = useIntegrations();

  const initiated = useRef(false);
  const [colour] = useState<string>(theme.palette.primary.main);
  const [cardInterface, setCardInterface] = useState<any>(null);

  const tokenize = useCallback(async () => {
    return await verify(cardInterface);
  }, [cardInterface]);

  useEffect(() => {
    const integration = getIntegration({
      engineName : 'square',
      channelName : 'accounts',
    });
    const app_id = integration?.settings?.app_id;
    const location_id = integration?.settings?.location_id;
    if (!app_id || !location_id) return;

    if (!initiated.current && !checkSquare(SCRIPT_ID)) {
      initiated.current = true;
      loadSquare(
        SCRIPT_ID,
        SQR_SCRIPT_URL,
        async () => {
          const card = await initSquareCard({
            appId : app_id,
            locationId : location_id,
            containerId : CONTAINER_ID,
            cardStyle : {
              focusColour : theme.palette.primary.main,
            },
          });
          setCardInterface(card ?? null);
        },
      );
    }

    return () => {
      if (cardInterface) disposeSquareCard(cardInterface);
      if (checkSquare(SCRIPT_ID)) unloadSquare(SCRIPT_ID);
    }
  }, [colour, cardInterface, theme, getIntegration]);

  useEffect(() => {
    setTokenize(tokenize);
  }, [tokenize, setTokenize]);

  return (
    <Center
      height={settings.dimensions.small}
    >
      <Center id={CONTAINER_ID} padding={{ x : settings.spacings.small }} />
    </Center>
  );
}

export default SquareForm;
