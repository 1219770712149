import React from 'react';

import { Location } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.locations;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  serviceIds : 'serviceIds',
  addressId : 'addressId',
  actions : 'actions',
};
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface LocationsTableProps {
  locations : Location[];
  tableKeys? : TableKeys[];
  count : number;
  generateActions? : (location : Location) => CellElement;
}

function LocationsTable({
  locations,
  tableKeys = Object.values(TABLE_KEYS),
  count,
  generateActions = () => <TableCell/>,
} : LocationsTableProps) {

  function headGenerator(key : string) {
    switch (key) {
      case TABLE_KEYS.id:
        return <TableCell>{ localize(localKeys.headings.id) }</TableCell>;
      case TABLE_KEYS.name:
        return <TableCell>{ localize(localKeys.headings.name) }</TableCell>;
      case TABLE_KEYS.serviceIds:
        return <TableCell>
          { localize(localKeys.headings.serviceIds) }
          </TableCell>;
      case TABLE_KEYS.addressId:
        return <TableCell>
          { localize(localKeys.headings.addressId) }
        </TableCell>;
      case TABLE_KEYS.actions: return <TableCell/>;
      default: return <TableCell />;
    }
  }

  function rowGenerator(key : string, index : number) {
    const location = locations[index];
    switch (key) {
      case TABLE_KEYS.id: return <TableCell>
          { `# ${location.id}` }
        </TableCell>;
      case TABLE_KEYS.name: return <TableCell>
          { location.name }
        </TableCell>;
      case TABLE_KEYS.serviceIds: return <TableCell>
        { location.serviceIds && location.serviceIds.length > 0
          ? `# ${location.serviceIds.join(', ')}`
          : '' }
        </TableCell>
      case TABLE_KEYS.addressId: return <TableCell>
        { location.addressId ? `# ${location.addressId}`: '' }
        </TableCell>
      case TABLE_KEYS.actions: return generateActions(location);
      default: return <TableCell />;
    }
  }

  return (
    <Table
      keys={tableKeys}
      length={count}
      headGenerator={headGenerator}
      rowGenerator={rowGenerator}
    />
  );
}

export default LocationsTable;
