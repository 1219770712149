import React, { useCallback, useEffect, useState } from 'react';

import { ServiceChannel } from '#types';

import useServices from '#hooks/useServices';

import { Dimension, settings } from '#materials';
import Select from '#materials/Select';

import { listRecords } from '#utils/data';

interface ServiceChannelSelectProps {
  serviceChannel : ServiceChannel | null;
  onChange? : (channel : ServiceChannel | null) => void;
  width? : Dimension;
}

function doNothing() {};
function generateLabel(channel : ServiceChannel | null) {
  return channel?.name ?? '';
}
function generateKey(channel : ServiceChannel | null) {
  return channel?.id ? `${channel.id}` : `${channel?.id}-new`;
}
function isSameChannel(a : ServiceChannel | null, b : ServiceChannel | null) {
  return a?.id === b?.id;
}

function ServiceChannelSelect({
  serviceChannel,
  onChange,
  width = settings.dimensions.full,
} : ServiceChannelSelectProps) {
  const { retrieveServiceChannels } = useServices();
  const [channels, setChannels] = useState<ServiceChannel[]>([]);

  const retrieve = useCallback(async () => {
    const retrievedChannels = await retrieveServiceChannels();
    if (retrievedChannels) setChannels(listRecords(retrievedChannels));
  }, [retrieveServiceChannels, setChannels]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <Select
      label="Service Channel"
      options={channels}
      selected={serviceChannel}
      onChange={onChange ?? doNothing}
      labelGenerator={generateLabel}
      keyGenerator={generateKey}
      isEqual={isSameChannel}
      width={width}
    />
  );
}

export default ServiceChannelSelect;
