import React, { useCallback, useEffect, useState } from 'react';

import { Integration, DraftOrder } from '#types';

import useIntegrations from '#hooks/useIntegrations';

import Table from '#materials/Table';
import { TableCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderIntegrations;
const localeTableKeys = locale.keys.tables.orderIntegrations;

const TABLE_KEYS = {
  integration : 'integration',
  externalId : 'externalId',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
const defaultTableKeys = Object.values(TABLE_KEYS);

interface OrderIntegrationsProps {
  order : DraftOrder,
  tableKeys? : TableKeys[],
}

function OrderIntegrations({
  order,
  tableKeys = defaultTableKeys,
} : OrderIntegrationsProps) {
  const { retrieveIntegrations } = useIntegrations();

  const initIntegrations = order.order?.integrations
    ? Object.values(order.order.integrations)
    : [];
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [orderIntegrations, setOrderIntegrations] = useState(initIntegrations);

  const retrieve = useCallback(async () => {
    const integrations = await retrieveIntegrations();
    setIntegrations(listRecords(integrations));
  }, [retrieveIntegrations]);

  const generateHeader = useCallback((tableKeys : TableKeys[]) => {
    return (
      <>
        { tableKeys.map((key) => {
          switch (key) {
            case TABLE_KEYS.integration:
              return (
                <TableCell key={key}>
                  { localize(localeTableKeys.headings.integration) }
                </TableCell>
              );
            case TABLE_KEYS.externalId:
              return (
                <TableCell key={key}>
                  { localize(localeTableKeys.headings.externalId) }
                </TableCell>
              );
            default:
              return null;
          }
        }) }
      </>
    )
  }, []);

  const generateRows = useCallback((
    tableKeys : TableKeys[],
  ) => {
    return orderIntegrations.map((i) => {
      const integration = integrations.find((j) => j.id === i.integrationId);
      return (
        <React.Fragment key={i.id}>
          { tableKeys.map((key) => {
            switch (key) {
              case TABLE_KEYS.integration:
                return (
                  <TableCell key={key}>
                    { integration?.name
                      ?? localize(localeTableKeys.defaults.integration) }
                  </TableCell>
                );
              case TABLE_KEYS.externalId:
                return (
                  <TableCell key={key}>
                    { i.externalId }
                  </TableCell>
                );
              default:
                return null;
            }
          }) }
        </React.Fragment>
      );
    });
  }, [integrations, orderIntegrations]);

  const [head, setHead] = useState<React.ReactNode>(generateHeader(tableKeys));
  const [rows, setRows] = useState<React.ReactNode[]>(generateRows(tableKeys));

  useEffect(() => {
    setOrderIntegrations(order.order?.integrations
      ? Object.values(order.order.integrations)
      : []);
  }, [order]);
  useEffect(() => { retrieve(); }, [retrieve]);
  useEffect(() => {
    setHead(generateHeader(tableKeys));
  }, [generateHeader, tableKeys]);
  useEffect(() => {
    setRows(generateRows(tableKeys));
  }, [generateRows, tableKeys]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
    >
      { orderIntegrations.length
        ? (
          <Table
            head={head}
            rows={rows}
          />
        ) : (
          <Section
            title={localize(localeContentKeys.notFound)}
          />
        )
      }
    </Section>
  );
}

export default OrderIntegrations;
