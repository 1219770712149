import React from 'react';

import { convert, settings } from '#materials';
import Divider from '#materials/Divider';
import Text from '#materials/Text';

interface SegmentProps {
  title? : string;
  children? : React.ReactNode;
}

function Segment({ title, children } : SegmentProps) {
  const margin = {
    x : settings.spacings.dense,
    y : settings.spacings.medium,
  }

  return (
    <>
      <Divider
        textAlign={convert.textAlignment(settings.alignments.left)}
        sx={{
          ...(margin && {
            mt : convert.margin(margin).top,
            mr : convert.margin(margin).right,
            mb : convert.margin(margin).bottom,
            ml : convert.margin(margin).left,
          }),
        }}
      >
        { !!title &&
          <Text
            variant={settings.variants.typography.body2}
            spacing={settings.spacings.dense}
          >
            { title }
          </Text>
        }
      </Divider>
      { children }
    </>
  );
}

export default Segment;
