import React, { useCallback, useState } from 'react';

import { ServiceChannel } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';
import Switch from '#materials/Switch';

import locale, { localize } from '#utils/locale';

const localeFormHeaderKeys = locale.keys.forms.serviceChannels.labels;

interface ServiceChannelFormProps {
  serviceChannel : ServiceChannel;
  subscriptionEnabled? : boolean;
  onChange? : (serviceChannel : ServiceChannel) => void;
  onSubscriptionEnabledChange? : (enabled : boolean) => void;
}

function ServiceChannelForm({
  serviceChannel : fallback,
  subscriptionEnabled,
  onChange,
  onSubscriptionEnabledChange,
} : ServiceChannelFormProps) {
  const { state, dispatch, editing } = useForm<ServiceChannel>({ onChange });

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);
  const setActive = useCallback((active : boolean) => {
    dispatch({ active });
  }, [dispatch]);
  const setVisible = useCallback((visible : boolean) => {
    dispatch({ visible });
  }, [dispatch]);
  const setRequireCheckout = useCallback((requireCheckout : boolean) => {
    dispatch({ requireCheckout });
  }, [dispatch]);
  const setDescription = useCallback((description : string) => {
    dispatch({ description });
  }, [dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `serviceChannel-${fallback.id}`
    : 'serviceChannel';

  const showSubscription = subscriptionEnabled !== undefined;

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localeFormHeaderKeys.name)}
        value={state?.name ?? fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.half}
      />
      <Switch
        checked={state?.active ?? fallback.active}
        label={localize(localeFormHeaderKeys.active)}
        onChange={setActive}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      <Switch
        checked={state?.visible ?? fallback.visible}
        label={localize(localeFormHeaderKeys.visible)}
        onChange={setVisible}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      <TextInput
        id={`${idPrefix}-description`}
        label={localize(localeFormHeaderKeys.description)}
        value={state?.description ?? fallback.description}
        onChange={setDescription}
        disabled={!editing}
        width={settings.dimensions.full}
        minRows={2}
      />
      <Switch
        checked={state?.requireCheckout ?? fallback.requireCheckout}
        label={localize(localeFormHeaderKeys.requireCheckout)}
        onChange={setRequireCheckout}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      {showSubscription && (
        <Switch
          checked={subscriptionEnabled}
          label={localize(localeFormHeaderKeys.subscriptionEnabled)}
          onChange={onSubscriptionEnabledChange ?? (() => {})}
          disabled={!editing}
          width={settings.dimensions.quarter}
        />
      )}
    </Form>
  );
}

export default ServiceChannelForm;
