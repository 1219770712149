import React, { useCallback, useState } from 'react';

import { styled } from '@mui/material/styles';

import { settings } from '#materials/types';
import { IconElement } from '#materials/Icon';
import Button from '#materials/Button';

const HiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface UploadButtonProps {
  url? : string;
  getUrl? : () => string | Promise<string>;
  icon? : IconElement;
  filename? : string;
  onSubmit? : () => void | Promise<void>;
  disabled? : boolean;
  children? : React.ReactNode;
}
interface UploadButtonUrlProps extends UploadButtonProps { url : string; }
interface UploadButtonGetUrlProps extends UploadButtonProps {
  getUrl : () => string | Promise<string>;
}

function UploadButton(props : UploadButtonUrlProps) : React.ReactElement;
function UploadButton(props : UploadButtonGetUrlProps) : React.ReactElement;
function UploadButton({
  url,
  getUrl,
  icon,
  filename = 'file',
  onSubmit,
  disabled,
  children,
} : UploadButtonProps) {
  const [uploading, setUploading] = useState(false);

  const submit = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || event.target.files.length <= 0) return;
      setUploading(true);

      const uploadUrl = url || (getUrl && await getUrl());
      if (!uploadUrl) {
        setUploading(false);
        return;
      }

      const formData = new FormData();
      formData.append(filename, event.target.files[0]);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', uploadUrl, true);
      xhr.onload = () => {};
      xhr.send(formData);

      if (onSubmit) await onSubmit();
      setUploading(false);
    },
    [url, getUrl,filename, onSubmit],
  );

  return (
    <form
     action={url}
     method="post"
     encType="multipart/form-data"
    >
      <Button
        component={settings.components.label}
        icon={icon}
        disabled={disabled || uploading}
      >
        { children }
        <HiddenInput type="file" name={filename} onChange={submit} />
      </Button>
    </form>
  );
}

export default UploadButton;
