import React, { useCallback, useEffect, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import Box from '@mui/material/Box/Box';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';

import { Dimension, convert, settings } from '#materials';
import FlexItem from '#materials/FlexItem';

interface DatePickerProps {
  label : string;
  value : Date | null;
  setValue : (value : Date | null) => void;
  validate? : (date : Date) => boolean;
  round? : boolean;
  allowClear? : boolean;
  onClear? : () => void;
  errors? : string[];
  hideErrors? : boolean;
  disabled? : boolean;
  width? : Dimension;
}

function DatePicker({
  label,
  value,
  setValue,
  round = false,
  allowClear = true,
  onClear,
  disabled = false,
  validate,
  errors,
  hideErrors = false,
  width = settings.dimensions.full,
} : DatePickerProps) {
  const [dateTime, setDateTime] = useState<Dayjs | null>(
    value ? dayjs(value) : null
  );

  const handleDateChange = useCallback((newDay : Dayjs | null) => {
    if (!newDay) {
      setValue(null);
    } else {
      const newDate = value ? new Date(value.getTime()) : new Date();
      if (round) {
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
      }
      newDate.setFullYear(newDay.year());
      newDate.setMonth(newDay.month());
      newDate.setDate(newDay.date());
      setValue(newDate);
    }
  }, [value, round, setValue]);

  const disableDate = useCallback((date : Dayjs) => {
    if (!validate) return false;
    return !validate(new Date(date.toDate()));
  }, [validate]);

  const clear = useCallback(() => {
    setValue(null);
  }, [setValue]);

  useEffect(() => {
    const newDateTime = value ? dayjs(value) : null;
    setDateTime(newDateTime);
  }, [
    value,
    setDateTime,
  ]);

  return (
    <FlexItem
      width={settings.dimensions.full}
      maxWidth={width}
      shrink
    >
      <Box
        sx={{
          mx : [1],
          my : [1],
        }}
      >
        <MuiDatePicker
          label={label}
          value={dateTime}
          onChange={handleDateChange}
          shouldDisableDate={disableDate}
          disabled={disabled}
          slotProps={{
            ...(allowClear && { field : {
              clearable : true,
              onClear : onClear ?? clear
            }}),
            textField: {
              ...(hideErrors
                ? { error : false }
                : (errors?.length && {
                  error : !hideErrors && !!errors?.length,
                  helperText : (!hideErrors && errors?.length)
                    ? errors?.join('. ') + '.'
                    : null,
                })),
            },
          }}
          sx={{
            minWidth : convert.width(settings.dimensions.full),
            "& .MuiInputBase-input.Mui-disabled" : {
              WebkitTextFillColor : "#444",
            },
          }}
        />
      </Box>
    </FlexItem>
  )
}

export default DatePicker;
