import React from 'react';

import SettingsIndex from '#components/settings/SettingsIndex';

function Settings() {
  return (
    <SettingsIndex />
  );
}

export default Settings;
