import { useCallback, useState } from 'react';

import { Adjustment } from '#types';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import useAdjustments from '#hooks/useAdjustments';
import { useFormContext } from '#hooks/useForm';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import AdjustmentForm from './AdjustmentForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.adjustments.createAdjustment;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.adjustments;

interface CreateAdjustmentProps {
  onCancel? : () => void;
  onSave? : (adjustment: Adjustment) => void;
}

const CreateAdjustmentControl = ({
  onCancel,
  onSave,
} : CreateAdjustmentProps) => {
  const { createNotification } = useNotifications();
  const { createAdjustment } = useAdjustments()
  const {
    state : adjustment,
    reset,
  } = useFormContext<Adjustment>();

  const handleSave = useCallback(async () => {
    if (!adjustment) return;
    const newAdjustment = await createAdjustment(adjustment);
    if (newAdjustment) {
      createNotification({
        key : 'adjustment-create-success',
        message : localize(localeNotificationKeys.create.success),
        icon : <Icon icon={settings.svgIcons.loyalty} />,
        colour : settings.colours.alert.primary,
      });
      onSave?.(newAdjustment);
    } else {
      createNotification({
        key : 'adjustment-create-failure',
        message : localize(localeNotificationKeys.create.error),
        icon : <Icon icon={settings.svgIcons.loyalty} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [adjustment, createAdjustment, onSave, createNotification]);

  const handleCancel = useCallback(() => {
    if(onCancel) onCancel();
  }, [onCancel]);

  return (
    <Section title={localize(localeContentKeys.title)}>
      { adjustment && (
        <AdjustmentForm adjustment={adjustment} />
      ) }
      <Button onClick={handleSave}>
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
    </Section>
  )
}

function CreateAdjustment({ onCancel, onSave } : CreateAdjustmentProps) {
  const [init] = useState({
    name : '',
    scope : 'lineItem',
    factor : 0,
    currency : {
      amount : 0,
      currencyCode : 'CAD',
      increment : 0.01,
      calculatedValue : 0,
    },
  });

  return (
    <FormProvider init={init}>
      <CreateAdjustmentControl onCancel={onCancel} onSave={onSave}/>
    </FormProvider>
  );
}

export default CreateAdjustment;
