import React, { useEffect, useState } from 'react';

import { Integration } from '#types';

import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import IntegrationForm from '#components/integrations/IntegrationForm';

import locale, { localize } from '#utils/locale';

const localeIntegrationKeys = locale.keys.content.integrations.integrationIndex;
const localeButtonKeys = locale.keys.buttons;

interface CreateIntegrationProps {
  onSave? : (integration : Integration) => void;
  onCancel? : () => void;
}

function CreateIntegration({ onCancel, onSave } : CreateIntegrationProps) {
  const { createIntegration,  } = useIntegrations();

  const [integration, setIntegration] = useState<Integration>({
    name : '',
    engineId : 0,
    channelIds : [],
    settings : {},
    configs : [],
  });

  const [formKey, setFormKey] = useState(true);
  const [saved, setSaved] = useState(true);
  const [cleared, setCleared] = useState(true);

  useEffect(() => {
    setSaved(false);
    setCleared(false);
  }, [integration] );

  function resetIntegration() {
    setIntegration({
      name : '',
      engineId : 0,
      channelIds : [],
      settings : {},
      configs : [],
    });
    setFormKey(!formKey);
  }

  function handleSave() {
    async function save() {
      if (integration) {
        const newIntegration = await createIntegration(integration);
        if (!newIntegration) return;

        setSaved(true);
        if (onSave) onSave(newIntegration);
      }
    }
    save();
  }

  function handleCancel() {
    if (onCancel) onCancel();
  }

  function handleClear() {
    resetIntegration();
    setCleared(true);
  }

  return (
    <Section title={localize(localeIntegrationKeys.form.title)}>
      <IntegrationForm
        id='create-integration'
        integration={integration}
        onChange={setIntegration}
        formKey={formKey ? '' : '_'}
      />
      <Button
        onClick={handleSave}
        disabled={saved}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
      <Button
        onClick={handleClear}
        disabled={cleared}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
    </Section>
  );
}

export default CreateIntegration;
