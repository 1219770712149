import React from 'react';
import MuiLink from '@mui/material/Link';

import { Colour } from './types';

interface LinkProps {
  href? : string;
  colour? : Colour;
  newTab? : boolean;
  children? : React.ReactNode;
}

function Link({ href, colour, newTab, children } : LinkProps) {
  return (
    <MuiLink
      color={colour ? colour : 'inherit'}
      href={href}
      target={newTab ? '_blank' : undefined}
    >
      { children }
    </MuiLink>
  );
}

export default Link;
