import React from 'react';

import { useParams } from '#hooks/useNavigation';

import AdjustmentsIndex from '#components/adjustments/AdjustmentIndex';
import AdjustmentInfo from '#components/adjustments/AdjustmentInfo';

function Adjustments() {
  const urlParams = useParams<{ adjustmentId? : string }>();

  const parsedId = parseInt(urlParams.adjustmentId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (!id
    ? <AdjustmentsIndex />
    : <AdjustmentInfo adjustmentId={id} />
  );
}

export default Adjustments;