import React, { useCallback, useEffect, useState } from 'react';

import { Customer, CustomerIntegration, Integration } from '#types';

import useIntegrations from '#hooks/useIntegrations';
import useCustomers from '#hooks/useCustomers';

import { settings } from '#materials';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.customerIntegrations;

export const TABLE_KEYS = {
  id : 'id' as const,
  integration : 'integrationId' as const,
  externalId : 'externalId' as const,
  name : 'name' as const,
  email : 'email' as const,
  phone : 'phone' as const,
  customer : 'customer' as const,
  actions : 'actions' as const,
};
type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultKeys = Object.values(TABLE_KEYS);

interface CustomerIntegrationTableProps {
  customerIntegrations : CustomerIntegration[];
  tableKeys? : TableKeys[];
  generateActions? : (customer : CustomerIntegration) => CellElement;
  pageCount? : number;
}

function CustomerIntegrationTable({
  customerIntegrations,
  tableKeys = defaultKeys,
  generateActions,
  pageCount = 20,
} : CustomerIntegrationTableProps) {
  const { retrieveIntegrations } = useIntegrations();
  const { retrieveCustomers } = useCustomers();

  const [
    integrations,
    setIntegrations,
  ] = useState<{ [key : string] : Integration | null }>({});
  const [
    customers,
    setCustomers,
  ] = useState<{ [key : string] : Customer | null }>({});
  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const retrieve = useCallback(async () => {
    const [newIntegrations, newCustomers] = await Promise.all([
      retrieveIntegrations(),
      retrieveCustomers(),
    ]);
    if (newCustomers) setCustomers(newCustomers);
    if (newIntegrations) setIntegrations(newIntegrations);
  }, [retrieveCustomers, retrieveIntegrations, setCustomers, setIntegrations]);

  const generateHead = useCallback(() => {
    setHead(
      <>
        { tableKeys.map((key) => {
          switch (key) {
            case TABLE_KEYS.id:
              return (<TableCell key={key}>
                { localize(localKeys.headings.id) }
              </TableCell>);
            case TABLE_KEYS.integration:
              return (<TableCell key={key}>
                { localize(localKeys.headings.integration) }
              </TableCell>);
            case TABLE_KEYS.externalId:
              return (<TableCell key={key}>
                { localize(localKeys.headings.externalId) }
              </TableCell>);
            case TABLE_KEYS.name:
              return (<TableCell key={key}>
                { localize(localKeys.headings.name) }
              </TableCell>);
            case TABLE_KEYS.email:
              return (<TableCell key={key}>
                { localize(localKeys.headings.email) }
              </TableCell>);
            case TABLE_KEYS.phone:
              return (<TableCell key={key}>
                { localize(localKeys.headings.phone) }
              </TableCell>);
            case TABLE_KEYS.customer:
              return (<TableCell key={key}>
                { localize(localKeys.headings.customer) }
              </TableCell>);
            default: return <TableCell key={key} />;
          }
        }) }
      </>
    );
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    setRows(customerIntegrations.map((customerIntegration, i) => {
      return (
        <React.Fragment key={customerIntegration.id ?? `row-${i}`}>
          { tableKeys.map((key) => {
            switch (key) {
              case TABLE_KEYS.id:
                return (<TableCell key={key}>
                  { `# ${customerIntegration.id}` }
                </TableCell>);
              case TABLE_KEYS.externalId:
                return (<TableCell key={key} font={settings.fonts.mono}>
                  { customerIntegration.externalId }
                </TableCell>);
              case TABLE_KEYS.integration:
                const integration = customerIntegration.integrationId
                  ? integrations[customerIntegration.integrationId]
                  : null;
                return (<TableCell key={key}>
                  { integration ? integration.name : '' }
                </TableCell>);
              case TABLE_KEYS.name:
                return (<TableCell key={key}>
                  { customerIntegration.name }
                </TableCell>);
              case TABLE_KEYS.email:
                return (<TableCell key={key}>
                  { customerIntegration.email }
                </TableCell>);
              case TABLE_KEYS.phone:
                return (<TableCell key={key}>
                  { customerIntegration.phone }
                </TableCell>);
              case TABLE_KEYS.customer:
                const customer = customerIntegration.customerId
                  ? customers[customerIntegration.customerId]
                  : undefined;
                return (<TableCell key={key}>
                  { customer ? customer.defaultName : '' }
                </TableCell>);
              case TABLE_KEYS.actions:
                return (<React.Fragment key={key}>
                  { generateActions
                    ? generateActions(customerIntegration)
                    : null
                  }
                </React.Fragment>);
              default: return <TableCell />;
            }
          }) }
        </React.Fragment>
      )
    }));
  }, [
    tableKeys,
    customerIntegrations,
    integrations,
    customers,
    generateActions,
  ]);

  useEffect(() => { retrieve(); }, [retrieve]);
  useEffect(() => { generateHead(); }, [generateHead]);
  useEffect(() => { generateRows(); }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={pageCount}
    />
  );
}

export default CustomerIntegrationTable;
