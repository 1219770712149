import React, { useEffect } from 'react';

import { useParams } from '#hooks/useNavigation';
import useProducts from '#hooks/useProducts';
import useOptions from '#hooks/useOptions';

import ProductIndex from '#components/products/ProductIndex';
import ProductInfo from '#components/products/ProductInfo';

function Products() {
  const urlParams = useParams<{ productId? : string; }>();

  const { load : loadProducts } = useProducts();
  const { load : loadOptions } = useOptions();

  useEffect(() => {
    loadProducts();
    loadOptions();
  }, [loadProducts, loadOptions]);

  const parsedId = parseInt(urlParams.productId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !id
        ? <ProductIndex />
        : <ProductInfo productId={id} />
      }
    </>
  );
}

export default Products;
