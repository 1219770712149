import React, { useCallback } from 'react';

import { Area } from '#types';

import { useFormContext } from '#hooks/useForm';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Table from '#materials/Table';
import {
  CellElement,
  TableCell,
  TableActionCell,
  Action,
} from '#materials/TableCell';

import VertexRow, { TABLE_KEYS } from '#components/areas/VertexRow';

import { generateRange } from '#utils/array';
import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.areaVertices;

interface VertexTableProps {
  area : Area;
  generateActions? : (index : number) => CellElement;
}

function VertexTable({
  area,
  generateActions = () => <TableCell />,
} : VertexTableProps) {
  const { state, dispatch, editing } = useFormContext<Area>();

  function headGenerator(key : string) {
    switch (key) {
      case TABLE_KEYS.index:
        return (
          <TableCell>
            { localize(localeTableKeys.headings.index) }
          </TableCell>
        );
      case TABLE_KEYS.latitude:
        return (
          <TableCell>
            { localize(localeTableKeys.headings.latitude) }
          </TableCell>
        );
      case TABLE_KEYS.longitude:
        return(
          <TableCell>
            { localize(localeTableKeys.headings.longitude) }
          </TableCell>
        );
      default:
        return (<TableCell />);
    }
  }

  const generateRows = useCallback((index : number) => {
    function addRow() {
      if (!state) return;
      dispatch({ vertices : [...state.vertices, [0, 0]] });
    }

    function generateLastRow(key : string) {
      switch (key) {
        case TABLE_KEYS.actions:
          return editing
            ? (<TableActionCell>
              <Action
                label={localize(localeTableKeys.actions.add)}
                onClick={addRow}
              >
                <Icon icon={ settings.svgIcons.add } />
              </Action>
            </TableActionCell>)
            : (<TableCell />);
        default:
          return (<TableCell />);
      }
    }

    return index < (state || area).vertices.length
      ? <VertexRow
        area={state || area}
        index={index}
        tableKeys={Object.values(TABLE_KEYS)}
        generateActions={generateActions}
      />
      : <>
        { Object.values(TABLE_KEYS).map((key) =>
          <React.Fragment key={`area-${area.id}-vertex-table-row-last-${key}`}>
            { generateLastRow(key) }
          </React.Fragment>
        ) }
      </>
  }, [area, generateActions, state, dispatch, editing]);

  return (
    <Table
      head={Object.values(TABLE_KEYS).map((key) =>
        <React.Fragment key={`area-${area.id}-vertex-table-head-${key}`}>
          { headGenerator(key) }
        </React.Fragment>
      )}
      rows={
        generateRange(area.vertices.length + (editing ? 1 : 0)).map(
          (i) => generateRows(i)
        )
      }
    />
  );
}

export default VertexTable;
