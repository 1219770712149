import React, { useCallback, useEffect, useState } from 'react';

import { RevenueCentre, Service } from '#types';

import useNotifications from '#hooks/useNotifications';
import usePayments from '#hooks/usePayments';
import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableCell, TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ServicesTable from '#components/services/ServicesTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys =
  locale.keys.content.revenueCentres.revenueCentreServices;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.services;
const localeNotificationKeys = locale.keys.notifications.services;

interface RevenueCentreServicesProps {
  revenueCentre : RevenueCentre;
}

function RevenueCentreServices({ revenueCentre } : RevenueCentreServicesProps) {
  const { createNotification } = useNotifications();
  const {
    addServiceToRevenueCentre,
    removeServiceFromRevenueCentre,
  } = usePayments();

  const { retrieveServices } = useServices();

  const [
    revenueCentreServices,
    setRevenueCentreServices
  ] = useState<Service[]>([]);
  const [
    availableRevenueCentreServices,
    setAvailableRevenueCentreServices
  ] = useState<Service[]>([]);
  const [edit, setEdit] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<Service | null>(null);

  const handleEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const handleCancel = useCallback(() => {
    setEdit(false);
  }, []);

  const handleAdd = useCallback((service : Service) => async () => {
    await addServiceToRevenueCentre(revenueCentre, service);
  }, [addServiceToRevenueCentre, revenueCentre]);

  const handleInitRemove = useCallback((service : Service) => () => {
    setRemoveTarget(service);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleConfirmRemove = useCallback(async () => {
    if (!removeTarget) return;
    const success = await removeServiceFromRevenueCentre(
      revenueCentre,
      removeTarget,
    );
    if (success) {
      createNotification({
        key : 'remove-service-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.pointOfSale} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-service-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.pointOfSale} />),
        colour : settings.colours.alert.alert,
      });
    }
    setRemoveTarget(null);
  }, [
    removeTarget,
    removeServiceFromRevenueCentre,
    revenueCentre,
    createNotification,
  ]);

  const generateRemoveActions = useCallback((service : Service) => {
    return (edit
      ? <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.removeService)}
          onClick={handleInitRemove(service)}
          disabled={!!removeTarget}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      </TableActionCell>
      : <TableCell/>
    );
  }, [edit, removeTarget, handleInitRemove]);

  const generateAddActions = useCallback((service : Service) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.addService)}
          onClick={handleAdd(service)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAdd]);

  useEffect(() => {
    if (!revenueCentre.id) return;

    async function fetchServices() {
      const retrievedServices = await retrieveServices();
      if (!retrievedServices) return;

      setRevenueCentreServices(listRecords(retrievedServices).filter(
        service => service.id && revenueCentre.serviceIds &&
          revenueCentre.serviceIds.includes(service.id),
      ));
      setAvailableRevenueCentreServices(listRecords(retrievedServices).filter(
        service => service.id && revenueCentre.serviceIds &&
          !(revenueCentre.serviceIds.includes(service.id)),
      ));
    }

    fetchServices();
  }, [revenueCentre, retrieveServices]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
    >
      { !!removeTarget && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button
                onClick={handleConfirmRemove}
              >
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button
                onClick={handleCancelRemove}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmRemove) +
            ` (${removeTarget.name})` }
        </Banner>
      ) }
      { revenueCentreServices.length
        ? <ServicesTable
          services={revenueCentreServices}
          count={revenueCentreServices.length}
          generateActions={generateRemoveActions}
        />
        : <Text>{ localize(localeContentKeys.notAssigned) }</Text>
      }
      { edit
        ? <>
          <Segment title={localize(localeContentKeys.availableServices)}>
            { !!availableRevenueCentreServices.length
              ? (<ServicesTable
                services={availableRevenueCentreServices}
                count={availableRevenueCentreServices.length}
                generateActions={generateAddActions}
              />)
              : (<Text>{ localize(localeContentKeys.notFound) }</Text>)
            }
            <Button
              onClick={handleCancel}
            >
              { localize(localeButtonKeys.cancel) }
            </Button>
          </Segment>
        </>
        : <Button
          onClick={handleEdit}
        >
          { localize(localeButtonKeys.add) }
        </Button>
      }
    </Section>
  );
}

export default RevenueCentreServices;
