import React, { useCallback, useEffect, useState } from 'react';

import {
  Product,
  LineItem,
  Subscription,
  Fulfilment,
  DraftOrder,
} from '#types';

import { FormProvider } from '#context/FormContext';

import useForm from '#hooks/useForm';
import useProducts from '#hooks/useProducts';
import useSubscriptions from '#hooks/useSubscriptions';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import {
  Action,
  CellElement,
  TableActionCell,
  TableTextInputCell,
  TableSelectCell,
  TableCell,
} from '#materials/TableCell';

import { listRecords } from '#utils/data';
import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.lineItems;

export const TABLE_KEYS = {
  icon : 'icon',
  id : 'id',
  productSku : 'productSku',
  product : 'product',
  period : 'period',
  quantity : 'quantity',
  unit : 'unit',
  price : 'price',
  actions : 'actions',
} as const;
export type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultTableKeys = Object.values(TABLE_KEYS);

function generateLabel(product : Product) { return product.name }
function generateKey(product : Product) {
  return `${product.id || product.sku || product.name}`
}
function areProductsEqual(a : Product, b : Product) {
  return a.id === b.id;
}

interface LineItemRowProps {
  order? : DraftOrder;
  fulfilment? : Fulfilment;
  lineItem : LineItem;
  subscription? : Subscription;
  period? : number;
  disableProduct? : boolean;
  showFulfilmment? : boolean;
  disabled? : boolean;
  editing? : boolean;
  fade? : boolean;
  icon? : React.ReactNode;
  tableKeys? : TableKey[];
  generateActions? : (lineItem : LineItem) => CellElement;
  onSave? : (
    lineItem : LineItem,
    subscriptionOptions : { period : number },
  ) => void | Promise<void>;
  onCancel? : () => void | Promise<void>;
  onProductChange? : (product : Product) => void | Promise<void>;
}

function LineItemRowControl({
  fulfilment,
  lineItem : fallback,
  subscription,
  period : defaultPeriod,
  disabled = false,
  fade = false,
  disableProduct = false,
  showFulfilmment = false,
  icon,
  tableKeys = defaultTableKeys,
  generateActions,
  onSave,
  onCancel,
  onProductChange,
} : LineItemRowProps) {
  const { state : lineItem, dispatch, reset, editing } = useForm<LineItem>();
  const { products } = useProducts();
  const { isLineItemRecurring } = useSubscriptions();

  const [period, setPeriod] = useState<number>(defaultPeriod
    ?? subscription?.period
    ?? 0);
  const [allProducts, setAllProducts] = useState<Product[]>(
    listRecords(products)
  );
  const [periodOptions, setPeriodOptions] = useState<number[]>(
    (lineItem && isLineItemRecurring(lineItem) && period)
      ? [1, 2, 3, 4]
      : [0, 1, 2, 3, 4]
  );

  const product = allProducts
    .find((p) => p.id === (fulfilment?.fulfilledProductId
      ?? fulfilment?.requestedProductId
      ?? lineItem?.productId)) ?? null;

  const handleSave = useCallback(async () => {
    if (!lineItem) return;
    if (onSave) await onSave(lineItem, { period });
  }, [onSave, lineItem, period]);

  const handleCancel = useCallback(async () => {
    if (onCancel) await onCancel();
    reset();
  }, [onCancel, reset]);

  const handleProduct = useCallback((product : Product | null) => {
    if (!product || !product.id) return;
    onProductChange?.(product);
    dispatch({ productId : product.id });
  }, [onProductChange, dispatch]);

  const handleQuantity = useCallback((quantity : number | null) => {
    dispatch({ quantity : quantity ?? 1 });
  }, [dispatch]);

  const gernerateEditActions = useCallback(() => {
    return (
      <TableActionCell width={settings.dimensions.xsmall}>
        <Action
          label={localize(localeTableKeys.actions.save)}
          onClick={handleSave}
          disabled={disabled}
        >
          <Icon icon={settings.svgIcons.check} />
        </Action>
        <Action
          label={localize(localeTableKeys.actions.cancel)}
          onClick={handleCancel}
          disabled={disabled}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.clear} />
        </Action>
      </TableActionCell>
    );
  }, [disabled, handleSave, handleCancel]);

  const rowGenerator = useCallback((key : string) => {
    const greyOut = fade || (!fulfilment && showFulfilmment);

    const price = fulfilment?.unitPrice ?? product?.price;
    switch (key) {
      case TABLE_KEYS.id: return (
        <TableCell faded={greyOut}>
          { lineItem?.id
            ? `# ${lineItem?.id}` + ((fulfilment && showFulfilmment )
              ? `-${fulfilment.id}`
              : '')
            : '' }
        </TableCell>
      );
      case TABLE_KEYS.icon: return icon
        ? (<TableCell faded={greyOut}>{ icon }</TableCell>)
        : (
          <TableCell faded={greyOut}>
            { (period > 0) && (
              <Icon
                icon={settings.svgIcons.refresh}
                colour={settings.colours.button.primary}
              />
            ) }
          </TableCell>
        );
      case TABLE_KEYS.productSku: return (
        <TableCell width={settings.dimensions.small} faded={greyOut}>
          { product?.sku }
        </TableCell>
      );
      case TABLE_KEYS.product: return (
        disableProduct
          ? (<TableCell width={settings.dimensions.medium} faded={greyOut}>
            { product?.name }
            </TableCell>)
          : (<TableSelectCell
            width={settings.dimensions.medium}
            label={localize(localeTableKeys.headings.product)}
            selected={product}
            options={allProducts}
            onChange={handleProduct}
            labelGenerator={generateLabel}
            keyGenerator={generateKey}
            isEqual={areProductsEqual}
            disableClear
            disabled={!editing}
          />)
      );
      case TABLE_KEYS.period: return (
        <TableSelectCell
          selected={period}
          options={periodOptions}
          onChange={setPeriod}
          labelGenerator={
            (i : number) => localize(localeTableKeys.values.period[i])
          }
          faded={greyOut}
          disabled={!editing}
          width={settings.dimensions.small}
        />
      );
      case TABLE_KEYS.quantity: return (
        <TableTextInputCell
          id={`${lineItem?.id}-quantity`}
          label={localize(localeTableKeys.headings.quantity)}
          value={lineItem?.quantity ?? null}
          onChange={handleQuantity}
          inputType={settings.inputType.number}
          inputFormat={settings.inputFormat.int}
          disabled={!editing}
          faded={greyOut}
          width={settings.dimensions.small}
        />
      );
      case TABLE_KEYS.unit: return (
        <TableCell faded={greyOut} alignment={settings.alignments.right}>
          { price ? `(× ${formatCurrency(price)})` : '' }
        </TableCell>
      );
      case TABLE_KEYS.price:
        const total = (price && lineItem?.quantity)
          ? {
            ...price,
            amount : price.amount * lineItem.quantity,
            calculatedValue : price.calculatedValue * lineItem.quantity,
          }
          : null
        return (
          <TableCell
            faded={greyOut}
            width={settings.dimensions.xsmall}
            alignment={settings.alignments.right}
          >
            { total ? formatCurrency(total) : '' }
          </TableCell>
        );
      case TABLE_KEYS.actions: return (
        <React.Fragment key={key}>
          { !editing
            ? (generateActions && generateActions(lineItem || fallback))
            : gernerateEditActions()
          }
        </React.Fragment>
      );
      default: return <TableCell />;
    }
  }, [
    fallback,
    fulfilment,
    icon,
    disableProduct,
    showFulfilmment,
    fade,
    lineItem,
    editing,
    product,
    period,
    periodOptions,
    allProducts,
    handleProduct,
    handleQuantity,
    generateActions,
    gernerateEditActions,
  ]);

  useEffect(() => {
    setPeriod(defaultPeriod ?? subscription?.period ?? 0)
  }, [subscription, defaultPeriod]);

  useEffect(() => {
    setPeriodOptions((lineItem && isLineItemRecurring(lineItem) && period)
      ? [1, 2, 3, 4]
      : [0, 1, 2, 3, 4]);
  }, [lineItem, period, isLineItemRecurring]);

  useEffect(() => { setAllProducts(listRecords(products)) }, [products]);

  return (
    <>
      { tableKeys.map((key) => (
        <React.Fragment key={key}>
          { rowGenerator(key) }
        </React.Fragment>
      )) }
    </>
  );
}

function LineItemRow({
  lineItem,
  order,
  editing = false,
  ...props
} : LineItemRowProps) {

  const fulfilment = props.fulfilment
    ?? listRecords(order?.order?.fulfilments ?? null)
      .find((f) => f.lineItemId === lineItem?.id) ?? null
        ?? undefined;

  return (
    <FormProvider
      init={{
        ...lineItem,
        quantity : fulfilment?.fulfilledQty
          ?? fulfilment?.requestedQty
          ?? props.subscription?.quantity
          ?? lineItem?.quantity,
      }}
      editing={editing}
    >
      <LineItemRowControl
        lineItem={lineItem}
        fulfilment={fulfilment}
        order={order}
        editing={editing}
        {...props}
      />
    </FormProvider>
  )
}

export default LineItemRow;
