import React, { useCallback, useState } from 'react';

import { Address } from '#types';

import Form from '#materials/Form';
import SearchAddressInput from '#components/addresses/SearchAddressInput';

import Button from '#materials/Button';

import locale, { localize } from '#utils/locale';

const localeButtonKeys = locale.keys.buttons;

interface SearchAddressProps {
  label: string;
  onSave? : (address : Address | null) => void;
}

function SearchAddress({ label, onSave } : SearchAddressProps) {
  const [address, setAddress] = useState<Address | null>(null);

  const handleAdd = useCallback(() => {
    if (onSave) onSave(address);
  }, [address, onSave]);

  return (
    <>
      <Form>
        <SearchAddressInput
          label={label}
          address={address}
          onChange={setAddress}
        />
      </Form>
      <Button
        onClick={handleAdd}
      >
        { localize(localeButtonKeys.add) }
      </Button>
    </>
  );
}

export default SearchAddress;
