import React, { useCallback } from 'react';

import { Collection } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';
import DateTimePicker from '#materials/DateTimePicker';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.collections;

interface CollectionFormProps {
  collection : Collection;
  onSubmit? : () => void;
}

function CollectionForm({
  collection : fallback,
  onSubmit,
} : CollectionFormProps) {
  const { state, dispatch, editing, errors } = useForm<Collection>();

  const setMin = useCallback((min : number | null) => {
    if (min === null) return;
    dispatch({ min });
  }, [dispatch]);

  const setMax = useCallback((max : number | null) => {
    if (max === null) return;
    dispatch({ max });
  }, [dispatch]);

  const handleSelectedStartDate = useCallback((date : Date | null) => {
    if (!date) return;
    dispatch({ starting : date });
  }, [dispatch]);

  const handleSelectedEndDate = useCallback((date : Date | null) => {
    dispatch({ ending : date });
  }, [dispatch]);

  function validateDate(date : Date) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return date >= currentDate;
  }

  const idPrefix = (fallback && fallback.id)
    ? `collection-${fallback?.id}`
    : 'collection-new';

  return (
    <Form onSubmit={onSubmit}>
      <DateTimePicker
        dateLabel={localize(localKeys.labels.startDate)}
        timeLabel=""
        value={state ? state.starting : fallback.starting}
        setValue={handleSelectedStartDate}
        disabled={!editing}
        width={settings.dimensions.half}
      />
      <DateTimePicker
        dateLabel={localize(localKeys.labels.endDate)}
        timeLabel=""
        value={state ? state.ending : fallback.ending}
        setValue={handleSelectedEndDate}
        allowClear
        disabled={!editing}
        width={settings.dimensions.half}
        errors={errors.ending}
        validateDate={validateDate}
      />
      <TextInput
        id={`${idPrefix}-min`}
        label={localize(localKeys.labels.min)}
        value={state ? state.min : fallback.min}
        onChange={setMin}
        inputType={settings.inputType.number}
        inputFormat={settings.inputFormat.int}
        disabled={!editing}
        width={settings.dimensions.third}
      />
      <TextInput
        id={`${idPrefix}-max`}
        label={localize(localKeys.labels.max)}
        value={state ? state.max : fallback.max}
        onChange={setMax}
        inputType={settings.inputType.number}
        inputFormat={settings.inputFormat.int}
        disabled={!editing}
        width={settings.dimensions.third}
        errors={errors.max}
      />
    </Form>
  );
}

export default CollectionForm;
