import React, { useCallback, useState, useEffect } from 'react';

import { Assembly, Collection, Product } from '#types';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import useNavigation from '#hooks/useNavigation';
import { useFormContext } from '#hooks/useForm';
import useOptions from '#hooks/useOptions';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import Segment from '#materials/Segment';

import AssemblyForm from '#components/assemblies/AssemblyForm';
import CollectionDetails from '#components/assemblies/CollectionDetails';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.assemblies.assemblyDetails;
const localeFormKeys = locale.keys.forms.assemblies;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.assemblies;

interface AssemblyDetailsProps {
  product : Product;
  assembly : Assembly;
  onSave? : (assembly : Assembly) => void;
}

function AssemblyDetailsControl({
  product,
  assembly : init,
  onSave,
} : AssemblyDetailsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();

  const {
    state : assembly,
    reset,
    editing,
    setEditing,
  } = useFormContext<Assembly>();
  const {
    updateAssembly,
    deleteAssembly,
    getAssemblyCollections,
    createAssemblyCollection,
  } = useOptions();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [collections, setCollections] = useState<Collection[]>([]);

  const handleCreateCollection = useCallback(() => {
    if (!assembly?.id) return;
    const newCollection : Collection = {
      id : undefined,
      name : 'New Collection',
      min : 0,
      max : 0,
      starting : new Date(),
      ending : null,
      tagIds : [],
      productIds : [],
      availableChannelIds : [],
    };

    createAssemblyCollection(assembly, newCollection);
  }, [assembly, createAssemblyCollection]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!assembly) return;

    const updatedAssembly = await updateAssembly(assembly);
    if (updatedAssembly) {
      createNotification({
        key : 'update-assembly-success',
        message : localize(localeNotificationKeys.updated.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
      if (onSave) onSave(updatedAssembly);
      setEditing(false);
    } else {
      createNotification({
        key : 'update-assembly-error',
        message : localize(localeNotificationKeys.updated.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [onSave, assembly, setEditing, updateAssembly, createNotification]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleDelete = useCallback(async () => {
    if (!assembly) return;
    const success = await deleteAssembly(assembly);
    if (success) {
      createNotification({
        key : 'delete-assembly-success',
        message : localize(localeNotificationKeys.deleted.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
      navigate(`/products/${product.id}`);
    } else {
      createNotification({
        key : 'delete-assembly-error',
        message : localize(localeNotificationKeys.deleted.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [product, assembly, deleteAssembly, createNotification, navigate]);

  const initDelete = useCallback(
    () => setConfirmDelete(true),
    [setConfirmDelete]
  );

  const cancelDelete = useCallback(
    () => setConfirmDelete(false),
    [setConfirmDelete]
  );

  useEffect(() => {
    if (!assembly) setCollections([]);
    else setCollections(getAssemblyCollections(assembly));
  }, [assembly, getAssemblyCollections]);

  return (
    <>
      { confirmDelete && (
        <Banner
          onClose={cancelDelete}
          colour={settings.colours.alert.alert}
          icon={ <Icon icon={settings.svgIcons.delete} /> }
          actions={
            <>
              <Button onClick={handleDelete}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={cancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          }
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { ( assembly && editing )
        ? <>
          <Button
            onClick={handleSave}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleEdit}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={initDelete}
            colour={settings.colours.button.alert}
            disabled={confirmDelete}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <AssemblyForm
        assembly={assembly || init}
        onSubmit={handleSave}
      />
      { Object.values(collections).map((collection : Collection) => (
        ( collection
          && (!collection.ending
            || (new Date(collection.ending).getTime() > new Date().getTime()))
        ) && (
          <CollectionDetails
            key={collection.id}
            product={product}
            collection={collection}
          />
        )
      )) }
      <Segment/>
      <Button
        onClick={handleCreateCollection}
      >
        { localize(localeContentKeys.addCollection) }
      </Button>
    </>
  );
}

function AssemblyDetails({ product, assembly, onSave } : AssemblyDetailsProps) {
  const validate = useCallback((assembly : Assembly) => {
    const errors : { [key : string] : string } = {};

    if (!assembly.name) {
      errors.name = localize(localeFormKeys.errors.invalidName);
    }

    return errors;
  }, []);

  return (
    <FormProvider
      init={assembly}
      validators={[validate]}
      editingInit={false}
    >
      <AssemblyDetailsControl
        product={product}
        assembly={assembly}
        onSave={onSave}
      />
    </FormProvider>
  );
}

export default AssemblyDetails;
