import React, { useCallback } from 'react';

import { Route } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.routes;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface RouteTableProps {
  routes : Route[];
  tableKeys? : TableKeys[];
  generateActions? : (route : Route) => CellElement;
}

function RouteTable({
  routes,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <></>,
} : RouteTableProps) {

  const generateHead = useCallback(() => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `route-table-head-${key}`
        switch (key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.id) }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.name) }
            </TableCell>;
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [tableKeys]);

  const generateRow = useCallback((route : Route) => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `route-table-row-${route.id}-${key}`
        switch (key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { `# ${route.id}` }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { route.name }
            </TableCell>;
          case TABLE_KEYS.actions:
            return (
              <React.Fragment key={mapKey}>
                { generateActions(route) }
              </React.Fragment>
            );
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [generateActions, tableKeys]);

  return (
    <Table
      head={generateHead()}
      rows={routes.map((route) => (
        <React.Fragment key={`route-table-row-${route.id}`}>
          { generateRow(route) }
        </React.Fragment>
      ))}
    />
  );
}

export default RouteTable;
