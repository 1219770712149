import React, { useCallback, useEffect, useState } from 'react';

import { Product, Tag } from '#types';

import useNavigation from '#hooks/useNavigation';
import useTags from '#hooks/useTags';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Button from '#materials/Button';
import { TableActionCell, Action } from '#materials/TableCell';
import Icon from '#materials/Icon';

import Section from '#components/dashboard/Section';
import ProductTable from '#components/products/ProductTable';
import ProductSearch from '#components/products/ProductSearch';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.tags.tagProducts;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.products;

interface TagProductsProps {
  tag : Tag;
}

function TagProducts({ tag } : TagProductsProps) {
  const  { navigate } = useNavigation();
  const {
    addProductToTag,
    removeProductFromTag,
  } = useTags();

  const { retrieveProducts } = useProducts();

  const [add, setAdd] = useState(false);
  const [
    tagProducts,
    setTagProducts
  ] = useState<Product[]>([]);

  useEffect(() => {
    if (!tag.id) return;

    async function fetchProducts() {
      const retrievedProducts = await retrieveProducts();
      if (!retrievedProducts) return;

      const tagId = tag.id;
      if (tagId !== undefined) {
        const tagProducts = listRecords(retrievedProducts).filter(
          product => product.id && tag.productIds.includes(product.id)
        );
        setTagProducts(tagProducts);
      }
    }
    fetchProducts();
  }, [tag, retrieveProducts]);

  const handleAdd = useCallback(() => {
    setAdd(true);
  }, []);

  const handleClose = useCallback(() => {
    setAdd(false);
  }, []);

  const handleView = useCallback((product : Product) => () => {
    if (!product.id) return;
    navigate(`/products/${product.id}`);
  }, [navigate]);

  const handleAddProduct = useCallback((product : Product) => async () => {
    await addProductToTag(product, tag);
  }, [addProductToTag, tag]);

  const handleRemoveProduct = useCallback((product : Product) => async () => {
    await removeProductFromTag(product, tag);
  }, [removeProductFromTag, tag]);

  const filterAvailableProducts = useCallback((product : Product) => {
    return !!product.id && !tag.productIds.includes(product.id);
  }, [tag]);

  const generateRemoveActions = useCallback((product : Product) => {
    return (add
      ? <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.removeProduct)}
          onClick={handleRemoveProduct(product)}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      </TableActionCell>
      : <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          onClick={handleView(product)}
        >
          <Icon icon={settings.svgIcons.shoppingBag} />
        </Action>
      </TableActionCell>
    )
  }, [add, handleView, handleRemoveProduct]);

  const generateAddActions = useCallback((product : Product) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.addProduct)}
          onClick={handleAddProduct(product)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAddProduct]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
    >
      { tagProducts.length
        ? <ProductTable
          products={tagProducts}
          generateActions={generateRemoveActions}
        />
        : <Text>{ localize(localeContentKeys.noProducts) }</Text>
      }
      { add
        ? <>
          <Segment title={localize(localeContentKeys.availableProducts)}>
            <ProductSearch
              pageCount={5}
              filter={filterAvailableProducts}
              generateActions={generateAddActions}
            />
            <Button
              onClick={handleClose}
              colour={settings.colours.button.alert}
            >
              { localize(localeButtonKeys.close) }
            </Button>
          </Segment>
        </>
        : <Button
          onClick={handleAdd}
        >
          { localize(localeButtonKeys.add) }
        </Button>
      }
    </Section>
  );
}

export default TagProducts;
