import React, { useState, useEffect } from 'react';

import { Location, Service } from '#types';

import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Text from '#materials/Text';
import { TableActionCell, Action } from '#materials/TableCell';
import Icon from '#materials/Icon';

import Section from '#components/dashboard/Section';
import ServicesTable from '#components/services/ServicesTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.locations.locationServices;
const localeTableKeys = locale.keys.tables.services;

interface LocationServicesProps {
  location : Location;
}

function LocationServices({ location, } : LocationServicesProps) {
  const { retrieveServices, } = useServices();

  const [locationServices, setLocationServices] = useState<Service[]>([]);

  useEffect(() => {
    if (!location.id) return;

    const fetchLocationServices = async () => {
      const retrievedServices = await retrieveServices();
      if (!retrievedServices) return ;

      if (retrievedServices) {
        setLocationServices(listRecords(retrievedServices).filter(
          service => !!service.id && location.serviceIds
            && location.serviceIds.includes(service.id)
        ));
      }
    }

    fetchLocationServices();
  }, [location, retrieveServices]);

  function generateServiceActions(service : Service) {
    if (!location || !location.id) return (<></>);
    return (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.view)} ${service.id}`}
          href={`/services/${service.id}`}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.localShipping}/>
        </Action>
      </TableActionCell>
    )
  }

  return (
    <>
      { location &&
        <Section
          title={localize(localeContentKeys.title)}
          text={localize(localeContentKeys.body)}
        >
          { !!(locationServices && locationServices.length)
            ? <ServicesTable
                services={locationServices}
                count={locationServices.length}
                generateActions={generateServiceActions}
              />
              : <Text>{ localize(localeContentKeys.notAssigned) }</Text>
          }
        </Section>
      }
    </>
  );
}

export default LocationServices;
