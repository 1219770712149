import React from 'react';

import Stack from '@mui/material/Stack';
import MuiPagination from '@mui/material/Pagination';

import { settings } from '#materials/types';
import Center from '#materials/Center';

interface PaginationProps {
  count : number;
  page : number;
  onChange : (event : React.ChangeEvent<unknown>, page : number) => void;
}

function Pagination({
  count,
  page,
  onChange,
} : PaginationProps) {
  return (
    <Center margin={{ top : settings.spacings.medium }}>
      <Stack alignItems="center">
        <MuiPagination
          count={count}
          page={page}
          onChange={onChange}
          color={settings.colours.button.primary}
        />
      </Stack>
    </Center>
  );
}

export default Pagination;
