import React from 'react';

import { useParams } from '#hooks/useNavigation';

import CustomerIntegrationIndex
  from '#components/customers/CustomerIntegrationIndex';
import CustomerIntegrationInfo
  from '#components/customers/CustomerIntegrationInfo';

function CustomersIntegration() {
  const urlParams = useParams<{
    customerId? : string;
    customerIntegrationId? : string;
  }>();

  const parsedCustomerId = parseInt(urlParams.customerId || '0');
  const parsedIntegrationId = parseInt(urlParams.customerIntegrationId || '0');
  const customerId = isNaN(parsedCustomerId) ? undefined : parsedCustomerId;
  const integrationId = isNaN(parsedIntegrationId)
    ? undefined
    : parsedIntegrationId;

  return (
    !!integrationId
      ? <CustomerIntegrationInfo
        customerId={customerId}
        customerIntegrationId={integrationId}
      />
      : <CustomerIntegrationIndex />
  );
}

export default CustomersIntegration;
