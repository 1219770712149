import React from 'react';

import { TimeSlot } from '#types';

import Section from '#components/dashboard/Section';
import WindowDetails from '#components/timeslots/WindowDetails';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.timeSlots.timeSlotWindows;

interface TimeSlotWindowsProps {
  timeSlot : TimeSlot;
}

function TimeSlotWindows({ timeSlot } : TimeSlotWindowsProps) {
  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.text)}
    >
      <WindowDetails timeSlot={timeSlot} />
    </Section>
  );
}

export default TimeSlotWindows;
