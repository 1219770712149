import React, { useCallback, useState } from 'react';

import { Schedule } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useTimeSlots from '#hooks/useTimeSlots';

import ScheduleForm from '#components/schedules/ScheduleForm';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.schedules.scheduleDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.schedules;

interface ScheduleDetailsProps {
  schedule : Schedule;
}

function ScheduleDetailsForm({
  schedule: init,
} : ScheduleDetailsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    state : schedule,
    editing,
    setEditing,
    reset,
  } = useFormContext<Schedule>();
  const { updateSchedule, deleteSchedule } = useTimeSlots();

  const [saved, setSaved] = useState(true);
  const [deleting, setDeleting] = useState(false);

  const handleUpdate = useCallback(async () => {
    if (!schedule) return;

    const updatedSchedule = await updateSchedule(schedule);

    if (!updatedSchedule) return;
    setSaved(true);
    setEditing(false);
  }, [schedule, updateSchedule, setSaved, setEditing]);

  const handleCancel = useCallback(() => {
    setEditing(false);
    reset();
  }, [setEditing, reset]);

  const handleEdit = useCallback(() => {
    setSaved(true);
    setEditing(true);
  }, [setEditing, setSaved]);

  const handleChange = useCallback(() => {
    setSaved(false);
  }, [setSaved]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleConfirmDelete = useCallback(async () => {
    if (!init) return;
    const success = await deleteSchedule(init);
    if (success) {
      createNotification({
        key : 'delete-schedule-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.dateRange} />),
        colour : settings.colours.alert.primary,
      });
      navigate('/scheduling/');
    } else {
      createNotification({
        key : 'delete-schedule-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.dateRange} />),
        colour : settings.colours.alert.alert,
      });
    }
    setDeleting(false);
  }, [init, setDeleting, deleteSchedule, navigate, createNotification]);

  return (
    <>
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button
                onClick={handleConfirmDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { !editing
        ? <>
          <Button
            onClick={handleEdit}
            disabled={deleting}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleUpdate}
            disabled={saved}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
      }
      <ScheduleForm schedule={init} onChange={handleChange} />
    </>
  );
}

function ScheduleDetails({ schedule } : ScheduleDetailsProps) {
  return (
    <FormProvider
      init={schedule}
      editingInit={false}
    >
      <ScheduleDetailsForm schedule={schedule} />
    </FormProvider>
  )
}

export default ScheduleDetails;
