import React, { useCallback, useEffect, useState } from 'react';

import { Customer } from '#types';

import useCustomers from '#hooks/useCustomers';

import CustomerDetails from '#components/customers/CustomerDetails';
import CustomerContactInfo from '#components/customers/CustomerContactInfo';
import CustomerAddresses from '#components/customers/CustomerAddresses';
import CustomerCards from '#components/customers/CustomerCards';
import CustomerIntegrations from '#components/customers/CustomerIntegrations';
import CustomerUsers from '#components/customers/CustomerUsers';

interface CustomerInfoProps {
  customerId : number;
}

function CustomerInfo({ customerId } : CustomerInfoProps) {
  const { retrieveCustomer } = useCustomers();

  const [customer, setCustomer] = useState<Customer | null>(null);

  const retrieve = useCallback(async () => {
    const customer = await retrieveCustomer(customerId);
    setCustomer(customer);
  }, [customerId, retrieveCustomer, setCustomer]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <>
      <CustomerDetails customer={customer} />
      { !!customer &&
        <>
          <CustomerContactInfo customer={customer} />
          <CustomerAddresses customer={customer}/>
          <CustomerCards customer={customer} />
          <CustomerIntegrations customer={customer} />
          <CustomerUsers customer={customer} />
        </>
      }
    </>
  );
}

export default CustomerInfo;
