import React, { useCallback } from 'react';

import { RevenueCentre } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import usePayments from '#hooks/usePayments';

import { settings } from '#materials';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import RevenueCentreForm from '#components/revenueCentres/RevenueCentreForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys =
  locale.keys.content.revenueCentres.createRevenueCentre;
const localeButtonKeys = locale.keys.buttons;

interface CreateRevenueCentreProps {
  onSave? : (revenueCentre : RevenueCentre) => void;
  onCancel? : () => void;
}

function CreateRevenueCentreControl(
  { onCancel, onSave } : CreateRevenueCentreProps
) {
  const { state : revenueCentre, reset } = useFormContext<RevenueCentre>();
  const { createRevenueCentre } = usePayments();

  const handleSave = useCallback(async () => {
    if (!revenueCentre) return;

    const newRevenueCentre = await createRevenueCentre(revenueCentre);
    if (newRevenueCentre && onSave) onSave(newRevenueCentre);
  }, [revenueCentre, createRevenueCentre, onSave]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Section title={localize(localeContentKeys.title)}>
      { !!revenueCentre && <RevenueCentreForm revenueCentre={revenueCentre} /> }
      <Button
        onClick={handleSave}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
    </Section>
  );
}

function CreateRevenueCentre({ onSave, onCancel } : CreateRevenueCentreProps) {
  return (
    <FormProvider
      init={{
        name: '',
      }}
      editing={true}
    >
      <CreateRevenueCentreControl onSave={onSave} onCancel={onCancel} />
    </FormProvider>
  );
}

export default CreateRevenueCentre;
