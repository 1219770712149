import React, { useState, useEffect } from 'react';

import { ServiceChannel, Service } from '#types';

import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Text from '#materials/Text';
import { TableActionCell, Action } from '#materials/TableCell';
import Icon from '#materials/Icon';

import Section from '#components/dashboard/Section';
import ServicesTable from '#components/services/ServicesTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys =
  locale.keys.content.serviceChannels.serviceChannelServices;
const localeTableKeys = locale.keys.tables.services;

interface ServiceChannelServicesProps {
  serviceChannel : ServiceChannel;
}

function ServiceChannelServices({
  serviceChannel,
} : ServiceChannelServicesProps) {

  const {
    retrieveServices,
  } = useServices();

  const [serviceChannelServices, setServiceChannelServices] = useState<Service[]>([]);

  useEffect(() => {
    if (!serviceChannel.id) return;

    const fetchServiceChannels = async () => {
      const retrievedServices = await retrieveServices();
      if (!retrievedServices) return ;

      if (retrievedServices) {
        setServiceChannelServices(listRecords(retrievedServices).filter(
          service => !!service.id && serviceChannel.serviceIds
            && serviceChannel.serviceIds.includes(service.id)
        ));
      }
    }

    fetchServiceChannels();
  }, [serviceChannel, retrieveServices]);

  function generateServiceActions(service : Service) {
    if (!serviceChannel || !serviceChannel.id) return (<></>);
    return (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.view)} ${service.id}`}
          href={`/services/${service.id}`}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.localShipping}/>
        </Action>
      </TableActionCell>
    )
  }

  return (
    <>
      { serviceChannel &&
        <Section
          title={localize(localeContentKeys.title)}
          text={localize(localeContentKeys.body)}
        >
          { !!(serviceChannelServices && serviceChannelServices.length)
            ? <ServicesTable
                services={serviceChannelServices}
                count={serviceChannelServices.length}
                generateActions={generateServiceActions}
              />
              : <Text>{ localize(localeContentKeys.notAssigned) }</Text>
          }
        </Section>
      }
    </>
  );
}

export default ServiceChannelServices;
