import React, { useEffect, useState } from 'react';

import {
  Customer,
  ServiceChannel,
  Location,
  Route,
  Product,
  Category,
  LineItem,
  DraftCustomOrder,
} from '#types';

import Segment from '#materials/Segment';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import OrdersSearchControl from '#components/orders/OrdersSearch';
import ItemSearch from '#components/orders/ItemSearch';
import ItemList from '#components/lineItems/ItemList';
import PackingList from '#components/orders/PackingList';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.ordersReport;
const localeButtonKeys = locale.keys.buttons;

interface OrdersReportProps {
  customer? : Customer | null;
  serviceChannel? : ServiceChannel | null;
  location? : Location | null;
  route? : Route | false | null;
  fromDate? : Date | null;
  toDate? : Date | null;
  product? : Product | null;
  category? : Category | null;
  setCustomer? : (customer : Customer | null) => void;
  setServiceChannel? : (channel : ServiceChannel | null) => void;
  setLocation? : (location : Location | null) => void;
  setRoute? : (route : Route | false | null) => void;
  setFromDate? : (date : Date | null) => void;
  setToDate? : (date : Date | null) => void;
  setProduct? : (product : Product | null) => void;
  setCategory? : (category : Category | null) => void;
  clear? : () => void;
}

function OrdersReport({
  customer = null,
  serviceChannel = null,
  location = null,
  route = null,
  fromDate = null,
  toDate = null,
  product = null,
  category = null,
  setCustomer = () => {},
  setServiceChannel = () => {},
  setLocation = () => {},
  setRoute = () => {},
  setFromDate = () => {},
  setToDate = () => {},
  setProduct = () => {},
  setCategory = () => {},
  clear = () => {},
} : OrdersReportProps) {
  const [orders, setOrders] = useState<DraftCustomOrder[]>([]);
  const [productOrders, setProductOrders] = useState<DraftCustomOrder[]>([]);
  const [productItems, setProductItems] = useState<LineItem[]>([]);

  const [filteredOrders, setFilteredOrders] = useState<DraftCustomOrder[]>([]);
  const [filteredItems, setFilteredItems] = useState<LineItem[]>([]);

  useEffect(() => {
    if (!product && !category) setFilteredOrders(orders);
    else setFilteredOrders(orders.filter((order) => (
      order.complete && productOrders.includes(order)
    )));
  }, [product, category, orders, productOrders]);

  useEffect(() => {
    setFilteredItems(productItems);
  }, [product, category, orders, productItems]);

  const searching = !!customer
    || !!serviceChannel
    || !!location
    || !!route
    || !!fromDate
    || !!toDate;

  return (
    <>
      <Section title={localize(localeContentKeys.title)}>
        <OrdersSearchControl
          customer={customer}
          serviceChannel={serviceChannel}
          location={location}
          route={route}
          fromDate={fromDate}
          toDate={toDate}
          setCustomer={setCustomer}
          setServiceChannel={setServiceChannel}
          setLocation={setLocation}
          setRoute={setRoute}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setOrders={setOrders}
        />
        <Segment />
        <ItemSearch
          orders={orders}
          product={product}
          category={category}
          setProduct={setProduct}
          setCategory={setCategory}
          setLineItems={setProductItems}
          setOrders={setProductOrders}
        />
        <Button
          onClick={clear}
        >
          {localize(localeButtonKeys.clear)}
        </Button>
      </Section>
      { searching && (
        <Section title={localize(localeContentKeys.itemCount)}>
          <ItemList
            lineItems={filteredItems}
            mergeProducts={true}
            mergePricing={true}
          />
        </Section>
      ) }
      { searching && filteredOrders.map((order) => (
        <PackingList
          key={order.order?.id
            ?? `${order.customer?.id}` +
              `-${order.serviceChannel?.id}` +
              `-${order.address?.id ?? order.location?.id}` +
              `-${order.timeSlot?.id}` +
              `-${order.timeSlotIteration}` +
              `-${order.timeSlotDivision}`}
          order={order}
        />
      )) }
    </>
  );
}

export default OrdersReport;
