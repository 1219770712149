import React, { useCallback, useEffect, useState } from 'react';

import { Hold } from '#types';

import useNotifications from '#hooks/useNotifications';
import useOrders from '#hooks/useOrders';

import { settings } from '#materials';
import Text from '#materials/Text';
import Button from '#materials/Button';
import Icon from '#materials/Icon';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import CreateHold from '#components/orders/CreateHold';
import HoldTable from '#components/orders/HoldTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.holds.holds
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.holds;

function filter(holds : Hold[]) {
  return holds.filter(hold => hold.end > new Date());
}

function Holds() {
  const { createNotification } = useNotifications();
  const { holds : holdIndex, retrieveHolds, deleteHold } = useOrders();

  const [holds, setHolds] = useState(filter(listRecords(holdIndex)));
  const [deleteTarget, setDeleteTarget] = useState<Hold | null>(null);

  const retrieve = useCallback(async () => {
    const newHolds = await retrieveHolds();
    setHolds(listRecords(newHolds).filter(hold => hold.end > new Date()));
  }, [retrieveHolds]);

  const confirmDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteHold(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-hold-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : <Icon icon={settings.svgIcons.store} />,
        colour : settings.colours.alert.primary,
      });
      setDeleteTarget(null);
    } else {
      createNotification({
        key : 'delete-hold-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : <Icon icon={settings.svgIcons.store} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [deleteHold, deleteTarget, createNotification]);

  const cancelDelete = useCallback(() => setDeleteTarget(null), []);

  const generateActions = useCallback((hold : Hold) => (
    <TableActionCell>
      <Action
        label={localize(localeButtonKeys.delete)}
        onClick={() => setDeleteTarget(hold)}
        disabled={!!deleteTarget}
        colour={settings.colours.alert.alert}
      >
        <Icon icon={settings.svgIcons.delete} />
      </Action>
    </TableActionCell>
  ), [deleteTarget]);

  useEffect(() => { retrieve() }, [retrieve]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { deleteTarget && (
        <Banner
          actions={(
            <>
              <Button onClick={confirmDelete}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={cancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          icon={<Icon icon={settings.svgIcons.store} />}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete)
            + ` (#${deleteTarget.id})` }
        </Banner>
      )}
      <CreateHold disabled={!!deleteTarget} />
      { holds.length
        ? (
          <HoldTable
            holds={holds}
            generateActions={generateActions}
          />
        ) : (
          <Text>{ localize(localeContentKeys.notFound) }</Text>
        )
      }
    </Section>
  );
}

export default Holds;
