import React, { useCallback, useEffect, useState } from 'react';

import { Integration } from '#types';

import useIntegrations from '#hooks/useIntegrations';

import IntegrationDetails from '#components/integrations/IntegrationDetails'
import IntegrationConfigs from '#components/integrations/IntegrationConfigs';
import IntegrationChannels from '#components/integrations/IntegrationChannels';

interface IntegrationInfoProps {
  integrationId : number;
}

function IntegrationInfo({ integrationId } : IntegrationInfoProps) {
  const { retrieveIntegration } = useIntegrations();

  const [integration, setIntegration] = useState<Integration | null>(null);

  const retrieve = useCallback(async () => {
    const integration = await retrieveIntegration(integrationId);
    setIntegration(integration);
  }, [integrationId, retrieveIntegration, setIntegration]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <>
      <IntegrationDetails integration={integration} />

      { !!integration &&
        <>
          <IntegrationConfigs integration={integration} />
          <IntegrationChannels integration={integration} />
        </>
      }
    </>
  );
}

export default IntegrationInfo;
