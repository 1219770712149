import React, { useCallback, useState, useEffect } from 'react';

import { LogEntry } from '#types';

import Table from '#materials/Table';
import { TableCell } from '#materials/TableCell';

import { formats, formatDateTime } from '#utils/date';
import locale, { localize } from '#utils/locale';
import { settings } from '#materials';

const localKeys = locale.keys.tables.logEntries;

export const TABLE_KEYS = {
  id : 'id',
  eventCode : 'eventCode',
  message : 'message',
  timestamp : 'timestamp',
  metaKeys : 'metaKeys',
  metaValues : 'metaValues',
} as const;
type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface LogEntryTableProps {
  logEntries : LogEntry[];
  tableKeys? : TableKeys[];
}

const defaultTableKeys = Object.values(TABLE_KEYS);

function LogEntryTable({
  logEntries,
  tableKeys = defaultTableKeys,
} : LogEntryTableProps) {
  const [head, setHead] = useState<React.ReactNode>(null);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const generateHead = useCallback(() => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `log-entry-table-head-${key}`
        switch (key) {
          case TABLE_KEYS.id:
            return (
            <TableCell key={mapKey} width={settings.dimensions.xsmall}>
              { localize(localKeys.headings.id) }
            </TableCell>);
          case TABLE_KEYS.eventCode:
            return (<TableCell key={mapKey} width={settings.dimensions.small}>
              { localize(localKeys.headings.eventCode) }
              </TableCell>);
          case TABLE_KEYS.message:
            return (<TableCell key={mapKey} width={settings.dimensions.small}>
              { localize(localKeys.headings.message) }
            </TableCell>);
          case TABLE_KEYS.timestamp:
            return (<TableCell key={mapKey} width={settings.dimensions.small}>
              { localize(localKeys.headings.timestamp) }
            </TableCell>);
          case TABLE_KEYS.metaKeys:
            return (
              <TableCell
                key={mapKey}
                alignment={settings.alignments.end}
              >
                { localize(localKeys.headings.metaData) }
              </TableCell>
            );
          case TABLE_KEYS.metaValues:
            return (
              <TableCell key={mapKey} width={settings.dimensions.small} />
            );
          default: return (<TableCell key={mapKey} />);
        }
      }) }
    </>
  }, [tableKeys]);

  const generateRow = useCallback((logEntry : LogEntry) => {
    return (<>
      { tableKeys.map((key) => {
        const mapKey = `log-entry-table-row-${logEntry.id}-${key}`
        switch (key) {
          case TABLE_KEYS.id:
            return (<TableCell key={mapKey} width={settings.dimensions.xsmall}>
              { `# ${logEntry.id}` }
            </TableCell>);
          case TABLE_KEYS.eventCode:
            return (<TableCell key={mapKey} width={settings.dimensions.small}>
              { logEntry.eventCode }
            </TableCell>);
          case TABLE_KEYS.message:
            return (<TableCell key={mapKey} width={settings.dimensions.medium}>
              { logEntry.message }
            </TableCell>);
          case TABLE_KEYS.timestamp:
            return (<TableCell key={mapKey} width={settings.dimensions.small}>
              { formatDateTime(logEntry.timestamp, formats.numeric) }
            </TableCell>);
          case TABLE_KEYS.metaKeys:
            return (
              <TableCell
                key={mapKey}
                alignment={settings.alignments.end}
              >
                { Object.keys(logEntry.metaData).map((metaKey) => {
                  return (<React.Fragment key={`${mapKey}-metaKey`}>
                    { metaKey }
                  </React.Fragment>);
                }) }
              </TableCell>
            );
          case TABLE_KEYS.metaValues:
            return (<TableCell key={mapKey}>
              { Object.values(logEntry.metaData).map((metaValue) => {
                return (<React.Fragment key={`${mapKey}-metaValue`}>
                  { metaValue ? metaValue.toString() : '' }
                </React.Fragment>);
              }) }
            </TableCell>);
          default:
            return (<TableCell key={mapKey} />);
        }
      }) }
    </>);
  }, [tableKeys]);

  useEffect(() => {
    setHead(generateHead());
  }, [generateHead]);

  useEffect(() => {
    const sorted = logEntries.slice().sort((a, b) => {
      return b.timestamp.getTime() - a.timestamp.getTime();
    });
    setRows(sorted.map((logEntry) => (
      <React.Fragment key={`log-entry-table-row-${logEntry.id}`}>
        { generateRow(logEntry) }
      </React.Fragment>
    )));
  }, [logEntries, generateRow]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={20}
    />
  );
}

export default LogEntryTable;
