import React, { useCallback, useEffect, useState } from 'react';

import { ProductIntegration, Integration } from '#types';

import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.productIntegrations;

export const TABLE_KEYS = {
  id : 'id' as const,
  integration : 'integrationId' as const,
  externalId : 'externalId' as const,
  name : 'name' as const,
  sku : 'sku' as const,
  price : 'price' as const,
  actions : 'actions' as const,
};
type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface ProductIntegrationsTableProps {
  productIntegrations : ProductIntegration[];
  tableKeys? : TableKey[];
  generateActions? : (product : ProductIntegration) => CellElement;
}

function ProductIntegrationsTable({
  productIntegrations,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <TableCell />,
} : ProductIntegrationsTableProps) {
  const { retrieveIntegrations } = useIntegrations();

  const [
    integrations,
    setIntegrations,
  ] = useState<{ [key : string] : Integration | null }>({});

  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const retrieve = useCallback(async () => {
    const newIntegrations = await retrieveIntegrations();
    if (newIntegrations) setIntegrations(newIntegrations);
  }, [retrieveIntegrations]);

  const generateHead = useCallback(() => {
    function generate(key : TableKey) {
      switch (key) {
        case TABLE_KEYS.id:
          return (<TableCell>{ localize(localKeys.headings.id) }</TableCell>);
        case TABLE_KEYS.integration:
          return (
            <TableCell>{ localize(localKeys.headings.integration) }</TableCell>
          );
        case TABLE_KEYS.externalId:
          return (
            <TableCell>{ localize(localKeys.headings.externalId) }</TableCell>
          );
        case TABLE_KEYS.name:
          return (<TableCell>{ localize(localKeys.headings.name) }</TableCell>);
        case TABLE_KEYS.sku:
          return (<TableCell>{ localize(localKeys.headings.sku) }</TableCell>);
        default: return <TableCell />;
      }
    }

    return (
      <>
        { tableKeys.map((key) => (
          <React.Fragment key={`product-integrations-table-head-${key}`}>
            { generate(key) }
          </React.Fragment>
        )) }
      </>
    )
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    function generate(key : TableKey, product : ProductIntegration) {
      switch (key) {
        case TABLE_KEYS.id:
          return (
            <TableCell
              width={settings.dimensions.xsmall}
            >
              { `# ${product.id}` }
            </TableCell>
          );
        case TABLE_KEYS.externalId:
          return (<TableCell font={settings.fonts.mono}>
            { product.externalId }
          </TableCell>);
        case TABLE_KEYS.integration:
          const integration = product.integrationId
            ? integrations[product.integrationId]
            : undefined;
          return (
            <TableCell
              width={settings.dimensions.small}
            >
              { integration ? integration.name : '' }
            </TableCell>
          );
        case TABLE_KEYS.name:
          return (
            <TableCell
              width={settings.dimensions.large}
            >
              { product.data.name }
            </TableCell>
          );
        case TABLE_KEYS.sku:
          return (
            <TableCell
              width={settings.dimensions.small}
            >
              { product.data.sku }
            </TableCell>
          );
        case TABLE_KEYS.actions:
          return generateActions(product);
        default: return <TableCell />;
      }
    }

    return productIntegrations.map((product, index) => (
      tableKeys.map((key) => (
        <React.Fragment
          key={`product-integrations-table-row-${index}-${key}`}
        >
          { generate(key, product) }
        </React.Fragment>
      ))
    ));
  }, [productIntegrations, tableKeys, generateActions, integrations]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  useEffect(() => {
    setHead(generateHead());
  }, [generateHead]);

  useEffect(() => {
    setRows(generateRows());
  }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={20}
    />
  );
}

export default ProductIntegrationsTable;
