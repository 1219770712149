import React, { useEffect, useState } from 'react';

import { Tax, Tag } from '#types';

import useTaxes from '#hooks/useTaxes';
import useTags from '#hooks/useTags';

import Section from '#components/dashboard/Section';
import TaxDetails from '#components/taxes/TaxDetails';
import TaxIntegrations from '#components/taxes/TaxIntegrations';
import TagProducts from '#components/tags/TagProducts';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.taxes.taxDetails;

interface TaxInfoProps {
  taxId : number;
}

function TaxInfo({ taxId } : TaxInfoProps) {
  const { retrieveTax } = useTaxes();
  const { retrieveTag } = useTags();

  const [tax, setTax] = useState<Tax | null>(null);
  const [tag, setTag] = useState<Tag | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function retrieve() {
      const newTax = await retrieveTax(taxId);
      if (newTax) setTax(newTax);

      const newTag = await retrieveTag(taxId);
      if (newTag) setTag(newTag);
      setLoaded(true);
    }
    retrieve();
  }, [taxId, retrieveTax, retrieveTag]);

  if (!loaded) return <></>;
  if (!tax || !tag) return (
    <Section title={localize(localeContentKeys.notFound)}> </Section>
  );

  return (
    <>
      <Section
        title={`${tax.name} (#${tax.id})`}
        text={localize(localeContentKeys.text)}
      >
        <TaxDetails tax={tax} />
      </Section>
      <TaxIntegrations tax={tax} />
      <TagProducts tag={tag} />
    </>
  )
}

export default TaxInfo;
