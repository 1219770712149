import React, { useCallback, useState } from 'react';

import { Tax } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useTaxes from '#hooks/useTaxes';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import TaxForm from '#components/taxes/TaxForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.taxes.taxDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.taxes;

interface TaxDetailsProps {
  tax : Tax;
  onSave? : (tax : Tax) => void;
}

function TaxDetailsControl(
  { tax : init, onSave } : TaxDetailsProps
) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();

  const {
    state : tax,
    reset,
    editing,
    setEditing,
  } = useFormContext<Tax>();
  const { refreshTax, updateTax, deleteTax } = useTaxes();

  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!tax || !tax.id) return;
    refreshTax(tax.id);
  }, [tax, refreshTax]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!tax) return;

    const updatedTax = await updateTax(tax);
    if (updatedTax) {
      if (onSave) onSave(updatedTax);
      setEditing(false);
    }
  }, [tax, updateTax, onSave, setEditing]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleDelete = useCallback(async () => {
    if (!tax) return;

    const success = await deleteTax(tax);
    if (success) {
      createNotification({
        key : 'delete-tax-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : <Icon icon={settings.svgIcons.percent} />,
        colour : settings.colours.alert.primary,
      });
      navigate('/taxes/');
    } else {
      createNotification({
        key : 'delete-tax-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : <Icon icon={settings.svgIcons.percent} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [tax, deleteTax, navigate, createNotification]);

  return (
    <>
      { deleting && (
        <Banner
          icon={ <Icon icon={settings.svgIcons.delete} /> }
          actions={(
            <>
              <Button
                onClick={handleDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (tax && editing)
        ? <>
          <Button
            onClick={handleSave}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleRefresh}
          >
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button
            onClick={handleEdit}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <TaxForm tax={tax || init} />
    </>
  );
}

function TaxDetails({ tax, onSave } : TaxDetailsProps) {
  return (
    <FormProvider init={tax} editingInit={false}>
      <TaxDetailsControl tax={tax} onSave={onSave} />
    </FormProvider>
  )
}

export default TaxDetails;
