import React, { useCallback } from 'react';

import { Product, Assembly } from '#types';

import useOptions from '#hooks/useOptions';

import Section from '#components/dashboard/Section';
import Button from '#materials/Button';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content;

interface AddAssemblyProps {
  product : Product;
}

function AddAssembly({ product } : AddAssemblyProps) {
  const { createProductAssembly } = useOptions();

  const handleCreateAssembly = useCallback(() => {
    if (!product.id) return;
    const newAssembly : Assembly = {
      id : undefined,
      name : 'New Assembly',
      complimentary : false,
      cumulative : false,
      collectionIds : [],
      productIds : [product.id],
    };

    createProductAssembly(product, newAssembly);
  }, [product, createProductAssembly]);

  return (
    <Section title={"Add Options"}>
      <Button onClick={handleCreateAssembly}>
        { localize(localeContentKeys.assemblies.createAssembly.addAssembly) }
      </Button>
    </Section>
  );
}

export default AddAssembly;
