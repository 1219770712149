import React, { useCallback, useState } from 'react';

import { TimeSlot } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useTimeSlots from '#hooks/useTimeSlots';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import TimeSlotForm from '#components/timeslots/TimeSlotForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.timeSlots.timeSlotDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.timeSlots;

interface TimeSlotDetailsProps {
  timeSlot : TimeSlot;
}

function TimeSlotDetailsControl({ timeSlot : init } : TimeSlotDetailsProps) {
  const {
    state : timeSlot,
    reset,
    editing,
    setEditing,
  } = useFormContext<TimeSlot>();

  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const { refreshTimeSlot, updateTimeSlot, deleteTimeSlot } = useTimeSlots();

  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!timeSlot || !timeSlot.id) return;
    refreshTimeSlot(timeSlot.id);
  }, [timeSlot, refreshTimeSlot]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!timeSlot) return;
    const updatedTimeSlot = await updateTimeSlot(timeSlot);
    if (updatedTimeSlot) setEditing(false);
  }, [timeSlot, updateTimeSlot, setEditing]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleConfirmDelete = useCallback(async () => {
    if (!timeSlot) return;
    const success = await deleteTimeSlot(timeSlot);
    if (success) {
      createNotification({
        key : 'delete-time-slot-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.accessTime} />),
        colour : settings.colours.alert.primary,
      });
      navigate('/time-slots');
    } else {
      createNotification({
        key : 'delete-time-slot-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.accessTime} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [timeSlot, deleteTimeSlot, navigate, createNotification]);

  return (
    <>
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button
                onClick={handleConfirmDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (timeSlot && editing)
        ? <>
          <Button
            onClick={handleSave}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleRefresh}
            disabled={!!deleting}
          >
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button
            onClick={handleEdit}
            disabled={!!deleting}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={!!deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <TimeSlotForm timeSlot={timeSlot || init} enableStart={false} />
    </>
  );
}

function TimeSlotDetails({ timeSlot } : TimeSlotDetailsProps) {
  return (
    <FormProvider
      init={timeSlot}
      editingInit={false}
    >
      <TimeSlotDetailsControl timeSlot={timeSlot} />
    </FormProvider>
  );
}

export default TimeSlotDetails;
