import React, { useCallback, useEffect, useState } from 'react';

import { Subtotal, DraftOrder } from '#types';

import useTaxes from '#hooks/useTaxes';

import { settings } from '#materials';
import Table from '#materials/Table';
import { TableCell } from '#materials/TableCell';

import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderTotals;

const TABLE_KEYS = {
  'label' : 'label' as const,
  'amount' : 'amount' as const,
};
type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
const defaultTableKeys = Object.values(TABLE_KEYS);

const ROOT_LABLES = [
  'subtotal',
  'total',
];

interface OrderTotalsProps {
  order : DraftOrder;
  tableKeys? : TableKey[];
}

function OrderTotals({
  order,
  tableKeys = defaultTableKeys,
} : OrderTotalsProps) {
  const { taxes } = useTaxes();

  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const generateRows = useCallback(() => {
    function generate(subtotal : Subtotal, key : TableKey) {
      switch (key) {
        case TABLE_KEYS.label:
          const tax = subtotal.taxId ? taxes?.[subtotal.taxId] : null;
          return (<TableCell key={key}>
            { (ROOT_LABLES.includes(subtotal.key) ? '' : '\t') + (tax
              ? tax.name
              : localize((localeContentKeys as any)[subtotal.key]))
            }
          </TableCell>);
        case TABLE_KEYS.amount:
          return (
            <TableCell
              key={key}
              width={settings.dimensions.xsmall}
              alignment={settings.alignments.right}
            >
              { formatCurrency(subtotal.total) }
            </TableCell>
          );
        default:
          return <TableCell key={key} />;
      }
    }

    setRows(order.totals.map((subtotal) => (
      <React.Fragment key={subtotal.key}>
        { tableKeys.map((key) => generate(subtotal, key)) }
      </React.Fragment>
    )));
  }, [order, tableKeys, taxes]);

  useEffect(() => { generateRows(); }, [generateRows]);

  return (
    <Table
      head={null}
      rows={rows}
      width={settings.dimensions.half}
      alignment={settings.alignments.right}
    />
  );
}

export default OrderTotals;
