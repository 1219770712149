import React, { useCallback, useEffect, useState } from 'react';

import {
  LineItem,
  Assembly,
  Product,
  isSelection,
  Selection,
  DraftOrder,
} from '#types';

import useOptions from '#hooks/useOptions';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import {
  CellElement,
  TableTextInputCell,
  TableSelectCell,
  TableCell,
} from '#materials/TableCell';

import { listRecords } from '#utils/data';
import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.selections;

export const SELECTION_TABLE_KEYS = {
  id : 'id',
  selection : 'selection',
  assembly : 'assembly',
  product : 'product',
  quantity : 'quantity',
  unit : 'unit',
  price : 'price',
  actions : 'actions',
} as const;
export type SelectionTableKey =
  typeof SELECTION_TABLE_KEYS[keyof typeof SELECTION_TABLE_KEYS];
export const defaultSelectionTableKeys = Object.values(SELECTION_TABLE_KEYS);

function generateAssemblyLabel(assembly : Assembly) { return assembly.name };
function generateProductLabel(product : Product) { return product.name };

function generateAssemblyKey(assembly : Assembly) {
  return `${assembly.id}`
}
function generateProductKey(product : Product) {
  return `${product.id}`
}

interface SelectionRowProps {
  lineItem : LineItem;
  selection : Selection;
  order? : DraftOrder;
  showFulfilmment? : boolean;
  disabled? : boolean;
  tableKeys? : SelectionTableKey[];
  setSelection? : (selection : Selection) => void;
  generateActions? : (selection : Selection) => CellElement;
}

function SelectionRow({
  lineItem,
  selection,
  order,
  showFulfilmment,
  disabled = false,
  tableKeys = defaultSelectionTableKeys,
  setSelection,
  generateActions,
} : SelectionRowProps) {
  const {
    assemblies : allAssemblies,
    getProductAssemblies,
    getAssemblyCollections,
    getCollectionProducts,
    calculateSelectionPrice,
  } = useOptions();
  const { products : allProducts } = useProducts();
  const fulfilment = order?.order
    ? Object.values(order?.order?.fulfilments).find(
      f => f.id && selection.fulfilmentIds.includes(f.id)
    )
    : null;
  const lineItemProduct = listRecords(allProducts).find(
    product => product.id === lineItem.productId
  );

  const [assemblies, setAssemblies] = useState<Assembly[]>(lineItemProduct
    ? getProductAssemblies(lineItemProduct)
    : [],
  )
  const [assembly, setAssembly] = useState<Assembly | null>(
    listRecords(allAssemblies).find(a => a.id === selection?.assemblyId) ?? null
  );
  const [products, setProducts] = useState<Product[]>(assembly
      ? getAssemblyCollections(assembly).map(getCollectionProducts).reduce(
        (acc, products) => [...acc, ...products],
        [],
      ) : listRecords(allProducts)
  );
  const [product, setProduct] = useState<Product | null>(
    listRecords(allProducts).find(p => p.id === selection?.productId) ?? null
  );

  const getAssemblyProducts = useCallback((assembly : Assembly) => {
    const collections = getAssemblyCollections(assembly);
    setProducts(collections.map(getCollectionProducts).reduce(
      (acc, products) => [...acc, ...products],
      [],
    ));
  }, [getAssemblyCollections, getCollectionProducts]);

  const handleProduct = useCallback((product : Product) => {
    if (!product || !product.id) return;
    setProduct(product);
    if (isSelection(selection)) {
      setSelection?.({ ...selection, productId : product.id });
    }
  }, [selection, setSelection]);

  const handleAssembly = useCallback(async (assembly : Assembly) => {
    if (!assembly || !assembly.id) return;
    setAssembly(assembly);
    if (isSelection(selection)) {
      setSelection?.({ ...selection, assemblyId : assembly.id });
    }
    getAssemblyProducts(assembly);
  }, [selection, setSelection, getAssemblyProducts]);

  const handleQuantity = useCallback((quantity : number | null) => {
    if (isSelection(selection)) {
      setSelection?.({ ...selection, quantity : quantity ?? 1 });
    }
  }, [selection, setSelection]);

  const rowGenerator = useCallback(() => {
    const greyOut = showFulfilmment && !fulfilment;
    const price = fulfilment?.unitPrice ?? calculateSelectionPrice(selection);

    return tableKeys.map((key) => {
      switch (key) {
        case SELECTION_TABLE_KEYS.id: return (
          <TableCell key={key} faded={greyOut}>
            { fulfilment?.id
              ? `#${selection?.id}-${fulfilment?.id}`
              : `#${selection?.id}`
            }
          </TableCell>
        );
        case SELECTION_TABLE_KEYS.assembly: return (
          disabled
            ? (
              <TableCell
                key={key}
                faded={greyOut}
                width={settings.dimensions.small}
              >
                { assembly?.name
                  ?? localize(localeTableKeys.defaults.assembly) }
              </TableCell>
            ) : (
              <TableSelectCell
                key={key}
                label={localize(localeTableKeys.headings.assembly)}
                selected={assembly}
                options={assemblies || []}
                onChange={handleAssembly}
                disabled={disabled}
                labelGenerator={assembly => generateAssemblyLabel(assembly)}
                keyGenerator={generateAssemblyKey}
                width={settings.dimensions.medium}
              />
            )
        );
        case SELECTION_TABLE_KEYS.product: return (
          disabled
            ? (
              <TableCell
                key={key}
                faded={greyOut}
                width={settings.dimensions.medium}
              >
                { product?.name ?? localize(localeTableKeys.defaults.product) }
              </TableCell>
            ) : (
              <TableSelectCell
                key={key}
                label={localize(localeTableKeys.headings.product)}
                selected={product}
                options={products || []}
                onChange={handleProduct}
                labelGenerator={product => String(generateProductLabel(product))}
                keyGenerator={generateProductKey}
                width={settings.dimensions.large}
                disabled={disabled}
              />
            )
        );
        case SELECTION_TABLE_KEYS.quantity: return (
          <TableTextInputCell
            key={key}
            id={`${selection?.id}-quantity`}
            label={localize(localeTableKeys.headings.quantity)}
            value={selection?.quantity ?? null}
            onChange={handleQuantity}
            inputType={settings.inputType.number}
            inputFormat={settings.inputFormat.int}
            disabled={disabled}
            faded={greyOut}
            width={settings.dimensions.small}
          />
        );
        case SELECTION_TABLE_KEYS.unit:
          return (<TableCell key={key} faded={greyOut}>
            { price ? `(× ${formatCurrency(price)})` : ''}
          </TableCell>)
        case SELECTION_TABLE_KEYS.price:
          const qty = selection?.quantity;
          const total = price
            ? {
              ...price,
              amount : price.amount * qty,
              calculatedValue : price.calculatedValue * qty,
            }
            : null
          return (
            <TableCell key={key} faded={greyOut}>
              { total ? formatCurrency(total) : '' }
            </TableCell>
          );
        case SELECTION_TABLE_KEYS.actions : return (
          <React.Fragment key={key}>
            { selection && generateActions?.(selection) }
          </React.Fragment>
        )
        default: return <TableCell key={key}/>;
      }
    });
  }, [
    disabled,
    selection,
    tableKeys,
    showFulfilmment,
    product,
    fulfilment,
    assembly,
    assemblies,
    products,
    handleAssembly,
    handleProduct,
    handleQuantity,
    generateActions,
    calculateSelectionPrice,
  ]);

  const [row, setRow] = useState<React.ReactNode[]>(rowGenerator());

  useEffect(() => {
    const lineItemProduct = listRecords(allProducts).find(
      product => product.id === lineItem.productId
    );
    setAssemblies(lineItemProduct
      ? getProductAssemblies(lineItemProduct)
      : [],
    )
  }, [lineItem, allProducts, getProductAssemblies]);

  useEffect(() => { setRow(rowGenerator()) }, [rowGenerator]);

  return row;
}

export default SelectionRow;
