import React, { useCallback, useEffect, useState } from 'react';

import { TimeSlot, Window } from '#types';

import useForm from '#hooks/useForm';
import useScheduling from '#hooks/useTimeSlots';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Form from '#materials/Form';
import DateTimePicker from '#materials/DateTimePicker';

import { timeScales, roundDateTime } from '#utils/date';
import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.windows;

interface WindowFormProps {
  timeSlot : TimeSlot;
}

function WindowForm({ timeSlot } : WindowFormProps) {
  const { state, dispatch, editing, setValid } = useForm<Window>();
  const {
    calculateMinStep,
    calculateDate,
    calculateDuration,
    findNextIteration,
    getIteration,
    validateTimeSlotDay,
    validateTimeSlotDateTime,
  } = useScheduling();

  const [selectedStartDate, setSlectedStartDate] = useState<Date>(
    calculateDate(
      timeSlot,
      findNextIteration(timeSlot, new Date()) ?? 0,
    ),
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(
    new Date(selectedStartDate.getTime() + timeSlot.duration)
  );
  const [newEndDate, setNewEndDate] = useState<Date>(selectedEndDate);
  const [untilStartDate, setUntilStartDate] = useState<Date | null>(
    selectedStartDate
  );
  const [untilEndDate, setUntilEndDate] = useState<Date | null>(
    selectedEndDate
  );

  const [newEndTimeValid, setNewEndTimeValid] = useState(true);

  const handleSelectedStartDate = useCallback((date : Date | null) => {
    if (date === null) return;
    setSlectedStartDate(date);
    setSelectedEndDate(new Date(date.getTime() + timeSlot.duration));

    if (!state) return;
    const fromIteration = getIteration(timeSlot, date);
    if (fromIteration === undefined) return;

    dispatch({
      fromIteration,
      toIteration: fromIteration,
      start: date,
      duration: calculateDuration(timeSlot, fromIteration),
    });
  }, [
    timeSlot,
    state,
    setSlectedStartDate,
    dispatch,
    calculateDuration,
    getIteration,
  ]);

  const handleNewStartDate = useCallback((date : Date | null) => {
    if (!state || !date) return;
    dispatch({
      toIteration: state.fromIteration,
      start: date,
      duration: calculateDuration(timeSlot, state.fromIteration),
    });
  }, [timeSlot, state, dispatch, calculateDuration]);

  const handleNewEndDate = useCallback((date : Date | null) => {
    if (!state || !date) return;
    setNewEndDate(date);

    dispatch({
      duration: date.getTime() - state.start.getTime(),
    });
  }, [state, dispatch]);

  const handleUntilStartDate = useCallback((date : Date | null) => {
    if (!state) return;

    dispatch({
      toIteration : date !== null
        ? (Math.round((date.getTime() - state.start.getTime()) /
          (timeSlot.scale * timeSlot.period))) + state.fromIteration
        : null,
    });
  }, [timeSlot, state, dispatch]);

  const validateSelectedStartDate = useCallback((date : Date) => {
    return (date.getTime() >= new Date().getTime() &&
      validateTimeSlotDay(timeSlot, date));
  }, [timeSlot, validateTimeSlotDay]);

  const validateSelectedStartTime = useCallback((date : Date) => {
    return validateTimeSlotDateTime(timeSlot, date);
  }, [timeSlot, validateTimeSlotDateTime]);

  const validateNewStartDate = useCallback((date : Date) => {
    if (!state) return false;
    const prevDate = calculateDate(timeSlot, state.fromIteration - 1);
    const nextDate = calculateDate(timeSlot, state.fromIteration + 1);
    return ((roundDateTime(date, timeScales.day).getTime() >
      roundDateTime(prevDate, timeScales.day).getTime()) &&
        (roundDateTime(date, timeScales.day).getTime() <
          roundDateTime(nextDate, timeScales.day).getTime()));
  }, [timeSlot, state, calculateDate]);

  const validateNewStartTime = useCallback((date : Date) => {
    if (!state) return false;
    const prevDate = calculateDate(timeSlot, state.fromIteration - 1);
    const nextDate = calculateDate(timeSlot, state.fromIteration + 1);
    return (date.getTime() > prevDate.getTime() &&
      date.getTime() < nextDate.getTime());
  }, [timeSlot, state, calculateDate]);

  const validateNewEndDate = useCallback((date : Date) => {
    if (!state) return false;
    return((roundDateTime(date, timeScales.day).getTime() >=
      roundDateTime(state.start, timeScales.day).getTime()));
  }, [state]);

  const validateNewEndTime = useCallback((date : Date) => {
    if (!state) return false;
    return (date.getTime() >= state.start.getTime());
  }, [state]);

  const validateUntilStartDate = useCallback((date : Date) => {
    if (!state) return false;
    if (date.getTime() < roundDateTime(state.start, timeScales.day).getTime()) {
      return false;
    }

    const roundedStart = roundDateTime(state.start, timeScales.day);
    const roundedEnd = roundDateTime(date, timeScales.day);
    return (roundedStart.getTime() - roundedEnd.getTime()) %
        (timeSlot.scale * timeSlot.period) === 0;
  }, [timeSlot, state]);

  const validateUntilEndTime = useCallback((date : Date) => {
    if (!state) return false;
    return (date.getTime() - state.start.getTime()) %
        (timeSlot.scale * timeSlot.period) === 0;
  }, [timeSlot, state]);

  const doNothing = useCallback(() => {}, []);

  useEffect(() => {
    if (!state) return;
    if (getIteration(timeSlot, selectedStartDate) !== state.fromIteration) {
      setSlectedStartDate(calculateDate(timeSlot, state.fromIteration));
    }
  }, [
    timeSlot,
    state,
    selectedStartDate,
    setSlectedStartDate,
    calculateDate,
    getIteration,
  ]);

  useEffect(() => {
    if (!state) return;
    const date = calculateDate(timeSlot, state.fromIteration);
    const dur = calculateDuration(timeSlot, state.fromIteration);
    if (selectedEndDate.getTime() !== date.getTime() + dur) {
      setSelectedEndDate(new Date(date.getTime() + dur));
    }
  }, [
    timeSlot,
    state,
    selectedEndDate,
    setSelectedEndDate,
    calculateDate,
    calculateDuration,
  ]);

  useEffect(() => {
    if (!state) return;
    if (newEndDate.getTime() !== state.start.getTime() + state.duration) {
      setNewEndDate(new Date(state.start.getTime() + state.duration));
    }
  }, [timeSlot, state, newEndDate, setNewEndDate]);

  useEffect(() => {
    if (!state) return;
    if (state.toIteration === null) {
      if (untilStartDate !== null) setUntilStartDate(null);
      return;
    }
    if (
      untilStartDate?.getTime() !==
        calculateDate(timeSlot, state.toIteration, [state]).getTime()
    ) {
      setUntilStartDate(calculateDate(timeSlot, state.toIteration, [state]));
    }
  }, [
    timeSlot,
    state,
    untilStartDate,
    setUntilStartDate,
    calculateDate,
    getIteration,
  ]);

  useEffect(() => {
    if (!state) return;
    if (state.toIteration === null) {
      if (untilEndDate !== null) setUntilEndDate(null);
      return;
    }
    if (
      untilEndDate?.getTime() !==
        calculateDate(timeSlot, state.toIteration, [state]).getTime() +
          calculateDuration(timeSlot, state.toIteration, [state])
    ) {
      setUntilEndDate(
        new Date(
          calculateDate(timeSlot, state.toIteration, [state]).getTime() +
            calculateDuration(timeSlot, state.toIteration, [state])
        )
      );
    }
  }, [
    timeSlot,
    state,
    untilEndDate,
    setUntilEndDate,
    calculateDate,
    calculateDuration,
    getIteration,
  ]);

  useEffect(() => {
    if (!state) return;
    if (validateNewEndTime(newEndDate) !== newEndTimeValid) {
      setNewEndTimeValid(validateNewEndTime(newEndDate));
    }
  }, [
    timeSlot,
    state,
    newEndDate,
    newEndTimeValid,
    setNewEndTimeValid,
    validateNewEndDate,
    validateNewEndTime,
  ]);

  useEffect(() => {
    setValid(
      !!state &&
        validateSelectedStartDate(selectedStartDate) &&
        validateSelectedStartTime(selectedStartDate) &&
        validateNewStartDate(state.start) &&
        validateNewStartTime(state.start) &&
        validateNewEndDate(newEndDate) &&
        validateNewEndTime(newEndDate) &&
        (untilStartDate === null ||
          (validateUntilStartDate(untilStartDate) &&
            validateUntilEndTime(untilStartDate))) &&
        (calculateDate(timeSlot, state.fromIteration, [state]).getTime() !==
          calculateDate(timeSlot, state.fromIteration).getTime() ||
            calculateDuration(timeSlot, state.fromIteration, [state]) !==
              calculateDuration(timeSlot, state.fromIteration))
    );
  }, [
    timeSlot,
    state,
    selectedStartDate,
    newEndDate,
    untilStartDate,
    validateSelectedStartDate,
    validateSelectedStartTime,
    validateNewStartDate,
    validateNewStartTime,
    validateNewEndDate,
    validateNewEndTime,
    validateUntilStartDate,
    validateUntilEndTime,
    calculateDate,
    calculateDuration,
    getIteration,
    setValid,
  ]);

  const minStep = calculateMinStep(timeSlot, state ? [state] : undefined);
  if (minStep < 6e4) {
    return (<></>);
  }

  return (
    <>
      <Form>
        <DateTimePicker
          dateLabel={localize(localKeys.labels.selectDateTime)}
          timeLabel=""
          value={selectedStartDate}
          setValue={handleSelectedStartDate}
          minuteStep={minStep / 6e4}
          validateDate={validateSelectedStartDate}
          validateTime={validateSelectedStartTime}
          hideErrors
          disabled={!editing}
          width={settings.dimensions.half}
        />
        <DateTimePicker
          dateLabel={localize(localKeys.labels.selectDateTimeEnd)}
          timeLabel=""
          value={selectedEndDate}
          setValue={doNothing}
          disabled
          width={settings.dimensions.half}
        />
      </Form>
      <Segment
        title={localize(localKeys.segmemts.reschedule)}
      >
        <Form>
          <DateTimePicker
            dateLabel={localize(localKeys.labels.rescheduledDateTime)}
            timeLabel=""
            value={state?.start ?? null}
            setValue={handleNewStartDate}
            validateDate={validateNewStartDate}
            validateTime={validateNewStartTime}
            hideErrors
            disabled={!editing}
            width={settings.dimensions.half}
          />
          <DateTimePicker
            dateLabel={localize(localKeys.labels.untilDateTime)}
            timeLabel=""
            value={untilStartDate}
            setValue={handleUntilStartDate}
            minuteStep={minStep / 6e4}
            validateDate={validateUntilStartDate}
            validateTime={validateUntilEndTime}
            allowClear
            hideErrors
            disabled={!editing}
            width={settings.dimensions.half}
          />
          <DateTimePicker
            dateLabel={localize(localKeys.labels.rescheduledDateTimeEnd)}
            timeLabel=""
            value={newEndDate}
            setValue={handleNewEndDate}
            validateDate={validateNewEndDate}
            validateTime={validateNewEndTime}
            hideErrors={newEndTimeValid}
            disabled={!editing}
            width={settings.dimensions.half}
          />
        </Form>
      </Segment>
    </>
  );
}

export default WindowForm;
