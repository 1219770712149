import React, { useCallback, useState } from 'react';

import { TimeSlot, Window } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import useTimeSlots from '#hooks/useTimeSlots';

import { settings } from '#materials';
import Button from '#materials/Button';

import WindowForm from '#components/timeslots/WindowForm';

import locale, { localize } from '#utils/locale';

const localeButtonKeys = locale.keys.buttons;

interface WindowDetailsProps {
  timeSlot : TimeSlot;
}

function WindowDetailsControl({ timeSlot } : WindowDetailsProps) {
  const {
    state : window,
    reset,
    valid,
  } = useFormContext<Window>();
  const { createWindow } = useTimeSlots();

  const handleSave = useCallback(async () => {
    if (!window) return;
    await createWindow(window);
  }, [window, createWindow]);

  return (
    <>
      <WindowForm timeSlot={timeSlot} />
      <Button
        onClick={handleSave}
        disabled={!window || !valid}
      >
        {localize(localeButtonKeys.save)}
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        {localize(localeButtonKeys.reset)}
      </Button>
    </>
  );
}

function WindowDetails({ timeSlot } : WindowDetailsProps) {
  const {
    findNextIteration,
    calculateDate,
    calculateDuration,
  } = useTimeSlots();

  const now = new Date();
  const nextiteration = findNextIteration(timeSlot, now) ?? 0;
  const start = calculateDate(timeSlot ,nextiteration);
  const duration = calculateDuration(timeSlot, nextiteration);
  const [defaultWindow] = useState<Window>({
    start : start,
    fromIteration : nextiteration,
    toIteration : nextiteration,
    timeSlotId : timeSlot.id ?? -1,
    duration : duration,
    division : timeSlot.division,
  });

  return (
    <FormProvider init={defaultWindow} editing={true}>
      <WindowDetailsControl timeSlot={timeSlot} />
    </FormProvider>
  );
}

export default WindowDetails;
