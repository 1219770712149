import React, { useCallback, useEffect, useState } from 'react';

import { Product } from '#types';

import useProducts from '#hooks/useProducts';

import Form from '#materials/Form';
import TextInput from '#materials/TextInput';
import { CellElement } from '#materials/TableCell';

import ProductTable from '#components/products/ProductTable';

import { listRecords } from '#utils/data';

interface ProductSearchProps {
  pageCount? : number;
  filter? : (product : Product) => boolean;
  generateActions? (product : Product) : CellElement
}

function ProductSearch({
  pageCount = 20,
  filter,
  generateActions,
} : ProductSearchProps) {
  const { retrieveProducts, searchProducts } = useProducts();
  const [query, setQuery] = useState('');
  const [products, setProducts] = useState<Product[]>([]);

  const retireveAll = useCallback(async () => {
    const productIndex = await retrieveProducts();
    if (!productIndex) return;

    let newProducts = listRecords(productIndex);
    if (filter) newProducts = newProducts.filter(filter);

    if (newProducts) setProducts(newProducts);
  }, [filter, retrieveProducts, setProducts]);

  useEffect(() => {
    if (!query) {
      retireveAll();
    } else {
      const results = searchProducts(query);
      if (results !== null) {
        let productResults = Object.values(results.results).map(
          result => result.record
        );
        if (filter) productResults = productResults.filter(filter);
        setProducts(productResults);
      };
    }
  }, [query, filter, retireveAll, searchProducts, setProducts]);

  return (
    <>
      <Form>
        <TextInput
          id="product-search-query"
          label="Search"
          value={query}
          onChange={setQuery}
        />
      </Form>
      <ProductTable
        products={products}
        generateActions={generateActions}
        pageCount={pageCount}
      />
    </>
  );
}

export default ProductSearch;
