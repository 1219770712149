import React, { useCallback, useEffect, useState } from 'react';

import { Product, Tag, Supplier } from '#types';

import useNavigation from '#hooks/useNavigation';
import useSuppliers from '#hooks/useSuppliers';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import { TableActionCell, Action } from '#materials/TableCell';

import ProductTags from '#components/products/ProductTags';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productSuppliers;
const localeTableKeys = locale.keys.tables.suppliers;

interface ProductSuppliersProps {
  product : Product;
}

function ProductSuppliers({ product } : ProductSuppliersProps) {
  const { navigate } = useNavigation();
  const { retrieveSuppliers, refreshSupplier } = useSuppliers();
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const retrieve = useCallback(async () => {
    const newSuppliers = await retrieveSuppliers();
    if (newSuppliers) setSuppliers(listRecords(newSuppliers));
  }, [retrieveSuppliers, setSuppliers]);

  const refresh = useCallback(async (supplier : Tag) => {
    if (!supplier.id) return;
    refreshSupplier(supplier.id);
  }, [refreshSupplier]);

  const view = useCallback((supplier : Tag) => () => {
    navigate(`/suppliers/${supplier.id}`);
  }, [navigate]);

  const generateActions = useCallback((supplier : Tag) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          onClick={view(supplier)}
        >
          <Icon icon={settings.svgIcons.agriculture} />
        </Action>
      </TableActionCell>
    )
  }, [view]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <ProductTags
      product={product}
      tags={suppliers}
      generateActions={generateActions}
      onUpdate={refresh}
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
      notAssigned={localize(localeContentKeys.notAssigned)}
      notFound={localize(localeContentKeys.notFound)}
    />
  );
}

export default ProductSuppliers;
