import React, { useCallback } from 'react';

import useForm from '#hooks/useForm';

import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localeFormKeys = locale.keys.forms.auth;

export interface PasswordConfirmation {
  password : string;
  confirm : string;
}

export function validateConfirmation(
  { password, confirm } : PasswordConfirmation
) {
  if (password !== confirm) {
    return { confirm : 'Passwords do not match' };
  }
  return null;
}

interface PasswordFormProps {
  disabled? : boolean;
}

function PasswordForm({ disabled } : PasswordFormProps) {
  const { state, dispatch, errors } = useForm<PasswordConfirmation>({
    validators : [validateConfirmation],
  });

  const setPassword = useCallback((password : string) => {
    dispatch({ password })
  }, [dispatch]);

  const setConfirm = useCallback((confirm : string) => {
    dispatch({ confirm })
  }, [dispatch]);

  return (
    <Form>
      <TextInput
        id='password'
        label={localize(localeFormKeys.labels.password)}
        value={state ? state.password : ''}
        onChange={setPassword}
        disabled={disabled}
        password
      />
      <TextInput
        id='confirm'
        label={localize(localeFormKeys.labels.confirmPassword)}
        value={state ? state.confirm : ''}
        onChange={setConfirm}
        errors={errors?.confirm}
        password
        disabled={disabled}
      />
    </Form>
  );
}

export default PasswordForm;
