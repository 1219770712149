import React, { useCallback, useState } from 'react';

import { Adjustment } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useAdjustments from '#hooks/useAdjustments';

import Button from '#materials/Button';
import { settings } from '#materials';
import Banner from '#materials/Banner';
import Icon from '#materials/Icon';

import Section from '#components/dashboard/Section';
import AdjustmentForm from '#components/adjustments/AdjustmentForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.adjustments.adjustmentDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.adjustments;

interface AdjustmentDetailsProps {
  adjustment: Adjustment;
  onSave?: (adjustment: Adjustment) => void;
}

const AdjustmentDetailsControl = ({
  adjustment : init,
  onSave,
} : AdjustmentDetailsProps) => {
  const { createNotification } = useNotifications();
  const { navigate } = useNavigation();
  const {
    refreshAdjustment,
    updateAdjustment,
    deleteAdjustment,
  } = useAdjustments();
  const {
    state : adjustment,
    editing,
    setEditing,
    reset,
  } = useFormContext<Adjustment>();

  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if(!adjustment || !adjustment.id) return;
    refreshAdjustment(adjustment.id);
  }, [refreshAdjustment, adjustment])

  const handleSaveEdit = useCallback(async () => {
    if (!adjustment) return
    const updatedAdjustment = await updateAdjustment(adjustment)

    if (updatedAdjustment) {
      createNotification({
        key : 'update-adjustment-success',
        message : localize(localeNotificationKeys.update.success),
        icon : <Icon icon={settings.svgIcons.loyalty} />,
        colour : settings.colours.alert.primary,
      })
      if(onSave) onSave(updatedAdjustment)
      setEditing(false)
    } else {
      createNotification({
        key : 'update-adjustment-error',
        message : localize(localeNotificationKeys.update.error),
        icon : <Icon icon={settings.svgIcons.loyalty} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [adjustment, onSave, updateAdjustment, setEditing, createNotification])

  const handleInitEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing])

  const handleCancelEdit = useCallback(() => {
    reset();
    setEditing(false);
  }, [setEditing, reset])

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [])

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [])

  const handleDeleteAdjustment = useCallback(async () => {
		if(!adjustment) return;

		const success = await deleteAdjustment(adjustment)
		if(success) {
			createNotification({
				key : 'delete-adjustment-success',
				message : localize(localeNotificationKeys.delete.success),
				icon : <Icon icon={settings.svgIcons.loyalty} />,
				colour : settings.colours.alert.primary,
			});
      navigate('/adjustments')
		} else {
			createNotification({
				key : 'delete-adjustment-error',
				message : localize(localeNotificationKeys.delete.error),
				icon : <Icon icon={settings.svgIcons.loyalty} />,
				colour : settings.colours.alert.alert,
			});
		}
	}, [createNotification, deleteAdjustment, adjustment, navigate])

  return (
    <Section
      title={`${localize(localeContentKeys.title)} (#${adjustment?.id})`}
      text={localize(localeContentKeys.body)}
    >
      { deleting && (
          <Banner
            icon={ <Icon icon={settings.svgIcons.delete} /> }
            actions={(
              <>
                <Button
                  onClick={handleDeleteAdjustment}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
          )}
          colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.confirmDelete)}
          </Banner>
        )
      }
      { (adjustment && editing)
        ?  (
        <>
            <Button
              onClick={handleSaveEdit}
            >
              { localize(localeButtonKeys.save) }
            </Button>
            <Button
              onClick={handleCancelEdit}
              colour={settings.colours.button.alert}
            >
              { localize(localeButtonKeys.cancel) }
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={handleRefresh}
            >
              { localize(localeButtonKeys.refresh) }
            </Button>
            <Button
              onClick={handleInitEdit}
            >
              { localize(localeButtonKeys.edit) }
            </Button>
            <Button
              onClick={handleInitDelete}
              disabled={deleting}
              colour={settings.colours.button.alert}
            >
              { localize(localeButtonKeys.delete) }
            </Button>
          </>
        )
      }
      <AdjustmentForm adjustment={adjustment || init} />
    </Section>
  );
}

const AdjustmentDetails = ({ adjustment, onSave } : AdjustmentDetailsProps) => {
  return (
    <FormProvider init={adjustment} editingInit={false}>
      <AdjustmentDetailsControl adjustment={adjustment} onSave={onSave} />
    </FormProvider>
  );
}

export default AdjustmentDetails;
