import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';

import { Dimension, Spacing, settings } from '#materials';
import FlexItem from '#materials/FlexItem';

interface SwitchProps {
  label? : string,
  checked : boolean;
  onChange : (checked : boolean) => void;
  disabled? : boolean;
  width? : Dimension;
  spacing? : Spacing;
}

function Switch({
  label,
  checked,
  onChange,
  disabled = false,
  width = settings.dimensions.full,
  spacing = settings.spacings.medium,
} : SwitchProps) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    },
    [onChange],
  );

  return (
    <FlexItem
      width={settings.dimensions.full}
      maxWidth={width}
    >
      <Box
        sx={{
          height : 56,
          mx : spacing !== settings.spacings.dense ? [1] : [0.4],
          my : spacing !== settings.spacings.dense ? [1] : [0.4],
        }}
      >
        <FormControlLabel
          label={label}
          control={
            <MuiSwitch
              disabled={disabled}
              checked={checked}
              onChange={handleChange}
              sx={{
                "& .MuiSwitch-switchBase" : {
                  color : 'primary.contrastText',
                },
                "& .MuiSwitch-switchBase.Mui-checked" : {
                  color : 'primary.main',
                  opacity : 1,
                },
              }}
            />
          }
          sx={{
            px : [2],
            pt : [1.1],
            "& .MuiFormControlLabel-label.Mui-disabled" : {
              color : 'text.primary',
            },
          }}
        />
      </Box>
    </FlexItem>
  );
}

export default Switch;
