import React, { useCallback, useEffect, useState } from 'react';

import {  Integration, ExternalProduct } from '#types';

import useIntegrations from '#hooks/useIntegrations';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import Text from '#materials/Text';
import Form from '#materials/Form';
import Button from '#materials/Button';
import TextInput from '#materials/TextInput';
import Select from '#materials/Select';
import { CellElement } from '#materials/TableCell';

import ExternalProductTable, {
  TABLE_KEYS,
} from '#components/products/ExternalProductTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeButtonsKeys = locale.keys.buttons;
const localeFormKeys = locale.keys.forms.productsExternal;
const localeTableKeys = locale.keys.tables.productIntegrations;

interface ExternalProductsSearchProps {
  filter? :
    (externalProducts : ExternalProduct[]) => ExternalProduct[];
  generateActions? : (product : ExternalProduct) => CellElement;
  setIntegration? : (integration : Integration | null) => void;
  onCancel? : () => void;
}

function ExternalProductsSearch({
  filter,
  generateActions = () => <></>,
  setIntegration : selectIntegration = () => {},
  onCancel,
} : ExternalProductsSearchProps) {
  const { retrieveChannels, retrieveIntegrations } = useIntegrations();
  const { retrieveExternalProducts } = useProducts();

  const [integrations, setIntegrations] = useState<Integration[] | null>(null);
  const [
    externalProducts,
    setExternalProducts,
  ] = useState<ExternalProduct[] | null>(null);
  const [integration, setIntegration] = useState<Integration | null>(null);
  const [query, setQuery] = useState<string>('');
  const [querying, setQuerying] = useState<boolean>(false);
  const [queried, setQueried] = useState<string>('');
  const [
    filteredProducts,
    setFilteredProducts,
  ] = useState<ExternalProduct[]>([]);

  const retrieve = useCallback(async () => {
    const channelsPromise = retrieveChannels();
    const integrationsPromise = retrieveIntegrations();

    const newChannels = await channelsPromise;
    if (!newChannels) return;
    const channel = listRecords(newChannels).find(
      (channel) => channel.name === 'products'
    ) ?? null
    if (!channel) return;

    const newIntegrations = await integrationsPromise;
    if (newIntegrations) setIntegrations(
      listRecords(newIntegrations).filter((integration) => {
        return channel?.id && integration.channelIds.includes(channel?.id);
      })
    );
  }, [
    retrieveChannels,
    retrieveIntegrations,
    setIntegrations,
  ]);

  const searchExternalProducts = useCallback(async () => {
    setQuerying(true);
    const ext = await retrieveExternalProducts(query);
    setQuerying(false);
    if (!ext) return;

    setQueried(query);
    setExternalProducts(Object.values(ext).map((e) => Object.values(e)).flat());
  }, [query, retrieveExternalProducts, setExternalProducts]);

  const handleIntegrationChange = useCallback((int : Integration | null) => {
    setIntegration(int);
    selectIntegration(int);
  }, [setIntegration, selectIntegration]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  useEffect(() => {
    if (!externalProducts) return;
    if (integration === null) {
      setFilteredProducts([]);
      return;
    }

    let filtered = externalProducts.filter((product) => {
      return product.integrationId === integration.id;
    });
    setFilteredProducts(filter ? filter(filtered) : filtered);
  }, [filter, externalProducts, integration, query, setFilteredProducts]);

  return (
    <>
      <Button
        onClick={searchExternalProducts}
        disabled={!integration || !query || querying || query === queried}
      >
        { localize(localeButtonsKeys.search) }
      </Button>
      { onCancel && (
        <Button
          onClick={onCancel}
          colour={settings.colours.button.alert}
        >
          { localize(localeButtonsKeys.close) }
        </Button>
      ) }
      <Form>
        <Select
          label={localize(localeFormKeys.labels.integration)}
          selected={integration}
          options={integrations ?? (integration ? [integration] : [])}
          onChange={handleIntegrationChange}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={(integration) => integration?.name ?? ''}
          width={settings.dimensions.half}
        />
        <TextInput
          id="integration-product-search-query"
          label={localize(localeFormKeys.labels.search)}
          value={query}
          onChange={setQuery}
          width={settings.dimensions.half}
        />
      </Form>
      { integration && (
        !!filteredProducts.length
          ? <ExternalProductTable
            externalProducts={filteredProducts}
            tableKeys={[
              TABLE_KEYS.externalId,
              TABLE_KEYS.name,
              TABLE_KEYS.sku,
              TABLE_KEYS.actions,
            ]}
            generateActions={generateActions}
          />
          : <Text>
              { localize(localeTableKeys.notFound) }
            </Text>
      ) }
    </>
  );
}

export default ExternalProductsSearch;
