import React from 'react';

import { PaymentToken } from '#types';

import Form from '#materials/Form';

import SquareForm from '#components/cards/SquareForm';

interface CardFormProps {
  onSubmit? : () => void;
  setTokenize : (tokenize : () => Promise<PaymentToken | null>) => void;
}

function CardForm({ onSubmit, ...props } : CardFormProps) {
  return (
    <Form onSubmit={onSubmit}>
      <SquareForm {...props} />
    </Form>
  );
}

export default CardForm;
