import React, { useCallback, useEffect, useState } from 'react';

import { Product, Tag, Category } from '#types';

import useNavigation from '#hooks/useNavigation';
import useCategories from '#hooks/useCategories';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import { TableActionCell, Action } from '#materials/TableCell';

import ProductTags from '#components/products/ProductTags';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productCategories;
const localeTableKeys = locale.keys.tables.categories

interface ProductCategoriesProps {
  product : Product;
}

function ProductCategories({ product } : ProductCategoriesProps) {
  const { navigate } = useNavigation();
  const { retrieveCategories, refreshCategory } = useCategories();
  const [categories, setCategories] = useState<Category[]>([]);

  const retrieve = useCallback(async () => {
    const newCategories = await retrieveCategories();
    if (newCategories) setCategories(listRecords(newCategories));
  }, [retrieveCategories, setCategories]);

  const refresh = useCallback(async (category : Tag) => {
    if (!category.id) return;
    refreshCategory(category.id);
  }, [refreshCategory]);

  const view = useCallback((category : Tag) => () => {
    navigate(`/categories/${category.id}`);
  }, [navigate]);

  const generateActions = useCallback((category : Tag) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          onClick={view(category)}
        >
          <Icon icon={settings.svgIcons.category} />
        </Action>
      </TableActionCell>
    )
  }, [view]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <ProductTags
      product={product}
      tags={categories}
      generateActions={generateActions}
      onUpdate={refresh}
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
      notAssigned={localize(localeContentKeys.notAssigned)}
      notFound={localize(localeContentKeys.notFound)}
    />
  );
}

export default ProductCategories;
