import React, { useCallback } from 'react';

import useForm from '#hooks/useForm';

import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localeFormKeys = locale.keys.forms.auth;

interface LoginFormData {
  username : string;
  password : string;
}

interface LoginFormProps {
  unauthorized? : boolean;
  onSubmit? : (data : LoginFormData) => void;
}

function LoginForm({ unauthorized = false, onSubmit } : LoginFormProps) {
  const { state, dispatch } = useForm<LoginFormData>();

  const handleSubmit = useCallback(() => {
    if (state && onSubmit) onSubmit(state);
  }, [onSubmit, state]);

  const setUsername = useCallback((username : string) => {
    dispatch({ username });
  }, [dispatch]);
  const setPassword = useCallback((password : string) => {
    dispatch({ password });
  }, [dispatch]);

  return (
    <Form onSubmit={handleSubmit}>
      <TextInput
        id='login-username'
        label={localize(localeFormKeys.labels.username)}
        autoComplete='username'
        value={state ? state.username : ''}
        onChange={setUsername}
        errors={unauthorized ?
          [localize(localeFormKeys.errors.unauthorized)]
          : []
        }
        showErrorText={false}
      />
      <TextInput
        id='login-password'
        label={localize(localeFormKeys.labels.password)}
        autoComplete='current-password'
        value={state ? state.password : ''}
        onChange={setPassword}
        password
        errors={unauthorized ?
          [localize(localeFormKeys.errors.unauthorized)]
          : []
        }
      />
    </Form>
  );
}

export default LoginForm;
