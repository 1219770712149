import React, { useCallback } from 'react';

import { Config } from '#types';

import useForm from '#hooks/useForm';
import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import {
  Action,
  CellElement,
  TableActionCell,
  TableTextInputCell,
  TableCell,
} from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.configs;

export const TABLE_KEYS = {
  id : 'id' as const,
  name : 'name' as const,
  value : 'value' as const,
  actions : 'actions' as const,
};

export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface ConfigRowProps {
  config : Config;
  tableKeys : TableKeys[];
  generateActions? : (config : Config) => CellElement;
  onSave? : () => Promise<void>;
  onCancel? : () => void;
}

function ConfigRow({
  config : fallback,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <TableCell />,
  onSave = async () => {},
  onCancel = () => {},
} : ConfigRowProps) {
  const { state, dispatch, reset, editing } = useForm<Config>();
  const config = state || fallback;

  const { updateConfig } = useIntegrations();

  const handleSave = useCallback(async () => {
    if (!state) return;
    await updateConfig(state);
    await onSave();
    reset();
  }, [state, reset, onSave, updateConfig]);

  const handleCancel = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);

  const setValue = useCallback((value : string) => {
    dispatch({ value });
  }, [dispatch]);

  function generateEditActions() {
    return (
      <TableActionCell>
        <Action
          label="Save Config"
          onClick={handleSave}
        >
          <Icon icon={settings.svgIcons.check} />
        </Action>
        <Action
          label="Cancel"
          onClick={handleCancel}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.clear} />
        </Action>
      </TableActionCell>
    );
  }

  function rowGenerator(key : string) {
    switch (key) {
      case TABLE_KEYS.id: return (
        <TableCell>{ `# ${config.id}` }</TableCell>
      );
      case TABLE_KEYS.name: return (
        <TableTextInputCell
          id={`${config.id}-name`}
          label={localize(localeTableKeys.headings.name)}
          value={config.name}
          onChange={setName}
          disabled={!editing}
        />
      );
      case TABLE_KEYS.value: return (
        <TableTextInputCell
          id={`${config.id}-value`}
          label={localize(localeTableKeys.headings.value)}
          value={config.value}
          onChange={setValue}
          disabled={!editing}
        />
      );
      case TABLE_KEYS.actions:
        return !editing
          ? generateActions(config)
          : generateEditActions();
      default: return <TableCell></TableCell>;
    }
  }

  return (
    <>
      { tableKeys.map(key =>
        <React.Fragment key={`config-${config.id}-${key}`}>
          { rowGenerator(key) }
        </React.Fragment>
      ) }
    </>
  );
}

export default ConfigRow;
