import React, { useEffect } from 'react';

import AppRoutes from 'AppRoutes';
import Providers from 'Providers';
import { checkCache } from 'version';

import './App.css';

function App() {
  useEffect(() => { checkCache(); }, [])

  return (
    <div className='App'>
      <React.StrictMode>
        <Providers>
          <AppRoutes />
        </Providers>
      </React.StrictMode>
    </div>
  );
}

export default App;
