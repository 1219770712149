import React, { useCallback } from 'react';

import { RevenueCentre } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.revenueCentres;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface RevenueCentreTableProps {
  revenueCentres : RevenueCentre[];
  tableKeys? : TableKeys[];
  generateActions? : (revenueCentre : RevenueCentre) => CellElement;
}

function RevenueCentreTable({
  revenueCentres,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <></>,
} : RevenueCentreTableProps) {

  const generateHead = useCallback(() => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `revenue-centre-table-head-${key}`
        switch (key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.id) }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.name) }
            </TableCell>;
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [tableKeys]);

  const generateRow = useCallback((revenueCentre : RevenueCentre) => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `revenue-centre-table-row-${revenueCentre.id}-${key}`
        switch (key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { `# ${revenueCentre.id}` }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { revenueCentre.name }
            </TableCell>;
          case TABLE_KEYS.actions:
            return (
              <React.Fragment key={mapKey}>
                { generateActions(revenueCentre) }
              </React.Fragment>
            );
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [generateActions, tableKeys]);

  return (
    <Table
      head={generateHead()}
      rows={revenueCentres.map((revenueCentre) => (
        <React.Fragment key={`revenue-centre-table-row-${revenueCentre.id}`}>
          { generateRow(revenueCentre) }
        </React.Fragment>
      ))}
    />
  );
}

export default RevenueCentreTable;
