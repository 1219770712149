import React, { useCallback } from 'react';

import { Tag } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';
import { settings } from '#materials';

const localKeys = locale.keys.tables.tags;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface TagTableProps {
  tags : Tag[];
  pageCount? : number;
  tableKeys? : TableKeys[];
  generateActions? : (tag : Tag) => CellElement;
}

function TagTable({
  tags,
  pageCount = 20,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <></>,
} : TagTableProps) {
  const generateHead = useCallback(() => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `tag-table-head=${key}`
        switch(key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.id) }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey} width={settings.dimensions.large}>
              { localize(localKeys.headings.name) }
            </TableCell>;
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [tableKeys]);

  const generateRow = useCallback(( tag : Tag ) => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `tag-table-row-${tag.id}-${key}`
        switch(key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { `# ${tag.id}` }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { tag.name }
            </TableCell>;
          case TABLE_KEYS.actions:
            return (
              <React.Fragment key={mapKey}>
                  { generateActions(tag) }
              </React.Fragment>
            );
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  },
  [generateActions, tableKeys]);

  return (
    <Table
      head={generateHead()}
      rows={tags.map((tag) => (
        <React.Fragment key={`tag-table-row-${tag.id}`}>
          { generateRow(tag) }
        </React.Fragment>
      ))}
      pageCount={pageCount}
    />
  );
}

export default TagTable;
