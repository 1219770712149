import React, { useCallback, useState } from 'react';

import { Integration } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Form from '#materials/Form';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import TextInput from '#materials/TextInput';
import Banner from '#materials/Banner';

import Section from '#components/dashboard/Section';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.integrations.integrationDetails;
const localeFormKeys = locale.keys.forms.integrations;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.integrations;

interface IntegrationDetailsProps {
  integration : Integration | null;
}

function IntegrationDetails({ integration } : IntegrationDetailsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const { refreshIntegration, deleteIntegration } = useIntegrations();

  const [refreshing, setRefreshing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!integration?.id) return;
    setRefreshing(true);
    await refreshIntegration(integration?.id);
    setRefreshing(false);
  }, [integration, setRefreshing, refreshIntegration]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleDelete = useCallback(async () => {
    if (!integration) return;
    const success = await deleteIntegration(integration);
    if (success) {
      createNotification({
        key : 'delete-integration-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.primary,
      });
      navigate('/integrations/');
    } else {
      createNotification({
        key : 'delete-integration-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [integration, deleteIntegration, createNotification, navigate]);

  if (!integration) return (<></>);

  return (
    <Section
      title={`${integration ? integration?.name : ''} (#${integration.id})`}
      text={localize(localeContentKeys.body)}
    >
      { deleting && (
        <Banner
          icon={<Icon icon={settings.svgIcons.delete} />}
          actions={(
            <>
              <Button onClick={handleDelete}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={handleCancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      <Button
        onClick={handleRefresh}
        disabled={refreshing || deleting}
      >
        { localize(localeButtonKeys.refresh) }
      </Button>
      <Button
        onClick={handleInitDelete}
        disabled={deleting}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.delete) }
      </Button>
      <Form>
        <TextInput
          id={`integration-${integration.id}-name`}
          label={localize(localeFormKeys.labels.integrationName)}
          value={integration.name ?? ''}
          onChange={() => {}}
          disabled={true}
          width={settings.dimensions.half}
        />
      </Form>
    </Section>
  );
}

export default IntegrationDetails;
