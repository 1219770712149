import React, { useCallback, useEffect, useState } from 'react';

import { Customer, ExternalCustomer, Integration } from '#types';

import useCustomers from '#hooks/useCustomers';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ExternalCustomerSearch
  from '#components/customers/ExternalCustomerSearch';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customerIntegrationIndex;
const localeTableKeys = locale.keys.tables.customerIntegrations;

function CustomerIntegrationIndex() {
  const { retrieveCustomers } = useCustomers();
  const [integration, setIntegration] = useState<Integration | null>(null);
  const [customers, setCustomers] = useState<Customer[] | null>(null);

  const retrieve = useCallback(async () => {
    const customers = await retrieveCustomers();
    if (customers) setCustomers(listRecords(customers));
  }, [retrieveCustomers]);

  const generateActions = useCallback((external : ExternalCustomer) => {
    const customer = customers?.find((customer) => {
        if (!customer.integrations) return false;
        return Object.values(customer.integrations).some(
          (integration) => integration.externalId === external.externalId,
        )
      }
    ) ?? null;

    return (
      <TableActionCell>
        { !!customer
          ? <Action
            label={
              `${localize(localeTableKeys.actions.view)} ${customer.id}`
            }
            href={`/customers/${customer.id}`}
            colour={settings.colours.button.primary}
          >
            <Icon icon={settings.svgIcons.person}/>
          </Action>
          : <></>
        }
      </TableActionCell>
    );
  }, [customers]);

  useEffect(() => { retrieve(); }, [retrieve]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <ExternalCustomerSearch
        integration={integration}
        setIntegration={setIntegration}
        generateActions={generateActions}
      />
    </Section>
  );
}

export default CustomerIntegrationIndex;
