import React, { useCallback, useState } from 'react';

import { Service } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useServices from '#hooks/useServices';

import ServiceForm from '#components/services/ServiceForm';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.services.serviceDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.services;

interface ServiceDetailsProps {
  service : Service;
}

function ServiceDetailsForm({
  service : init,
} : ServiceDetailsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();

  const {
    state : service,
    editing,
    setEditing,
    reset,
  } = useFormContext<Service>();

  const { refreshService, updateService, deleteService } = useServices();

  const [saved, setSaved] = useState(true);
  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!init.id) return;
    const refreshedService = await refreshService(init.id);
    if (!refreshedService) return;
  }, [init, refreshService]);

  const handleUpdate = useCallback(async () => {
    if (!service) return;

    const updatedService = await updateService(service);

    if (!updatedService) return;
    setSaved(true);
    setEditing(false);
  }, [service, updateService, setSaved, setEditing]);

  const handleCancel = useCallback(() => {
    setEditing(false);
    reset();
  }, [setEditing, reset]);

  const handleEdit = useCallback(() => {
    setSaved(true);
    setEditing(true);
  }, [setEditing, setSaved]);

  const handleChange = useCallback(() => {
    setSaved(false);
  }, [setSaved]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleDelete = useCallback(async () => {
    if (!init) return;
    const success = await deleteService(init);
    if (success) {
      createNotification({
        key : 'delete-service-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.localShipping} />),
        colour : settings.colours.alert.primary,
      });
      navigate('/services/');
    } else {
      createNotification({
        key : 'delete-service-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.localShipping} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [init, deleteService, navigate, createNotification]);

  return (
    <>
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button
                onClick={handleDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { !editing
        ? <>
          <Button onClick={handleRefresh}>
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button
            onClick={handleEdit}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleUpdate}
            disabled={saved}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
      }
      <ServiceForm service={init} onChange={handleChange} />
    </>
  );
}

function ServiceDetails({ service } : ServiceDetailsProps) {
  return (
    <FormProvider
      init={service}
      editingInit={false}
    >
      <ServiceDetailsForm service={service} />
    </FormProvider>
  )
}

export default ServiceDetails;
