import React from 'react';

import useNotifications from '#hooks/useNotifications';

import { settings } from '#materials';
import SnackPack from '#materials/SnackPack';

function Toaster() {
  const {
    open,
    close,
    closed,
    notification,
  } = useNotifications();

  const safeColour = Object.values(settings.colours.alert)
    .find(c => c === notification?.colour);

  return (
    <SnackPack
      open={open}
      handleClose={close}
      handleClosed={closed}
      colour={safeColour}
      icon={notification?.icon}
      actions={notification?.actions}
    >
      { `${notification?.message}` + (notification?.count
        ? ` ( x${notification?.count} )` : '') }
    </SnackPack>
  );
}

export default Toaster;
