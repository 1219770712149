import React, { useCallback, useState } from 'react';

import { ContactInfo, Customer } from '#types';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useCustomers from '#hooks/useCustomers';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import Section from '#components/dashboard/Section';
import ContactForm, {
  validate_full_name,
  validate_nickname,
  validate_email,
  validate_phone,
} from '#components/customers/ContactForm';

import locale, {localize} from '#utils/locale';

const localeContentKeys = locale.keys.content.contactInfo.contactDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.contactInfo;

interface ContactDetailsProps {
  customer? : Customer;
  contactInfo : ContactInfo;
  enableDelete? : boolean;
  slice? : boolean;
}

function ContactDetailsContainer(
  { slice, children } : { slice : boolean, children : React.ReactNode }
) {
  return slice
    ? (<Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
      >
        { children }
      </Section>
    )
    : (<>{ children }</>);
}

function ContactDetailsControl({
  customer,
  contactInfo : initContactInfo,
  enableDelete = true,
  slice = true,
} : ContactDetailsProps) {
  const { createNotification } = useNotifications();
  const {
    state : contactInfo,
    valid,
    editing,
    setEditing,
    reset,
  } = useFormContext<ContactInfo>();
  const {
    updateContactInfo,
    deleteContactInfo,
  } = useCustomers();

  const [deleting, setDeleting] = useState(false);

  const handleSave = useCallback(async () => {
    if (!contactInfo) return;

    const updatedContactInfo = await updateContactInfo(contactInfo);
    if (!!updatedContactInfo) {
      createNotification({
        key : 'contact-info-updated-success',
        message : localize(localeNotificationKeys.update.success),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.primary,
      });
      setEditing(false);
    } else {
      createNotification({
        key : 'contact-info-updated-error',
        message : localize(localeNotificationKeys.update.error),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [contactInfo, updateContactInfo, setEditing, createNotification]);

  const handleEdit = useCallback(() => { setEditing(true); }, [setEditing]);
  const handleCancel = useCallback(() => {
    setEditing(false);
    reset();
  }, [setEditing, reset]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleDelete = useCallback(async () => {
    if (!contactInfo) return;

    const success = await deleteContactInfo(contactInfo);
    if (success) {
      createNotification({
        key : 'contact-info-delete-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.primary,
      });
      setEditing(false);
    } else {
      createNotification({
        key : 'contact-info-delete-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [contactInfo, deleteContactInfo, setEditing, createNotification]);

  return (
    <ContactDetailsContainer slice={slice}>
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button onClick={handleDelete}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={handleCancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { editing
        ? (<>
          <Button
            onClick={handleSave}
            disabled={!customer || !valid}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button onClick={handleCancel} colour={settings.colours.alert.alert}>
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>)
        : (<>
          <Button
            onClick={handleEdit}
            disabled={deleting}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={!enableDelete || deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>)
      }
      <ContactForm contactInfo={contactInfo ?? initContactInfo} />
    </ContactDetailsContainer>
  );
}

function ContactDetails({
  contactInfo,
  ...props
} : ContactDetailsProps) {
  return (
    <FormProvider
      init={contactInfo}
      editingInit={false}
      validators={[
        validate_full_name,
        validate_nickname,
        validate_email,
        validate_phone,
      ]}
    >
      <ContactDetailsControl contactInfo={contactInfo} {...props} />
    </FormProvider>
  );
}

export default ContactDetails;
