import React from 'react';

import Text from '#materials/Text';
import Link from '#materials/Link';
import { settings } from '#materials';

import locale from '#utils/locale';

function Copyright () {
  return (
    <Text
      variant={settings.variants.typography.body2}
      colour={settings.colours.text.primary}
      align={settings.alignments.center}
    >
      { locale.localize(locale.keys.content.copywright) }
      <Link href='https://mrktbox.com/'>
        { process.env.REACT_APP_COMPANY }
      </Link>
      { ' ' + new Date().getFullYear() }
    </Text>
  );
}

export default Copyright;
