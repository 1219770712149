import React, { useCallback, useState } from 'react';

import { Customer } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useCustomers from '#hooks/useCustomers';
import useCards from '#hooks/useCards';

import { settings } from '#materials';
import Form from '#materials/Form';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import TextInput from '#materials/TextInput';
import Banner from '#materials/Banner';

import locale, { localize } from '#utils/locale';
import Section from '#components/dashboard/Section';

const localeContentKeys = locale.keys.content.customers.customerDetails;
const localeFormKeys = locale.keys.forms.customer.labels;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.customers;

interface CustomerDetailsProps {
  customer : Customer | null;
  loading? : boolean;
}

function CustomerDetails({
  customer,
  loading = false,
} : CustomerDetailsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const { refreshCustomer, deleteCustomer } = useCustomers();
  const { refreshCreditCards } = useCards();

  const [refreshing, setRefreshing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!customer?.id) return;

    setRefreshing(true);
    await Promise.all([refreshCustomer(customer.id), refreshCreditCards()]);
    setRefreshing(false);
  }, [customer, setRefreshing, refreshCustomer, refreshCreditCards]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleDelete = useCallback(async () => {
    if (!customer) return;
    const success = await deleteCustomer(customer);
    if (success) {
      createNotification({
        key : 'customer-delete-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.primary,
      });
      navigate('/customers/');
    } else {
      createNotification({
        key : 'customer-delete-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [customer, createNotification, deleteCustomer, navigate]);

  if (!loading && !customer) return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.notFound)}
    />
  );

  return (
    <Section
      title={loading
        ? localize(localeContentKeys.title)
        : `${customer ? customer?.defaultName : ''} (#${customer?.id})`}
      text={loading
        ? localize(localeContentKeys.loading)
        : localize(localeContentKeys.body)
      }
    >
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button onClick={handleDelete}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={handleCancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      <Button onClick={handleRefresh} disabled={refreshing || deleting}>
          { localize(localeButtonKeys.refresh) }
      </Button>
      <Button
        onClick={handleInitDelete}
        colour={settings.colours.button.alert}
        disabled={deleting}
      >
        { localize(localeButtonKeys.delete) }
      </Button>
      <Form>
        <TextInput
          id={`customer-${customer?.id}-defaultName`}
          label={localize(localeFormKeys.defaultName)}
          value={customer?.defaultName ?? ''}
          onChange={() => {}}
          disabled={true}
          width={settings.dimensions.half}
        />
      </Form>
    </Section>
  );
}

export default CustomerDetails;
