import React, { useCallback } from 'react';

import { RevenueCentre } from '#types';

import useForm from '#hooks/useForm';

import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.revenueCentres;

interface RevenueCentreFormProps {
  revenueCentre : RevenueCentre;
}

function RevenueCentreForm({
  revenueCentre : fallback
} : RevenueCentreFormProps) {
  const { state, dispatch, editing } = useForm<RevenueCentre>();

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `revenueCentre-${fallback?.id}`
    : 'revenueCentre-new';

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localKeys.labels.name)}
        value={state ? state.name : fallback.name}
        onChange={setName}
        disabled={!editing}
      />
    </Form>
  );
}

export default RevenueCentreForm;
