import React from 'react';

import { useParams } from '#hooks/useNavigation';

import RouteIndex from '#components/routes/RouteIndex';
import RouteInfo from '#components/routes/RouteInfo';

function Routes() {
  const urlParams = useParams<{ routeId? : string; }>();

  const parsedId = parseInt(urlParams.routeId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !id
        ? <RouteIndex />
        : <RouteInfo routeId={id} />
      }
    </>
  );
}

export default Routes;
