import React, { useCallback, useEffect, useState } from 'react';

import { Condition, Product, Service } from '#types';

import useForm from '#hooks/useForm';
import useProducts from '#hooks/useProducts';
import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';
import Select from '#materials/Select';
import Switch from '#materials/Switch';

import { listRecords } from '@mrktbox/clerk/utils';
import locale, { localize } from '#utils/locale';

const localeKeys = locale.keys.forms.conditions;

interface ConditionFormProps {
  condition : Condition;
}

const ConditionForm = ({
  condition : fallback,
} : ConditionFormProps) => {
  const { state : condition, dispatch, editing } = useForm<Condition>();
  const [products, setProducts] = useState<Product[]>([])
  const [services, setServices] = useState<Service[]>([])

  const { retrieveProducts } = useProducts()
  const { retrieveServices } = useServices()

  const handleSelectProduct = useCallback((product : Product | null) => {
    if(!product?.id) {
      dispatch({ productIds: [] })
    }
    if(product?.id) {
      dispatch({ productIds: [product?.id]})
    }
  }, [dispatch])

  const handleCountChange = useCallback((count : number | null) => {
    if(!count) {
      dispatch({ count: 0 })
      return
    }

    dispatch({ count })
  }, [dispatch])

  const handleCumulativeChange = useCallback((cumulative : boolean) => {
    dispatch({ scope: cumulative ? 'account' : 'order' })
  }, [dispatch])

  const handleSelectService = useCallback((service : Service | null) => {
    if(!service?.id) dispatch({ serviceIds: [] });
    else dispatch({ count : 0, serviceIds: [service.id] });
  }, [dispatch])

  useEffect(() => {
    const retrieve = async () => {
      const [prods, services] = await Promise.all(
        [retrieveProducts(), retrieveServices()]
      )

      if(prods) setProducts(listRecords(prods))
      if(services) setServices(listRecords(services))
    }
    retrieve()
  }, [retrieveProducts, retrieveServices])

  const productId = fallback.productIds[0] ?? condition?.productIds[0] ?? null;
  const serviceId = fallback.serviceIds[0] ?? condition?.serviceIds[0] ?? null;
  const product = products.find((p) => p.id === productId) ?? null;
  const service = services.find((s) => s.id === serviceId) ?? null;

  const hasProduct = product ? true : false;
  const hasService = serviceId ? true : false;

  const scope = fallback.scope ?? condition?.scope ?? 'order';

  return (
    <Form>
      <Select
        label='Product'
        selected={product}
        options={products}
        onChange={handleSelectProduct}
        labelGenerator={(p) => {
          return p?.name ?? ''
        }}
        keyGenerator={(p) => { return `${p?.id}` }}
        disabled={!editing || hasService}
        width={settings.dimensions.half}
      />
      <TextInput
        id={`new-condition-count`}
        label={localize(localeKeys.labels.count)}
        onChange={handleCountChange}
        inputType='number'
        inputFormat='int'
        value={fallback.count ?? condition?.count ?? 0}
        disabled={!editing || hasService}
        width={settings.dimensions.quarter}
      />
      <Switch
        label='Cumulative'
        checked={scope === 'account'}
        onChange={handleCumulativeChange}
        disabled={!editing || hasService}
        width={settings.dimensions.quarter}
      />
      <Select
        label='Service'
        selected={service}
        options={services}
        onChange={handleSelectService}
        labelGenerator={(service) => service?.name ?? ''}
        keyGenerator={(service) => `${service?.id}`}
        disabled={!editing || hasProduct || scope === 'account'}
        width={settings.dimensions.half}
      />
    </Form>
  );
}

export default ConditionForm;
