import React, { useCallback, useEffect, useState } from 'react';

import { Integration, Config } from '#types';

import useNotifications from '#hooks/useNotifications';
import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell, CellElement } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ConfigTable from '#components/configs/ConfigTable';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.integrations.integrationConfigs;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables;
const localeNotificationKeys = locale.keys.notifications.integrations;

interface IntegrationConfigsProps {
  integration : Integration;
  generateActions? : (config : Config) => CellElement;
}

function IntegrationConfigs({ integration } : IntegrationConfigsProps) {
  const { createNotification } = useNotifications();
  const { createConfig, deleteConfig } = useIntegrations();

  const [configs, setConfigs] = useState<Config[]>([]);
  const [editing, setEditing] = useState<number[]>([]);
  const [deleteTarget, setDeleteTarget] = useState<Config | null>(null);

  const handleCreate = useCallback(async () => {
    const config = await createConfig(
      integration,
      { name : 'New Config', value : ' ' },
    );
    if (!!config) {
      createNotification({
        key : 'create-config-success',
        message : localize(localeNotificationKeys.create.success),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.primary,
      });
      if (config.id) setEditing([...editing, config.id]);
    } else {
      createNotification({
        key : 'create-config-error',
        message : localize(localeNotificationKeys.create.error),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [integration, editing, setEditing, createConfig, createNotification]);

  const handleEdit = useCallback((config : Config) => () => {
    if (!config.id) return;
    setEditing([...editing, config.id])
  }, [editing, setEditing]);

  const handleInitDelete = useCallback((config : Config) => () => {
    setDeleteTarget(config);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteConfig(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-config-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-config-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.alert,
      });
    }
    setDeleteTarget(null);
  }, [deleteTarget, setDeleteTarget, deleteConfig, createNotification]);

  useEffect(() => {
    setConfigs(Object.values(integration.configs));
  }, [integration, setConfigs]);

  const generateActions = useCallback((config : Config) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.configs.actions.edit)}
          onClick={handleEdit(config)}
        >
          <Icon icon={ settings.svgIcons.edit }/>
        </Action>
        <Action
          label={localize(localeTableKeys.configs.actions.delete)}
          onClick={handleInitDelete(config)}
          disabled={deleteTarget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={ settings.svgIcons.delete }/>
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleEdit, handleInitDelete]);

  useEffect(() => {
    setConfigs(Object.values(integration.configs));
  }, [integration, setConfigs]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { deleteTarget && (
        <Banner
          icon={<Icon icon={settings.svgIcons.delete} />}
          actions={(
            <>
              <Button onClick={handleDelete}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={handleCancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      <ConfigTable
        configs={configs}
        generateActions={generateActions}
        editing = {editing}
        setEditing={setEditing}
      />
      <Button onClick={handleCreate}>
        { localize(localeButtonKeys.new) }
      </Button>
    </Section>
  );
}

export default IntegrationConfigs;
