import React from 'react';

import { Address } from '#types'

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';

interface AddressFormProps {
  id : string;
  address : Address;
  formKey? : string;
  onChange : (address : Address) => void;
}

function AddressForm({
  id,
  address,
  formKey,
  onChange,
} : AddressFormProps) {
  const { dispatch } = useForm({
    init : address,
    onChange,
    key : formKey,
  });

  function setStreet(street : string) { dispatch({ street }) }
  function setCity(city : string) { dispatch({ city }) }
  function setPostal(postal: string) { dispatch({ postal })}

  return (
    <Form>
      <TextInput
        id={`${id}-street`}
        label="Street"
        value={address.street}
        onChange={setStreet}
      />
      <TextInput
        id={`${id}-city`}
        label="City"
        value={address.city}
        onChange={setCity}
        width={settings.dimensions.half}
      />
      <TextInput
        id={`${id}-postal`}
        label="Postal Code"
        value={address.postal}
        onChange={setPostal}
        width={settings.dimensions.half}
      />
    </Form>
  );
}

export default AddressForm;
