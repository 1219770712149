import React from 'react';

import { useParams } from '#hooks/useNavigation';

import RevenueCentreIndex from '#components/revenueCentres/RevenueCentreIndex';
import RevenueCentreInfo from '#components/revenueCentres/RevenueCentreInfo';

function RevenueCentres() {
  const urlParams = useParams<{ revenueCentreId? : string; }>();

  const parsedId = parseInt(urlParams.revenueCentreId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !id
        ? <RevenueCentreIndex />
        : <RevenueCentreInfo revenueCentreId={id} />
      }
    </>
  );
}

export default RevenueCentres;
