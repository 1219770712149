import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import Assignment from '@mui/icons-material/Assignment';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CategoryIcon from '@mui/icons-material/Category';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUpload from '@mui/icons-material/CloudUpload';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DateRange from '@mui/icons-material/DateRange';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Extention from '@mui/icons-material/Extension';
import ImageIcon from '@mui/icons-material/Image';
import InfoIcon from '@mui/icons-material/Info';
import IntegrationInstructionsIcon
from '@mui/icons-material/IntegrationInstructions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowUpIcon
  from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon
  from '@mui/icons-material/KeyboardDoubleArrowDown';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import MenuIcon from '@mui/icons-material/Menu';
import Percent from '@mui/icons-material/Percent';
import PersonIcon from '@mui/icons-material/Person';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveIcon from '@mui/icons-material/Remove';
import RouteIcon from '@mui/icons-material/Route';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsInputComponentIcon
  from '@mui/icons-material/SettingsInputComponent';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import StoreIcon from '@mui/icons-material/Store';
import UploadIcon from '@mui/icons-material/Upload';
import WorkIcon from '@mui/icons-material/Work';
import { ButtonColour, SvgIcon, Size, convert,settings } from '#materials';

interface IconProps {
  icon? : SvgIcon;
  size? : Size;
  colour? : ButtonColour;
}

interface InnerIconProps {
  icon? : SvgIcon;
  fontSize? : Size;
  color? : ButtonColour;
}

export type IconElement = React.ReactElement<IconProps>;

function InnerIcon(props : InnerIconProps) {
  const { icon, ...innerProps } = props;

  const convertedProps = {
    ...innerProps,
    fontSize : convert.size(innerProps.fontSize),
  }

  switch (icon) {
    case settings.svgIcons.accessTime:
      return <AccessTimeIcon {...convertedProps} />;
    case settings.svgIcons.add:
      return <AddIcon {...convertedProps} />;
    case settings.svgIcons.agriculture:
      return <AgricultureIcon {...convertedProps} />;
    case settings.svgIcons.assignment:
      return <Assignment {...convertedProps} />;
    case settings.svgIcons.callSplit:
      return <CallSplitIcon {...convertedProps} />;
    case settings.svgIcons.category:
      return <CategoryIcon {...convertedProps} />;
    case settings.svgIcons.check:
      return <CheckIcon {...convertedProps} />;
    case settings.svgIcons.chevronLeft:
      return <ChevronLeftIcon {...convertedProps} />;
    case settings.svgIcons.clear:
      return <ClearIcon {...convertedProps} />;
    case settings.svgIcons.cloudUpload:
      return <CloudUpload {...convertedProps} />;
    case settings.svgIcons.copy:
      return <CopyIcon {...convertedProps} />;
    case settings.svgIcons.delete:
      return <DeleteIcon {...convertedProps} />;
    case settings.svgIcons.dateRange:
      return <DateRange {...convertedProps} />;
    case settings.svgIcons.download:
      return <DownloadIcon {...convertedProps} />;
    case settings.svgIcons.edit:
      return <EditIcon {...convertedProps} />;
    case settings.svgIcons.expandLess:
      return <ExpandLessIcon {...convertedProps} />;
    case settings.svgIcons.expandMore:
      return <ExpandMoreIcon {...convertedProps} />;
    case settings.svgIcons.extension:
      return <Extention {...convertedProps} />;
    case settings.svgIcons.menu:
      return <MenuIcon {...convertedProps} />;
    case settings.svgIcons.image:
      return <ImageIcon {...convertedProps} />;
    case settings.svgIcons.info:
      return <InfoIcon {...convertedProps} />;
    case settings.svgIcons.integrationInstructions:
      return <IntegrationInstructionsIcon {...convertedProps} />;
    case settings.svgIcons.keyboardArrowUp:
      return <KeyboardArrowUpIcon {...convertedProps} />;
    case settings.svgIcons.keyboardArrowDown:
      return <KeyboardArrowDownIcon {...convertedProps} />;
    case settings.svgIcons.keyboardDoubleArrowUp:
      return <KeyboardDoubleArrowUpIcon {...convertedProps} />;
    case settings.svgIcons.keyboardDoubleArrowDown:
      return <KeyboardDoubleArrowDownIcon {...convertedProps} />;
    case settings.svgIcons.link:
      return <LinkIcon {...convertedProps} />;
    case settings.svgIcons.linkOff:
      return <LinkOffIcon {...convertedProps} />;
    case settings.svgIcons.localOffer:
      return <LocalOfferIcon {...convertedProps} />;
    case settings.svgIcons.localShipping:
      return <LocalShippingIcon {...convertedProps} />;
    case settings.svgIcons.loyalty:
      return <LoyaltyIcon {...convertedProps} />;
    case settings.svgIcons.percent:
      return <Percent {...convertedProps} />;
    case settings.svgIcons.person:
      return <PersonIcon {...convertedProps} />;
    case settings.svgIcons.pinDrop:
      return <PinDropIcon {...convertedProps} />;
    case settings.svgIcons.pointOfSale:
      return <PointOfSaleIcon {...convertedProps} />;
    case settings.svgIcons.receipt:
      return <ReceiptIcon {...convertedProps} />;
    case settings.svgIcons.refresh:
      return <RefreshIcon {...convertedProps} />;
    case settings.svgIcons.remove:
      return <RemoveIcon {...convertedProps} />;
    case settings.svgIcons.route:
      return <RouteIcon {...convertedProps} />;
    case settings.svgIcons.settings:
      return <SettingsIcon {...convertedProps} />;
    case settings.svgIcons.settingsInputComponent:
      return <SettingsInputComponentIcon {...convertedProps} />;
    case settings.svgIcons.shoppingBag:
      return <ShoppingBagIcon {...convertedProps} />;
    case settings.svgIcons.store:
      return <StoreIcon {...convertedProps} />;
    case settings.svgIcons.work:
      return <WorkIcon {...convertedProps} />;
    case settings.svgIcons.upload:
      return <UploadIcon {...convertedProps} />;
    default:
      return <QuestionMarkIcon {...convertedProps} />;
  }
}

function Icon({ icon, size, colour } : IconProps) {
  return (
    <InnerIcon
      icon={icon}
      fontSize={size}
      color={colour}
    />
  );
}

export default Icon;
