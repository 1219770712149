import React, { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Spacing, Dimension, convert, settings } from '#materials';
import Icon from '#materials/Icon';
import FlexItem from '#materials/FlexItem';

import { copy } from '@mrktbox/clerk/utils';

interface ListInputProps {
  label : string;
  values : string[];
  onChange : (values : string[]) => void;
  errors? : string[];
  hideErrors? : boolean;
  width? : Dimension;
  spacing? : Spacing;
  disabled? : boolean;
}

function ListInput({
  label,
  values,
  onChange,
  errors,
  hideErrors = false,
  width = settings.dimensions.full,
  spacing = settings.spacings.medium,
  disabled,
} : ListInputProps) {
  const [focused, setFocused] = useState(false);

  const handleInput = useCallback((event : React.ChangeEvent<HTMLInputElement>) => {
    const parts = event.target.value.split(',');
    if (parts.length > 1) {
      const newValues = [
        ...values,
        ...parts.splice(0, parts.length - 1)
          .map((part : string) => part.trim())
          .filter((part : string) => part.length > 0),
      ];
      if (onChange) onChange(newValues);
      event.target.value = parts[parts.length - 1].trim();
      event.target.setSelectionRange(0, 0);
    }
  },  [values, onChange]);

  const handleChange = useCallback((event : any, newValue : string[]) => {
    if (onChange) onChange(newValue);
  }, [onChange]);

  const handleCopy = useCallback((event : any) => {
    copy(values.join(', '));
  }, [values]);

  const handleFocus = useCallback((event : any) => { setFocused(true); }, []);
  const handleBlur = useCallback((event : any) => { setFocused(false); }, []);

  useEffect(() => { setFocused(false); }, [disabled]);

  return (
    <FlexItem
      width={settings.dimensions.full}
      maxWidth={width}
    >
      <Box
        sx={{
          minHeight : 38.8,
          mx : spacing !== settings.spacings.dense ? [1] : [0.5],
          my : spacing !== settings.spacings.dense ? [1] : [0.6],
        }}
      >
        <Autocomplete
          multiple
          id="tags-filled"
          value={values}
          options={[]}
          onInput={handleInput}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                label={option}
                variant="outlined"
                size='small'
                {...getTagProps({ index })}
                disabled={false}
                deleteIcon={disabled ? <></> : undefined}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!hideErrors && !!errors?.length}
              helperText={(!hideErrors && errors?.length)
                ? errors?.join('. ') + '.'
                : null
              }
              InputProps={{
                ...params.InputProps,
                endAdornment : (
                  <>
                    { params.InputProps.endAdornment }
                    <InputAdornment
                      position="end"
                      sx={{
                        visibility : (focused && values.length)
                          ? "visible"
                          : "hidden"
                      }}
                    >
                      <IconButton onClick={handleCopy} >
                        <Icon icon={settings.svgIcons.copy} />
                      </IconButton>
                    </InputAdornment>
                  </>
                )
              }}
            />
          )}
          sx={{
            minWidth : convert.width(settings.dimensions.full),
            "&:hover .MuiInputAdornment-positionEnd" : {
              visibility : values.length ? "visible" : "hidden",
            },
            "& .MuiInputBase-input.Mui-disabled" : {
              WebkitTextFillColor : "#444",
            },
            ...(spacing === settings.spacings.dense && {
              "& .MuiInputBase-root" : {
                fontSize : "0.875rem",
              }
            }),
          }}
        />
      </Box>
    </FlexItem>
  );
}

export default ListInput;
