import React, { useEffect, useState } from 'react';

import { TimeSlot } from '#types';

import useTimeSlots from '#hooks/useTimeSlots';

import Section from '#components/dashboard/Section';
import TimeSlotDetails from '#components/timeslots/TimeSlotDetails';
import TimeSlotWindows from '#components/timeslots/TimeSlotWindows';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.timeSlots.timeSlotInfo;

interface TimeSlotInfoProps {
  timeSlotId : number;
}

function TimeSlotInfo({ timeSlotId } : TimeSlotInfoProps) {
  const { retrieveTimeSlot } = useTimeSlots();

  const [timeSlot, setTimeSlot] = useState<TimeSlot | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function retrieve() {
      const newTimeSlot = await retrieveTimeSlot(timeSlotId);
      if (newTimeSlot) setTimeSlot(newTimeSlot);
      setLoaded(true);
    }
    retrieve();
  }, [timeSlotId, retrieveTimeSlot]);

  if (!loaded) return <></>;
  if (!timeSlot) return (
    <Section title={localize(localeContentKeys.notFound)}> </Section>
  );

  return (
    <>
      <Section
        title={`${timeSlot.name} (#${timeSlot.id})`}
        text={localize(localeContentKeys.text)}
      >
        <TimeSlotDetails timeSlot={timeSlot} />
      </Section>
      <TimeSlotWindows timeSlot={timeSlot} />
    </>
  );
}

export default TimeSlotInfo;
