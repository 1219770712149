import React, { useCallback, useState } from 'react';

import { Product } from '#types';

import { FormProvider } from '#context/FormContext';
import useNotifications from '#hooks/useNotifications';

import useNavigation from '#hooks/useNavigation';
import { useFormContext } from '#hooks/useForm';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import ProductForm from '#components/products/ProductForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productIndex;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications;

interface ProductDetailsProps {
  product : Product;
  onSave? : (product : Product) => void;
}

function ProductDetailsControl(
  { product : init, onSave } : ProductDetailsProps
) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();

  const {
    state : product,
    reset,
    editing,
    setEditing,
  } = useFormContext<Product>();
  const { refreshProduct, updateProduct, deleteProduct } = useProducts();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!product || !product.id) return;
    if (!await refreshProduct(product.id, true)) {
      createNotification({
        key : 'refresh-product-error',
        message : localize(localeNotificationKeys.products.retrieved.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [product, refreshProduct, createNotification]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!product) return;

    const updatedProduct = await updateProduct(product);
    if (updatedProduct) {
      createNotification({
        key : 'update-product-success',
        message : localize(localeNotificationKeys.products.updated.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
      if (onSave) onSave(updatedProduct);
      setEditing(false);
    } else {
      createNotification({
        key : 'update-product-error',
        message : localize(localeNotificationKeys.products.updated.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [onSave, product, setEditing, updateProduct, createNotification]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleDelete = useCallback(async () => {
    if (!product) return;
    const success = await deleteProduct(product);
    if (success) {
      createNotification({
        key : 'delete-product-success',
        message : localize(localeNotificationKeys.products.deleted.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
      navigate('/products/');
    } else {
      createNotification({
        key : 'delete-product-error',
        message : localize(localeNotificationKeys.products.deleted.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [product, deleteProduct, createNotification, navigate]);

  const initDelete = useCallback(
    () => setConfirmDelete(true),
    [setConfirmDelete],
  );

  const cancelDelete = useCallback(
    () => setConfirmDelete(false),
    [setConfirmDelete],
  );

  return (
    <>
      { confirmDelete && (
        <Banner
          onClose={cancelDelete}
          colour={settings.colours.alert.alert}
          icon={<Icon icon={settings.svgIcons.delete} />}
          actions={
            <>
              <Button onClick={handleDelete}>
                {localize(localeButtonKeys.delete)}
              </Button>
              <Button onClick={cancelDelete}>
                {localize(localeButtonKeys.cancel)}
              </Button>
            </>
          }
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (product && editing)
        ? <>
          <Button
            onClick={handleSave}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleRefresh}
          >
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button
            onClick={handleEdit}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={initDelete}
            colour={settings.colours.button.alert}
            disabled={confirmDelete}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <ProductForm
        product={product || init}
        detailed={true}
        onSubmit={handleSave}
      />
    </>
  );
}

function ProductDetails({ product, onSave } : ProductDetailsProps) {
  return (
    <FormProvider init={product} editingInit={false}>
      <ProductDetailsControl product={product} onSave={onSave} />
    </FormProvider>
  );
}

export default ProductDetails;
