import React, { useCallback } from 'react';

import { Supplier } from '#types';

import { FormProvider } from '#context/FormContext';

import {useFormContext} from '#hooks/useForm';
import useSuppliers from '#hooks/useSuppliers';

import { settings } from '#materials';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import SupplierForm from '#components/suppliers/SupplierForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.suppliers.createSupplier;
const localeButtonKeys = locale.keys.buttons;

interface CreateSupplierProps {
  onSave? : (supplier : Supplier) => void;
  onCancel? : () => void;
}

function CreateSupplierControl({ onSave, onCancel } : CreateSupplierProps) {
  const { state : supplier, reset } = useFormContext<Supplier>();
  const { createSupplier } = useSuppliers();

  const handleSave = useCallback(async () => {
    if (!supplier) return;

    const newSupplier = await createSupplier(supplier);
    if (newSupplier && onSave) onSave(newSupplier);
  }, [supplier, createSupplier, onSave]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Section title={localize(localeContentKeys.title)}>
      { !!supplier && <SupplierForm supplier={supplier} /> }
      <Button
        onClick={handleSave}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
    </Section>
  );
}

function CreateSupplier({ onSave, onCancel } : CreateSupplierProps) {
  return (
    <FormProvider
      init={{
        name : '',
        tagId : '',
        productIds : [],
        availableChannelIds : [],
      }}
      editing={true}
    >
      <CreateSupplierControl onSave={onSave} onCancel={onCancel} />
    </FormProvider>
  );
}

export default CreateSupplier;
