import React from 'react';

import { useParams } from '#hooks/useNavigation';

import LocationIndex from '#components/locations/LocationIndex';
import LocationInfo from '#components/locations/LocationInfo';

function Locations() {
  const urlParams = useParams<{ locationId? : string }>();

  const parsedId = parseInt(urlParams.locationId || '0');
  const id = isNaN(parsedId) ? undefined : parsedId;

  return (
    <>
      { !!id
        ?
        <LocationInfo locationId={id} />
        :
        <LocationIndex />
      }
    </>
  );
}

export default Locations;
