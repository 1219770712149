import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Product, Image } from '#types';

import useNotifications from '#hooks/useNotifications';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Text from '#materials/Text';
import Button from '#materials/Button';
import UploadButton from '#materials/UploadButton';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ImageTable from '#components/images/ImageTable';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productImages;
const lcoaleNotificationsKeys = locale.keys.notifications.images;
const localeTableKeys = locale.keys.tables.images;
const localeButtonsKeys = locale.keys.buttons;

interface ProductImagesProps {
  product : Product;
}

function ProductImages({ product } : ProductImagesProps) {
  const { createNotification } = useNotifications();
  const {
    refreshProduct,
    createProductImageUpload,
    pullProductImage,
    removeImageFromProduct,
  } = useProducts();

  const uploadImageId = useRef<number | null>(null);
  const [images, setImages] = useState<Image[]>(
    product.images ? Object.values(product.images) : []
  );
  const [removeTarget, setRemoveTarget] = useState<Image | null>(null);

  const handleInitRemove = useCallback((image : Image) => () => {
    setRemoveTarget(image);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleConfirmRemove = useCallback(async () => {
    if (!removeTarget) return;
    const success = await removeImageFromProduct(product, removeTarget);
    if (success) {
      createNotification({
        key : 'remove-image-success',
        message : localize(lcoaleNotificationsKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-image-error',
        message : localize(lcoaleNotificationsKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.alert,
      });
    }
    setRemoveTarget(null);
  }, [
    product,
    removeTarget,
    setRemoveTarget,
    removeImageFromProduct,
    createNotification,
  ]);

  const handleSubmit = useCallback(async () => {
    async function pull() {
      if (!product.id || !uploadImageId.current) return null;
      const latestProduct = await refreshProduct(product.id);
      if (!latestProduct || !latestProduct.images) return null;

      const image = latestProduct.images[uploadImageId.current];
      if (!image) return null;
      return await pullProductImage(product, image);
    }

    const image = await pull();
    if (!!image) {
      createNotification({
        key : 'submit-image-success',
        message : localize(lcoaleNotificationsKeys.submit.success),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'submit-image-error',
        message : localize(lcoaleNotificationsKeys.submit.error),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.alert,
      });
    }
    uploadImageId.current = null;
  }, [
    product,
    refreshProduct,
    pullProductImage,
    createNotification,
  ]);

  const getUploadUrl = useCallback(async () => {
    const upload = await createProductImageUpload(product);
    if (!upload) {
      createNotification({
        key : 'submit-image-error',
        message : localize(lcoaleNotificationsKeys.submit.error),
        icon : (<Icon icon={settings.svgIcons.image} />),
        colour : settings.colours.alert.alert,
      });
    }
    uploadImageId.current = upload?.id ?? null;
    return upload?.uploadUrl ?? '';
  }, [createProductImageUpload, product, createNotification]);

  const generateActions = useCallback((image : Image) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.remove)}
          onClick={handleInitRemove(image)}
          disabled={removeTarget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      </TableActionCell>
    )
  }, [removeTarget, handleInitRemove]);

  useEffect(() => {
    setImages(product.images ? Object.values(product.images) : [])
  }, [product]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { images
        ? (<>
          { !!removeTarget && (
            <Banner
              icon={(<Icon icon={settings.svgIcons.remove} />)}
              actions={(
                <>
                  <Button onClick={handleConfirmRemove}>
                    { localize(localeButtonsKeys.remove) }
                  </Button>
                  <Button onClick={handleCancelRemove}>
                    { localize(localeButtonsKeys.cancel) }
                  </Button>
                </>
              )}
              colour={settings.colours.alert.alert}
            >
              { localize(localeContentKeys.confirmRemove) +
                ` (# ${removeTarget.id})` }
            </Banner>
          ) }
          <ImageTable
            images={images}
            generateActions={generateActions}
          />
        </>)
        : <Text>{localize(localeContentKeys.notAssigned)}</Text>
      }
      <UploadButton
        getUrl={getUploadUrl}
        icon={(<Icon icon={settings.svgIcons.cloudUpload} />)}
        onSubmit={handleSubmit}
      >
        { localize(localeButtonsKeys.upload) }
      </UploadButton>
    </Section>
  );
}

export default ProductImages;
