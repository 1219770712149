import React, { useCallback, useEffect, useState } from 'react';

import { Integration, Channel } from '#types';

import useNotifications from '#hooks/useNotifications';
import useIntegrations from '#hooks/useIntegrations';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell'

import Section from '#components/dashboard/Section';
import ChannelTable from '#components/channels/ChannelTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.integrations.integrationChannels;
const localeTableKeys = locale.keys.tables.integrations;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.channels;

interface IntegrationChannelsProps {
  integration : Integration;
}

function IntegrationChannels({ integration } : IntegrationChannelsProps) {
  const { createNotification } = useNotifications();
  const {
    retrieveChannels,
    addIntegrationToChannel,
    removeIntegrationFromChannel,
  } = useIntegrations();

  const [integrationChannels, setIntegrationChannels] = useState<Channel[]>([]);
  const [availableChannels, setAvailableChannels] = useState<Channel[]>([]);
  const [edit, setEdit] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<Channel | null>(null);

  const retrieve = useCallback(async () => {
    const retrievedChannels = await retrieveChannels();
    if (!retrievedChannels) return;

    setIntegrationChannels(listRecords(retrievedChannels).filter(
      channel => integration.channelIds.includes(channel?.id as number)
    ));
    setAvailableChannels(listRecords(retrievedChannels).filter(
      channel => !(channel.id &&
        integration.channelIds.includes(channel.id))
    ));
  }, [integration, retrieveChannels]);

  const handleManage = useCallback(() => {
    setEdit(true);
  }, [setEdit]);

  const handleCancel = useCallback(() => {
    setEdit(false);
  }, [setEdit]);

  const handleAdd = useCallback((channel  : Channel) => async () => {
    const success = await addIntegrationToChannel(channel, integration);
    if (success) {
      createNotification({
        key : 'add-channel-success',
        message : localize(localeNotificationKeys.add.success),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'add-channel-error',
        message : localize(localeNotificationKeys.add.error),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [integration, addIntegrationToChannel, createNotification]);

  const handleInitRemove = useCallback((channel : Channel) => () => {
    setRemoveTarget(channel);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemove = useCallback(async () => {
    if (!removeTarget) return;
    const success = await removeIntegrationFromChannel(
      removeTarget,
      integration
    );
    if (success) {
      createNotification({
        key : 'remove-channel-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-channel-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.integrationInstructions} />),
        colour : settings.colours.alert.alert,
      });
    }
    setRemoveTarget(null);
  }, [
    removeTarget,
    integration,
    removeIntegrationFromChannel,
    createNotification,
  ]);

  const generateActions = useCallback((channel : Channel) => {
    if (!integration) return (<></>);
    return (
      <TableActionCell>
        { channel.id && integration.channelIds.includes(channel.id)
          ? <Action
            label={`${localize(localeTableKeys.actions.removeChannel)}` +
              ` ${channel.id}`}
            onClick={handleInitRemove(channel)}
            disabled={removeTarget !== null}
            colour={settings.colours.button.alert}
          >
            <Icon icon={settings.svgIcons.remove}/>
          </Action>
          : <Action
            label={`${localize(localeTableKeys.actions.addChannel)}` +
              ` ${channel.id}`}
            onClick={handleAdd(channel)}
            colour={settings.colours.button.primary}
          >
            <Icon icon={settings.svgIcons.add}/>
          </Action>
        }
      </TableActionCell>
    );
  }, [integration, removeTarget, handleAdd, handleInitRemove]);

  useEffect(() => { retrieve(); }, [retrieve]);

  return (
    <>
      { integration &&
        <Section
          title={localize(localeContentKeys.title)}
          text={localize(localeContentKeys.body)}
        >
          { removeTarget && (
            <Banner
              icon={<Icon icon={settings.svgIcons.delete} />}
              actions={(
                <>
                  <Button onClick={handleRemove}>
                    { localize(localeButtonKeys.delete) }
                  </Button>
                  <Button onClick={handleCancelRemove}>
                    { localize(localeButtonKeys.cancel) }
                  </Button>
                </>
              )}
              colour={settings.colours.alert.alert}
            >
              { localize(localeContentKeys.confirmRemove) }
            </Banner>
          ) }
          { !!(integrationChannels && integrationChannels.length)
            ?
            <ChannelTable
              channels={integrationChannels}
              generateActions={generateActions}
            />
            : <Text>{ localize(localeContentKeys.notAssigned) }</Text>
          }
          { !edit &&
            <Button onClick={handleManage}>
              { localize(localeButtonKeys.add) }
            </Button>
          }
          { edit &&
            <Segment
              title="Available Channels"
            >
              { !!(availableChannels && availableChannels.length)
                ? <ChannelTable
                  channels={availableChannels}
                  generateActions={generateActions}
                />
                : <Text>{ localize(localeContentKeys.notFound) }</Text>
              }
              <Button onClick={handleCancel}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </Segment>
          }
        </Section>
      }
    </>
  );
}

export default IntegrationChannels;
