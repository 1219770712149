import React, { useCallback, useState } from 'react';

import { Location } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useServices from '#hooks/useServices';

import LocationForm from '#components/locations/LocationForm';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.locations.locationDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.locations;

interface LocationDetailsProps {
  location : Location;
}

function LocationDetailsForm({ location : init } : LocationDetailsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    state : location,
    editing,
    setEditing,
    reset,
  } = useFormContext<Location>();
  const { refreshLocation, updateLocation, deleteLocation } = useServices();

  const [saved, setSaved] = useState(true);
  const [deleting, setDeleting] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (!location?.id) return;

    const updatedLocation = await refreshLocation(location.id);

    if (!updatedLocation) return;
    setSaved(true);
  }, [location, refreshLocation, setSaved]);

  const handleUpdate = useCallback(async () => {
    if (!location) return;

    const updatedLocation = await updateLocation(location);

    if (!updatedLocation) return;
    setSaved(true);
    setEditing(false);
  }, [location, updateLocation, setSaved, setEditing]);

  const handleCancel = useCallback(() => {
    setEditing(false);
    reset();
  }, [setEditing, reset]);

  const handleEdit = useCallback(() => {
    setSaved(true);
    setEditing(true);
  }, [setEditing, setSaved]);

  const handleChange = useCallback(() => {
    setSaved(false);
  } , [setSaved]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleConfirmDelete = useCallback(async () => {
    if (!init) return;
    const success = await deleteLocation(init);
    if (success) {
      createNotification({
        key : 'delete-location-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.delete} />),
        colour : settings.colours.alert.primary,
      });
      navigate('/locations/');
    } else {
      createNotification({
        key : 'delete-location-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.delete} />),
        colour : settings.colours.alert.alert,
      });
    }
    setDeleting(false);
  }, [init, deleteLocation, navigate, createNotification, setDeleting]);

  return (
    <>
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button
                onClick={handleConfirmDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { !editing
        ? <>
          <Button onClick={handleRefresh} disabled={deleting}>
            { localize(localeButtonKeys.refresh) }
          </Button>
          <Button onClick={handleEdit} disabled={deleting}>
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleUpdate}
            disabled={saved}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
      }
      <LocationForm location={init} onChange={handleChange} />
    </>
  );
}

function LocationDetails({ location } : LocationDetailsProps) {
  return (
    <FormProvider
      init={location}
      editingInit={false}
    >
      <LocationDetailsForm location={location} />
    </FormProvider>
  )
}

export default LocationDetails;
