import React, { createContext, useState } from "react";

const SidebarContext = createContext({
  open: false,
  setOpen: (open: boolean) => {},
});

interface NavigationListProviderProps {
  children : React.ReactNode;
}

export function SidebarProvider({
  children
} : NavigationListProviderProps) {
  const [open, setOpen] = useState(false);

  const context = {
    open,
    setOpen,
  }

  return (
    <SidebarContext.Provider value={context}>
      {children}
    </SidebarContext.Provider>
  )
}

export default SidebarContext;
