import React, { useCallback } from 'react';

import { Category } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import useCategories from '#hooks/useCategories';

import { settings } from '#materials';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import CategoryForm from '#components/categories/CategoryForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.categories.createCategory;
const localeButtonKeys = locale.keys.buttons;

interface CreateCategoryProps {
  onSave? : (category : Category) => void;
  onCancel? : () => void;
}

function CreateCategoryControl({ onSave, onCancel } : CreateCategoryProps) {
  const { state : category, reset } = useFormContext<Category>();
  const { createCategory } = useCategories();

  const handleSave = useCallback(async () => {
    if (!category) return;

    const newCategory = await createCategory(category);
    if (newCategory && onSave) onSave(newCategory);
  }, [category, createCategory, onSave]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Section title={localize(localeContentKeys.title)}>
      { !!category && <CategoryForm category={category} /> }
      <Button
        onClick={handleSave}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
    </Section>
  );
}

function CreateCategory({ onSave, onCancel } : CreateCategoryProps) {
  return (
    <FormProvider
      init={{
        name : '',
        tagId : '',
        subcategoryIds : [],
        productIds : [],
        availableChannelIds : [],
      }}
      editing={true}
    >
      <CreateCategoryControl onSave={onSave} onCancel={onCancel} />
    </FormProvider>
  );
}

export default CreateCategory;
