import React, { useCallback, useEffect, useState } from 'react';

import { Integration, ExternalLocation } from '#types';

import useIntegration from '#hooks/useIntegrations';

import { settings } from '#materials';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localeKeys = locale.keys.tables.locationIntegrations;

export const TABLE_KEYS = {
  integration : 'integrationId',
  externalId : 'externalId',
  name : 'name',
  actions : 'actions',
} as const;
export type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defatultTableKeys = Object.values(TABLE_KEYS);

interface ExternalLocationsTableProps {
  externalLocations : ExternalLocation[];
  tableKeys? : TableKey[];
  generateActions? : (location : ExternalLocation) => CellElement;
}

function ExternalLocationsTable({
  externalLocations,
  tableKeys = defatultTableKeys,
  generateActions,
} : ExternalLocationsTableProps) {
  const { retrieveIntegrations } = useIntegration();

  const [
    integrations,
    setIntegrations,
  ] = useState<{ [key : string] : Integration | null }>({});
  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const retrieve = useCallback(async () => {
    const newIntegrations = await retrieveIntegrations();
    if (newIntegrations) setIntegrations(newIntegrations);
  }, [retrieveIntegrations]);

  const generateHead = useCallback(() => {
    function generate(key : TableKey) {
      switch (key) {
        case TABLE_KEYS.externalId:
          return (
            <TableCell width={settings.dimensions.large} key={key}>
              { localize(localeKeys.headings.externalId) }
            </TableCell>
          );
        case TABLE_KEYS.integration:
          return (
            <TableCell  key={key}>
              { localize(localeKeys.headings.integration) }
            </TableCell>
          );
        case TABLE_KEYS.name:
          return (<TableCell key={key}>
            { localize(localeKeys.headings.name) }
          </TableCell>);
        default:
          return (<TableCell key={key} />);
      }
    }

    return (
      <>
        { tableKeys.map((key) => (
          <React.Fragment key={key}>{generate(key)}</React.Fragment>
        )) }
      </>
    );
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    function generate(key : TableKey, location : ExternalLocation) {
      switch (key) {
        case TABLE_KEYS.externalId:
          return (<TableCell key={key}>{location.externalId}</TableCell>);
        case TABLE_KEYS.integration:
          return (
            <TableCell key={key}>
              {integrations[location.integrationId]?.name}
            </TableCell>
          );
        case TABLE_KEYS.name:
          return (<TableCell key={key}>{location.name}</TableCell>);
        case TABLE_KEYS.actions:
          return (
            <React.Fragment key={key}>
              { generateActions?.(location) }
            </React.Fragment>
          )
        default:
          return (<TableCell key={key} />);
      }
    }

    return externalLocations.map((location) => (
      tableKeys.map((key) => (
        <React.Fragment key={key}>{generate(key, location)}</React.Fragment>
      ))
    ));
  }, [externalLocations, integrations, generateActions, tableKeys]);

  useEffect(() => { retrieve(); }, [retrieve]);
  useEffect(() => { setHead(generateHead()); }, [generateHead]);
  useEffect(() => { setRows(generateRows()); }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={20}
    />
  );
}

export default ExternalLocationsTable;
