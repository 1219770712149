import React, { useCallback, useEffect, useState } from 'react';

import { Fulfilment, Order, isFulfilmentStatus } from '#types';

import useNotifications from '#hooks/useNotifications';
import useOrders from '#hooks/useOrders';
import useSubscriptions from '#hooks/useSubscriptions';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Banner from '#materials/Banner';
import Button from '#materials/Button';
import ToggleButton, { ToggleButtonGroup } from '#materials/ToggleButton';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import OrdersSearchControl from '#components/orders/OrdersSearch';
import OrderTable from '#components/orders/OrderTable';
import CreateOrder from '#components/orders/CreateOrder';
import Holds from '#components/orders/Holds';

import locale, { localize } from '#utils/locale';
import { DraftOrder } from '#types';

const localeContentKeys = locale.keys.content.orders.orderIndex;
const localeNotificationKeys = locale.keys.notifications;
const localeTableKeys = locale.keys.tables.orders;
const localeButtonKeys = locale.keys.buttons;

function payableOrders(orders : DraftOrder[]) : Order[] {
  return orders.filter((order) => order?.order?.id && !order?.paid)
    .map((order) => order.order) as Order[];
}

function OrderIndex() {
  const { createNotification } = useNotifications();
  const {
    refreshLineItems,
    bulkUpdateFulfilments,
    refreshOrders,
    refreshHolds,
    buildOrders,
    generateOrderUrl,
    payOrders,
  } = useOrders();
  const { refreshSubscriptions } = useSubscriptions();

  const [refreshing, setRefreshing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [orders, setOrders] = useState<DraftOrder[]>([]);

  const [confirmProcess, setConfirmProcess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState('');

  const retrieve = useCallback(async () => {
    await buildOrders();
  }, [buildOrders]);

  const refresh = useCallback(async () => {
    setRefreshing(true);
    await Promise.all([
      refreshOrders(),
      refreshLineItems(),
      refreshSubscriptions(),
      refreshHolds(),
    ]);
    await retrieve();
    setRefreshing(false);
  }, [
    retrieve,
    refreshSubscriptions,
    refreshLineItems,
    refreshOrders,
    refreshHolds,
  ]);

  const handleCreate = useCallback(() => {
    setCreating(true);
  }, [setCreating]);

  const handleCancel = useCallback(() => {
    setCreating(false);
  }, [setCreating]);

  const handleInitProcess = useCallback(() => {
    setConfirmProcess(true);
  }, [setConfirmProcess]);

  const handleCancelProcess = useCallback(() => {
    setConfirmProcess(false);
  }, [setConfirmProcess]);

  const handleConfirmProcess = useCallback(async () => {
    const realOrders = payableOrders(orders);
    if (!realOrders.length) return;

    setProcessing(true);
    const paidOrders = await payOrders(realOrders);
    if (paidOrders) {
      createNotification({
        key : 'process-order-success',
        message : localize(localeNotificationKeys.orders.process.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'process-order-error',
        message : localize(localeNotificationKeys.orders.process.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
    setProcessing(false);
    setConfirmProcess(false);
  }, [orders, payOrders, createNotification]);

  const generateActions = useCallback((order : DraftOrder) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          href={generateOrderUrl(order)}
        >
          <Icon icon={settings.svgIcons.receipt} />
        </Action>
      </TableActionCell>
    )
  }, [generateOrderUrl]);

  const handleStatusInit = useCallback((status : string) => {
    setConfirmStatus(status);
  }, [setConfirmStatus]);

  const handleStatusCancel = useCallback(() => {
    setConfirmStatus('');
  }, [setConfirmStatus]);

  const handleStatusConfirm = useCallback(async () => {
    if (!isFulfilmentStatus(confirmStatus)) return;
    const fulfilments = orders.reduce((acc, order) => {
      if (order.order?.fulfilments) {
        return acc.concat(Object.values(order.order.fulfilments));
      }
      return acc;
    }, [] as Fulfilment[]);
    if (!fulfilments.length) return;

    const updates = await bulkUpdateFulfilments(
      fulfilments.map((fulfilment) => ({
        ...fulfilment,
        status : confirmStatus,
      })
    ));
    if (updates) {
      createNotification({
        key : 'update-fulfilments-success',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.success,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'update-fulfilments-error',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.error,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
    setConfirmStatus('');
  }, [orders, confirmStatus, bulkUpdateFulfilments, createNotification]);

  useEffect(() => { retrieve() }, [retrieve]);

  const busy = refreshing || processing || confirmProcess || !!confirmStatus;
  const status =
    (orders.every((order) => order.status === 'fulfilled') && 'fulfilled')
    || (orders.every((order) => order.status === 'ready') && 'ready')
    || (orders.every((order) => order.status === 'inProgress') && 'inProgress')
    || ''

  return (
    <>
      <Section
        title={localize(localeContentKeys.title)}
        text={localize(localeContentKeys.body)}
      >
        <Button onClick={refresh} disabled={refreshing}>
          { localize(localeButtonKeys.refresh) }
        </Button>
        <Button onClick={handleCreate} disabled={creating}>
          { localize(localeButtonKeys.new) }
        </Button>
      </Section>
      { creating && (
        <CreateOrder
          onSave={handleCancel}
          onCancel={handleCancel}
        />
      ) }
      <Holds />
      <Section
        title={localize(localeContentKeys.index.title)}
        text={localize(localeContentKeys.index.body)}
      >
        { confirmProcess && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.info} />)}
            actions={(
              <>
                <Button onClick={handleConfirmProcess}>
                  { localize(localeButtonKeys.process) }
                </Button>
                <Button onClick={handleCancelProcess}>
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.secondary}
          >
            {`${localize(localeContentKeys.index.confirmProcess)}
              (${payableOrders(orders).length})`}
          </Banner>
        ) }
        { confirmStatus && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.info} />)}
            actions={(
              <>
                <Button onClick={handleStatusConfirm}>
                  { localize(localeButtonKeys.update) }
                </Button>
                <Button onClick={handleStatusCancel}>
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.secondary}
          >
            {`${localize(localeContentKeys.index.confirmStatus)}
              (${orders.length})`}
          </Banner>
        ) }
        <Button
          onClick={handleInitProcess}
          disabled={busy || !payableOrders(orders).length}
        >
          { orders.every(order => order.paid)
            ? localize(localeContentKeys.paid)
            : localize(localeContentKeys.processPayments)
          }
        </Button>
        <ToggleButtonGroup>
            <ToggleButton
              value="inProgress"
              selected={status === 'inProgress'}
              onSelect={handleStatusInit}
              first
            >
              { localize(localeContentKeys.markInProgress) }
            </ToggleButton>
            <ToggleButton
              value="ready"
              selected={status === 'ready'}
              onSelect={handleStatusInit}
            >
              { localize(localeContentKeys.markReady) }
            </ToggleButton>
            <ToggleButton
              value="fulfilled"
              selected={status === 'fulfilled'}
              onSelect={handleStatusInit}
              last
            >
              { localize(localeContentKeys.markFulfiled) }
            </ToggleButton>
          </ToggleButtonGroup>
        <OrdersSearchControl setOrders={setOrders}/>
        <OrderTable
          orders={orders}
          generateActions={generateActions}
          pageCount={20}
        />
      </Section>
    </>
  );
}

export default OrderIndex;
