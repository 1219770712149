import React, { useCallback, useEffect, useState } from 'react';

import { Hold } from '#types';

import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import { listRecords } from '#utils/data';
import { formats, formatDateTime } from '#utils/date';
import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.holds;

export const TABLE_KEYS = {
  id : 'id',
  location : 'locations',
  start : 'start',
  end : 'end',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultTableKeys = Object.values(TABLE_KEYS);

interface HoldTableProps {
  holds : Hold[];
  tableKeys? : TableKeys[];
  pageCount? : number;
  generateActions? : (hold : Hold) => CellElement;
}

function HoldTable({
  holds,
  tableKeys = defaultTableKeys,
  pageCount = 20,
  generateActions,
} : HoldTableProps) {
  const { services, locations } = useServices();

  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const generateHead = useCallback(() => {
    setHead(
      <>
        { tableKeys.map((key) => {
          const mapKey = `hold-table-head-${key}`;
          switch(key) {
            case TABLE_KEYS.id:
              return (<TableCell
                key={mapKey}
                width={settings.dimensions.xsmall}
              >
                { localize(localeTableKeys.headings.id) }
              </TableCell>);
            case TABLE_KEYS.location:
              return (<TableCell
                key={mapKey}
                width={settings.dimensions.medium}
              >
                { localize(localeTableKeys.headings.location) }
              </TableCell>);
            case TABLE_KEYS.start:
              return (<TableCell
                key={mapKey}
                width={settings.dimensions.medium}
              >
                { localize(localeTableKeys.headings.start) }
              </TableCell>);
            case TABLE_KEYS.end:
              return (<TableCell
                key={mapKey}
                width={settings.dimensions.medium}
              >
                { localize(localeTableKeys.headings.end) }
              </TableCell>);
            default:
              return null;
          }
        })}
      </>
    );
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    setRows(
      holds.map((hold) => {
        const location = hold.serviceIds.length
          ? listRecords(locations).find((location) => {
            const allServices = listRecords(services);
            return allServices.some((s) => s.locationId === location.id
              && !allServices.some((s) => s.id
                && hold.serviceIds.includes(s.id)
                && s.locationId !== location.id
              ));
          }) : null;

        return (
          <React.Fragment key={hold.id}>
            { tableKeys.map((key) => {
              switch(key) {
                case TABLE_KEYS.id:
                  return (<TableCell key={key}>
                    { hold.id }
                  </TableCell>);
                case TABLE_KEYS.location:
                  return (<TableCell key={key}>
                    { location?.name }
                  </TableCell>);
                case TABLE_KEYS.start:
                  return (<TableCell key={key}>
                    { formatDateTime(hold.start, formats.easy) }
                  </TableCell>);
                case TABLE_KEYS.end:
                  return (<TableCell key={key}>
                    { formatDateTime(hold.end, formats.easy) }
                  </TableCell>);
                case TABLE_KEYS.actions:
                  return (<TableCell key={key}>
                    { generateActions?.(hold) ?? null }
                  </TableCell>);
                default:
                  return (<TableCell key={key} />);
              }
            })}
          </React.Fragment>
        );
      })
    );
  }, [holds, tableKeys, generateActions, services, locations]);

  useEffect(() => generateHead(), [generateHead]);
  useEffect(() => generateRows(), [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={pageCount}
    />
  );
}

export default HoldTable;
