import React from 'react';

import { Dimension, settings } from '#materials';
import Center from '#materials/Center';
import FlexItem from '#materials/FlexItem';

import Copyright from '#components/dashboard/Copyright';

interface WorkspaceProps {
  width? : Dimension;
  children? : React.ReactNode;
}

function Workspace({
  width = settings.dimensions.large,
  children,
} : WorkspaceProps) {
  return (
    <FlexItem
      component={settings.components.main}
      grow
      overflow
      fill
      height={settings.dimensions.fullview}
    >
      <Center
        maxWidth={width}
        margin={{
          top : settings.spacings.xxlarge,
        }}
      >
        { children }
        <Copyright />
      </Center>
    </FlexItem>
  )
}

export default Workspace;
