import React, { useEffect, useState } from 'react';

import { Route } from '#types';

import useRoutes from '#hooks/useRoutes';

import Section from '#components/dashboard/Section';
import RouteDetails from '#components/routes/RouteDetails';
import RouteAreas from '#components/routes/RouteAreas';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.routes.routeDetails;

interface RouteInfoProps {
  routeId : number;
}

function RouteInfo({ routeId } : RouteInfoProps) {
  const { retrieveRoute } = useRoutes();

  const [route, setRoute] = useState<Route | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function retrieve() {
      const newRoute = await retrieveRoute(routeId);
      if (newRoute) setRoute(newRoute);
      setLoaded(true);
    }
    retrieve();
  }, [routeId, retrieveRoute]);


  if (!loaded) return <></>;
  if (!route) return (
    <Section title={localize(localeContentKeys.notFound)}> </Section>
  );

  return (
    <>
      <Section
        title={`${route.name} (#${route.id})`}
        text={localize(localeContentKeys.text)}
      >
        <RouteDetails route={route} />
      </Section>
      <RouteAreas route={route} />
    </>
  );
}

export default RouteInfo;
