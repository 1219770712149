import Box from '@mui/material/Box';
import MuiTableCell from '@mui/material/TableCell';

import Button from '#materials/Button';
import IconButton from '#materials/IconButton';
import CheckboxInput from '#materials/CheckboxInput';
import TextInput from '#materials/TextInput';
import Select from '#materials/Select';

import {
  OptionalChildren,
  ButtonColour,
  Direction,
  Dimension,
  Spacing,
  Alignment,
  Font,
  InputType,
  InputFormat,
  convert,
  settings,
} from '#materials/types';

import { px } from '#materials';

interface CellProps {
  id? : string;
  colSpan? : number;
  direction? : Direction;
  alignment? : Alignment;
  justify? : Alignment;
  height? : Dimension;
  width? : Dimension;
  padding? : Spacing;
  font? : Font;
  faded? : boolean;
  children? : React.ReactNode;
}

interface CheckboxCellProps extends CellProps {
  id : string,
  label? : string,
  checked : boolean;
  onChange : (checked : boolean) => void;
  disabled? : boolean;
}

interface ButtonCellProps extends CellProps {
  onClick : () => void;
  disabled? : boolean;
  colour? : ButtonColour;
}

interface TextBaseInputCellProps extends CellProps {
  id : string;
  label? : string;
  disabled? : boolean;
}

interface TextStringInputCellProps extends TextBaseInputCellProps {
  value : string;
  onChange? : (value: string) => void;
  inputType? : typeof settings.inputType.string;
}

interface TextNumberInputCellProps extends TextBaseInputCellProps {
  value : number | null;
  onChange? : (value: number | null) => void;
  inputType : typeof settings.inputType.number;
  inputFormat? : typeof settings.inputFormat.int |
    typeof settings.inputFormat.float |
    typeof settings.inputFormat.currency;
}

interface TextInputCellProps extends TextBaseInputCellProps {
  value : any;
  onChange? : any;
  inputType? : InputType;
  inputFormat? : InputFormat;
}

interface SelectCellProps<T> extends CellProps {
  label? : string;
  selected : T | null;
  options : T[];
  onChange : (value : T | null) => void;
  labelGenerator : (value : T) => string;
  keyGenerator? : (value : T) => string;
  isEqual? : (value1 : T, value2 : T) => boolean;
  renderer? : (value : T) => React.ReactNode;
  search? : (value : string) => Promise<T[] | null>;
  width? : Dimension;
  disableClear? : boolean;
  disabled? : boolean;
}

interface ActionProps {
  label : string;
  href? : string;
  onClick? : () => void;
  disabled? : boolean;
  colour? : ButtonColour;
  children : React.ReactNode;
}

export function Action({
  label,
  href,
  onClick,
  disabled = false,
  colour = settings.colours.button.primary,
  children,
} : ActionProps) {
  return (
    <IconButton
      label={label}
      href={href}
      onClick={onClick}
      disabled={disabled}
      size={settings.sizes.small}
      spacing={settings.spacings.dense}
      colour={colour}
    >
      { children }
    </IconButton>
  );
}

interface ActionCellProps extends CellProps {
  width? : Dimension;
  children? : OptionalChildren<React.ReactElement<ActionProps>>;
}

export type CellElement = React.ReactElement<CellProps>;

function TableSubCell({
  width,
  height,
  padding,
  alignment,
  children,
} : CellProps) {
  return (
    <Box
      sx={{
        display : 'flex',
        flexDirection : 'row',
        alignItems : 'center',
        justifyContent : alignment,
        whiteSpace : 'pre',
        height : height === settings.dimensions.auto ? undefined : px(3),
        width : width === settings.dimensions.full ? '100%' : 'auto',
        py : padding === settings.spacings.dense ? [0] : [1],
        boxSizing : 'border-box',
      }}
    >
      { children }
    </Box>
  );
}

export function TableCell({
  id,
  colSpan = 1,
  direction = settings.directions.column,
  alignment = settings.alignments.left,
  justify = settings.alignments.start,
  width,
  height = settings.dimensions.medium,
  padding = settings.spacings.medium,
  font,
  faded = false,
  children,
} : CellProps) {
  if (children instanceof Array) {
    return (
      <MuiTableCell
        colSpan={colSpan}
        sx={{
          padding : [0],
          borderStyle : 'none',
        }}
        style={{ opacity : faded ? 0.6 : 1}}
      >
        <Box
          sx={{
            display : 'flex',
            flexDirection : direction,
            alignItems : alignment,
            justifyContent : justify,
            gap : '0.8px',
            width : convert.columnWidth(width),
            px : [2],
            py : [0],
            fontFamily : convert.font(font),
          }}
        >
          { children.map((child : CellElement, index : number) => (
            <TableSubCell
              key={`${id ?? 'cell'}-${index}`}
              width={direction === settings.directions.column
                ? settings.dimensions.full
                : undefined}
              height={height}
              padding={padding}
              alignment={alignment}
            >
              { child }
            </TableSubCell>
          )) }
        </Box>
      </MuiTableCell>
    );
  } else {
    return (
      <MuiTableCell
        colSpan={colSpan}
        sx={{
          padding : [0],
          borderStyle : 'none',
        }}
        style={{ opacity : faded ? 0.6 : 1 }}
      >
        <Box
          sx={{
            display : 'flex',
            flexDirection : direction,
            alignItems : alignment,
            justifyContent : justify,
            gap : '0.8px',
            width : convert.columnWidth(width),
            px : [2],
            py : [0],
            overflow : 'hidden',
            fontFamily : convert.font(font),
          }}
        >
          <TableSubCell
            width={direction === settings.directions.column
              ? settings.dimensions.full
              : undefined}
            height={height}
            padding={padding}
            alignment={alignment}
          >
            { children }
          </TableSubCell>
        </Box>
      </MuiTableCell>
    );
  }
}

export function TableButtonCell({
  children,
  onClick,
  disabled,
  colour,
} : ButtonCellProps) {
  return (
    <MuiTableCell
      sx={{
        padding : [0],
        borderStyle : 'none',
      }}
    >
      <Button
        onClick={onClick}
        disabled={disabled}
        variant={settings.variants.button.table}
        size={settings.sizes.small}
        colour={colour}
      >
        { children }
      </Button>
    </MuiTableCell>
  );
}

export function TableCheckboxCell({
  id,
  label,
  checked,
  onChange,
  disabled,
  width,
} : CheckboxCellProps) {
  return (
    <MuiTableCell
      sx={{
        padding : [0],
        borderStyle : 'none',
      }}
    >
      <Box
        sx={{
          width : convert.columnWidth(width),
          height : px(2),
          px : [0.5],
          py : [0.5],
        }}
      >
        <CheckboxInput
          id={id}
          label={label}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </Box>
    </MuiTableCell>
  );
}

function TableTextStringInputCell({
  id,
  value,
  onChange,
  disabled,
  inputType,
  width,
} : TextStringInputCellProps) {
  return (
    <MuiTableCell
      sx={{
        padding : [0],
        borderStyle : 'none',
      }}
    >
      <Box
        sx={{
          width : convert.columnWidth(width),
          px : [0.5],
          py : [0],
        }}
      >
      <TextInput
        id={id}
        value={value}
        onChange={onChange}
        inputType={inputType}
        disabled={disabled}
        spacing={settings.spacings.dense}
        width={settings.dimensions.full}
      />
      </Box>
    </MuiTableCell>
  );
}

function TableTextNumberInputCell({
  id,
  value,
  onChange,
  disabled,
  inputType,
  inputFormat,
  width,
} : TextNumberInputCellProps) {
  return (
    <MuiTableCell
      sx={{
        padding : [0],
        borderStyle : 'none',
      }}
    >
      <Box
        sx={{
          width : convert.columnWidth(width),
          px : [0.5],
          py : [0],
        }}
      >
        <TextInput
          id={id}
          value={value}
          onChange={onChange}
          inputType={inputType}
          inputFormat={inputFormat}
          disabled={disabled}
          spacing={settings.spacings.dense}
          width={settings.dimensions.full}
        />
      </Box>
    </MuiTableCell>
  );
}

export function TableTextInputCell(props : TextStringInputCellProps) : JSX.Element;
export function TableTextInputCell(props : TextNumberInputCellProps) : JSX.Element;
export function TableTextInputCell(props : TextInputCellProps) {
  switch (props.inputType) {
    case settings.inputType.number:
      return <TableTextNumberInputCell {...props} inputType={props.inputType}/>;
    default:
      return <TableTextStringInputCell
        {...props}
        inputType={settings.inputType.string}
      />;
  }
}

export function TableSelectCell({
  label,
  selected,
  options,
  onChange,
  labelGenerator,
  keyGenerator,
  isEqual,
  renderer,
  search,
  disableClear,
  disabled,
  width,
} : SelectCellProps<any>) {
  return (
    <MuiTableCell
      sx={{
        padding : [0],
        borderStyle : 'none',
      }}
    >
      <Box
        sx={{
          width : convert.columnWidth(width),
          px : [0.5],
          py : [0],
        }}
      >
        <Select
          label={''}
          selected={selected}
          options={options}
          onChange={onChange}
          labelGenerator={labelGenerator}
          keyGenerator={keyGenerator}
          isEqual={isEqual}
          renderer={renderer}
          search={search}
          spacing={settings.spacings.dense}
          width={settings.dimensions.full}
          disableClear={disableClear}
          disabled={disabled}
        />
      </Box>
    </MuiTableCell>
  );
}

export function TableActionCell({
  width = settings.dimensions.small,
  children,
} : ActionCellProps) {
  return TableCell({
    direction : settings.directions.row,
    justify : settings.alignments.end,
    width : width,
    children,
  });
}
