import React, { useCallback } from 'react';

import Box from '@mui/material/Box';

import {
  Alignment,
  Component,
  Dimension,
  Margin,
  convert,
  settings,
} from './types';

interface FlexProps {
  onSubmit? : () => void;
  preventDefault? : boolean;
  width? : Dimension;
  wrap? : boolean;
  alignment? : Alignment;
  justification? : Alignment;
  margin? : Margin;
  component? : Component;
  children : React.ReactNode;
}

function Flex({
  width = settings.dimensions.full,
  wrap = false,
  alignment,
  justification,
  component = settings.components.div,
  margin,
  onSubmit,
  preventDefault = true,
  children,
} : FlexProps) {
  const handleSubmit = useCallback(
    (event : React.FormEvent<HTMLFormElement>) => {
      if (preventDefault) event.preventDefault();
      if (onSubmit) onSubmit();
    },
    [onSubmit, preventDefault],
  );

  return (
    <Box
      component={component}
      onSubmit={(component === settings.components.form)
        ? handleSubmit
        : undefined
      }
      sx={{
        display : 'flex',
        flexWrap : wrap ? 'wrap' : 'nowrap',
        alignItems : alignment ? convert.flexAlignment(alignment) : undefined,
        justifyContent : justification
          ? convert.flexJustification(justification)
          : undefined,
        width : convert.width(width),
        mt : margin ? convert.margin(margin).top : 'auto',
        mr : margin ? convert.margin(margin).right : 'auto',
        mb : margin ? convert.margin(margin).bottom : 'auto',
        ml : margin ? convert.margin(margin).left : 'auto',
      }}
    >
      { children }
    </Box>
  );
}

export default Flex;
