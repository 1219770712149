import React from 'react';

import Container from '@mui/material/Container';
import { Dimension, Margin, Padding, convert } from './types';

interface CenterProps {
  id? : string;
  width? : Dimension;
  height? : Dimension;
  maxWidth? : Dimension;
  margin? : Margin;
  padding? : Padding;
  children? : React.ReactNode;
  fullWidth? : boolean;
}

function Center({
  id,
  width,
  height,
  maxWidth,
  margin,
  padding,
  fullWidth = true,
  children,
} : CenterProps) {
  return (
    <Container
      id={id}
      maxWidth={false}
      disableGutters={!!padding}
      sx={{
        ...(width
          ? { width : convert.width(width) }
          : { width : fullWidth ? '100%' : 'fit-content' }),
        ...(maxWidth && { maxWidth : convert.width(maxWidth) }),
        ...(height && { height : convert.height(height) }),
        ...(margin
          ? {
            mt : convert.margin(margin).top,
            mr : convert.margin(margin).right,
            mb : convert.margin(margin).bottom,
            ml : convert.margin(margin).left,
          } : { margin : 'auto' }),
        ...(padding && {
            pt : convert.padding(padding).top,
            pr : convert.padding(padding).right,
            pb : convert.padding(padding).bottom,
            pl : convert.padding(padding).left,
        }),
      }}
    >
      { children }
    </Container>
  );
}

export default Center;
