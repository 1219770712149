import React, { useEffect, useState } from 'react';

import { Product } from '#types';

import useProducts from '#hooks/useProducts';

import Section from '#components/dashboard/Section';
import ProductDetails from '#components/products/ProductDetails';
import ProductImages from '#components/products/ProductImages';
import ProductIntegrations from '#components/products/ProductIntegrations';
import ProductCategories from '#components/products/ProductCategories';
import ProductSuppliers from '#components/products/ProductSuppliers';
import ProductTags from '#components/products/ProductTags';
import AddAssembly from '#components/assemblies/AddAssembly';
import ProductAssemblies from '#components/products/ProductAssemblies';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productDetails;

interface ProductInfoProps {
  productId : number;
}

function ProductInfo( { productId } : ProductInfoProps) {
  const { retrieveProduct } = useProducts();

  const [product, setProduct] = useState<Product | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
  async function retrieve() {
      const newProduct = await retrieveProduct(productId, true);
      if (newProduct) setProduct(newProduct);
      setLoaded(true);
    }
    retrieve();
  }, [productId, retrieveProduct]);

  if (!loaded) return <></>;
  if (!product) return (
    <Section title={localize(localeContentKeys.notFound)}> </Section>
  );

  return (
    <>
      <Section
        title={`${product.name} (#${product.id})`}
        text={localize(localeContentKeys.text)}
      >
        <ProductDetails product={product} />
      </Section>
      <ProductImages product={product} />
      <ProductIntegrations product={product} />
      <ProductCategories product={product} />
      <ProductSuppliers product={product} />
      <ProductTags product={product}  />
      <AddAssembly product={product} />
      <ProductAssemblies product={product} />
    </>
  );
}

export default ProductInfo;
