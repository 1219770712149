import React, { useCallback, useEffect, useState } from 'react';

import { Tax } from '#types';

import useNotifications from '#hooks/useNotifications';
import useTaxes from '#hooks/useTaxes';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import TaxTable from '#components/taxes/TaxTable';
import CreateTax from '#components/taxes/CreateTax';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.taxes.taxIndex;
const localeTableKeys = locale.keys.tables.taxes;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.taxes;

interface TaxIndexProps {
  showHead? : boolean;
}

function TaxIndex({ showHead = true } : TaxIndexProps) {
  const { createNotification } = useNotifications();
  const {
    refreshTaxes,
    retrieveTaxes,
    deleteTax,
  } = useTaxes();

  const [taxes, setTaxes] = useState<Tax[]>([]);
  const [createNew, setCreateNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<Tax | null>(null);

  const handleRefresh = useCallback(async () => {
    await refreshTaxes();
  }, [refreshTaxes]);

  const handleNew = useCallback(() => {
    setCreateNew(true);
  }, [setCreateNew]);

  const handleSave = useCallback((tax : Tax) => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleCancel = useCallback(() => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleInitDelete = useCallback((tax : Tax) => () => {
    setDeleteTarget(tax);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteTax(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-tax-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : <Icon icon={settings.svgIcons.percent} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-tax-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : <Icon icon={settings.svgIcons.percent} />,
        colour : settings.colours.alert.alert,
      });
    }
    setDeleteTarget(null);
  }, [deleteTarget, deleteTax, createNotification]);

  const generateActions = useCallback((tax : Tax) => {
    return (
      <TableActionCell>
        <Action
          label={`${localize(localeTableKeys.actions.view)} ${tax.id}`}
          href={`/taxes/${tax.id}`}
        >
          <Icon icon={settings.svgIcons.percent}/>
        </Action>
        <Action
          label={`${localize(localeTableKeys.actions.delete)}`}
          onClick={handleInitDelete(tax)}
          disabled={deleteTarget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete}/>
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  useEffect(() => {
    const retrieve = async () => {
      const newTaxes = await retrieveTaxes();
      if (newTaxes) {
        setTaxes(listRecords(newTaxes));
      }
    };
    retrieve();
  }, [retrieveTaxes, setTaxes]);

  return (
    <>
      <Section
        title={localize(localeContentKeys.title)}
        text={localize(localeContentKeys.body)}
      >
        <Button
          onClick={handleRefresh}
        >
          { localize(localeButtonKeys.refresh) }
        </Button>
        <Button
          onClick={handleNew}
          disabled={createNew}
        >
          { localize(localeButtonKeys.new) }
        </Button>
      </Section>
      { createNew && (
        <CreateTax
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) }
      <Section
        title={localize(localeContentKeys.index.title)}
      >
        { deleteTarget && (
          <Banner
            icon={ <Icon icon={settings.svgIcons.delete} /> }
            actions={(
              <>
                <Button
                  onClick={handleDelete}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.index.confirmDelete) }
          </Banner>
        ) }
        <TaxTable
          taxes={taxes}
          generateActions={generateActions}
        />
      </Section>
    </>
  );
}

export default TaxIndex;
