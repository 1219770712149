import React, { useCallback, useEffect, useState } from 'react';

import { Service, ServiceChannel } from '#types';

import useNotifications from '#hooks/useNotifications';
import useServices from '#hooks/useServices';

import { settings } from '#materials';
import Form from '#materials/Form';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import CreateService from '#components/services/CreateService';
import ServicesTable from '#components/services/ServicesTable';
import ServiceChannelsSelect from
  '#components/serviceChannels/ServiceChannelSelect';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.services.serviceIndex;
const localeTableKeys = locale.keys.tables.services;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.services;

interface ServiceIndexProps {
  showHead? : boolean;
}

function ServiceIndex({ showHead = true } : ServiceIndexProps) {
  const { createNotification } = useNotifications();
  const {
    retrieveServices,
    deleteService,
  } = useServices();
  const [services, setServices] = useState<Service[]>([]);
  const [filteredServices, setFilteredServices] = useState<Service[]>([]);
  const [channel, setChannel] = useState<ServiceChannel | null>(null);
  const [creatingNew, setCreatingNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<Service | null>(null);

  const retrieve = useCallback(async () => {
    const retrievedServices = await retrieveServices();
    if (retrievedServices) {
      setServices(listRecords(retrievedServices));
    }
  }, [retrieveServices, setServices]);

  const refresh = useCallback(() => {
    retrieve();
  }, [retrieve]);

  const handleCreateNew = useCallback(() => {
    setCreatingNew(true);
  }, [setCreatingNew]);

  const handleRetrieve = useCallback(() => {
    refresh();
  }, [refresh]);

  const handleCancelNew = useCallback(() => {
    setCreatingNew(false);
  }, [setCreatingNew]);

  const handleSave = useCallback(() => {
    setCreatingNew(false);
    refresh();
  }, [setCreatingNew, refresh]);

  const handleInitDelete = useCallback((service : Service) => () => {
    setDeleteTarget(service);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleConfirmDelete = useCallback(async () => {
    if (!deleteTarget) return;
    const success = await deleteService(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-service-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.localShipping} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-service-failure',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.localShipping} />),
        colour : settings.colours.alert.primary,
      });
    }
    setDeleteTarget(null);
  }, [deleteTarget, deleteService, createNotification]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  useEffect(() => {
    if (!channel) {
      setFilteredServices(services);
      return;
    }
    setFilteredServices(
      services.filter(service => service.serviceChannelId === channel.id)
    );
  }, [channel, services, setFilteredServices]);

  const generateActions = useCallback((service : Service) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          href={`/services/${service.id}`}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.localShipping}/>
        </Action>
        <Action
          label={`${localize(localeTableKeys.actions.delete)} ${service.id}`}
          onClick={handleInitDelete(service)}
          disabled={!!deleteTarget}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete}/>
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  return (
    <>
      { showHead &&
        <>
          <Section
            title={localize(localeContentKeys.title)}
            text={localize(localeContentKeys.body)}
          >
            <Button
              onClick={handleRetrieve}
            >
              { localize(localeButtonKeys.refresh) }
            </Button>
            <Button
              onClick={handleCreateNew}
            >
              { localize(localeButtonKeys.new) }
            </Button>
          </Section>
          { creatingNew &&
            <CreateService onCancel={handleCancelNew} onSave={handleSave} />
          }
        </>
      }
      <Section
        title={localize(localeContentKeys.index.title)}
        text={localize(localeContentKeys.index.body)}
      >
        { deleteTarget && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.delete} />)}
            actions={(
              <>
                <Button
                  onClick={handleConfirmDelete}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.index.confirmDelete) +
              ` (${deleteTarget.name})` }
          </Banner>
        ) }
        <Form>
          <ServiceChannelsSelect
            serviceChannel={channel}
            onChange={setChannel}
            width={settings.dimensions.half}
          />
        </Form>
        <ServicesTable
          services={filteredServices}
          generateActions={generateActions}
        />
      </Section>
    </>
  );
}

export default ServiceIndex;
