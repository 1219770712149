import React, { useCallback } from 'react';

import { Area } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import {
  TableCell,
  TableTextInputCell,
  CellElement,
} from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localTableKeys = locale.keys.tables.areaVertices;

export const TABLE_KEYS = {
  index : 'index' as const,
  latitude : 'latitude' as const,
  longitude : 'longitude' as const,
  actions : 'actions' as const,
};
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface VertexRowProps {
  area : Area;
  index : number;
  tableKeys : TableKeys[];
  generateActions? : (index : number) => CellElement;
}

function VertexRow({
  area : fallback,
  index,
  tableKeys = Object.values(TABLE_KEYS),
  generateActions = () => <TableCell />,
} : VertexRowProps) {
  const { state, dispatch, editing } = useForm<Area>();

  const setVertex = useCallback((i : number, value : number | null) => {
    if (!state || !value) return;
    const newVertices = [...state.vertices];
    newVertices[index][i] = value;
    dispatch({ vertices : newVertices });
  }, [state, index, dispatch]);

  const setLatitude = useCallback((value : number | null) => {
    setVertex(0, value);
  }, [setVertex]);

  const setLongitude = useCallback((value : number | null) => {
    setVertex(1, value);
  }, [setVertex]);

  const rowGenerator = useCallback((key : string) => {
    switch (key) {
      case TABLE_KEYS.index:
        return (<TableCell>{ index }</TableCell>);
      case TABLE_KEYS.latitude:
        return (
          <TableTextInputCell
            id={`area-${fallback.id}-${index}-${key}`}
            label={localize(localTableKeys.headings.latitude)}
            value={state?.vertices?.[index]?.[0] ?? null}
            onChange={setLatitude}
            inputType={settings.inputType.number}
            inputFormat={settings.inputFormat.float}
            disabled={!editing}
          />
        );
      case TABLE_KEYS.longitude:
        return (
          <TableTextInputCell
            id={`area-${fallback.id}-${index}-${key}`}
            label={localize(localTableKeys.headings.longitude)}
            value={state?.vertices?.[index]?.[1] ?? null}
            onChange={setLongitude}
            inputType={settings.inputType.number}
            inputFormat={settings.inputFormat.float}
            disabled={!editing}
          />
        );
      case TABLE_KEYS.actions:
        return editing
          ? generateActions(index)
          : <TableCell />;
      default:
        return (<TableCell />);
    }
  }, [
    fallback,
    index,
    generateActions,
    state,
    editing,
    setLatitude,
    setLongitude,
  ]);

  return (
    <>
      { tableKeys.map(key =>
        <React.Fragment key={`area-${fallback.id}-${index}-${key}`}>
          { rowGenerator(key) }
        </React.Fragment>
      ) }
    </>
  );
}

export default VertexRow;
