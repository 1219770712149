import React, { useCallback, useEffect, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import Box from '@mui/material/Box/Box';
import { TimeField } from '@mui/x-date-pickers/TimeField';

import { Dimension, convert, settings } from '#materials';
import FlexItem from '#materials/FlexItem';
import Select from '#materials/Select';

interface TimePickerProps {
  label : string;
  value : Date | null;
  setValue : (value : Date | null) => void;
  minuteStep? : number;
  validate? : (time : Date) => boolean;
  allowClear? : boolean;
  errors? : string[];
  hideErrors? : boolean;
  disabled? : boolean;
  width? : Dimension;
}

function TimePicker({
  label,
  value,
  setValue,
  disabled = false,
  minuteStep,
  validate,
  allowClear,
  errors,
  hideErrors = false,
  width = settings.dimensions.full,
} : TimePickerProps) {
  const [dateTime, setDateTime] = useState<Dayjs | null>(dayjs(value));
  const [timeOptions, setTimeOptions] = useState<Dayjs[]>([]);
  const [safeOptions, setSafeOptions] = useState<Dayjs[]>([]);

  const generateTimeOptions = useCallback((date : Dayjs) => {
    if (minuteStep === undefined) return [];
    const safeStep = minuteStep >= 1 ? minuteStep : 1;

    const options : Dayjs[] = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += safeStep) {
        const t = date.hour(i).minute(j);
        if (validate === undefined || validate(t.toDate())) {
          options.push(t);
        }
      }
    }
    return options;
  }, [minuteStep, validate]);

  const handleTimeChange = useCallback((newTime : Dayjs | null) => {
    if (!newTime) {
      setValue(null);
    } else {
      const newDate = value ? new Date(value.getTime()) : new Date();
      newDate.setHours(newTime.hour());
      newDate.setMinutes(newTime.minute());
      newDate.setSeconds(newTime.second());
      if (!validate || validate(newDate)) setValue(newDate);
    }
  }, [value, setValue, validate]);

  const disableTime = useCallback((time : Dayjs) => {
    if (!validate) return false;
    return !validate(new Date(time.toDate()));
  }, [validate]);

  const areTimesEqual = useCallback((a : Dayjs | null, b : Dayjs | null) => {
    if (a === null) return b === null;
    return a.isSame(b);
  }, []);

  useEffect(() => {
    const newDateTime = value ? dayjs(value) : null;
    setDateTime(newDateTime);

    const options = newDateTime ? generateTimeOptions(newDateTime) : [];
    setTimeOptions(options);
    setSafeOptions(options);
    if (
      newDateTime &&
        options.length && !options.find((t) => areTimesEqual(t, newDateTime))
    ) {
      setDateTime(options[0]);
      setValue(options[0].toDate());
    }
  }, [
    value,
    setValue,
    setDateTime,
    generateTimeOptions,
    areTimesEqual,
  ]);

  const useTimeSelector = minuteStep !== undefined;

  const validSelection = dateTime === null ||
    !!timeOptions.find((t) => areTimesEqual(t, dateTime))
  if (!validSelection && !safeOptions.find((t) => areTimesEqual(t, dateTime))) {
    safeOptions.unshift(dateTime);
  }

  return useTimeSelector
    ? (<Select
      label={label}
      selected={dateTime}
      options={validSelection ? timeOptions : safeOptions}
      onChange={handleTimeChange}
      disableClear={!allowClear}
      disabled={disabled}
      labelGenerator={(time) => time?.format('hh:mm A') ?? ''}
      isEqual={areTimesEqual}
      errors={errors?.length ? [''] : undefined}
      hideErrors={hideErrors}
    />) : (<FlexItem
      width={settings.dimensions.full}
      maxWidth={width}
    >
      <Box
        sx={{
          mx : [1],
          my : [1],
        }}
      >
        <TimeField
          label={label}
          value={dateTime}
          onChange={handleTimeChange}
          shouldDisableTime={disableTime}
          disabled={disabled}
          clearable={allowClear}
          slotProps={{
            textField: {
              ...(hideErrors && { error: false }),
            },
          }}
          sx={{
            minWidth : convert.width(settings.dimensions.full),
            "& .MuiInputBase-input.Mui-disabled" : {
              WebkitTextFillColor : "#444",
            },
          }}
        />
      </Box>
    </FlexItem>);
}

export default TimePicker;
