import React, { useCallback } from 'react';

import { Adjustment, Condition } from '#types';

import { FormProvider } from '#context/FormContext';

import { useNotifications } from '@mrktbox/clerk';
import { useFormContext } from '#hooks/useForm';
import useAdjustments from '#hooks/useAdjustments';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';

import ConditionForm from '#components/conditions/ConditionForm';

import locale, { localize } from '#utils/locale';

const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.conditions;

interface CreateConditionProps {
  adjustment : Adjustment;
  onSave? : (condition: Condition) => void;
  onCancel? : () => void;
}

interface CreateConditionControlProps extends CreateConditionProps {
  condition : Condition;
  onSave? : (condition: Condition) => void;
  onCancel? : () => void;
}

const CreateConditionControl = ({
  adjustment,
  condition : init,
  onSave,
  onCancel,
} : CreateConditionControlProps) => {
  const { createNotification } = useNotifications();
  const { state : condition, reset } = useFormContext<Condition>();
  const { createCondition } = useAdjustments();

  const handleSave = useCallback(async () => {
    if(!condition) return;
    const { productIds, serviceIds } = condition

    if(!adjustment.id) return;
    const newCondition = await createCondition(condition, {
      adjustments: [adjustment],
      products: productIds,
      services: serviceIds,
    })
    if (newCondition) {
      createNotification({
        key : 'create-condition-success',
        message: localize(localeNotificationKeys.create.success),
        icon : (<Icon icon={settings.svgIcons.settings} />),
        colour : settings.colours.alert.primary,
      })
      onSave?.(newCondition);
    } else {
      createNotification({
        key : 'create-condition-error',
        message: localize(localeNotificationKeys.create.error),
        icon : (<Icon icon={settings.svgIcons.settings} />),
        colour : settings.colours.alert.alert,
      })
    }
}, [condition, adjustment, onSave, createCondition, createNotification]);

  return (
    <>
      <Button
        onClick={handleSave}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.reset) }
      </Button>
      <Button
        onClick={onCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
      <ConditionForm condition={condition ?? init} />
    </>
  )
}

const CreateCondition = (props : CreateConditionProps) => {
  const init = {
    scope: 'order' as const,
    count: 0,
    adjustmentIds: [],
    serviceIds: [],
    productIds: [],
  }

  return (
    <FormProvider init={init} editingInit={true}>
      <CreateConditionControl condition={init} {...props}/>
    </FormProvider>
  );
}

export default CreateCondition;
