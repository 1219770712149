import React, { useEffect, useState } from 'react';

import { Tag } from '#types';

import useNavigation from '#hooks/useNavigation';
import useTags from '#hooks/useTags';
import useCategories from '#hooks/useCategories';
import useSuppliers from '#hooks/useSuppliers';

import Section from '#components/dashboard/Section';
import TagDetails from '#components/tags/TagDetails';
import TagProducts from '#components/tags/TagProducts';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.tags.tagDetails;

interface TagInfoProps {
  tagId : number;
}

function TagInfo({ tagId } : TagInfoProps) {
  const { navigate } = useNavigation();
  const { retrieveTag } = useTags();
  const { retrieveCategories } = useCategories();
  const { retrieveSuppliers } = useSuppliers();

  const [categories, setCategories] = useState<Tag[]>([]);
  const [suppliers, setSuppliers] = useState<Tag[]>([]);
  const [tag, setTag] = useState<Tag | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!tag) return;
    if (categories.some((c) => c.id === tag.id)) {
      navigate(`/categories/${tag.id}`, { replace: true });
    } else if (suppliers.some((s) => s.id === tag.id)) {
      navigate(`/suppliers/${tag.id}`, { replace: true });
    }
  }, [tag, categories, suppliers, navigate]);

  useEffect(() => {
    async function retrieve() {
      const [newTag, newCategories, newSuppliers] = await Promise.all([
        retrieveTag(tagId),
        retrieveCategories(),
        retrieveSuppliers(),
      ]);

      if (newTag) setTag(newTag);
      if (newSuppliers) setSuppliers(listRecords(newSuppliers));
      if (newCategories) setCategories(listRecords(newCategories));
      setLoaded(true);
    }
    retrieve();
  }, [tagId, retrieveTag, retrieveCategories, retrieveSuppliers]);

  if (!loaded) return <></>;
  if (!tag) return (
    <Section title={localize(localeContentKeys.notFound)}> </Section>
  );

  return (
    <>
      <Section
        title={`${tag.name} (#${tag.id})`}
        text={localize(localeContentKeys.text)}
      >
        <TagDetails tag={tag} />
      </Section>
      <TagProducts tag={tag} />
    </>
  );
}

export default TagInfo;
