import React, { useCallback, useEffect, useState } from 'react';

import { Adjustment } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.adjustments;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  amount : 'amount',
  adjustment : 'factor',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
const defaultTableKeys = Object.values(TABLE_KEYS);

interface AdjustmentsTableProps {
	adjustments : Adjustment[];
	tableKeys? : TableKeys[];
  generateActions? : (adjustment : Adjustment) => CellElement;
}

const AdjustmentsTable = ({
  adjustments,
  tableKeys = defaultTableKeys,
  generateActions = () => <></>,
} : AdjustmentsTableProps) => {
	const generateHead = useCallback(() => {
    return <>
      { tableKeys.map((key) => {
        const mapKey = `adjustment-table-head=${key}`
        switch (key) {
          case TABLE_KEYS.id:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.id) }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.name) }
            </TableCell>;
          case TABLE_KEYS.adjustment:
            return <TableCell key={mapKey}>
              { localize(localKeys.headings.adjustment) }
            </TableCell>;
          default: return <TableCell key={mapKey} />;
        }
      }) }
    </>
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    return adjustments.map((adjustment) => (
      <React.Fragment key={`adjustment-${adjustment.id}`}>
        { tableKeys.map((key) => {
          const mapKey = `adjustment-table-row-${adjustment.id}-${key}`
          switch(key) {
            case TABLE_KEYS.id:
              return <TableCell key={mapKey}>
                { `# ${adjustment.id}` }
              </TableCell>;
            case TABLE_KEYS.name:
              return <TableCell key={mapKey}>
                { adjustment.name }
              </TableCell>;
            case TABLE_KEYS.adjustment:
              return (
                <TableCell key={mapKey}>
                  { adjustment.factor
                    ? `${adjustment.factor * 100} %`
                    : formatCurrency(adjustment.currency)
                  }
                </TableCell>
              );
            case TABLE_KEYS.actions:
              return (
                <React.Fragment key={mapKey}>
                  { generateActions(adjustment) }
                </React.Fragment>
              );
            default: return <TableCell key={mapKey} />;
          }
        }) }
      </React.Fragment>
    ))
  },[adjustments, tableKeys, generateActions]);

  const [head, setHead] = useState(generateHead());
  const [rows, setRows] = useState(generateRows());

  useEffect(() => { setHead(generateHead()) }, [generateHead]);
  useEffect(() => { setRows(generateRows()) }, [generateRows]);

	return (
    <Table
      head={head}
      rows={rows}
    />
  );
}

export default AdjustmentsTable;
