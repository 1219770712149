import React, { useCallback, useEffect, useState } from 'react';

import { TimeSlot } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';
import DateTimePicker from '#materials/DateTimePicker';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.timeSlots;

function roundTime(date : Date) {
  const newDate = new Date(date);
  newDate.setSeconds(0, 0);
  return newDate;
}

interface TimeSlotFormProps {
  timeSlot : TimeSlot;
  enableStart? : boolean;
}

function TimeSlotForm({
  timeSlot: fallback,
  enableStart = true,
} : TimeSlotFormProps) {
  const { state, dispatch, editing } = useForm<TimeSlot>();

  const [roundedTime, setRoundedTime] = useState<Date>(
    roundTime(fallback.start),
  );

  const setName = useCallback((name : string) => {
    dispatch({ name })
  }, [dispatch]);

  const setStart = useCallback((newDateTime: Date | null) => {
    if (newDateTime === null) return;
    dispatch({ start: roundTime(newDateTime) });
  }, [dispatch]);

  const setDivision = useCallback((division : number | null) => {
    dispatch({ division : division ?? null })
  }, [dispatch]);

  useEffect(() => {
    setRoundedTime(roundTime(fallback.start));
  }, [fallback]);

  const idPrefix = (fallback && fallback.id)
    ? `timeSlot-${fallback?.id}`
    : `timeSlot-new`

  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localKeys.labels.name)}
        value={state?.name ?? fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      <DateTimePicker
        dateLabel={localize(localKeys.labels.startDateTime)}
        timeLabel=""
        value={roundedTime}
        setValue={setStart}
        disabled={!enableStart || !editing}
        width={settings.dimensions.half}
      />
      <TextInput
        id={`${idPrefix}-divisions`}
        label={localize(localKeys.labels.divisions)}
        value={state?.division ?? fallback.division ?? 0}
        onChange={setDivision}
        disabled={!enableStart || !editing}
        width={settings.dimensions.quarter}
        inputType={settings.inputType.time}
      />
    </Form>
  );
}

export default TimeSlotForm;
