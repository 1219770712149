import React, { useCallback, useEffect, useState } from 'react';

import { Adjustment, Tax } from '#types';

import useAdjustments from '#hooks/useAdjustments';
import useTaxes from '#hooks/useTaxes';

import { settings } from '#materials';
import Banner from '#materials/Banner';
import Button from '#materials/Button';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import Segment from '#materials/Segment';
import { Action, TableActionCell, TableCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import TaxTable from '#components/taxes/TaxTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.adjustments;
const localeButtonKeys = locale.keys.buttons;

interface AdjustmentTaxesProps {
  adjustment : Adjustment;
}

const AdjustmentTaxes = ({
  adjustment,
} : AdjustmentTaxesProps) => {
  const { addTaxToAdjustment, removeTaxFromAdjustment } = useAdjustments();
  const { retrieveTaxes } = useTaxes();

  const [taxes, setTaxes] = useState<Tax[]>([]);
  const [editing, setEditing] = useState(false);
  const [removeTax, setRemoveTax] = useState<Tax | null>(null);

  const adjustmentTaxes = adjustment.taxIds;
  const addedTaxes = taxes.filter((tax) => {
    return tax.id !== undefined && adjustmentTaxes.includes(tax.id);
  })
  const unusedTaxes = taxes.filter((tax) => {
    return (tax.id !== undefined
      && !addedTaxes.map((tax) => tax.id).includes(tax.id));
  })

  const handleToggleEditing = useCallback(() => {
    setEditing(!editing);
  }, [editing, setEditing]);

  const handleInitDelete = useCallback((tax : Tax) => {
    setRemoveTax(tax);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setRemoveTax(null);
  }, []);

  const handleAddTaxToAdjustment = useCallback(
    (adjustment : Adjustment, tax : Tax) => async () => {
    await addTaxToAdjustment(adjustment, tax);
  }, [addTaxToAdjustment]);

  const handleRemoveTaxFromAdjustment = useCallback(() => {
    return async () => {
      if (!removeTax) return;
      await removeTaxFromAdjustment(adjustment, removeTax);
      handleCancelDelete();
    }
  }, [removeTaxFromAdjustment, removeTax, adjustment, handleCancelDelete]);

  const retrieve = useCallback(async () => {
    const retrievedTaxes = await retrieveTaxes()
    setTaxes(listRecords(retrievedTaxes))
  }, [retrieveTaxes]);

  useEffect(() => { retrieve() }, [retrieve]);

  const actionGenerator = useCallback((tax : Tax) => {
    if(!tax.id) return (<TableCell />);
    const mapKey = `adjustment-tax-action-${tax.id}`;

    return (
      <>
        {
          editing
          ?
            adjustmentTaxes.includes(tax.id)
            ? (
              <TableActionCell key={mapKey}>
                <Action
                  label='remove tax from adjustment'
                  onClick={() => {
                    handleInitDelete(tax)
                  }}
                >
                  <Icon icon={settings.svgIcons.remove} />
                </Action>
              </TableActionCell>
            )
            : (
              <TableActionCell key={mapKey}>
                <Action
                  label='add tax to adjustment'
                  onClick={handleAddTaxToAdjustment(adjustment, tax)}
                >
                  <Icon icon={settings.svgIcons.add} />
                </Action>
              </TableActionCell>
            )
          : <TableCell key={mapKey} />
        }
      </>
    )
  }, [
    editing,
    adjustment,
    adjustmentTaxes,
    handleAddTaxToAdjustment,
    handleInitDelete
  ]);

  return (
    <>
      <Section title={localize(localeContentKeys.adjustmentTaxes.title)}>
        {
          removeTax && (
            <Banner
              icon={(<Icon icon={settings.svgIcons.remove} />)}
              colour={settings.colours.alert.alert}
              actions={(
                <>
                  <Button
                    onClick={handleRemoveTaxFromAdjustment()}
                  >
                    {localize(localeButtonKeys.remove)}
                  </Button>
                  <Button
                    onClick={handleCancelDelete}
                  >
                    {localize(localeButtonKeys.cancel)}
                  </Button>
                </>
              )}
            >
              {
                `${localize(localeContentKeys.adjustmentTaxes.confirmRemove)}`
                  + `(#${removeTax.id})`
              }
            </Banner>
          )
        }
        {
          addedTaxes.length
          ? (
            <TaxTable
              taxes={addedTaxes}
              generateActions={actionGenerator}
            />
          ) : (
            <Text>
              {localize(localeContentKeys.adjustmentTaxes.notApplied)}
            </Text>
          )
        }
        {
          editing
          ? <Segment
              title={localize(localeContentKeys.adjustmentTaxes.availableTaxes)}
            >
              <TaxTable
                taxes={unusedTaxes}
                generateActions={actionGenerator}
              />
            </Segment>
          : null
        }
        {
          editing
          ? (
            <Button
              onClick={handleToggleEditing}
              colour={settings.colours.alert.alert}
            >
              { localize(localeButtonKeys.cancel) }
            </Button>
          ) : (
            <Button onClick={handleToggleEditing}>
              { localize(localeButtonKeys.edit) }
            </Button>
          )
        }
      </Section>
    </>
  );
}

export default AdjustmentTaxes;
