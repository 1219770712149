import React, { useCallback } from 'react';

import { Location, Address } from '#types';

import useForm from '#hooks/useForm';

import SearchAddressInput from '#components/addresses/SearchAddressInput';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';

import locale, { localize } from '#utils/locale';

const localeFormHeaderKeys = locale.keys.forms.locations.labels;

interface LocationFormProps {
  location : Location;
  onChange? : (location : Location) => void;
}

function LocationForm({ location : fallback, onChange, } : LocationFormProps) {
  const { state, dispatch, editing } = useForm<Location>({ onChange });

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);

  const setLocationAddress = useCallback(
    (address : Address | null) => {
      console.log('setLocationAddress', address);
      dispatch({
        address : address,
        addressId : address ? address.id : null,
      });
    },
    [dispatch],
  );

  function setDescription(description : string) { dispatch({ description }) };

  const idPrefix = (fallback && fallback.id)
    ? `location-${fallback.id}`
    : 'serviceChannels';
    console.log('LocationForm', state, fallback);
  return (
    <Form>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localeFormHeaderKeys.name)}
        value={state?.name ?? fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.half}
      />
      <SearchAddressInput
        label={localize(localeFormHeaderKeys.address)}
        address={(state ? state.address : fallback.address) ?? null}
        onChange={setLocationAddress}
        disabled={!editing}
        width={settings.dimensions.half}
      />
      <TextInput
        id={`${idPrefix}-description`}
        label={localize(localeFormHeaderKeys.description)}
        value={state?.description ?? fallback.description}
        onChange={setDescription}
        disabled={!editing}
        width={settings.dimensions.full}
        minRows={2}
      />
    </Form>
  );
}

export default LocationForm;
