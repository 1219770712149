import React, { useCallback, useEffect, useState } from 'react';

import {
  Service,
  LineItem,
  Selection,
  Fulfilment,
  Subscription,
  DraftOrder,
  ProjectedOrder,
  isFulfilmentStatus,
} from '#types';

import  { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useForm from '#hooks/useForm';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';

import { settings } from '#materials';
import Split from '#materials/Splt';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import ToggleButton, { ToggleButtonGroup } from '#materials/ToggleButton';
import Banner from '#materials/Banner';

import Section from '#components/dashboard/Section';
import OrderForm from '#components/orders/OrderForm';
import OrderTotals from '#components/orders/OrderTotals';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderDetails;
const localeFormKeys = locale.keys.forms.orders;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications;

interface OrderDetailsProps {
  order : ProjectedOrder;
}

interface OrderDetailsControlProps extends OrderDetailsProps {
  setEditing : (editing : boolean) => void;
}

function OrderDetailsControl({
  order : initialOrder,
  setEditing,
} : OrderDetailsControlProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const { state : order, editing, valid, reset } = useForm<ProjectedOrder>();
  const {
    refreshLineItems,
    bulkUpdateLineItems,
    deleteLineItem,
    deleteFulfilment,
    bulkUpdateFulfilments,
    refreshOrder,
    payOrders,
    evaluateOptions,
    generateOrderUrl,
  } = useOrders();
  const {
    refreshSelections,
    bulkUpdateCustomisedLineItems,
  } = useOptions();
  const {
    findProjectedOrder,
    isLineItemRecurring,
    isOrderRecurring,
    determineTrueIteration,
    buildLineItemSubscription,
    updateRecurringLineItem,
    bulkCreateRecurringLineItems,
    bulkCreateFulfilments,
  } = useSubscriptions();

  const [services, setServices] = useState<Service[]>([]);
  const [exisingOrder, setExistingOrder] = useState<DraftOrder | null>(null);
  const [formReady, setFormReady] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletingSubscription, setDeletingSubscription] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [confirmProcess, setConfirmProcess] = useState(false);
  const [updating, setConfirmUpdate] = useState(false);

  const match = useCallback(async () => {
    if (!order) return;
    if (
      order.address === initialOrder.address &&
      order.customer === initialOrder.customer &&
      order.serviceChannel === initialOrder.serviceChannel &&
      order.location === initialOrder.location &&
      order.timeSlot === initialOrder.timeSlot &&
      order.timeSlotIteration === initialOrder.timeSlotIteration &&
      order.timeSlotDivision === initialOrder.timeSlotDivision &&
      order.selections === initialOrder.selections
    ) {
      setExistingOrder(null);
      return;
    }

    const foundOrder = await findProjectedOrder({
      ...order,
      iteration : order.timeSlotIteration,
      division : order.timeSlotDivision,
    });
    if (foundOrder) {
      setExistingOrder(foundOrder);
      return;
    }
  }, [initialOrder, order, findProjectedOrder]);

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    await Promise.all([
      refreshLineItems(),
      refreshSelections(),
      order?.order?.id ? refreshOrder(order.order.id) : null,
    ]);
    setRefreshing(false);
  }, [order, refreshLineItems, refreshSelections, refreshOrder]);

  const handleEdit = useCallback(() => { setEditing(true); }, [setEditing]);
  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [setEditing, reset]);

  const handleSave = useCallback(async () => {
    if (!order || !valid) return;

    if (isOrderRecurring(order)) {
      setConfirmUpdate(true);
      return;
    }

    const newLineItems = await bulkUpdateLineItems(
        Object.values(order.lineItems).map((lineItem) => ({
          ...lineItem,
          timeSlotIteration : order.timeSlotIteration,
          timeSlotDivision : order.timeSlotDivision,
        }),
        {
          address : order.address,
          customer : order.customer,
          serviceChannel : order.serviceChannel,
          location : order.location,
          timeSlot : order.timeSlot,
        }
      )
    );

    if (newLineItems) {
      createNotification({
        key : 'update-order-success',
        message : localize(localeNotificationKeys.orders.update.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
      setEditing(false);
      navigate(generateOrderUrl(order));
    } else {
      createNotification({
        key : 'update-order-error',
        message : localize(localeNotificationKeys.orders.update.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [
    setEditing,
    order,
    valid,
    bulkUpdateLineItems,
    generateOrderUrl,
    isOrderRecurring,
    createNotification,
    navigate,
  ]);

  const handleCancelUpdate = useCallback(() => {
    setConfirmUpdate(false);
  }, []);

  const handleConfirmUpdate = useCallback(
    (thisOnly : boolean) => async () => {
      if (!order || !valid) return;

      const oneTimeItems = Object.values(order.lineItems).filter(
        (lineItem) => !isLineItemRecurring(lineItem)
      );
      const recurringItems = Object.values(order.lineItems).filter(
        (lineItem) => isLineItemRecurring(lineItem)
      );

      const newLineItems = await bulkUpdateLineItems(
          oneTimeItems.map((lineItem) => ({
            ...lineItem,
            timeSlotIteration : order.timeSlotIteration,
            timeSlotDivision : order.timeSlotDivision,
          }),
          {
            address : order.address,
            customer : order.customer,
            serviceChannel : order.serviceChannel,
            location : order.location,
            timeSlot : order.timeSlot,
          }
      ));

      const newSubscriptions : (Subscription | null)[] = [];
      for (const lineItem of recurringItems) {
          if (!initialOrder.timeSlot) return null;

        const subscription = Object.values(order.subscriptions).filter(
          (subscription) => subscription.lineItemId === lineItem.id
        )[0];
        if (!subscription) return null;

        const trueIteration = determineTrueIteration(
          lineItem,
          initialOrder.timeSlot,
          initialOrder.timeSlotIteration,
        )

        newSubscriptions.push(await updateRecurringLineItem(
            {
              ...lineItem,
              timeSlotIteration : order.timeSlotIteration,
              timeSlotDivision : order.timeSlotDivision,
              addressId : order.address?.id ?? null,
              customerId : order.customer?.id ?? null,
              serviceChannelId : order.serviceChannel?.id ?? null,
              locationId : order.location?.id ?? null,
              timeSlotId : order.timeSlot?.id ?? null,
            },
            Object.values(order.selections).filter(
              (selection) => selection.lineItemId === lineItem.id
            ),
            {
              startIteration : trueIteration,
              endIteration : thisOnly ? trueIteration : null,
              targetIteration : order.timeSlotIteration,
              period : subscription.period,
            }
        ));
      }

      if (newLineItems && newSubscriptions) {
        createNotification({
          key : 'update-order-success',
          message : localize(localeNotificationKeys.orders.update.success),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.primary,
        });
        setEditing(false);
        navigate(generateOrderUrl(order));
      } else {
        createNotification({
          key : 'update-order-error',
          message : localize(localeNotificationKeys.orders.update.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
      }
    },
    [
      initialOrder,
      setEditing,
      order,
      valid,
      bulkUpdateLineItems,
      updateRecurringLineItem,
      isLineItemRecurring,
      determineTrueIteration,
      createNotification,
      generateOrderUrl,
      navigate,
    ],
  );

  const handleInitDelete = useCallback(async () => {
    if (!order) return;
    if (isOrderRecurring(order)) setDeletingSubscription(true);
    else setDeleting(true);
  }, [order, isOrderRecurring]);

  const handleCancelDelete = useCallback(async () => {
    setDeleting(false);
    setDeletingSubscription(false);
  }, []);

  const handleConfirmDelete = useCallback(
    (thisOnly : boolean) => async () => {
      if (!order) return;

      const oneTimeItems = Object.values(order.lineItems).filter(
        (lineItem) => !isLineItemRecurring(lineItem)
      );
      const recurringItems = Object.values(order.lineItems).filter(
        (lineItem) => !oneTimeItems.includes(lineItem),
      );

      const orphanedFulfilments = order.order
        ? Object.values(order.order.fulfilments).filter(
          (fulfilment) => !Object.values(order.lineItems).some(
            (lineItem) => fulfilment.lineItemId === lineItem.id
          )
        ) : [];

      let failureCount = 0;
      for (const lineItem of oneTimeItems) {
        const success = await deleteLineItem(lineItem);
        if (!success) failureCount += 1;
      }

      for (const lineItem of recurringItems) {
        if (!initialOrder.timeSlot) return;
        const trueIteration = determineTrueIteration(
          lineItem,
          initialOrder.timeSlot,
          initialOrder.timeSlotIteration,
        );
        const subscription = await updateRecurringLineItem(
          { ...lineItem, quantity : 0 },
          [],
          {
            startIteration : trueIteration,
            endIteration : thisOnly ? trueIteration : null,
            targetIteration : order.timeSlotIteration,
            period : 1,
          }
        );
        if (!subscription) failureCount += 1;
      }

      for (const fulfilment of orphanedFulfilments) {
        const success = await deleteFulfilment(fulfilment);
        if (!success) failureCount += 1;
      }

      if (!failureCount) {
        createNotification({
          key : 'delete-order-success',
          message : localize(localeNotificationKeys.orders.delete.success),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.primary,
        });
        navigate('/orders');
      } else {
        createNotification({
          key : 'delete-line-item-error',
          message : localize(localeNotificationKeys.lineItems.delete.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
          count : failureCount,
        });
      }
    }, [
      initialOrder,
      order,
      deleteLineItem,
      isLineItemRecurring,
      determineTrueIteration,
      updateRecurringLineItem,
      deleteFulfilment,
      createNotification,
      navigate,
    ]);

    const handleInitProcess = useCallback(async () => {
      if (!order?.order) return;
      setConfirmProcess(true);
    }, [order]);

    const handleCancelProcess = useCallback(async () => {
      setConfirmProcess(false);
    }, []);

    const handleConfirmProcess = useCallback(async () => {
      if (!order?.order) return;
      setProcessing(true);
      const paidOrders = await payOrders([order.order]);
      if (paidOrders) {
        createNotification({
          key : 'process-order-success',
          message : localize(localeNotificationKeys.orders.process.success),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.primary,
        });
      } else {
        createNotification({
          key : 'process-order-error',
          message : localize(localeNotificationKeys.orders.process.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
      }
      setProcessing(false);
      setConfirmProcess(false);
    },
    [order, payOrders, createNotification],
  );

  const handleStatusChange = useCallback(async (status : string) => {
    if (!order?.order || !isFulfilmentStatus(status)) return;
    const fulfilments = Object.values(order.order.fulfilments);
    if (!fulfilments.length) return;

    const updates = await bulkUpdateFulfilments(
      fulfilments.map((fulfilment) => ({
        ...fulfilment,
        status,
      })
    ));
    if (updates) {
      createNotification({
        key : 'update-fulfilments-success',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.success,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'update-fulfilments-error',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.error,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [order, bulkUpdateFulfilments, createNotification]);

  const handleGenerate = useCallback(async () => {
    if (!order) return;

    const success = !!(await bulkCreateFulfilments({
      lineItems : Object.values(order.lineItems),
      selections : Object.values(order.selections),
      subscriptions : Object.values(order.subscriptions),
      targetIteration : order.timeSlotIteration,
    }));
    if (success) {
      createNotification({
        key : 'create-fulfilments-success',
        message : localize(localeNotificationKeys.fulfilments.generate.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'create-fulfilments-error',
        message : localize(localeNotificationKeys.fulfilments.generate.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }

    handleRefresh();
  }, [order, handleRefresh, bulkCreateFulfilments, createNotification]);

  const handleAddLineItem = useCallback(async (
    lineItem : LineItem,
    selections? : Selection[],
    options? : { period? : number },
  ) => {
    const proposedSelections = (selections ?? []).map(
      (selection) => ({ ...selection, id : undefined }),
    );
    const result = await bulkCreateRecurringLineItems({
      lineItems : [{
          ...lineItem,
          serviceChannelId : initialOrder.serviceChannel?.id ?? null,
          locationId : initialOrder.location?.id ?? null,
          addressId : initialOrder.address?.id ?? null,
          timeSlotId : initialOrder.timeSlot?.id ?? null,
          timeSlotIteration : initialOrder.timeSlotIteration,
          timeSlotDivision : initialOrder.timeSlotDivision,
        }],
      selections : proposedSelections,
      subscriptions : options?.period
        ? [buildLineItemSubscription(
          lineItem,
          proposedSelections,
          options,
        )]
        : [],
    })
    const lineItems = result?.lineItems ?? null;
    const newSelections = result?.selections ?? null;
    const subscriptions = result?.subscriptions ?? null;

    const subscriptionError = !subscriptions
      || (options?.period && !Object.values(subscriptions).length);

    if (!!order && !!lineItems && !!newSelections && !subscriptionError) {
      createNotification({
        key : 'create-line-item-success',
        message : localize(localeNotificationKeys.lineItems.create.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      if (!lineItems) {
        createNotification({
          key : 'create-line-item-error',
          message : localize(localeNotificationKeys.lineItems.create.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert
        });
      } else if (!newSelections) {
        createNotification({
          key : 'create-item-customisation-error',
          message : localize(localeNotificationKeys.orders.customise.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert
        });
      } else if (subscriptionError) {
        createNotification({
          key : 'create-subscription-error',
          message : localize(localeNotificationKeys.orders.subscribe.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert
        });
      }
    }
  }, [
    initialOrder,
    order,
    buildLineItemSubscription,
    bulkCreateRecurringLineItems,
    createNotification,
  ]);

  const handleUpdateLineItem = useCallback(async (
    lineItem : LineItem,
    selections? : Selection[],
    options? : {
      startIteration? : number,
      endIteration? : number | null,
      targetIteration? : number,
      period? : number
    },
  ) => {
    let success = false;
    if (!options?.period) {
      if (isLineItemRecurring(lineItem)) return;
      success = !!(await bulkUpdateCustomisedLineItems(
        [lineItem],
        selections ? selections.map((selection) => ({
          ...selection,
          id : ((selection.id ?? 0) > 0) ? selection.id : undefined,
        })) : [],
      ));
    } else {
      success = !!(await updateRecurringLineItem(
        {
          ...lineItem,
          serviceChannelId : initialOrder.serviceChannel?.id ?? null,
          locationId : initialOrder.location?.id ?? null,
          addressId : initialOrder.address?.id ?? null,
          timeSlotId : initialOrder.timeSlot?.id ?? null,
          timeSlotIteration : initialOrder.timeSlotIteration,
          timeSlotDivision : initialOrder.timeSlotDivision,
        },
        (selections ?? []).map(
          (selection) => ({ ...selection, id : undefined }),
        ),
        options,
      ));
    }

    if (success) {
      createNotification({
        key : 'update-line-item-success',
        message : localize(localeNotificationKeys.lineItems.update.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'update-line-item-error',
        message : localize(localeNotificationKeys.lineItems.update.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert
      });
    }
  }, [
    initialOrder,
    bulkUpdateCustomisedLineItems,
    updateRecurringLineItem,
    isLineItemRecurring,
    createNotification,
  ]);

  const handleDeleteLineItem = useCallback(async (
    lineItem : LineItem,
    options? : { startIteration? : number, endIteration? : number | null },
  ) => {
    if (!options) {
      const deleted = await deleteLineItem(lineItem);
      if (deleted && !!order && !!lineItem.id) {
        createNotification({
          key : 'delete-line-item-success',
          message : localize(localeNotificationKeys.lineItems.delete.success),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.primary,
        });
      } else {
        createNotification({
          key : 'delete-line-item-error',
          message : localize(localeNotificationKeys.lineItems.delete.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert
        });
      }
      return !!deleted;
    }

    const subscription = await updateRecurringLineItem(
      { ...lineItem, quantity : 0 },
      [],
      options,
    );
    if (subscription) {
      createNotification({
        key : 'cancel-subscription-success',
        message : localize(localeNotificationKeys.subscriptions.cancel.success),
        icon : (<Icon icon={settings.svgIcons.refresh} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'cancel-subscription-error',
        message : localize(localeNotificationKeys.subscriptions.cancel.error),
        icon : (<Icon icon={settings.svgIcons.refresh} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [order, deleteLineItem, updateRecurringLineItem, createNotification]);

  const handleDeleteFulfilment = useCallback((
    fulfilment : Fulfilment,
  ) => async () => {
    const sucess = !!(await deleteFulfilment(fulfilment));
    if (sucess) {
      createNotification({
        key : 'delete-fulfilment-success',
        message : localize(localeNotificationKeys.fulfilments.delete.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-fulfilment-error',
        message : localize(localeNotificationKeys.fulfilments.delete.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [deleteFulfilment, createNotification]);

  const handleView = useCallback(() => {
    if (!exisingOrder) return;
    navigate(generateOrderUrl(exisingOrder));
  }, [exisingOrder, generateOrderUrl, navigate]);

  useEffect(() => {
    if (!order) {
      setServices([]);
      return;
    }
    const { services } = evaluateOptions(order);
    setServices(services);
  }, [order, evaluateOptions]);

  useEffect(() => { if (!editing) setConfirmUpdate(false); }, [editing]);
  useEffect(() => { match(); }, [match]);

  const generated = order?.order
    && Object.keys(order.order?.fulfilments).length >= (
      Object.keys(order.lineItems).length + Object.keys(order.selections).length
    )
    && Object.values(order.lineItems).every((li) => (
      order.order
        && Object.values(order.order.fulfilments).some(f => (
          f.lineItemId === li.id && f.requestedProductId === li.productId
        ))
    ))
    && Object.values(order.selections).every((s) => (
      order.order
        && Object.values(order.order.fulfilments).some(f => (
          f.id && s.fulfilmentIds.includes(f.id)
        ))
    ));
  const busy = refreshing
    || deleting
    || deletingSubscription
    || processing
    || confirmProcess;

  return (
    <Section
      title={order?.order?.id
        ? `${localize(localeContentKeys.title)} (# ${order?.order?.id})`
        : localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { confirmProcess && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          actions={(
            <>
              <Button onClick={handleConfirmProcess}>
                { localize(localeButtonKeys.process) }
              </Button>
              <Button onClick={handleCancelProcess}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.secondary}
        >
          { localize(localeContentKeys.confirmProcess) }
        </Banner>
      ) }
      { updating && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          actions={(
            <>
              <Button onClick={handleConfirmUpdate(true)}>
                { localize(localeContentKeys.updateThis) }
              </Button>
              <Button onClick={handleConfirmUpdate(false)}>
                { localize(localeContentKeys.updateFuture) }
              </Button>
              <Button onClick={handleCancelUpdate}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.secondary}
        >
          { localize(localeContentKeys.confirmUpdate) }
        </Banner>
      ) }
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button onClick={handleConfirmDelete(true)}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={handleCancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { deletingSubscription && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button onClick={handleConfirmDelete(true)}>
                { localize(localeContentKeys.deleteThis) }
              </Button>
              <Button onClick={handleConfirmDelete(false)}>
                { localize(localeContentKeys.deleteFuture) }
              </Button>
              <Button onClick={handleCancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (editing && !deleting && !updating && !processing ) && (
        <Banner
          icon={valid
            ? (exisingOrder
              ? (<Icon icon={settings.svgIcons.info} />)
              : (<Icon icon={settings.svgIcons.check} />))
            : (<Icon icon={settings.svgIcons.clear} />)
          }
          actions={exisingOrder
            ? (
              <Button onClick={handleView}>
                { localize(localeButtonKeys.edit) }
              </Button>
            )
            : undefined
          }
          colour={valid
            ? (exisingOrder
              ? settings.colours.alert.secondary
              : settings.colours.alert.primary)
            : settings.colours.alert.alert
          }
        >
          { valid
            ? (exisingOrder
              ? localize(localeContentKeys.existingOrder)
              : localize(localeContentKeys.fulfillingServices) +
                ` ${services.map((service) => service.name).join(', ')}.`)
            : localize(localeContentKeys.invalidOptions)
          }
        </Banner>
      ) }
      <Split
        left = {<>
          { editing
            ? (<>
              <Button onClick={handleSave} disabled={busy}>
                { localize(localeButtonKeys.save) }
              </Button>
              <Button onClick={handleCancel} colour={settings.colours.button.alert}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>)
            : (<>
              <Button onClick={handleRefresh} disabled={busy}>
                { localize(localeButtonKeys.refresh) }
              </Button>
              <Button onClick={handleEdit} disabled={busy}>
                { localize(localeButtonKeys.edit) }
              </Button>
              <Button
                onClick={handleInitDelete}
                colour={settings.colours.button.alert}
                disabled={busy}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
            </>)
          }
        </>}
        right = {
          !editing && (
            (order?.order && generated)
              ? (
                <>
                  <Button
                    disabled={busy || order?.paid}
                    onClick={handleInitProcess}
                  >
                    { localize(order?.paid
                      ? localeContentKeys.paid
                      : localeContentKeys.processPayment
                    ) }
                  </Button>
                  <ToggleButtonGroup>
                    <ToggleButton
                      value="inProgress"
                      selected={order?.status === 'inProgress'}
                      onSelect={handleStatusChange}
                      first
                    >
                      { localize(localeContentKeys.markInProgress) }
                    </ToggleButton>
                    <ToggleButton
                      value="ready"
                      selected={order?.status === 'ready'}
                      onSelect={handleStatusChange}
                    >
                      { localize(localeContentKeys.markReady) }
                    </ToggleButton>
                    <ToggleButton
                      value="fulfilled"
                      selected={order?.status === 'fulfilled'}
                      onSelect={handleStatusChange}
                      last
                    >
                      { localize(localeContentKeys.markFulfiled) }
                    </ToggleButton>
                  </ToggleButtonGroup>
                </>
              ) : (
                <Button onClick={handleGenerate} disabled={busy}>
                  { localize(localeButtonKeys.generate) }
                </Button>
              )
          )
        }
      />
      <OrderForm
        order={initialOrder}
        itemsReady={formReady}
        setItemsReady={setFormReady}
        onAddLineItem={editing ? handleAddLineItem : undefined}
        onUpdateLineItem={editing ? handleUpdateLineItem : undefined}
        onRemoveLineItem={editing ? handleDeleteLineItem : undefined}
        onDeleteFulfilment={editing ? handleDeleteFulfilment : undefined}
        disableCustomer
        disableProduct
        showFulfilmment={!editing}
      />
      { order && (<OrderTotals order={order}/> ) }
    </Section>
  );
}

const OrderDetails = ({ order, ...props } : OrderDetailsProps) => {
  const [editing, setEditing] = useState(false);

  const { evaluateOptions } = useOrders();

  const validateOrder = useCallback(
    (order : ProjectedOrder) : { [key : string] : string } | null => {
      const { errors, valid } = evaluateOptions({ ...order, service : null });
      if (valid) return null;
      return {
        ...(errors.address && {
          address : localize(localeFormKeys.errors.invalidAddress),
        }),
        ...(errors.serviceChannel && {
          serviceChannel : localize(
            localeFormKeys.errors.invalidServiceChannel
          ),
        }),
        ...(errors.location && {
          location : localize(localeFormKeys.errors.invalidLocation),
        }),
        ...(errors.timeSlot && {
          timeSlot : localize(localeFormKeys.errors.invalidTimeSlot),
        }),
      }
    },
    [evaluateOptions],
  );

  const requireFulfillment = useCallback(
    (order : ProjectedOrder) : { [key : string] : string } | null => {
      const { addresses, locations } = evaluateOptions({
        ...order,
        service : null,
      });
      if (!order.address && !order.location) return {
        address : localize(localeFormKeys.errors.fulfillmentRequired),
        location : localize(localeFormKeys.errors.fulfillmentRequired),
      };
      if (
        !addresses.length &&
        !!locations.length &&
        !order.location
      ) return {
        location : localize(localeFormKeys.errors.locationRequired),
        ...(!!order.address && {
          address : localize(localeFormKeys.errors.addressNotAllowed),
        }),
      };
      if (
        !!addresses.length &&
        !locations.length &&
        !order.address
      ) return {
        address : localize(localeFormKeys.errors.addressRequired),
        ...(!!order.location && {
          location : localize(localeFormKeys.errors.locationNotAllowed),
        }),
      };
      return null;
    },
    [evaluateOptions],
  );

  return (
    <FormProvider
      init={order}
      editing={editing}
      validators={[
        validateOrder,
        requireFulfillment,
      ]}
    >
      <OrderDetailsControl {...props} order={order} setEditing={setEditing} />
    </FormProvider>
  );
}

export default OrderDetails;
