import React from 'react';

import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';

import { Link } from '#hooks/useNavigation';

import { ButtonColour, Size, Spacing, convert, settings } from './types';

interface IconButtonProps {
  label : string;
  hidden? : boolean;
  href? : string;
  onClick? : React.MouseEventHandler;
  disabled? : boolean;
  size? : Size;
  spacing? : Spacing;
  colour? : ButtonColour;
  children? : React.ReactNode;
}

function IconButtonWrapper<C extends React.ElementType>(
  props: MuiIconButtonProps<C, { component?: C }>
) {
  return <MuiIconButton {...props} />;
};

function IconButton({
  label,
  hidden = false,
  href,
  onClick,
  disabled = false,
  size = settings.sizes.medium,
  spacing = settings.spacings.medium,
  colour = settings.colours.button.primary,
  children,
} : IconButtonProps) {

  const mx = spacing === settings.spacings.dense
    ? 0
    : spacing === settings.spacings.small
      ? 0.25
      : 1;

  return (
    <IconButtonWrapper
      edge='start'
      color={colour}
      size={convert.size(size)}
      aria-label={label}
      disabled={disabled}
      onClick={onClick}
      to={href ? href : ''}
      component={href ? Link : undefined}
      sx={{
        mx,
        ...(hidden && { display: 'none' }),
      }}
    >
      { children }
    </IconButtonWrapper>
  )
}

export type IconButtonElement = React.ReactElement<IconButtonProps>;

export function isIconButtonElement(
  element : React.ReactNode
) : element is IconButtonElement {
  return React.isValidElement<IconButtonProps>(element) &&
    element.type === IconButton;
}

export default IconButton;
