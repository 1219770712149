import React, { useCallback, useEffect, useState } from 'react';

import { Adjustment } from '#types';

import useAdjustments from '#hooks/useAdjustments';

import Section from '#components/dashboard/Section';
import AdjustmentDetails from '#components/adjustments/AdjustmentDetails';
import AdjustmentConditions from '#components/adjustments/AdjustmentConditions';
import AdjustmentTaxes from '#components/adjustments/AdjustmentTaxes';

import locale, { localize } from '#utils/locale';


const localeContentKeys = locale.keys.content.adjustments.adjustmentDetails;

interface AdjustmentInfoProps {
  adjustmentId: number;
}

function AdjustmentInfo({ adjustmentId }: AdjustmentInfoProps) {
  const { retrieveAdjustment } = useAdjustments();
  const [adjustment, setAdjustment] = useState<Adjustment | null>(null);

  const retrieve = useCallback(async () => {
    const adjustment = await retrieveAdjustment(adjustmentId)
    setAdjustment(adjustment)
  }, [retrieveAdjustment, adjustmentId])

  useEffect(() => { retrieve() }, [retrieve])

  if(!adjustment) return (
    <Section title={localize(localeContentKeys.notFound)}></Section>
  )

  return (
    <>
      <AdjustmentDetails adjustment={adjustment} />
      <AdjustmentConditions adjustment={adjustment} />
      <AdjustmentTaxes adjustment={adjustment} />
    </>
  );
}

export default AdjustmentInfo;
