import React, { useEffect } from 'react';

import { useParams, useSearchParams } from '#hooks/useNavigation';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';

import OrderIndex from '#components/orders/OrderIndex';
import OrderInfo from '#components/orders/OrderInfo';

function Orders() {
  const { orderId : idString } = useParams();
  const [urlParams] = useSearchParams();
  const { load: loadOrders, loaded: ordersLoaded } = useOrders();
  const { load: loadOptions } = useOptions();
  const { load: loadSubscriptions } = useSubscriptions();

  useEffect(() => {
    loadOrders();
    loadOptions();
    loadSubscriptions();
  }, [loadOrders, loadOptions, loadSubscriptions]);

  if (!ordersLoaded) return null;

  const orderId = parseInt(idString ?? '');

  const addressId = urlParams.has('address')
    ? parseInt(urlParams.get('address') ?? '')
    : null;
  const customerId = parseInt(urlParams.get('customer') ?? '');
  const serviceChannelId = parseInt(urlParams.get('serviceChannel') ?? '');
  const locationId = urlParams.has('location')
    ? parseInt(urlParams.get('location') ?? '')
    : null;
  const timeParam = urlParams.get('timeSlot');
  const [
    timeSlotParam,
    iterationParam,
    divisionParam,
  ] = timeParam ? timeParam.split('-') : [];
  const timeSlotId = parseInt(timeSlotParam ?? '');
  const iteration = parseInt(iterationParam ?? '');
  const division = parseInt(divisionParam ?? '');

  const props = (
    (addressId !== null && isNaN(addressId)) ||
    isNaN(customerId) ||
    isNaN(serviceChannelId) ||
    (locationId !== null && isNaN(locationId)) ||
    isNaN(timeSlotId) ||
    isNaN(iteration) ||
    isNaN(division)
  ) ? (orderId ? { orderId } : null) : {
    addressId,
    customerId,
    serviceChannelId,
    locationId,
    timeSlotId,
    iteration,
    division,
  };

  return (
    !!props
      ? (<OrderInfo {...props} />)
      : (<OrderIndex />)
  );
}

export default Orders;
