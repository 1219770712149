import React, { useCallback } from 'react';

import { Product } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import useNotifications from '#hooks/useNotifications';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import ProductForm from '#components/products/ProductForm';

import locale, { localize } from '#utils/locale';
import Icon from '#materials/Icon';

const localeContentKeys = locale.keys.content.products.createProduct;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications;

interface CreateProductProps {
  onSave? : (product : Product) => void;
  onCancel? : () => void;
}

function CreateProductControl({ onSave, onCancel } : CreateProductProps) {
  const { state : product, reset } = useFormContext<Product>();
  const { createNotification } = useNotifications();
  const { createProduct } = useProducts();

  const handleSave = useCallback(async () => {
    if (!product) return;

    const newProduct = await createProduct(product);
    if (newProduct) {
      createNotification({
        key : 'create-product-success',
        message : localize(localeNotificationKeys.products.created.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
      if (onSave) onSave(newProduct);
    } else {
      createNotification({
        key : 'create-product-error',
        message : localize(localeNotificationKeys.products.created.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [onSave, product, createProduct, createNotification]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Section title={localize(localeContentKeys.title)}>
      { !!product && (
        <ProductForm
          product={product}
          detailed={false}
          onSubmit={handleSave}
        />
      ) }
      <Button
        onClick={handleSave}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
    </Section>
  );
}

function CreateProduct({ onSave, onCancel } : CreateProductProps) {
  return (
    <FormProvider
      init={{
        name : '',
        sku : '',
        description : '',
        price : { amount : 0, calculatedValue : 0 },
        availableChannelIds : [],
      }}
      editing={true}
    >
      <CreateProductControl onSave={onSave} onCancel={onCancel} />
    </FormProvider>
  );
}

export default CreateProduct;
