import React, { useCallback, useEffect, useState } from 'react';

import { Image } from '#types';

import { settings } from '#materials';
import Link from '#materials/Link';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.images;

export const TABLE_KEYS = {
  id : 'id',
  url : 'url',
  variants : 'variants',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultTableKeys = Object.values(TABLE_KEYS);

interface ImageTableProps {
  images : Image[];
  tableKeys? : TableKeys[];
  pageCount? : number;
  generateActions? : (image : Image) => CellElement;
}

function ImageTable({
  images,
  tableKeys = defaultTableKeys,
  pageCount = 5,
  generateActions,
} : ImageTableProps) {
  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const generateHead = useCallback(() => {
    return <>
      { tableKeys.map((key) => {
        switch(key) {
          case TABLE_KEYS.id:
            return (<TableCell key={key}>
              { localize(localeTableKeys.headings.id) }
            </TableCell>);
          case TABLE_KEYS.url:
            return (<TableCell key={key}>
              { localize(localeTableKeys.headings.url) }
            </TableCell>);
          case TABLE_KEYS.variants:
            return (<TableCell key={key}>
              { localize(localeTableKeys.headings.variants) }
            </TableCell>);
          default: return (<TableCell key={key} />);
        }
      }) }
    </>
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    return images.map((image) => (
      <React.Fragment key={image.id}>
        { tableKeys.map((key) => {
          switch(key) {
            case TABLE_KEYS.id:
              return (<TableCell key={key}>
                { image.id }
              </TableCell>);
            case TABLE_KEYS.url:
              return (<TableCell font={settings.fonts.mono} key={key}>
                { image.imageUrl
                  ? (<Link href={image.imageUrl} newTab>
                    { image.imageUrl }
                  </Link>)
                  : ''
                }
              </TableCell>);
            case TABLE_KEYS.variants:
              return (<TableCell font={settings.fonts.mono} key={key}>
                { Object.keys(image.variants).filter(
                  key => key !== 'public'
                ).map((key) => (
                  <Link href={image.variants[key]} newTab key={key}>
                    { key }
                  </Link>
                )) }
              </TableCell>);
            case TABLE_KEYS.actions:
              return (<React.Fragment key={key}>
                { generateActions && generateActions(image) }
              </React.Fragment>);
            default: return (<TableCell key={key} />);
          }
        }) }
      </React.Fragment>
    ))
  }, [images, tableKeys, generateActions]);

  useEffect(() => { setHead(generateHead()) }, [generateHead]);
  useEffect(() => { setRows(generateRows()) }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={pageCount}
    />
  );
}

export default ImageTable;
