import React, { useEffect, useState, useCallback } from 'react';

import { RevenueCentre } from '#types';

import usePayments from '#hooks/usePayments';

import Section from '#components/dashboard/Section';
import RevenueCentreDetails
  from '#components/revenueCentres/RevenueCentreDetails';
import RevenueCentreServices
  from '#components/revenueCentres/RevenueCentreServices';

import locale, { localize } from '#utils/locale';

const localeContentKeys =
  locale.keys.content.revenueCentres.revenueCentreDetails;

interface RevenueCentreInfoProps {
  revenueCentreId : number;
}

function RevenueCentreInfo({ revenueCentreId } : RevenueCentreInfoProps) {
  const { retrieveRevenueCentre } = usePayments();

  const [
    revenueCentre,
    setRevenueCentre
  ] = useState<RevenueCentre | null>(null);

  const retrieve = useCallback(async () => {
    const revenueCentre = await retrieveRevenueCentre(revenueCentreId);
    setRevenueCentre(revenueCentre);
  }, [revenueCentreId, retrieveRevenueCentre, setRevenueCentre]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <>
      <Section
        title={`${revenueCentre?.name} (#${revenueCentre?.id})`}
        text={localize(localeContentKeys.text)}
      >
        { !!revenueCentre &&
          <RevenueCentreDetails revenueCentre={revenueCentre} />
        }
      </Section>
      { !!revenueCentre &&
        <RevenueCentreServices
          revenueCentre={revenueCentre}
        />
      }
    </>
  );
}

export default RevenueCentreInfo;
