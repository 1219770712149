import React, { useEffect, useCallback, useRef, useState } from 'react';

import { PaymentToken, CreditCard, Customer } from '#types';

import useCustomers from '#hooks/useCustomers';
import useCards from '#hooks/useCards';

import Button from '#materials/Button';

import CardForm from '#components/cards/CardForm';

import locale, { localize } from '#utils/locale';

const localeButtonKeys = locale.keys.buttons;

interface CreateCardProps {
  customerId : number;
  email : string;
  onSave? : () => void;
}

function CreateCard({ customerId, onSave } : CreateCardProps) {
  const { retrieveCustomer } = useCustomers();
  const { createCreditCard } = useCards();

  const [customer, setCustomer] = useState<Customer | null>(null)
  const [submitted, setSubmitted] = useState(false);
  const tokenize = useRef<() => Promise<PaymentToken | null>>(async () => null);

  const setTokenize = useCallback(
    (newTokenize : () => Promise<PaymentToken | null>) => {
      tokenize.current = newTokenize;
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    if (!customer || !customer.id || submitted) return;
    setSubmitted(true);

    const tokenized = tokenize.current ? await tokenize.current() : null;
    if (tokenized) {
      const newCard : CreditCard = {
        last4 : tokenized.last4,
        brand : tokenized.brand,
        expMonth : tokenized.expMonth,
        expYear : tokenized.expYear,
        customerId : customer.id,
      }

      const latestCard = await createCreditCard(
        newCard,
        customer,
        tokenized.token,
        tokenized.postal,
      )
      if (latestCard) {
        if (onSave) onSave();
        return;
      }
    }

    setSubmitted(false);
  }, [onSave, tokenize, customer, submitted, setSubmitted, createCreditCard]);

  useEffect(() => {
    const retrieve = async () => {
      const customer = await retrieveCustomer(customerId);
      setCustomer(customer);
    }
    retrieve();
  }, [customerId, retrieveCustomer]);

  return (
    <>
      <CardForm onSubmit={handleSubmit} setTokenize={setTokenize} />
      <Button onClick={handleSubmit} disabled={submitted} >
        { localize(localeButtonKeys.save) }
      </Button>
    </>
  );
}

export default CreateCard;
