import React from 'react';

import { ServiceChannel } from '#types';

import Table from '#materials/Table';
import {
  CellElement,
  TableCell,
  TableCheckboxCell,
} from '#materials/TableCell';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.serviceChannels;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  active : 'active',
  actions : 'actions',
};
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface ServiceChannelsTableProps {
  serviceChannels : ServiceChannel[];
  tableKeys? : TableKeys[];
  count : number;
  generateActions? : (serviceChannel : ServiceChannel) => CellElement;
}

function ServiceChannelsTable({
  serviceChannels,
  tableKeys = Object.values(TABLE_KEYS),
  count,
  generateActions = () => <TableCell/>,
} : ServiceChannelsTableProps) {

  function headGenerator(key : string) {
    switch (key) {
      case TABLE_KEYS.id:
        return <TableCell>{ localize(localKeys.headings.id) }</TableCell>;
      case TABLE_KEYS.name:
        return <TableCell>{ localize(localKeys.headings.name) }</TableCell>;
      case TABLE_KEYS.active:
        return <TableCell>{ localize(localKeys.headings.active) }</TableCell>;
      default: return <TableCell />;
    }
  }

  function rowGenerator(key : string, index : number) {
    const serviceChannel = serviceChannels[index];
    switch (key) {
      case TABLE_KEYS.id: return <TableCell>
          { `# ${serviceChannel.id}` }
        </TableCell>;
      case TABLE_KEYS.name: return <TableCell>
          { serviceChannel.name }
        </TableCell>;
      case TABLE_KEYS.active: return <TableCheckboxCell
          id={`serviceChannel-${serviceChannel.id}-active`}
          onChange={() => {}}
          checked={serviceChannel.active}
          disabled
        />;
      case TABLE_KEYS.actions: return generateActions(serviceChannel);
      default: return <TableCell />;
    }
  }

  return (
    <Table
      keys={tableKeys}
      length={count}
      headGenerator={headGenerator}
      rowGenerator={rowGenerator}
    />
  );
}

export default ServiceChannelsTable;
