import React, { useEffect, useState } from 'react';

import { Supplier, Tag } from '#types'

import useSuppliers from '#hooks/useSuppliers';
import useTags from '#hooks/useTags';

import Section from '#components/dashboard/Section';
import SupplierDetails from '#components/suppliers/SupplierDetails';
import TagProducts from '#components/tags/TagProducts';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.suppliers.supplierDetails;

interface SupplierInfoProps {
  supplierId : number;
}

function SupplierInfo({ supplierId } : SupplierInfoProps) {
  const { retrieveSupplier } = useSuppliers();
  const { retrieveTag } = useTags();

  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [tag, setTag] = useState<Tag | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function retrieve() {
      const newSupplier = await retrieveSupplier(supplierId);
      if (newSupplier) setSupplier(newSupplier);

      const newTag = await retrieveTag(supplierId);
      if (newTag) setTag(newTag);
      setLoaded(true);
    }
    retrieve();
  }, [supplierId, retrieveSupplier, retrieveTag]);

  if (!loaded) return <></>;
  if (!supplier || !tag) return (
    <Section title={localize(localeContentKeys.notFound)}> </Section>
  );

  return (
    <>
      <Section
        title={`${supplier.name} (#${supplier.id})`}
        text={localize(localeContentKeys.text)}
      >
        <SupplierDetails supplier={supplier} />
      </Section>
      <TagProducts tag={tag} />
    </>
  );
}

export default SupplierInfo;
