import React, { useCallback, useEffect, useState } from 'react';

import { ServiceChannel, SubscriptionOption } from '#types';

import { FormProvider } from '#context/FormContext';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useServices from '#hooks/useServices';
import useSubscriptions from '#hooks/useSubscriptions';

import ServiceChannelForm from '#components/serviceChannels/ServiceChannelForm';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys =
  locale.keys.content.serviceChannels.serviceChannelDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.serviceChannels;

interface ServiceChannelDetailsProps {
  serviceChannel : ServiceChannel;
}

function ServiceChannelDetailsForm({
  serviceChannel : init,
} : ServiceChannelDetailsProps) {
  const {
    state : serviceChannel,
    editing,
    setEditing,
    reset,
  } = useFormContext<ServiceChannel>();

  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const { updateServiceChannel, deleteServiceChannel } = useServices();
  const {
    retrieveSubscriptionOptions,
    addServiceChannelToSubscriptionOption,
    removeServiceChannelFromSubscriptionOption,
  } = useSubscriptions();

  const [subOption, setSubOption] = useState<SubscriptionOption | null>(null);
  const [enableSubscription, setEnableSubscription] = useState(false);
  const [channelSaved, setChannelSaved] = useState(true);
  const [subOptionSaved, setSubOptionSaved] = useState(true);
  const [deleting, setDeleting] = useState(false);

  const retrieveSubOptions = useCallback(async () => {
    const options = listRecords(await retrieveSubscriptionOptions());
    if (!init?.id || !options.length) {
      setSubOption(null);
      setEnableSubscription(false);
      return;
    }

    setSubOption(options[0]);
    setEnableSubscription(options[0].serviceChannelIds.includes(init?.id));
  }, [init, retrieveSubscriptionOptions]);

  const handleUpdate = useCallback(async () => {
    if (!serviceChannel) return;

    const updatedServiceChannel = channelSaved
      ? true
      : await updateServiceChannel(serviceChannel);

    if (!updatedServiceChannel) {
      createNotification({
        key : 'update-service-channel-error',
        message : localize(localeNotificationKeys.update.error),
        icon : (<Icon icon={settings.svgIcons.callSplit} />),
        colour : settings.colours.alert.alert,
      });
      return;
    }

    if (init.id && subOption) {
      const currentlyEnabled = subOption.serviceChannelIds.includes(init.id);
      if (enableSubscription !== currentlyEnabled) {
        const enabled = await (enableSubscription
          ? addServiceChannelToSubscriptionOption
          : removeServiceChannelFromSubscriptionOption
        )({
          option : subOption,
          serviceChannel : init,
        });

        if (!enabled) {
          createNotification({
            key : 'update-service-channel-subscription-error',
            message : localize(localeNotificationKeys.updateSubscription.error),
            icon : (<Icon icon={settings.svgIcons.callSplit} />),
            colour : settings.colours.alert.alert,
          });
          return;
        }
      }
    }

    createNotification({
      key : 'update-service-channel-success',
      message : localize(localeNotificationKeys.update.success),
      icon : (<Icon icon={settings.svgIcons.callSplit} />),
      colour : settings.colours.alert.primary,
    });
    setEditing(false);
    setChannelSaved(true);
  }, [
    init,
    channelSaved,
    serviceChannel,
    subOption,
    enableSubscription,
    updateServiceChannel,
    addServiceChannelToSubscriptionOption,
    removeServiceChannelFromSubscriptionOption,
    setEditing,
    createNotification,
  ]);

  const handleCancel = useCallback(() => {
    setEditing(false);
    reset();
  }, [setEditing, reset]);

  const handleEdit = useCallback(() => {
    setChannelSaved(true);
    setEditing(true);
  }, [setEditing, setChannelSaved]);

  const handleChange = useCallback(() => {
    setChannelSaved(false);
  }, [setChannelSaved]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleConfirmDelete = useCallback(async () => {
    if (!init) return;
    const success = await deleteServiceChannel(init);
    if (success) {
      createNotification({
        key : 'delete-service-channel-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.callSplit} />),
        colour : settings.colours.alert.primary,
      });
      navigate('/service-channels/');
    } else {
      createNotification({
        key : 'delete-service-channel-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.callSplit} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [init, deleteServiceChannel, createNotification, navigate]);

  const handleSubscriptionEnabledChange = useCallback((enabled : boolean) => {
    const currentlyEnabled = (init?.id && subOption)
      ? subOption.serviceChannelIds.includes(init.id)
      : false;
    setSubOptionSaved(enabled === currentlyEnabled)
    setEnableSubscription(enabled);
  }, [init, subOption, setEnableSubscription]);

  useEffect(() => { retrieveSubOptions() }, [retrieveSubOptions]);

  return (
    <>
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button
                onClick={handleConfirmDelete}
              >
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { !editing
        ? <>
          <Button
            onClick={handleEdit}
            disabled={deleting}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            disabled={deleting}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleUpdate}
            disabled={channelSaved && subOptionSaved}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
      }
      <ServiceChannelForm
        serviceChannel={init}
        subscriptionEnabled={enableSubscription}
        onChange={handleChange}
        onSubscriptionEnabledChange={handleSubscriptionEnabledChange}
      />
    </>
  );
}

function ServiceChannelDetails({
  serviceChannel } : ServiceChannelDetailsProps) {
  return (
    <FormProvider
      init={serviceChannel}
      editingInit={false}
    >
      <ServiceChannelDetailsForm serviceChannel={serviceChannel} />
    </FormProvider>
  )
}

export default ServiceChannelDetails;
