import React, { useCallback, useState } from 'react';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import useForm from '#hooks/useForm';
import useAuth from '#hooks/useAuth';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import LoginFrom from '#components/auth/LoginForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.auth.login;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.auth;

function LoginControl() {
  const { createNotification } = useNotifications();
  const { state, reset } = useForm();
  const { login } = useAuth();

  const [unauthorized, setUnauthorized] = useState(false);

  const handleLogin = useCallback(async () => {
    if (!state || !state.username || !state.password) return;
    const { user, error } = await login(
      state.username,
      state.password,
    );
    if (!!user) {
      setUnauthorized(false);
      createNotification({
        key : 'login-success',
        message : localize(localeNotificationKeys.login.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.person} />,
      });
    } else if (!error) {
      setUnauthorized(true);
    } else {
      setUnauthorized(false);
      createNotification({
        key : 'login-error',
        message : localize(localeNotificationKeys.login.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.person} />,
      });
    }
  }, [state, login, createNotification]);

  const handleClear = useCallback(() => {
    reset();
    setUnauthorized(false);
  }, [reset, setUnauthorized]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <LoginFrom unauthorized={unauthorized} onSubmit={handleLogin} />
      <Button
        onClick={handleLogin}
        disabled={!state || !state.username || !state.password}
      >
        { localize(localeButtonKeys.login) }
      </Button>
      <Button
        onClick={handleClear}
        colour={settings.colours.button.alert}
        disabled={
          !unauthorized &&
          (!state || (!state.username && !state.password))
        }
      >
        { localize(localeButtonKeys.clear) }
      </Button>
    </Section>
  );
}

function Login() {
  return (
    <FormProvider
      init={{ username : '', password : '' }}
    >
      <LoginControl />
    </FormProvider>
  );
}

export default Login;
