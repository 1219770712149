import React, { useCallback, useState } from 'react';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import useForm from '#hooks/useForm';
import useAuth from '#hooks/useAuth';

import { settings } from '#materials';
import Section from '#components/dashboard/Section';
import Icon from '#materials/Icon';
import Button from '#materials/Button';

import PasswordForm, {
  PasswordConfirmation,
  validateConfirmation,
} from '#components/auth/PasswordForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.auth.passwordReset;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.auth;

interface PasswordResetProps {
  username : string;
  token : string;
}

function PasswordResetControl({
  username,
  token,
} : PasswordResetProps) {
  const { createNotification } = useNotifications();
  const { resetPassword } = useAuth();
  const { state, errors } = useForm<PasswordConfirmation>({
    validators : [validateConfirmation],
  });
  const [ submitted, setSubmitted ] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!state) return;
    const success = await resetPassword(username, state.password, token);
    if (success) {
      createNotification({
        key : 'reset-password-success',
        message : localize(localeNotificationKeys.resetPassword.success),
        icon : (<Icon icon={settings.svgIcons.info} />),
        colour : settings.colours.alert.primary,
      });
      setSubmitted(true);
    } else {
      createNotification({
        key : 'reset-password-error',
        message : localize(localeNotificationKeys.resetPassword.error),
        icon : (<Icon icon={settings.svgIcons.info} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [username, token, state, resetPassword, createNotification]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={submitted
        ? localize(localeContentKeys.success)
        : `${localize(localeContentKeys.hello)} ${username}. ` +
          localize(localeContentKeys.body)
      }
    >
      <PasswordForm disabled={submitted} />
      <Button
        onClick={handleSubmit}
        disabled={
          !state ||
          !state.password ||
          !!Object.keys(errors).length ||
          submitted
        }
      >
        { localize(localeButtonKeys.save) }
      </Button>
    </Section>
  );
}

function PasswordReset({
  username,
  token,
} : PasswordResetProps) {
  return (
    <FormProvider init={{ password : '', confirm : '' }}>
      <PasswordResetControl username={username} token={token} />
    </FormProvider>
  );
}

export default PasswordReset;
