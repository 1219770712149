import React, { useCallback, useEffect, useState } from 'react';

import {
  Customer,
  CustomerIntegration,
  ExternalCustomer,
  Integration,
} from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useCustomers from '#hooks/useCustomers';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import CustomerIntegrationTable from
  '#components/customers/CustomerIntegrationTable';
import ExternalCustomerSearch
  from '#components/customers/ExternalCustomerSearch';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customers.customerIntegrations;
const localeTableKeys = locale.keys.tables.customerIntegrations;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.customers;

interface CustomerIntegrationsProps {
  customer : Customer;
}

function CustomerIntegrations({ customer } : CustomerIntegrationsProps) {
  const { openTab } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    refreshCustomer,
    createCustomerIntegration,
    deleteCustomerIntegration,
    syncCustomerIntegration,
    pushCustomerIntegration,
    pullCustomerIntegration,
  } = useCustomers();

  const [
    customerIntegrations,
    setCustomerIntegrations,
  ] = useState<CustomerIntegration[] | null>(null);
  const [
    removeTarget,
    setRemoveTarget,
  ] = useState<CustomerIntegration | null>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [manage, setManage] = useState<boolean>(false);
  const [integration, setIntegration] = useState<Integration | null>(null);

  const sync = useCallback(
    (customerIntegration : CustomerIntegration) => async () => {
      const success = await syncCustomerIntegration(customerIntegration);
      if (success) {
        createNotification({
          key : 'sync-customer-integration-success',
          message : localize(localeNotificationKeys.sync.success),
          icon : <Icon icon={settings.svgIcons.person} />,
          colour : settings.colours.alert.primary,
        });
      } else {
        createNotification({
          key : 'sync-customer-integration-error',
          message : localize(localeNotificationKeys.sync.error),
          icon : <Icon icon={settings.svgIcons.person} />,
          colour : settings.colours.alert.alert,
        });
      }
    },
    [syncCustomerIntegration, createNotification],
  );

  const push = useCallback(
    (customerIntegration : CustomerIntegration) => async () => {
      const success = await pushCustomerIntegration(customerIntegration);
      if (success) {
        createNotification({
          key : 'push-customer-integration-success',
          message : localize(localeNotificationKeys.push.success),
          icon : <Icon icon={settings.svgIcons.person} />,
          colour : settings.colours.alert.primary,
        });
      } else {
        createNotification({
          key : 'push-customer-integration-error',
          message : localize(localeNotificationKeys.push.error),
          icon : <Icon icon={settings.svgIcons.person} />,
          colour : settings.colours.alert.alert,
        });
      }
    },
    [pushCustomerIntegration, createNotification],
  );

  const pull = useCallback(
    (customerIntegration : CustomerIntegration) => async () => {
      const success = await pullCustomerIntegration(customerIntegration);
      if (success) {
        createNotification({
          key : 'pull-customer-integration-success',
          message : localize(localeNotificationKeys.pull.success),
          icon : <Icon icon={settings.svgIcons.person} />,
          colour : settings.colours.alert.primary,
        });
      } else {
        createNotification({
          key : 'pull-customer-integration-error',
          message : localize(localeNotificationKeys.pull.error),
          icon : <Icon icon={settings.svgIcons.person} />,
          colour : settings.colours.alert.alert,
        });
      }
    },
    [pullCustomerIntegration, createNotification],
  );

  const handleInitUnlink = useCallback(
    (customerIntegration : CustomerIntegration) => () => {
      setRemoveTarget(customerIntegration);
    },
    [setRemoveTarget],
  );

  const link = useCallback(
    (customerIntegration : ExternalCustomer) => async () => {
      const retrievedCustomer = await createCustomerIntegration(
        customer,
        customerIntegration,
      );
      if (!!retrievedCustomer) {
        createNotification({
          key : 'link-customer-integration-success',
          message : localize(localeNotificationKeys.link.success),
          icon : <Icon icon={settings.svgIcons.person} />,
          colour : settings.colours.alert.primary,
        });
      } else {
        createNotification({
          key : 'link-customer-integration-error',
          message : localize(localeNotificationKeys.link.error),
          icon : <Icon icon={settings.svgIcons.person} />,
          colour : settings.colours.alert.alert,
        });
      }
    },
    [createCustomerIntegration, customer, createNotification],
  );

  const handleCancelUnlink = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const unlink = useCallback(async () => {
    if (!removeTarget) return;
    const retrievedCustomer = await deleteCustomerIntegration(removeTarget);
    if (!!retrievedCustomer) {
      createNotification({
        key : 'unlink-customer-integration-success',
        message : localize(localeNotificationKeys.unlink.success),
        icon : <Icon icon={settings.svgIcons.person} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'unlink-customer-integration-error',
        message : localize(localeNotificationKeys.unlink.error),
        icon : <Icon icon={settings.svgIcons.person} />,
        colour : settings.colours.alert.alert,
      });
    }
    setRemoveTarget(null);
  }, [removeTarget, deleteCustomerIntegration, createNotification]);

  const view = useCallback((customerIntegration : CustomerIntegration) =>
    () => {
      openTab(`/customers/${customer.id}/` +
        `integrations/${customerIntegration.id}`);
    },
    [openTab, customer],
  );

  const openManage = useCallback(() => { setManage(true); }, [setManage]);
  const closeManage = useCallback(() => { setManage(false); }, [setManage]);

  const generateIntegrationActions = useCallback(
    (customerIntegration : CustomerIntegration) => (
      <TableActionCell>
        { !!manage
          ? (<Action
            label={localize(localeTableKeys.actions.remove)}
            onClick={handleInitUnlink(customerIntegration)}
            colour={settings.colours.button.alert}
            disabled={!!removeTarget}
          >
            <Icon
              icon={settings.svgIcons.linkOff}
            />
          </Action>)
          : <>
            <Action
              label={localize(localeTableKeys.actions.view)}
              onClick={view(customerIntegration)}
            >
              <Icon icon={settings.svgIcons.info} />
            </Action>
            <Action
              label={localize(localeTableKeys.actions.sync)}
              onClick={sync(customerIntegration)}
            >
              <Icon icon={settings.svgIcons.refresh} />
            </Action>
            <Action
              label={localize(localeTableKeys.actions.push)}
              onClick={push(customerIntegration)}
            >
              <Icon icon={settings.svgIcons.upload} />
            </Action>
            <Action
              label={localize(localeTableKeys.actions.pull)}
              onClick={pull(customerIntegration)}
            >
              <Icon icon={settings.svgIcons.download} />
            </Action>
          </>
        }
      </TableActionCell>
    ), [
      manage,
      removeTarget,
      sync,
      push,
      pull,
      view,
      handleInitUnlink,
    ]);

  const generateExternalActions = useCallback(
    (externalCustomer : ExternalCustomer) => (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.link)}
          onClick={link(externalCustomer)}
          colour={settings.colours.button.primary}
        >
          <Icon
            icon={settings.svgIcons.link}
          />
        </Action>
      </TableActionCell>
    ), [link],
  );

  const filterExternal = useCallback(
    (externalCustomers : ExternalCustomer[]) => {
      return externalCustomers.filter((ext) => {
        return !customerIntegrations?.some((customerIntegration) => {
          return customerIntegration.externalId === ext.externalId;
        });
      })
    },
    [customerIntegrations],
  );

  useEffect(() => {
    if (!customer.integrations) {
      if (!loaded && customer.id) {
        refreshCustomer(customer.id);
        setLoaded(true);
      }
      return;
    };
    setLoaded(true);
    setCustomerIntegrations(Object.values(customer.integrations));
  }, [customer, loaded, refreshCustomer]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { removeTarget && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.linkOff} />)}
          actions={(
            <>
              <Button onClick={unlink}>
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button onClick={handleCancelUnlink}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmUnlink) }
        </Banner>
      ) }
      <CustomerIntegrationTable
        customerIntegrations={customerIntegrations ?? []}
        generateActions={generateIntegrationActions}
      />
      { manage && (
        <Segment>
          <ExternalCustomerSearch
            integration={integration}
            setIntegration={setIntegration}
            filter={filterExternal}
            generateActions={generateExternalActions}
          />
        </Segment>
      ) }
      { manage
        ? (
          <Button
            onClick={closeManage}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.close) }
          </Button>
        ) : (
          <Button onClick={openManage}>
            { localize(localeButtonKeys.edit) }
          </Button>
        )
      }
    </Section>
  );
}

export default CustomerIntegrations;
