import React, { useCallback, useEffect, useState } from 'react';

import { Collection, Tag } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useOptions from '#hooks/useOptions';
import useTags from '#hooks/useTags';

import Banner from '#materials/Banner';
import Button from '#materials/Button';
import Icon from '#materials/Icon';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import { settings } from '#materials';
import { TableActionCell, Action } from '#materials/TableCell';

import TagTable from '#components/tags/TagTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productTags;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.tags;
const localeNotificationKeys = locale.keys.notifications.tags;

interface CollectionTagsProps {
  collection : Collection;
  onUpdate? (collection : Collection) : void;
}

function CollectionTags({ collection, onUpdate } : CollectionTagsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    addTagToCollection,
    removeTagFromCollection,
    getCollectionTags,
  } = useOptions();

  const { retrieveTags } = useTags();

  const [collectionTags, setCollectionTags] = useState<Tag[]>([]);
  const [
    availableCollectionTags,
    setAvailableCollectionTags
  ] = useState<Tag[]>([]);

  const [add, setAdd] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<Tag | null>(null);

  const retrieve = useCallback(async () => {
    if (!collection) return;

    const [newCollectionTags, newTags] = await Promise.all(
      [
        getCollectionTags(collection),
        retrieveTags(),
      ]
    );

    if (!newTags || !newCollectionTags) return;
    setCollectionTags(newCollectionTags);

    setAvailableCollectionTags(
      listRecords(newTags).filter(tag => !newCollectionTags.some(
        collectionTag => collectionTag.id === tag.id
      ))
    );
  }, [collection, getCollectionTags, retrieveTags]);

  const handleAdd = useCallback(() => {
    setAdd(true);
  }, []);

  const handleClose = useCallback(() => {
    setAdd(false);
  }, []);

  const handleView = useCallback((tag : Tag) => () => {
    if (!tag.id) return;
    navigate(`/tags/${tag.id}`);
  }, [navigate]);

  const handleAddTag = useCallback((tag : Tag) => async () => {
    const success = await addTagToCollection(collection, tag);
    if (onUpdate) onUpdate(collection);
    if (success) {
      createNotification({
        key : 'add-tag-success',
        message : localize(localeNotificationKeys.add.success),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.primary,
      });
    } else{
      createNotification({
        key : 'add-tag-error',
        message : localize(localeNotificationKeys.add.error),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [collection, onUpdate, createNotification, addTagToCollection]);

  const handleInitRemoveTag = useCallback((tag : Tag) => () => {
    setRemoveTarget(tag);
  }, [setRemoveTarget]);

  const handleCancelRemoveTag = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemoveTag = useCallback((tag : Tag) => async () => {
    const success = await removeTagFromCollection(collection, tag);
    if (onUpdate) onUpdate(collection);

    setRemoveTarget(null);
    if (success) {
      createNotification({
        key : 'remove-tag-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-tag-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : <Icon icon={settings.svgIcons.localOffer} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [collection, onUpdate, createNotification, removeTagFromCollection]);

  const generateRemoveActions = useCallback((tag : Tag) => {
    return (add
      ? <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.removeTag)}
          onClick={handleInitRemoveTag(tag)}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      </TableActionCell>
      : <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          onClick={handleView(tag)}
        >
          <Icon icon={settings.svgIcons.localOffer} />
        </Action>
      </TableActionCell>
    )
  }, [add, handleInitRemoveTag, handleView]);

  const generateAddActions = useCallback((tag : Tag) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.addTag)}
          onClick={handleAddTag(tag)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAddTag]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  useEffect(() => {
    if (!collectionTags.length) return;
  }, [collectionTags]);

  return (
    <>
      <Segment title={localize(localeContentKeys.title)} />
      { !!removeTarget &&
        <Banner
          icon={<Icon icon={settings.svgIcons.remove} />}
          actions={(
            <>
              <Button
                onClick={handleRemoveTag(removeTarget)}
              >
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button
                onClick={handleCancelRemoveTag}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmRemove) }
        </Banner>
      }
      { collectionTags.length
        ? <TagTable
          tags={collectionTags}
          generateActions={generateRemoveActions}
        />
        : <Text>{ localize(localeContentKeys.notFound) }</Text>
      }
      { add
        ? <>
          <Segment title={localize(localeContentKeys.availableTags)}>
            { availableCollectionTags.length
              ? <TagTable
                pageCount={5}
                tags={availableCollectionTags}
                generateActions={generateAddActions}
              />
              : <Text>{ localize(localeContentKeys.notFound) }</Text>
            }
            <Button
              onClick={handleClose}
              colour={settings.colours.button.alert}
            >
              { localize(localeButtonKeys.cancel) }
            </Button>
          </Segment>
        </>
        : <Button
          onClick={handleAdd}
        >
          { localize(localeButtonKeys.edit) }
        </Button>
      }
    </>
  );
}

export default CollectionTags;
