import React, { useCallback, useEffect, useState } from 'react';

import { Schedule } from '#types';

import useNotifications from '#hooks/useNotifications';
import useTimeSlots from '#hooks/useTimeSlots';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import { TableActionCell, Action } from '#materials/TableCell';
import Banner from '#materials/Banner';

import Section from '#components/dashboard/Section';
import CreateSchedule from '#components/schedules/CreateSchedule';
import ScheduleTable from '#components/schedules/ScheduleTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.schedules.scheduleIndex;
const localeTableKeys = locale.keys.tables.schedules;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.schedules;

interface ScheduleIndexProps {
  showHead? : boolean;
}

function ScheduleIndex({ showHead = true } : ScheduleIndexProps) {
  const { createNotification } = useNotifications();
  const {
    refreshSchedules,
    retrieveSchedules,
    deleteSchedule,
  } = useTimeSlots();

  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [creatingNew, setCreatingNew] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<Schedule | null>(null);

  const tableLength = schedules ? Object.keys(schedules).length : 0;

  const retrieve = useCallback(async () => {
    const newSchedules = await retrieveSchedules();
    if (newSchedules !== null) setSchedules(listRecords(newSchedules));
  }, [retrieveSchedules]);

  const refresh = useCallback(async () => {
    await refreshSchedules();
    await retrieve();
  }, [refreshSchedules, retrieve]);

  const handleRetrieve = useCallback(() => {
    refresh();
  }, [refresh]);

  function handleCreateNew() {
    setCreatingNew(true);
  }

  const handleCancelNew = useCallback(() => {
    setCreatingNew(false);
  }, [setCreatingNew]);

  const handleSave = useCallback(() => {
    setCreatingNew(false);
  }, [setCreatingNew]);

  const handleInitDelete = useCallback((schedule : Schedule) => () => {
    setDeleteTarget(schedule);
  }, [setDeleteTarget]);

  const handleCancelDelete = useCallback(() => {
    setDeleteTarget(null);
  }, [setDeleteTarget]);

  const handleConfrimDelete = useCallback(async() => {
    if (!deleteTarget) return;
    const success = await deleteSchedule(deleteTarget);
    if (success) {
      createNotification({
        key : 'delete-schedule-success',
        message : localize(localeNotificationKeys.delete.success),
        icon : (<Icon icon={settings.svgIcons.dateRange} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-schedule-error',
        message : localize(localeNotificationKeys.delete.error),
        icon : (<Icon icon={settings.svgIcons.dateRange} />),
        colour : settings.colours.alert.alert,
      });
    }
    setDeleteTarget(null);
  }, [deleteTarget, deleteSchedule, createNotification, setDeleteTarget]);

  const generateActions = useCallback((schedule : Schedule) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          href={`/scheduling/${schedule.id}`}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.dateRange} />
        </Action>
        <Action
          label={`${localize(localeTableKeys.actions.delete)} ${schedule.id})}`}
          onClick={handleInitDelete(schedule)}
          disabled={deleteTarget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete} />
        </Action>
      </TableActionCell>
    )
  }, [deleteTarget, handleInitDelete]);

  useEffect(() => {
    retrieve();
  }, [retrieve])

  return (
    <>
      { showHead &&
        <>
          <Section
            title={localize(localeContentKeys.index.title)}
            text={localize(localeContentKeys.body)}
          >
            <Button
              onClick={handleRetrieve}
            >
              { localize(localeButtonKeys.refresh) }
            </Button>
            <Button
              onClick={handleCreateNew}
            >
              { localize(localeButtonKeys.new) }
            </Button>
          </Section>
          { creatingNew &&
            <CreateSchedule onCancel={handleCancelNew} onSave={handleSave} />
          }
        </>
      }
      <Section
        title={localize(localeContentKeys.title)}
      >
        { !!deleteTarget && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.delete} />)}
            actions={(
              <>
                <Button
                  onClick={handleConfrimDelete}
                >
                  { localize(localeButtonKeys.delete) }
                </Button>
                <Button
                  onClick={handleCancelDelete}
                >
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.alert}
          >
            { localize(localeContentKeys.index.confirmDelete) }
          </Banner>
        ) }
        <ScheduleTable
          schedules={schedules}
          count={tableLength}
          generateActions={generateActions}
        />
      </Section>
    </>
  )
}

export default ScheduleIndex;
