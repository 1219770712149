import React, { useCallback, useEffect, useState } from 'react';

import { LineItem, Selection, DraftOrder } from '#types';

import Table from '#materials/Table';
import { CellElement } from '#materials/TableCell';

import SelectionRow, {
  SELECTION_TABLE_KEYS,
  SelectionTableKey,
  defaultSelectionTableKeys,
} from '#components/selections/SelectionRow';

export type { SelectionTableKey as TableKey };
export { SELECTION_TABLE_KEYS, defaultSelectionTableKeys };

interface SelectionTableProps {
  lineItem : LineItem;
  selections : Selection[];
  order? : DraftOrder;
  setSelection? : (selection : Selection) => void;
  extraRow? : React.ReactNode;
  tableKeys? : SelectionTableKey[];
  disabled? : boolean;
  showFulfilmment? : boolean;
  generateActions? : (selection : Selection) => CellElement;
}

function SelectionTable({
  lineItem,
  selections,
  order,
  tableKeys = defaultSelectionTableKeys,
  extraRow,
  disabled = false,
  showFulfilmment = false,
  setSelection,
  generateActions,
} : SelectionTableProps) {
  const generateRows = useCallback(() => {
    const rows : React.ReactNode[] = [];
    selections.forEach((selection) => {
      rows.push(
        <SelectionRow
          key={`${selection.id}`}
          lineItem={lineItem}
          selection={selection}
          order={order}
          tableKeys={tableKeys}
          disabled={disabled}
          showFulfilmment={showFulfilmment && disabled}
          setSelection={setSelection}
          generateActions={generateActions}
        />
      )
    });
    if (extraRow) {
      rows.push(<React.Fragment key={'extra'}>{ extraRow }</React.Fragment>);
    }
    return rows;
  }, [
    lineItem,
    selections,
    order,
    tableKeys,
    extraRow,
    disabled,
    showFulfilmment,
    setSelection,
    generateActions,
  ]);

  const [rows, setRows] = useState<React.ReactNode[]>(generateRows());

  useEffect(() => { setRows(generateRows()) }, [generateRows]);

  return (
    <Table
      head={null}
      rows={rows}
    />
  );
}

export default SelectionTable;
