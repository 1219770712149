import React from 'react';
import Checkbox from '@mui/material/Checkbox';

interface CheckboxInputProps {
  id : string,
  label? : string,
  checked : boolean;
  onChange : (checked : boolean) => void;
  disabled? : boolean;
}

function CheckboxInput({
  id,
  label,
  checked,
  onChange,
  disabled = false,
} : CheckboxInputProps) {

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.checked);
  }

  return (
    <Checkbox
      id={`Checkbox-input-${id}`}
      {...(!!label && { inputProps : { 'aria-label': label } })}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      sx={{ height : 34.8 }}
    />
  );
}

export default CheckboxInput;
