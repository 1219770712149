import React, { useCallback, useEffect, useState } from 'react';

import { Location } from '#types';

import useServices from '#hooks/useServices';

import Section from '#components/dashboard/Section';
import LocationsDetails from '#components/locations/LocationDetails';
import LocationImages from '#components/locations/LocationImages';
import LocationIntegrations from '#components/locations/LocationIntegrations';
import LocationServices from '#components/locations/LocationServices';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.locations.locationIndex;

interface LocationInfoProps {
  locationId : number;
}

function LocationInfo({ locationId } : LocationInfoProps) {
  const { retrieveLocation } = useServices();

  const [location, setLocation] = useState<Location | null>(null);

  const retrieve = useCallback(async () => {
    const location = await retrieveLocation(locationId);
    setLocation(location);
  }, [locationId, retrieveLocation, setLocation]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <>
      <Section
        title={`${location ? location?.name : ''} (#${locationId})`}
        text={location ? '' : localize(localeContentKeys.loading)}
      >
        { !!location &&
          <LocationsDetails location={location} />
        }
      </Section>
      { !!location &&
        <>
          <LocationImages location={location} />
          <LocationServices location={location} />
          <LocationIntegrations location={location} />
        </>
      }
    </>
  );
}

export default LocationInfo;
