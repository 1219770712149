import React, { useCallback, useEffect, useState } from 'react';

import { Adjustment } from '#types';

import useNotifications from '#hooks/useNotifications';
import useAdjustments from '#hooks/useAdjustments';

import { settings } from '#materials';
import Button from '#materials/Button';
import Icon from '#materials/Icon';
import { Action, TableActionCell } from '#materials/TableCell';
import Banner from '#materials/Banner';

import Section from '#components/dashboard/Section';
import AdjustmentsTable from '#components/adjustments/AdjustmentTable';
import CreateAdjustment from '#components/adjustments/CreateAdjustment';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.adjustments.adjustmentsIndex;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.taxes;
const localeNotificationKeys = locale.keys.notifications.adjustments;

function AdjustmentsIndex() {
	const {
    retrieveAdjustments,
    refreshAdjustments,
    deleteAdjustment,
  } = useAdjustments();
	const { createNotification } = useNotifications();

	const [adjustments, setAdjustments] = useState<Adjustment[]>([]);
  const [createNew, setCreateNew] = useState(false);
	const [deleteTarget, setDeleteTarget] = useState<Adjustment | null>(null);

  const retrieve = useCallback(async () => {
    const newAdjustments = await retrieveAdjustments();
    if(newAdjustments) {
      setAdjustments(listRecords(newAdjustments));
    }
  }, [retrieveAdjustments, setAdjustments]);

  const handleCreateNew = useCallback(() => {
    setCreateNew(true);
  }, [setCreateNew]);

	const handleSave = useCallback(() => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleCancel = useCallback(() => {
    setCreateNew(false);
  }, [setCreateNew]);

  const handleRefresh = useCallback(async () => {
		await refreshAdjustments()
  }, [refreshAdjustments])

	const handleInitDelete = useCallback((adjustment : Adjustment) => {
		return () => {
			setDeleteTarget(adjustment)
		}
	}, [setDeleteTarget])

	const handleDeleteAdjustment = useCallback(async () => {
		if(!deleteTarget) return;

		const success = await deleteAdjustment(deleteTarget)
		if(success) {
			createNotification({
				key : 'delete-adjustment-success',
				message : localize(localeNotificationKeys.delete.success),
				icon : <Icon icon={settings.svgIcons.loyalty} />,
				colour : settings.colours.alert.primary,
			})
		} else {
			createNotification({
				key : 'delete-adjustment-error',
				message : localize(localeNotificationKeys.delete.error),
				icon : <Icon icon={settings.svgIcons.loyalty} />,
				colour : settings.colours.alert.alert,
			})
		}
		setDeleteTarget(null)
	}, [createNotification, deleteAdjustment, deleteTarget])

	const handleCancelDelete = useCallback(() => {
		setDeleteTarget(null)
	}, [setDeleteTarget])

	const generateActions = useCallback((adjustment : Adjustment) => {
		return (
			<TableActionCell>
        <Action
					label={`${localize(localeTableKeys.actions.view)} ${adjustment.id}`}
					href={`/adjustments/${adjustment.id}`}
				>
					<Icon
					icon={settings.svgIcons.settings}
					/>
				</Action>
				<Action
					label={`${localize(localeTableKeys.actions.delete)}`}
					onClick={handleInitDelete(adjustment)}
					disabled={deleteTarget !== null}
					colour={settings.colours.button.alert}
				>
					<Icon
						icon={settings.svgIcons.delete}
					/>
				</Action>
			</TableActionCell>
		)
  }, [deleteTarget, handleInitDelete]);

	useEffect(() => { retrieve() }, [retrieve]);

	return (
		<>
			<Section
				title={localize(localeContentKeys.title)}
				text={localize(localeContentKeys.body)}
			>
        <Button onClick={handleRefresh}>
          { localize(localeButtonKeys.refresh) }
        </Button>
        <Button onClick={handleCreateNew} disabled={createNew}>
          { localize(localeButtonKeys.new) }
        </Button>
			</Section>
      {
        createNew && (
          <CreateAdjustment onCancel={handleCancel} onSave={handleSave} />
        )
      }
			<Section title={localize(localeContentKeys.index.title)}>
				{
					deleteTarget && (
						<Banner
							icon={<Icon icon={settings.svgIcons.delete} />}
							actions={(
								<>
									<Button onClick={handleDeleteAdjustment}>
										{ localize(localeButtonKeys.delete) }
									</Button>
									<Button onClick={handleCancelDelete}>
										{ localize(localeButtonKeys.cancel) }
									</Button>
								</>
							)}
							colour={settings.colours.alert.alert}
						>
							{ localize(localeContentKeys.index.confirmDelete)}
						</Banner>
					)
				}
				<AdjustmentsTable
					adjustments={adjustments}
					generateActions={generateActions}
				/>
			</Section>
		</>
	);
}

export default AdjustmentsIndex;
