import React, { useEffect, useState } from 'react';

import { Product, Assembly } from '#types';

import useOptions from '#hooks/useOptions';

import Section from '#components/dashboard/Section';
import AssemblyDetails from '#components/assemblies/AssemblyDetails';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productOptions;

interface ProductAssemblyProps {
  product : Product;
  title? : string;
  text? : string;
}

function ProductAssemblies({
  product,
  title = localize(localeContentKeys.title),
  text = localize(localeContentKeys.body)
} : ProductAssemblyProps) {
  const { getProductAssemblies } = useOptions();

  const [assemblies, setAssemblies] = useState<Assembly[]>([]);

  useEffect(() => {
    if (!product) setAssemblies([]);
    else setAssemblies(getProductAssemblies(product));
  }, [product, getProductAssemblies]);

  return (
    <>
      { Object.values(assemblies).map((assembly : Assembly) => {
        return (
          <Section key={assembly.id} title={title} text={text} >
            <AssemblyDetails product={product} assembly={assembly} />
          </Section>
        )
      }) }
    </>
  );
}

export default ProductAssemblies;
